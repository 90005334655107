import React, { Component } from 'react'
import { Polyline } from 'react-leaflet'
import * as L from 'leaflet'
require('../../lib/L.Symbol')

export default class LineaFlechas extends Component {
  componentDidMount () {
    const { refLinea, linea, focused } = this.props
    const polyline = refLinea.current.leafletElement
    const { map } = refLinea.current.props.leaflet
    const optionsSymbol = {
      pixelSize: focused ? 14 : 12,
      polygon: false,
      headAngle: 50,
      pathOptions: {
        fillOpacity: 1,
        weight: 3,
        color: linea.color,
        stroke: true
      },
      interactive: false
    }
    const patterns = [
      {
        offset: 0,
        repeat: linea.bloque.patron.patron,
        symbol: this.getSymbol(optionsSymbol)
      }

    ]
    this.decorator = L.polylineDecorator(polyline, {
      patterns: patterns
    })

    this.decorator.on('click', () => {
      linea.onClick(linea)
    })

    map.addLayer(this.decorator)
    if (focused) {
      this.animarFlechas(map, polyline, linea.bloque.patron.patron, linea.color)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { focused, linea, refLinea } = this.props
    const polyline = refLinea.current.leafletElement
    const { map } = refLinea.current.props.leaflet

    if (prevProps.focused !== focused) {
      if (focused) this.animarFlechas(map, polyline, linea.bloque.patron.patron, linea.color)
      else {
        if (this.timer) {
          clearInterval(this.timer)
        }
      }
    }
  }

  animarFlechas (mapa, linea, patron, color) {
    // console.info('ANIMAR')
    // Aseguramonos de parar calquera outra animación que puidera haber
    if (this.timer) {
      clearInterval(this.timer)
    }

    let arrayPoints = linea._latlngs.map(p => mapa.latLngToLayerPoint(p))
    let lengthPixels = L.GeometryUtil.length(arrayPoints)
    let maxOffset = lengthPixels > 200 ? 200 : lengthPixels
    // As variables para a animación podemos recalcula-las cada vez que se
    // faga zoom. Non e necesario recalcula-las siempre no bucle da animación.
    mapa.on('zoomend', () => {
      // console.info('zoom')
      arrayPoints = linea._latlngs.map(p => mapa.latLngToLayerPoint(p))
      lengthPixels = L.GeometryUtil.length(arrayPoints)
      maxOffset = lengthPixels > parseFloat(patron.split(' ')[0]) ? parseFloat(patron.split(' ')[0]) : lengthPixels
    })

    let offset = 0

    const optionsSymbol = {
      pixelSize: 12,
      polygon: false,
      headAngle: 50,
      pathOptions: {
        fillOpacity: 1,
        weight: 3,
        color: color,
        stroke: true
      },
      interactive: false
    }

    if (L.Symbol) {
      this.timer = setInterval(() => {
        const patterns = [
          {
            offset: offset,
            repeat: patron,
            symbol: this.getSymbol(optionsSymbol)
          }
        ]
        if (offset < maxOffset) {
          offset += 1
        } else {
          offset = 0
        }
        this.decorator.setPatterns(patterns)
      }, 40) // 40ms para 25fps
    }
  }

  getSymbol (opciones) {
    const { linea } = this.props
    switch (linea.bloque.patron.flechas_tipo) {
      case 'sa':
        return L.Symbol.arrowHeadSalidaArriba(opciones)
      case 'sb':
        return L.Symbol.arrowHeadSalidaAbajo(opciones)
      case 'ea':
        return L.Symbol.arrowHeadEntradaArriba(opciones)
      case 'eb':
        return L.Symbol.arrowHeadEntradaAbajo(opciones)
      case 'm':
        return L.Symbol.arrowHeadMango(opciones)
      default:
        return L.Symbol.arrowHead(opciones)
    }
  }

  componentWillUnmount () {
    const { refLinea } = this.props
    const { map } = refLinea.current.props.leaflet

    map.removeLayer(this.decorator)
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render () {
    const { linea, refLinea, children } = this.props
    return (
      <Polyline
        color={linea.color} weight={0} ref={refLinea}
        dashArray={linea.bloque.patron.patron} opacity={linea.opacidad}
        positions={linea.coordenadas.coordinates.map(row => [row[1], row[0]])}
        onClick={() => linea.onClick(linea)}
      >
        {children}
      </Polyline>
    )
  }
}
