export const ACTION_TYPES = {
  SET_RECORRIDO_SELECCIONADO: 'SET_RECORRIDO_SELECCIONADO'
}

function setRecorridoSeleccionado (recorrido) {
  return {
    type: ACTION_TYPES.SET_RECORRIDO_SELECCIONADO,
    recorrido
  }
}

export const actions = {
  setRecorridoSeleccionado
}
