import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import * as turf from '@turf/turf'
import { Link } from 'react-router-dom'
import * as L from 'leaflet'

import { fetchRemoteUsuario } from '../actions/usuario'
import { fetchRemoteLista } from '../actions/lista'
import { REQUEST_STATUS } from '../constants/state'
import NotFound from '../components/NotFound'
import { Helmet } from 'react-helmet'
import logo from '../lib/images/imagotipo-pq.png'
import { fetchRemoteRecorridos } from '../actions/recorrido'
import RecorridoListCanal from '../components/canal/RecorridoListCanal'
import MapaPortada from '../components/home/MapaPortada'
import { actions as actionsVisorLista } from '../actions/visorLista'

import './ListaScreen.css'
import Compartir from '../components/Compartir'

class ListaScreen extends Component {
  componentDidMount () {
    const { match, dispatch } = this.props

    dispatch(actionsVisorLista.recorridoMouseout())
    dispatch(fetchRemoteUsuario())
    if (match && match.params && match.params.id) {
      dispatch(fetchRemoteLista(match.params.id))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { dispatch, lista } = this.props

    if (!prevProps.listaCargada && this.props.listaCargada) {
      const fields = [
        'id', 'nombre', 'descripcion', 'unidad_distancia', 'captura', 'proyecto_nombre', 'deporte', 'sectores',
        'superficie', 'dificultad', 'distancia_metros', 'centro_geometrico', 'deporte_icono', 'captura_2x1'
      ]
      dispatch(fetchRemoteRecorridos(fields, { listas__lista: lista.id, visible: true }))
    }
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  metrosToUnitsRecorrido (recorrido, distancia) {
    switch (recorrido.unidad_distancia) {
      case 'km':
        return this.toFixed((distancia / 1000), 2) + ' km'
      case 'mi':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'miles'), 2) + ' mi'
      case 'ft':
        return turf.convertLength(distancia, 'meters', 'feet').toFixed(0) + ' ft'
      default:
        return distancia.toFixed(0) + ' m'
    }
  }

  getBoundsMapa () {
    const { recorridos } = this.props
    if (recorridos.length) {
      const bounds = L.latLngBounds()
      recorridos.forEach(rec => {
        if (rec.centro_geometrico) {
          bounds.extend(L.latLng(rec.centro_geometrico.coordinates[1], rec.centro_geometrico.coordinates[0]))
        } else {
          return null
        }
      })
      if (!bounds._northEast || !bounds._southWest) {
        return null
      } else {
        return bounds
      }
    }
    return null
  }

  onHoverRecorridoItem (rec) {
    const { dispatch } = this.props
    if (rec) {
      dispatch(actionsVisorLista.recorridoMouseover(rec))
    } else {
      dispatch(actionsVisorLista.recorridoMouseout())
    }
  }

  handleClickCompartirMostrar () {
    const { dispatch } = this.props
    dispatch(actionsVisorLista.compartirMostrar())
  }

  handleClickCompartirOcultar () {
    const { dispatch } = this.props
    dispatch(actionsVisorLista.compartirOcultar())
  }

  render () {
    const { lista, listaError, listaCargada, recorridos, visorLista } = this.props

    if (!listaCargada && !listaError) {
      return <div><Trans>Cargando...</Trans></div>
    } else if (listaError) {
      return <NotFound error={listaError} />
    }

    if (visorLista.compartirVisible) {
      return <Compartir clickCerrar={this.handleClickCompartirOcultar.bind(this)} />
    }

    return (
      <>
        <Helmet>
          <html lang='es' />
          <title>{lista.nombre} - RaceMapp</title>
          <Trans
            name='description'
            content='Lista de recorridos'
          />
        </Helmet>
        <div className='lista-subcabecera'>
          <button className='perfil__btn-share btn' onClick={this.handleClickCompartirMostrar.bind(this)}>
            <i className='fas fa-share-alt' />
          </button>
          <Link className='perfil__btn-cerrar btn' to={'/canal/' + lista.canal}>
            <i className='fas fa-times' />
          </Link>
          <div className='lista-subcabecera__avatar'>
            <img src={lista.logo} alt='Avatar usuario canal' />
          </div>
          <div className='lista-subcabecera__titulo'>
            <h2>{lista.nombre}</h2>
            <p><Link className='lista-subcabecera__link' to={'/canal/' + lista.canal}>{lista.canal_nombre}</Link></p>
          </div>
        </div>
        <div className='container lista-contenido'>
          <div className='row'>
            <div className='col-12 col-md-6 lista__col-mapa'>
              <MapaPortada
                recorridos={recorridos}
                bounds={this.getBoundsMapa()}
                recorridoHovered={visorLista.recorridoHovered}
                lista={lista}
              />
              <Link to='/'>
                <img className='lista__logo' alt='logotipo' src={logo} />
              </Link>
            </div>
            <div className='col-12 col-md-6 lista__col-listado'>
              <RecorridoListCanal
                recorridos={recorridos}
                metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
                onHoverRecorridoItem={this.onHoverRecorridoItem.bind(this)}
                lista={lista}
              />
            </div>
          </div>
        </div>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.usuario.data,
  lista: state.lista.data,
  listaCargada: state.lista.status === REQUEST_STATUS.SUCCEEDED,
  listaError: state.lista.error,
  recorridos: state.recorridos.data,
  visorLista: state.visorLista
})

export default connect(mapStateToProps)(ListaScreen)
