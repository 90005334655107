import { ACTION_TYPES } from '../actions/canal'
import { initialState, REQUEST_STATUS } from '../constants/state'

export function canalReducer (state = initialState.canal, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CANAL_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }
    case ACTION_TYPES.FETCH_CANAL_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.canal
      }
    case ACTION_TYPES.FETCH_CANAL_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return {
        ...state
      }
  }
}
