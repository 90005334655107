import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/notificacion'

export function notificacionesReducer (state = initialState.notificaciones, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_NOTIFICACIONES_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        error: null
      }

    case ACTION_TYPES.FETCH_NOTIFICACIONES_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.notificaciones
      }

    case ACTION_TYPES.FETCH_NOTIFICACIONES_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    case ACTION_TYPES.UPDATE_NOTIFICACION_SUCCESS:
      return {
        ...state,
        data: state.data.filter(n => n.id !== action.notificacion.id).concat(action.notificacion)
      }

    default:
      return {
        ...state
      }
  }
}

export function notificacionUpdateReducer (state = initialState.notificacionUpdate, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_NOTIFICACION_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.UPDATE_NOTIFICACION_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.notificacion
      }

    case ACTION_TYPES.UPDATE_NOTIFICACION_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}
