import React from 'react'
import './TopHorizontalSlider.css'

export default function TopHorizontalSlider (props) {
  const { mapa, mapas } = props

  return (
    <div className='top-slider'>
      <div className='top-slider__left'>
        <button className='top-slider__button' onClick={props.handleClickMapaAnterior}>
          <i className='fas fa-chevron-left' />
        </button>
      </div>
      <div className='top-slider__center'>
        <div className='top-slider__mapa'>{mapa.tipo.titulo}</div>
        <ul className='top-slider__dots'>
          {mapas.map(m => {
            if (m.id === mapa.id) {
              return (
                <li key={m.id}>
                  <button className='top-slider__dot top-slider__dot--current' />
                </li>
              )
            } else {
              return (
                <li key={m.id}>
                  <button className='top-slider__dot' onClick={() => props.handleClickOtroMapa(m)} />
                </li>
              )
            }
          })}
        </ul>
      </div>
      <div className='top-slider__right'>
        <button className='top-slider__button' onClick={props.handleClickMapaSiguiente}>
          <i className='fas fa-chevron-right' />
        </button>
      </div>
    </div>
  )
}
