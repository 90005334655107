export const ACTION_TYPES = {
  SET_LAST_SCREEN: 'SET_LAST_SCREEN_PERSONAL',
  SET_LOCATION: 'SET_LOCATION_PERSONAL',
  ADD_CAPA_OCULTA: 'ADD_CAPA_OCULTA_PERSONAL',
  REMOVE_CAPA_OCULTA: 'REMOVE_CAPA_OCULTA_PERSONAL',
  RESET_CAPAS_OCULTAS: 'RESET_CAPAS_OCULTAS_PERSONAL',
  LOCALIZACION_DESCENTRADA_ON: 'LOCALIZACION_DESCENTRADA_ON_PERSONAL',
  LOCALIZACION_DESCENTRADA_OFF: 'LOCALIZACION_DESCENTRADA_OFF_PERSONAL',
  SET_MAP_BOUNDS: 'SET_MAP_BOUNDS_PERSONAL',
  COMPARTIR_MOSTRAR: 'COMPARTIR_MOSTRAR_PERSONAL',
  COMPARTIR_OCULTAR: 'COMPARTIR_OCULTAR_PERSONAL',
  MODAL_DESCRIPCION_OCULTAR: 'MODAL_DESCRIPCION_OCULTAR',
  MODAL_DESCRIPCION_MOSTRAR: 'MODAL_DESCRIPCION_MOSTRAR',
  SELECCIONAR_CAPA_FONDO: 'SELECCIONAR_CAPA_FONDO_PERSONAL',
  SUBMENU_HERRAMIENTAS_OCULTAR: 'SUBMENU_HERRAMIENTAS_OCULTAR_PERSONAL',
  SUBMENU_HERRAMIENTAS_MOSTRAR: 'SUBMENU_HERRAMIENTAS_MOSTRAR_PERSONAL',
  MODAL_CAMBIAR_VISTA_MOSTRAR: 'MODAL_CAMBIAR_VISTA_MOSTRAR_PERSONAL',
  MODAL_CAMBIAR_VISTA_OCULTAR: 'MODAL_CAMBIAR_VISTA_OCULTAR_PERSONAL',
  SET_PDI_FOCUSED: 'SET_PDI_FOCUSED_PERSONAL',
  SET_PDI_DESTACADO: 'SET_PDI_DESTACADO_PERSONAL',
  MODAL_PDIS_MOSTRAR: 'MODAL_PDIS_MOSTRAR_PERSONAL',
  MODAL_PDIS_OCULTAR: 'MODAL_PDIS_OCULTAR_PERSONAL',
  MAP_ZOOM_CHANGED: 'MAP_ZOOM_CHANGED_PERSONAL',
  SET_FILTRO_PDIS: 'SET_FILTRO_PDIS_PERSONAL',
  MAPA_PDIS_MOSTRAR: 'MAPA_PDIS_MOSTRAR',
  MAPA_PDIS_OCULTAR: 'MAPA_PDIS_OCULTAR',
  MODAL_PERSONAL_MOSTRAR: 'MODAL_PERSONAL_MOSTRAR',
  MODAL_PERSONAL_OCULTAR: 'MODAL_PERSONAL_OCULTAR',
  SET_FILTRO_MODAL_PERSONAL: 'SET_FILTRO_MODAL_PERSONAL'
}

function setLastScreen (screen = null) {
  return {
    type: ACTION_TYPES.SET_LAST_SCREEN,
    screen
  }
}

function setLocation (location) {
  return {
    type: ACTION_TYPES.SET_LOCATION,
    location
  }
}

function addCapaOculta (capa) {
  return {
    type: ACTION_TYPES.ADD_CAPA_OCULTA,
    capa
  }
}

function removeCapaOculta (capa) {
  return {
    type: ACTION_TYPES.REMOVE_CAPA_OCULTA,
    capa
  }
}

function resetCapasOcultas () {
  return {
    type: ACTION_TYPES.RESET_CAPAS_OCULTAS
  }
}

function localizacionDescentradaOn () {
  return {
    type: ACTION_TYPES.LOCALIZACION_DESCENTRADA_ON
  }
}

function localizacionDescentradaOff () {
  return {
    type: ACTION_TYPES.LOCALIZACION_DESCENTRADA_OFF
  }
}

function setMapBounds (bounds) {
  console.log(bounds)
  return {
    type: ACTION_TYPES.SET_MAP_BOUNDS,
    bounds
  }
}

function compartirMostrar () {
  return {
    type: ACTION_TYPES.COMPARTIR_MOSTRAR
  }
}

function compartirOcultar () {
  return {
    type: ACTION_TYPES.COMPARTIR_OCULTAR
  }
}

function modalDescripcionMostrar () {
  return {
    type: ACTION_TYPES.MODAL_DESCRIPCION_MOSTRAR
  }
}

function modalDescripcionOcultar () {
  return {
    type: ACTION_TYPES.MODAL_DESCRIPCION_OCULTAR
  }
}

function seleccionarCapaFondo (capa) {
  return {
    type: ACTION_TYPES.SELECCIONAR_CAPA_FONDO,
    capa
  }
}

function submenuHerramientasMostrar () {
  return {
    type: ACTION_TYPES.SUBMENU_HERRAMIENTAS_MOSTRAR
  }
}

function submenuHerramientasOcultar () {
  return {
    type: ACTION_TYPES.SUBMENU_HERRAMIENTAS_OCULTAR
  }
}

function modalCambiarVistaMostrar () {
  return {
    type: ACTION_TYPES.MODAL_CAMBIAR_VISTA_MOSTRAR
  }
}

function modalCambiarVistaOcultar () {
  return {
    type: ACTION_TYPES.MODAL_CAMBIAR_VISTA_OCULTAR
  }
}

function setPdiFocused (pdi) {
  return {
    type: ACTION_TYPES.SET_PDI_FOCUSED,
    pdi
  }
}

function setPdiDestacado (pdi = null) {
  return {
    type: ACTION_TYPES.SET_PDI_DESTACADO,
    pdi
  }
}

function modalPdisMostrar () {
  return {
    type: ACTION_TYPES.MODAL_PDIS_MOSTRAR
  }
}

function modalPdisOcultar () {
  return {
    type: ACTION_TYPES.MODAL_PDIS_OCULTAR
  }
}

function mapZoomChanged (zoom) {
  return {
    type: ACTION_TYPES.MAP_ZOOM_CHANGED,
    zoom
  }
}

function setFiltroPdis (filtro) {
  return {
    type: ACTION_TYPES.SET_FILTRO_PDIS,
    filtro
  }
}

function mapaPdisMostrar () {
  return {
    type: ACTION_TYPES.MAPA_PDIS_MOSTRAR
  }
}

function mapaPdisOcultar () {
  return {
    type: ACTION_TYPES.MAPA_PDIS_OCULTAR
  }
}

function modalPersonalMostrar () {
  return {
    type: ACTION_TYPES.MODAL_PERSONAL_MOSTRAR
  }
}

function modalPersonalOcultar () {
  return {
    type: ACTION_TYPES.MODAL_PERSONAL_OCULTAR
  }
}

function setFiltroModalPersonal (filtro) {
  return {
    type: ACTION_TYPES.SET_FILTRO_MODAL_PERSONAL,
    filtro
  }
}

export const actions = {
  setLastScreen,
  setLocation,
  addCapaOculta,
  removeCapaOculta,
  resetCapasOcultas,
  localizacionDescentradaOn,
  localizacionDescentradaOff,
  setMapBounds,
  compartirMostrar,
  compartirOcultar,
  modalDescripcionMostrar,
  modalDescripcionOcultar,
  seleccionarCapaFondo,
  submenuHerramientasOcultar,
  submenuHerramientasMostrar,
  modalCambiarVistaMostrar,
  modalCambiarVistaOcultar,
  setPdiFocused,
  setPdiDestacado,
  modalPdisMostrar,
  modalPdisOcultar,
  mapZoomChanged,
  setFiltroPdis,
  mapaPdisMostrar,
  mapaPdisOcultar,
  modalPersonalMostrar,
  modalPersonalOcultar,
  setFiltroModalPersonal
}
