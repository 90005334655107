import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/visor'
import { ACTION_TYPES as ACTION_TYPES_REC } from '../actions/recorrido'
import * as turf from '@turf/turf'

export default function visorReducer (state = initialState.visor, action) {
  // console.info(state.distanciaTrayectoAnimada, action)
  switch (action.type) {
    case ACTION_TYPES.MODAL_ESQUEMA_MOSTRAR:
      return {
        ...state,
        modalEsquemaVisible: true
      }
    case ACTION_TYPES.MODAL_ESQUEMA_OCULTAR:
      return {
        ...state,
        modalEsquemaVisible: false
      }

    case ACTION_TYPES.MODAL_PDIS_MOSTRAR:
      return {
        ...state,
        modalPdisVisible: true,
        filtroPdisRecorrido: ''
      }
    case ACTION_TYPES.MODAL_PDIS_OCULTAR:
      return {
        ...state,
        modalPdisVisible: false
      }

    case ACTION_TYPES.MODAL_PDIS_PROYECTO_MOSTRAR:
      return {
        ...state,
        filtroPdis: '',
        modalPdisProyectoVisible: true
      }

    case ACTION_TYPES.MODAL_PDIS_PROYECTO_OCULTAR:
      return {
        ...state,
        modalPdisProyectoVisible: false
      }

    case ACTION_TYPES.MODAL_PDI_SELECCIONADO_MOSTRAR:
      return {
        ...state,
        modalPdiSeleccionado: action.modalPdiSeleccionado
      }
    case ACTION_TYPES.MODAL_PDI_SELECCIONADO_OCULTAR:
      return {
        ...state,
        modalPdiSeleccionado: null
      }

    case ACTION_TYPES.MODAL_PERFILES_MOSTRAR:
      return {
        ...state,
        modalPerfilVisible: true
      }
    case ACTION_TYPES.MODAL_PERFILES_OCULTAR:
      return {
        ...state,
        modalPerfilVisible: false
      }

    case ACTION_TYPES.SUBMENU_AJUSTES_VISUALES_MOSTRAR:
      return {
        ...state,
        submenuAjustesVisualesVisible: true
      }
    case ACTION_TYPES.SUBMENU_AJUSTES_VISUALES_OCULTAR:
      return {
        ...state,
        submenuAjustesVisualesVisible: false
      }

    case ACTION_TYPES.SUBMENU_PDIS_MOSTRAR:
      return {
        ...state,
        submenuPdisVisible: true
      }
    case ACTION_TYPES.SUBMENU_PDIS_OCULTAR:
      return {
        ...state,
        submenuPdisVisible: false
      }

    case ACTION_TYPES.SET_MAP_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }

    case ACTION_TYPES.MAPA_PDIS_PROYECTO_MOSTRAR:
      return {
        ...state,
        mapaPdisProyectoVisible: true
      }

    case ACTION_TYPES.MAPA_PDIS_PROYECTO_OCULTAR:
      return {
        ...state,
        mapaPdisProyectoVisible: false
      }

    case ACTION_TYPES.MAPA_PDIS_RECORRIDO_MOSTRAR:
      return {
        ...state,
        mapaPdisRecorridoVisible: true
      }

    case ACTION_TYPES.MAPA_PDIS_RECORRIDO_OCULTAR:
      return {
        ...state,
        mapaPdisRecorridoVisible: false
      }

    case ACTION_TYPES.COMPARTIR_MOSTRAR:
      return {
        ...state,
        compartirVisible: true
      }

    case ACTION_TYPES.COMPARTIR_OCULTAR:
      return {
        ...state,
        compartirVisible: false
      }

    case ACTION_TYPES.COMPARTIR_INSERTAR_TOGGLE:
      return {
        ...state,
        compartirInsertarVisible: !state.compartirInsertarVisible
      }

    case ACTION_TYPES.MODAL_CAMBIAR_VISTA_MOSTRAR:
      return {
        ...state,
        modalCambiarVistaVisible: true
      }

    case ACTION_TYPES.MODAL_CAMBIAR_VISTA_OCULTAR:
      return {
        ...state,
        modalCambiarVistaVisible: false
      }

    case ACTION_TYPES.SELECCIONAR_CAPA_FONDO:
      return {
        ...state,
        capaFondo: action.capa
      }

    case ACTION_TYPES.SELECCIONAR_PUNTO_KM:
      return {
        ...state,
        modalPuntosKmSeleccionado: action.punto
      }

    case ACTION_TYPES.SET_GRAFICO_DOMAIN:
      return {
        ...state,
        graficoDomain: action.domain
      }

    case ACTION_TYPES.SET_GRAFICO_ZOOM_DOMAIN:
      return {
        ...state,
        graficoZoomDomain: action.domain
      }

    case ACTION_TYPES.SET_LAST_SCREEN:
      return {
        ...state,
        lastScreen: action.lastScreen
      }

    case ACTION_TYPES.SET_PDI_FOCUSED:
      return {
        ...state,
        pdiFocused: action.pdi
      }

    case ACTION_TYPES.MAP_ZOOM_CHANGED:
      return {
        ...state,
        mapZoom: action.zoom
      }

    case ACTION_TYPES.SUBMENU_HERRAMIENTAS_MOSTRAR:
      return {
        ...state,
        submenuHerramientasVisible: true
      }

    case ACTION_TYPES.SUBMENU_HERRAMIENTAS_OCULTAR:
      return {
        ...state,
        submenuHerramientasVisible: false
      }

    case ACTION_TYPES_REC.FETCH_RECORRIDO_SUCCESS:
      return {
        ...state,
        pdiOrden: action.recorrido ? getOrdenPdis(action.recorrido) : null,
        zoomAnimacion: action.recorrido ? getZoomAnimacion(action.recorrido) : null,
        velocidadAnimacion: action.recorrido ? getVelocidadAnimacion(action.recorrido) : null,
        animando: false,
        animacionPausada: false,
        repeticionEnAnimacion: 1,
        trayectosYaAnimados: [],
        distanciaTrayectoAnimada: 0,
        multiplicadorVelocidadAnimacion: 1,
        animacionBucle: false
      }

    case ACTION_TYPES.SET_INDEX_PDI_ORDEN:
      return {
        ...state,
        indexPdiOrden: action.index
      }

    case ACTION_TYPES.ANIMACION_ON:
      return {
        ...state,
        animando: true,
        animacionPausada: !action.noPausa,
        animacionPausadaAuto: false,
        repeticionEnAnimacion: 1,
        trayectosYaAnimados: [],
        distanciaTrayectoAnimada: 0,
        puntosAnimados: []
      }

    case ACTION_TYPES.ANIMACION_OFF:
      return {
        ...state,
        animando: false,
        repeticionEnAnimacion: 1,
        trayectosYaAnimados: [],
        distanciaTrayectoAnimada: 0
      }

    case ACTION_TYPES.SET_ESQUEMA_ANIMACION:
      return {
        ...state,
        distanciaTrayectoAnimada: 0,
        esquemaEnAnimacion: action.esquema,
        repeticionEnAnimacion: 1,
        velocidadAnimacion: action.velocidad ? action.velocidad : state.velocidadAnimacion,
        zoomAnimacion: action.zoom ? action.zoom : state.zoomAnimacion
      }

    case ACTION_TYPES.SET_REPETICION_ANIMACION:
      return {
        ...state,
        repeticionEnAnimacion: action.repeticion,
        distanciaTrayectoAnimada: 0
      }

    case ACTION_TYPES.SET_DISTANCIA_ANIMADA:
      return {
        ...state,
        distanciaTrayectoAnimada: action.distancia
      }

    case ACTION_TYPES.ADD_TRAYECTO_YA_ANIMADO:
      return {
        ...state,
        trayectosYaAnimados: state.trayectosYaAnimados.concat(action.trayectoId)
      }

    case ACTION_TYPES.ANIMACION_PAUSADA_ON:
      return {
        ...state,
        animacionPausada: true
      }

    case ACTION_TYPES.ANIMACION_PAUSADA_OFF:
      return {
        ...state,
        animacionPausada: false
      }

    case ACTION_TYPES.SET_MULTIPLICADOR_VELOCIDAD_ANIM:
      return {
        ...state,
        multiplicadorVelocidadAnimacion: action.multiplo
      }

    case ACTION_TYPES.SUBMENU_VELOCIDAD_OCULTAR:
      return {
        ...state,
        submenuVelocidadVisible: false,
        animacionPausada: false
      }

    case ACTION_TYPES.SUBMENU_VELOCIDAD_MOSTRAR:
      return {
        ...state,
        submenuVelocidadVisible: true,
        animacionPausada: true
      }

    case ACTION_TYPES.ANIMACION_BUCLE_ON:
      return {
        ...state,
        animacionBucle: true
      }

    case ACTION_TYPES.ANIMACION_BUCLE_OFF:
      return {
        ...state,
        animacionBucle: false
      }

    case ACTION_TYPES.ANIMACION_PAUSADA_AUTO_ON:
      return {
        ...state,
        animacionPausadaAuto: true
      }

    case ACTION_TYPES.ANIMACION_PAUSADA_AUTO_OFF:
      return {
        ...state,
        animacionPausadaAuto: false
      }

    case ACTION_TYPES.SET_FILTRO_PDIS:
      return {
        ...state,
        filtroPdis: action.filtro
      }

    case ACTION_TYPES.SET_FILTRO_PDIS_RECORRIDO:
      return {
        ...state,
        filtroPdisRecorrido: action.filtro
      }

    case ACTION_TYPES.SET_PDI_DESTACADO:
      return {
        ...state,
        pdiDestacado: action.pdi
      }

    case ACTION_TYPES.ADD_PUNTO_ANIMADO:
      return {
        ...state,
        puntosAnimados: state.puntosAnimados.concat(action.punto)
      }

    case ACTION_TYPES.SET_DISTANCIAS_PUNTOS:
      return {
        ...state,
        distanciasPuntos: action.distancias
      }

    case ACTION_TYPES.SET_SIGUIENTE_DISTANCIA_ANIMADA:
      return {
        ...state,
        distanciaTrayectoAnimada: state.distanciaTrayectoAnimada + (state.velocidadAnimacion * state.multiplicadorVelocidadAnimacion)
      }

    case ACTION_TYPES.SET_LOCATION:
      if (action.location) action.location.id = state.location ? state.location.id + 1 : 0
      return {
        ...state,
        location: action.location,
        localizacionDescentrada: !action.location ? false : state.localizacionDescentrada
      }

    case ACTION_TYPES.ADD_CAPA_OCULTA:
      return {
        ...state,
        capasOcultas: state.capasOcultas.concat(action.capa)
      }

    case ACTION_TYPES.REMOVE_CAPA_OCULTA:
      return {
        ...state,
        capasOcultas: state.capasOcultas.filter(c => c.id !== action.capa.id)
      }

    case ACTION_TYPES.RESET_CAPAS_OCULTAS:
      return {
        ...state,
        capasOcultas: []
      }

    case ACTION_TYPES.LOCALIZACION_DESCENTRADA_ON:
      return {
        ...state,
        localizacionDescentrada: true
      }

    case ACTION_TYPES.LOCALIZACION_DESCENTRADA_OFF:
      return {
        ...state,
        localizacionDescentrada: false
      }

    default:
      return {
        ...state
      }
  }
}

function getOrdenPdis (recorrido) {
  let orden = []
  let distAcum = 0
  recorrido.sectores.forEach(sec => {
    if (sec.id) {
      sec.puntos_km.forEach(pk =>
        orden.push({ punto: pk.punto, dist: distAcum + toMetros(pk.distancia_oficial, recorrido) }))
      sec.esquemas.forEach(esq => {
        const trayecto = sec.trayectos.filter(tr => tr.id === esq.trayecto.id)
        if (trayecto.length) {
          for (let i = 0; i < esq.repeticiones; i++) {
            const ordenEsquema = []
            const puntos = sec.pdis_recorrido.filter(pdi => pdi.trayecto_ancla === esq.trayecto.id)
            for (const i in puntos) {
              const p = puntos[i]
              const dist = turf.length(turf.lineSlice(
                trayecto[0].coordenadas.coordinates[0], p.coordenadas.coordinates, trayecto[0].coordenadas
              ), { units: 'meters' })
              ordenEsquema.push({ punto: p, dist: distAcum + dist })
            }
            orden = orden.concat(ordenEsquema)
            distAcum += esq.trayecto.distancia_metros
          }
        }
      })
    }
  })
  return orden.sort((a, b) => a.dist - b.dist).map(ptOrden => ptOrden.punto.id)
}

function toMetros (distancia, recorrido) {
  switch (recorrido.unidad_distancia) {
    case 'km':
      return distancia * 1000
    case 'mi':
      return turf.convertLength(distancia, 'miles', 'meters')
    case 'ft':
      return turf.convertLength(distancia, 'feet', 'meters')
    default:
      return distancia
  }
}

function getZoomAnimacion (recorrido) {
  if (!recorrido.sectores[0].id) {
    return null
  }
  let trayectos = []
  recorrido.sectores.forEach(sec => {
    trayectos = trayectos.concat(sec.trayectos.map(tr => turf.envelope(tr.coordenadas)))
  })
  let coords = []
  trayectos.forEach(trEnv => {
    trEnv.geometry.coordinates.forEach(c => {
      coords = coords.concat(c)
    })
  })
  const envelopeKm2 = turf.area(turf.envelope(turf.lineString(coords))) / 10000
  let zoom = 11
  if (envelopeKm2 > 100000000) {
    zoom = 11
  } else if (envelopeKm2 > 10000000) {
    zoom = 12
  } else if (envelopeKm2 > 1000000) {
    zoom = 13
  } else if (envelopeKm2 > 100000) {
    zoom = 14
  } else if (envelopeKm2 > 10000) {
    zoom = 15
  } else if (envelopeKm2 > 1000) {
    zoom = 16
  } else if (envelopeKm2 > 100) {
    zoom = 17
  } else if (envelopeKm2 > 10) {
    zoom = 18
  } else {
    zoom = 19
  }
  if (window.innerWidth < 426) {
    zoom -= 1
  }
  return zoom
}

// Devolve a velocidade en metros/frame
function getVelocidadAnimacion (recorrido) {
  if (!recorrido.sectores[0].id) {
    return null
  }
  let dist = 0
  recorrido.sectores.forEach(sec => {
    dist += sec.esquemas.map(esq => esq.trayecto.distancia_metros).reduce((a, b) => a + b, 0)
  })
  let velocidad = Math.floor(dist / 60 / 30)
  if (velocidad > 100) {
    velocidad = 100
  } else if (velocidad < 20) {
    velocidad = 5
  }
  if (window.innerWidth < 426 && velocidad > 35) {
    velocidad -= 30
  }
  return velocidad
}
