import React from 'react'
import './VideosPortada.css'

export default class VideosPortada extends React.Component {
  constructor () {
    super()
    this.iframeRef = React.createRef()
  }

  render () {
    // console.info(props)
    const { portada, handleClick } = this.props

    let videoPrincipal = null
    if (portada.videos) {
      videoPrincipal = portada.videos[portada.videoSeleccionado]
    }

    return (
      <div className='videoportada py-5 my-5'>
        <div className='row justify-content-center py-3'>
          <div className='col-12 col-md-4 text-center mb-3 mb-md-0'>
            {videoPrincipal &&
              <iframe
                ref={this.iframeRef}
                style={{ width: 338, height: 607 }}
                src={videoPrincipal.url}
                title={videoPrincipal.titulo}
                frameBorder='0'
                allow='autoplay; clipboard-write; encrypted-media; picture-in-picture'
                allowFullScreen
              />}
          </div>
          <div className='col-12 col-lg-8 videoportada_lista'>
            {portada.videos.map((video, idx) => {
              let claseSeleccionada = ''
              if (idx === portada.videoSeleccionado) {
                claseSeleccionada = 'videoportada-activo'
              }

              return (
                <div
                  key={`video-${idx}`}
                  className={'row background-brandcolor py-3 mb-3 ' + claseSeleccionada}
                  onClick={() => {
                    handleClick(idx)
                    console.info(this.iframeRef.current)
                    this.iframeRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
                  }}
                >
                  <div className='col-3'>
                    <div className='videoportada_image'>
                      <img src={video.img} className='img' alt='Miniatura vídeo' />
                    </div>
                  </div>
                  <div id='videoPortada_vid01' className='col-9'>
                    <div className='videoportada_titulo'><strong>{video.titulo}</strong></div>
                    <div>
                      <p>{video.descripcion}</p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}
