import React, { useState } from 'react'

import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import './SearchHit.css'
import { SERVER_DOMAIN } from '../../constants/api'
import ImgMapaFallback from '../ImgFallback'
import notFoundImg from '../../lib/images/imagotipo800.png'
import { Collapse } from 'react-bootstrap'
import checkAzul from '../../lib/images/Check_azul.png'

export default function SearchHit (props) {
  const { hit } = props
  // console.info(hit)
  // console.info([SERVER_DOMAIN + hit.captura])
  const [open, setOpen] = useState(false)
  return (
    <>
      <Link className='clearfix d-flex' to={`/proyecto/${hit.id}-${hit.slug}`}>
        <div className='hit-image d-flex justify-content-start justify-content-sm-start'>
          {hit.logo
            ? <ImgMapaFallback className='proyecto-captura' src={`${SERVER_DOMAIN}${hit.logo}`} align='left' alt={hit.nombre} />
            : <img className='proyecto-captura' src={notFoundImg} align='left' alt={hit.nombre} />}
        </div>
        <div className='hit-data'>
          <div className='hit-name-user'>
            {hit.nombre_usuario}
          </div>
          <div className='hit-name text-left text-sm-left'>
            {hit.nombre}
            {hit.oficial && <img className='hit__check ml-2' src={checkAzul} alt='check' />}
          </div>
          <div className='hit-info text-left text-sm-left'>
            {hit.deportes_iconos.map((dep, i) =>
              <span
                key={i}
                dangerouslySetInnerHTML={{ __html: dep }}
                className='mr-1 hit-deporte'
                title={hit.deportes[i]}
              />
            )}
            {hit.num_recorridos} <Trans>recorrido</Trans>{hit.num_recorridos === 1 ? '' : 's'}
          </div>
          <div className='hit-date text-left text-sm-left'>
            <Trans>Modificado el</Trans> {new Date(hit.date_mod).toLocaleDateString()}
            <button
              onClick={e => {
                e.preventDefault()
                return setOpen(!open)
              }}
              className='hit__btn-mas'
              aria-expanded={open}
              aria-controls={'descripcion-' + hit.id}
            >
              {open
                ? <Trans>Leer Menos</Trans>
                : <><Trans>Leer más</Trans>...</>}
            </button>
          </div>

          <Collapse in={open}>
            <div id={'descripcion-' + hit.id} className='collapse text-left text-sm-left'>
              <div className='hit-description-text'>{hit.descripcion}</div>
            </div>
          </Collapse>
        </div>
      </Link>
    </>
  )
}
