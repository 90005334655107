import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/lista'

export function listaReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LISTA_BEGIN:
      return {
        ...state,
        data: null,
        status: REQUEST_STATUS.LOADING,
        error: null
      }
    case ACTION_TYPES.FETCH_LISTA_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.lista
      }
    case ACTION_TYPES.FETCH_LISTA_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return {
        ...state
      }
  }
}
