import L from 'leaflet'

require('leaflet-polylinedecorator/src/L.Symbol')

L.Symbol.ArrowHeadMango = L.Symbol.ArrowHead.extend({
  _buildArrowPath: function (dirPoint, map) {
    const d2r = Math.PI / 180
    const tipPoint = map.project(dirPoint.latLng)
    const direction = (-(dirPoint.heading - 90)) * d2r
    const radianArrowAngle = this.options.headAngle / 2 * d2r

    const headAngle1 = direction + radianArrowAngle
    const headAngle2 = direction - radianArrowAngle
    const pivote = L.point(
      tipPoint.x - 2 * this.options.pixelSize * Math.cos(-direction),
      tipPoint.y - 2 * this.options.pixelSize * Math.sin(-direction)
    )
    const arrowHead1 = L.point(
      tipPoint.x - this.options.pixelSize * Math.cos(headAngle1),
      tipPoint.y + this.options.pixelSize * Math.sin(headAngle1))
    const arrowHead2 = L.point(
      tipPoint.x - this.options.pixelSize * Math.cos(headAngle2),
      tipPoint.y + this.options.pixelSize * Math.sin(headAngle2))

    return [
      map.unproject(arrowHead1),
      dirPoint.latLng,
      map.unproject(pivote),
      dirPoint.latLng,
      map.unproject(arrowHead2)
    ]
  }
})

L.Symbol.arrowHeadMango = function (options) {
  return new L.Symbol.ArrowHeadMango(options)
}

L.Symbol.ArrowHeadEntradaArriba = L.Symbol.ArrowHead.extend({
  _buildArrowPath: function (dirPoint, map) {
    const d2r = Math.PI / 180
    const tipPoint = map.project(dirPoint.latLng)
    const direction = (-(dirPoint.heading - 90)) * d2r
    const radianArrowAngle = this.options.headAngle / 2 * d2r

    const headAngle1 = direction + radianArrowAngle
    const headAngle2 = direction - radianArrowAngle
    const point1 = L.point(
      tipPoint.x - this.options.pixelSize * Math.sin(direction),
      tipPoint.y - this.options.pixelSize * Math.cos(direction)
    )
    const point2 = L.point(
      point1.x + 2 * this.options.pixelSize * Math.cos(-direction),
      point1.y + 2 * this.options.pixelSize * Math.sin(-direction)
    )
    const arrowHead1 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle1),
      point2.y + this.options.pixelSize * Math.sin(headAngle1))
    const arrowHead2 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle2),
      point2.y + this.options.pixelSize * Math.sin(headAngle2))

    return [
      dirPoint.latLng,
      map.unproject(point1),
      map.unproject(point2),
      map.unproject(arrowHead1),
      map.unproject(point2),
      map.unproject(arrowHead2)
    ]
  }
})

L.Symbol.arrowHeadEntradaArriba = function (options) {
  return new L.Symbol.ArrowHeadEntradaArriba(options)
}

L.Symbol.ArrowHeadSalidaArriba = L.Symbol.ArrowHead.extend({
  _buildArrowPath: function (dirPoint, map) {
    const d2r = Math.PI / 180
    const tipPoint = map.project(dirPoint.latLng)
    const direction = (-(dirPoint.heading - 90)) * d2r
    const radianArrowAngle = this.options.headAngle / 2 * d2r

    const headAngle1 = direction + radianArrowAngle
    const headAngle2 = direction - radianArrowAngle
    const point1 = L.point(
      tipPoint.x + this.options.pixelSize * Math.cos(-direction),
      tipPoint.y + this.options.pixelSize * Math.sin(-direction)
    )
    const point2 = L.point(
      point1.x - 2 * this.options.pixelSize * Math.sin(direction),
      point1.y - 2 * this.options.pixelSize * Math.cos(direction)
    )
    const arrowHead1 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle1 + Math.PI / 2),
      point2.y + this.options.pixelSize * Math.sin(headAngle1 + Math.PI / 2))
    const arrowHead2 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle2 + Math.PI / 2),
      point2.y + this.options.pixelSize * Math.sin(headAngle2 + Math.PI / 2))

    return [
      dirPoint.latLng,
      map.unproject(point1),
      map.unproject(point2),
      map.unproject(arrowHead1),
      map.unproject(point2),
      map.unproject(arrowHead2)
    ]
  }
})

L.Symbol.arrowHeadSalidaArriba = function (options) {
  return new L.Symbol.ArrowHeadSalidaArriba(options)
}

L.Symbol.ArrowHeadSalidaAbajo = L.Symbol.ArrowHead.extend({
  _buildArrowPath: function (dirPoint, map) {
    const d2r = Math.PI / 180
    const tipPoint = map.project(dirPoint.latLng)
    const direction = (-(dirPoint.heading - 90)) * d2r
    const radianArrowAngle = this.options.headAngle / 2 * d2r

    const headAngle1 = direction + radianArrowAngle
    const headAngle2 = direction - radianArrowAngle
    const point1 = L.point(
      tipPoint.x + this.options.pixelSize * Math.cos(-direction),
      tipPoint.y + this.options.pixelSize * Math.sin(-direction)
    )
    const point2 = L.point(
      point1.x + 2 * this.options.pixelSize * Math.sin(direction),
      point1.y + 2 * this.options.pixelSize * Math.cos(direction)
    )
    const arrowHead1 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle1 - Math.PI / 2),
      point2.y + this.options.pixelSize * Math.sin(headAngle1 - Math.PI / 2))
    const arrowHead2 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle2 - Math.PI / 2),
      point2.y + this.options.pixelSize * Math.sin(headAngle2 - Math.PI / 2))

    return [
      dirPoint.latLng,
      map.unproject(point1),
      map.unproject(point2),
      map.unproject(arrowHead1),
      map.unproject(point2),
      map.unproject(arrowHead2)
    ]
  }
})

L.Symbol.arrowHeadSalidaAbajo = function (options) {
  return new L.Symbol.ArrowHeadSalidaAbajo(options)
}

L.Symbol.ArrowHeadEntradaAbajo = L.Symbol.ArrowHead.extend({
  _buildArrowPath: function (dirPoint, map) {
    const d2r = Math.PI / 180
    const tipPoint = map.project(dirPoint.latLng)
    const direction = (-(dirPoint.heading - 90)) * d2r
    const radianArrowAngle = this.options.headAngle / 2 * d2r

    const headAngle1 = direction + radianArrowAngle
    const headAngle2 = direction - radianArrowAngle
    const point1 = L.point(
      tipPoint.x + this.options.pixelSize * Math.sin(direction),
      tipPoint.y + this.options.pixelSize * Math.cos(direction)
    )
    const point2 = L.point(
      point1.x + 2 * this.options.pixelSize * Math.cos(-direction),
      point1.y + 2 * this.options.pixelSize * Math.sin(-direction)
    )
    const arrowHead1 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle1),
      point2.y + this.options.pixelSize * Math.sin(headAngle1))
    const arrowHead2 = L.point(
      point2.x - this.options.pixelSize * Math.cos(headAngle2),
      point2.y + this.options.pixelSize * Math.sin(headAngle2))

    return [
      dirPoint.latLng,
      map.unproject(point1),
      map.unproject(point2),
      map.unproject(arrowHead1),
      map.unproject(point2),
      map.unproject(arrowHead2)
    ]
  }
})

L.Symbol.arrowHeadEntradaAbajo = function (options) {
  return new L.Symbol.ArrowHeadEntradaAbajo(options)
}
