import React, { Component } from 'react'
import { Trans, withTranslation } from 'react-i18next'
import { Circle, Map, Marker } from 'react-leaflet'
import CapaMapa from '../CapaMapa'
import Trayecto from '../visor/Trayecto'
import Punto from '../visor/Punto'
import Linea from '../visor/Linea'
import Poligono from '../visor/Poligono'
import Control from 'react-leaflet-control'
import { Link } from 'react-router-dom'
import MapaModalBackdrop from '../visor/MapaModalBackdrop'
import MapaModalDescripcion from './MapaModalDescripcion'
import MapaModalCambiarVista from '../visor/MapaModalCambiarVista'
import MapaModalPdiSeleccionado from '../visor/MapaModalPdiSeleccionado'
import MapaModalPdis from '../visor/MapaModalPdis'
import MapaModalPersonal from '../visorPersonal/MapaModalPersonal'
import PopupPdiFocused from '../visor/PopupPdiFocused'
import SubmenuHerramientas from '../visor/SubmenuHerramientas'

class Mapa extends Component {
  constructor (props) {
    super(props)
    this.mapRef = React.createRef()
    this.controlRef = React.createRef()
  }

  handleClickBackdrop (e) {
    const controlDom = this.controlRef.current.parentNode.parentNode
    if (e.target.parentNode.parentNode === controlDom || e.target.parentNode === controlDom) {
      this.props.cerrarMenus()
    }
  }

  fitBounds () {
    const mapRef = this.mapRef.current
    if (mapRef) {
      mapRef.leafletElement.flyToBounds(this.props.visor.bounds)
    }
  }

  clickCentrarMapa () {
    this.fitBounds()
    this.props.cerrarMenus()
  }

  getLinkMapaSiguiente () {
    const { mapaMontaje, mapasMontaje } = this.props
    const siguiente = mapasMontaje.filter(mm => mm.orden > mapaMontaje.orden)
    if (siguiente && siguiente.length) {
      const mm = siguiente[0]
      return '/montaje/' + mm.id
    }
  }

  getLinkMapaAnterior () {
    const { mapaMontaje, mapasMontaje } = this.props
    const anterior = mapasMontaje.filter(mm => mm.orden < mapaMontaje.orden)
    if (anterior && anterior.length) {
      const r = anterior[anterior.length - 1]
      return '/montaje/' + r.id
    }
  }

  getSiguientePdi (pdi) {
    const { elementos } = this.props
    const pdisOrdenados = elementos.sort((a, b) =>
      a.bloque.capa.orden !== b.bloque.capa.orden
        ? a.bloque.capa.orden - b.bloque.capa.orden
        : (a.agrupacion_orden !== b.agrupacion_orden)
          ? a.agrupacion_orden - b.agrupacion_orden
          : a.orden_editor - b.orden_editor
    )
    return pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) + 1]
  }

  getAnteriorPdi (pdi) {
    const { elementos } = this.props
    const pdisOrdenados = elementos.sort((a, b) =>
      a.bloque.capa.orden !== b.bloque.capa.orden
        ? a.bloque.capa.orden - b.bloque.capa.orden
        : (a.agrupacion_orden !== b.agrupacion_orden)
          ? a.agrupacion_orden - b.agrupacion_orden
          : a.orden_editor - b.orden_editor
    )
    return pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) - 1]
  }

  isVisible (elemento) {
    const { visor } = this.props
    const map = this.mapRef.current ? this.mapRef.current.leafletElement : null
    if (visor.pdiFocused && visor.pdiFocused.id === elemento.id) return true
    if (map && !visor.capasOcultas.map(c => c.id).includes(elemento.bloque.capa.id) && visor.mapaPdisVisible) {
      const zoomBloque = elemento.bloque.zoom ? elemento.bloque.zoom : elemento.bloque.capa.zoom
      return map._zoom >= zoomBloque
    } else {
      return false
    }
  }

  render () {
    const { t, mapaMontaje, mapasMontaje, mapa, visor, mostrarBackdrop, capasFondo, elementos } = this.props
    let linkMapaAnterior = null
    let linkMapaSiguiente = null

    if (mapaMontaje && mapa && visor && visor.bounds && capasFondo) {
      if (mapasMontaje && mapasMontaje.length) {
        // console.info('DRAW')
        // console.info([recorrido, recorridos.length, mapa, mapas.length])
        linkMapaAnterior = this.getLinkMapaAnterior()
        linkMapaSiguiente = this.getLinkMapaSiguiente()

        if (this.controlRef.current) {
          const controlDom = this.controlRef.current.parentNode.parentNode

          // Añadese así para asegurarnos de que solo se executa unha vez.
          // Se non para cada vez que se executa "render" estaríamos engadindo un listerner.
          if (controlDom.getAttribute('listener') !== 'true') {
            controlDom.setAttribute('listener', 'true')
            // controlDom.addEventListener('click', evt => { evt.stopPropagation() })
            controlDom.addEventListener('click', this.handleClickBackdrop.bind(this), false)
          }
          // controlDom.classList.add('control-expanded')
          if (mostrarBackdrop) {
            controlDom.classList.add('control-expanded')
            // const leafletPane = controlDom.parent.parent.children
          } else {
            controlDom.classList.remove('control-expanded')
          }
        }
      }
      const fondo = visor.capaFondo || capasFondo.filter(c => c.id === mapaMontaje.capa_fondo)[0]

      let usuarios = elementos.map(el => el.usuarios).flat()
      usuarios = usuarios.filter((us, i) => usuarios.map(user => user.id).indexOf(us.id) === i)
      return (
        <Map
          minZoom={4}
          tap={false}
          maxZoom={20}
          center={[0, 0]} zoom={4} ref={this.mapRef}
          zoomControl={false} bounds={visor.bounds} scrollWheelZoom={!mostrarBackdrop}
          worldCopyJump
          onMove={this.props.handleMoveMap}
          onZoom={e => this.props.handleMapZoom(e.target)}
          whenReady={e => this.props.handleLoadMap(e.target)}
        >
          <CapaMapa capa={fondo} map={this.mapRef} />
          {visor.location &&
            <>
              <Marker position={visor.location.latlng} />
              <Circle center={visor.location.latlng} radius={visor.location.accuracy} />
            </>}
          {elementos.map(e => this.isVisible(e) &&
            <React.Fragment key={e.id}>
              {e.bloque.tipo === 'tr' &&
                <Trayecto
                  {...e} mapRef={this.mapRef} onClick={this.props.handleClickPdiMapa} pdiFocused={visor.pdiFocused}
                />}
              {e.bloque.tipo === 'pt' &&
                <Punto
                  {...e} onClick={this.props.handleClickPdiMapa} pdiFocused={visor.pdiFocused} mapRef={this.mapRef}
                />}
              {e.bloque.tipo === 'ln' &&
                <Linea
                  {...e} onClick={this.props.handleClickPdiMapa} pdiFocused={visor.pdiFocused} mapRef={this.mapRef}
                />}
              {e.bloque.tipo === 'pl' &&
                <Poligono
                  {...e} onClick={this.props.handleClickPdiMapa} pdiFocused={visor.pdiFocused} mapRef={this.mapRef}
                />}
            </React.Fragment>
          )}
          {mapasMontaje && mapasMontaje.length > 1 &&
            <Control position='topright'>
              {linkMapaAnterior ? (
                <Link
                  className='mapa-paginador__left'
                  to={linkMapaAnterior}
                >
                  &lsaquo;
                </Link>
              ) : (
                <div className='mapa-paginador__left'>&lsaquo;</div>
              )}
              <ul className='mapa-paginador__paginas'>
                {mapasMontaje.map(mm => {
                  let className = 'mapa-paginador__pagina'
                  if (mm.id === mapaMontaje.id) {
                    className += ' mapa-paginador__pagina--activa'
                  }
                  return (
                    <li key={mm.id} className='mapa-paginador__pagina-li'>
                      <Link
                        className={className}
                        to={`/montaje/${mm.id}`}
                      >
                        <Trans>Personal</Trans> {mm.nombre}
                      </Link>
                    </li>
                  )
                })}
              </ul>
              {linkMapaSiguiente ? (
                <Link
                  className='mapa-paginador__right'
                  to={linkMapaSiguiente}
                >
                  &rsaquo;
                </Link>
              ) : (
                <div className='mapa-paginador__right'>&rsaquo;</div>
              )}
            </Control>}
          <Control position='bottomleft'>
            {mostrarBackdrop &&
              <MapaModalBackdrop>
                {visor.modalDescripcionVisible &&
                  <MapaModalDescripcion
                    className='aparece-arriba'
                    mapaVoluntarios={mapaMontaje}
                    elementos={elementos}
                    titulo={
                      t('Ubicaciones') + ': ' + elementos.length + ', ' +
                      t('Usuarios') + ': ' + mapaMontaje.numero_usuarios
                    }
                    handleClickCerrar={this.props.cerrarMenus}
                    usuarios
                  />}
                {visor.modalCambiarVistaVisible &&
                  <MapaModalCambiarVista
                    className='aparece-abajo'
                    capasFondo={capasFondo}
                    onClickCapaFondo={this.props.handleClickSeleccionarCapaFondo}
                    titulo={t('Seleccionar capa de fondo')}
                    handleClickCerrar={this.props.cerrarMenus}
                    fondoActivo={fondo}
                  />}
                {visor.pdiDestacado &&
                  <MapaModalPdiSeleccionado
                    {...visor.pdiDestacado}
                    onClickCerrar={() => { this.props.cerrarMenus() }}
                    className='modal-km__modal aparece-abajo'
                    personal
                    noRecorrido
                  />}
                {visor.modalPdisVisible &&
                  <MapaModalPdis
                    className='aparece-abajo'
                    puntos={elementos}
                    titulo={t('Ubicaciones')}
                    handleClickCerrar={this.props.cerrarMenus}
                    onClickPdi={this.props.handleClickPdiLista}
                    mapRef={this.mapRef}
                    filtroPdis={visor.filtroPdis}
                    onChangeFiltroPdis={this.props.handleChangeFiltroPdis}
                    capasOcultas={visor.capasOcultas}
                    onClickVisibilidad={this.props.handleClickVisibilidadCapa}
                    onClickVisibilidadPdis={this.props.handleClickMapaOcultarPdis}
                    visibilidadPdis={visor.mapaPdisVisible}
                    montaje
                  />}
                {visor.modalPersonalVisible &&
                  <MapaModalPersonal
                    className='aparece-abajo'
                    titulo={t('Usuarios')}
                    handleClickCerrar={this.props.cerrarMenus}
                    elementos={elementos}
                    usuarios={usuarios}
                    onClickPdi={this.props.handleClickPdiLista}
                    mapRef={this.mapRef}
                    filtroPersonal={visor.filtroModalPersonal}
                    onChangeFiltroPersonal={this.props.handleChangeFiltroPersonal}
                  />}
              </MapaModalBackdrop>}
            {visor.location && visor.localizacionDescentrada &&
              <div className='d-flex justify-content-end'>
                <button onClick={() => this.props.handleClickRecentrar(this.mapRef)} className='btn btn-primary mr-4'>
                  <i className='fas fa-location-arrow mr-1' />
                  <Trans>Centrar</Trans>
                </button>
              </div>}
            {visor.pdiFocused &&
              <PopupPdiFocused
                {...visor.pdiFocused}
                onClickPdiDetalle={this.props.handleClickPdiDestacado}
                onClickCerrar={this.props.handleClickCerrarPdiFocused}
                getSiguientePdi={this.getSiguientePdi.bind(this)}
                getAnteriorPdi={this.getAnteriorPdi.bind(this)}
                onClickPrevPdi={this.props.handleClickPrevPdi}
                onClickSigPdi={this.props.handleClickSigPdi}
                onClickDescargar={this.props.handleClickDescargarLinea}
                personal
              />}
            <div className='mapa-botones' ref={this.controlRef}>
              {visor.submenuHerramientasVisible &&
                <SubmenuHerramientas
                  mapRef={this.mapRef}
                  onClickCambiarVista={this.props.handleClickCambiarVista}
                  fitBounds={this.clickCentrarMapa.bind(this)}
                />}
              <ul className='btn-group boton-visor__group text-center'>
                {this.props.handleClickHerramientas &&
                  <li className='btn boton-visor boton-visor--small'>
                    <button onClick={this.props.handleClickHerramientas} title={t('Herramientas')}>
                      <i className='fas fa-tools' />
                    </button>
                  </li>}
                {this.props.handleClickPdis && elementos.length > 0 &&
                  <li className='btn boton-visor boton-visor--small'>
                    <button onClick={this.props.handleClickPdis} title={t('Ubicaciones')}>
                      <i className='fas fa-map-marker-alt' />
                    </button>
                  </li>}
                {this.props.handleClickPersonal && usuarios.length > 0 &&
                  <li className='btn boton-visor boton-visor--small'>
                    <button onClick={this.props.handleClickPersonal} title={t('Ubicaciones')}>
                      <i className='fas fa-users' />
                    </button>
                  </li>}
                {!visor.location
                  ? (
                    <li className='btn boton-visor boton-visor--small' title={t('Centrar en mi')}>
                      <button onClick={() => this.props.handleClickLocate(this.mapRef)}>
                        <i className='fas fa-compass' />
                      </button>
                    </li>
                  ) : (
                    <li className='btn boton-visor boton-visor--small boton-visor--activo' title={t('Parar localización')}>
                      <button onClick={() => this.props.handleClickLocateStop(this.mapRef)}>
                        <span className='fa-stack fa-2x'>
                          <i className='fas fa-compass fa-stack-1x' />
                          <i className='fas fa-ban fa-stack-2x' />
                        </span>
                      </button>
                    </li>
                  )}
              </ul>
            </div>
          </Control>
        </Map>
      )
    } else {
      return false
    }
  }
}

export default withTranslation()(Mapa)
