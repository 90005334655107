/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_VOLUNTARIOS_ELEMENTOS_BEGIN: 'FETCH_VOLUNTARIOS_ELEMENTOS_BEGIN',
  FETCH_VOLUNTARIOS_ELEMENTOS_SUCCESS: 'FETCH_VOLUNTARIOS_ELEMENTOS_SUCCESS',
  FETCH_VOLUNTARIOS_ELEMENTOS_ERROR: 'FETCH_VOLUNTARIOS_ELEMENTOS_ERROR'
}

function fetchVoluntariosElementosBegin () {
  return {
    type: ACTION_TYPES.FETCH_VOLUNTARIOS_ELEMENTOS_BEGIN
  }
}

function fetchVoluntariosElementosSuccess (voluntariosElementos) {
  return {
    type: ACTION_TYPES.FETCH_VOLUNTARIOS_ELEMENTOS_SUCCESS,
    voluntariosElementos
  }
}

function fetchVoluntariosElementosError (error) {
  return {
    type: ACTION_TYPES.FETCH_VOLUNTARIOS_ELEMENTOS_ERROR,
    error
  }
}

export function fetchVoluntariosElementos (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchVoluntariosElementosBegin())
    const url = new URL(API_BASE_URL + 'voluntarioselemento/')
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchVoluntariosElementosSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchVoluntariosElementosError(err))
      })
  }
}
