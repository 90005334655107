import React from 'react'

import L from 'leaflet'
import 'leaflet-geometryutil'
import 'leaflet-polylinedecorator'
import { Polyline } from 'react-leaflet'
import TrayectoAnimacion from './TrayectoAnimacion'

class Trayecto extends React.Component {
  constructor (props) {
    super(props)
    this.polyRef = React.createRef()
    this.timer = null
    this.decorator = null
  }

  componentDidMount () {
    if (this.polyRef.current) {
      const polyline = this.polyRef.current.leafletElement
      const { map } = this.polyRef.current.props.leaflet

      const patterns = [{
        offset: 0,
        repeat: 200,
        symbol: L.Symbol.arrowHead({
          pixelSize: 17,
          pathOptions: {
            fillOpacity: 1,
            weight: 0,
            color: this.props.color
          },
          interactive: false
        })
      }]

      this.decorator = L.polylineDecorator(polyline, {
        patterns: patterns
      })
      map.addLayer(this.decorator)
      this.animarFlechas(map, polyline)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { pdiFocused, id, mapRef, coordenadas } = this.props
    if (pdiFocused && pdiFocused.id === id && (!prevProps.pdiFocused || prevProps.pdiFocused.id !== id)) {
      const bounds = L.geoJSON(coordenadas).getBounds()
      mapRef.current.leafletElement.flyToBounds(bounds)
    }
  }

  animarFlechas (mapa, linea) {
    // console.info('ANIMAR')
    // Aseguramonos de parar calquera outra animación que puidera haber
    if (this.timer) {
      clearInterval(this.timer)
    }

    let arrayPoints = linea._latlngs.map(p => mapa.latLngToLayerPoint(p))
    let lengthPixels = L.GeometryUtil.length(arrayPoints)
    let maxOffset = lengthPixels > 200 ? 200 : lengthPixels
    // As variables para a animación podemos recalcula-las cada vez que se
    // faga zoom. Non e necesario recalcula-las siempre no bucle da animación.
    mapa.on('zoomend', () => {
      // console.info('zoom')
      arrayPoints = linea._latlngs.map(p => mapa.latLngToLayerPoint(p))
      lengthPixels = L.GeometryUtil.length(arrayPoints)
      maxOffset = lengthPixels > 200 ? 200 : lengthPixels
    })

    let offset = 0

    if (L.Symbol) {
      this.timer = setInterval(() => {
        const patterns = [
          {
            offset: offset,
            repeat: maxOffset,
            symbol: L.Symbol.arrowHead({
              pixelSize: 17,
              pathOptions: {
                fillOpacity: 1,
                weight: 0,
                color: this.props.color
              }
            })
          }]
        if (offset < maxOffset) {
          offset += 1
        } else {
          offset = 0
        }
        this.decorator.setPatterns(patterns)
      }, 40) // 40ms para 25fps
    }
  }

  componentWillUnmount () {
    if (this.polyRef.current) {
      const { map } = this.polyRef.current.props.leaflet
      map.removeLayer(this.decorator)
    }
    if (this.timer) {
      clearInterval(this.timer)
    }
  }

  render () {
    const { color, grosor, coordenadas, animando } = this.props
    if (!animando) {
      return (
        <Polyline
          ref={this.polyRef}
          color={color} opacity={1} weight={grosor}
          positions={coordenadas.coordinates.map(row => [row[1], row[0]])}
          onClick={this.props.onClick ? () => this.props.onClick(this.props) : undefined}
        />
      )
    } else {
      return (
        <TrayectoAnimacion {...this.props} polyRef={this.polyRef} />
      )
    }
  }
}

export default Trayecto
