import React, { Component } from 'react'
import * as L from 'leaflet'
import 'leaflet.heightgraph'
import * as turf from '@turf/turf'

import 'leaflet.heightgraph/dist/L.Control.Heightgraph.min.css'
import './PerfilEsquema.css'

export default class PerfilEsquema extends Component {
  componentDidMount () {
    const { mapRef, trayecto } = this.props
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(trayecto.color)
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    if (mapRef.current) {
      const map = mapRef.current.leafletElement
      const width = window.innerWidth > 768 ? window.innerWidth * 0.30 : window.innerWidth * 0.9
      const height = window.innerWidth > 768 ? window.innerHeight * 0.1 : window.innerHeight * 0.15
      this.elevationOptions = {
        width: width,
        height: height,
        position: 'topright',
        graphStyle: {
          opacity: 1,
          'fill-opacity': 1,
          'stroke-width': '3px'
        },
        yTicks: 1,
        margins: {
          top: 25,
          right: 25,
          bottom: 25,
          left: 50
        },
        mappings: {
          elevation: {
            0: {
              color: 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 1)'
            },
            1: {
              color: 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', .25)'
            }
          }
        },
        expandControls: false
      }
      const coordenadas = this.getCoordinatesPerfil()
      this.coordenadas = coordenadas
      this.controlElevation = L.control.heightgraph(this.elevationOptions).addTo(map)
      const fc = [{
        type: 'FeatureCollection',
        features: [{
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordenadas
          },
          properties: {
            attributeType: 0
          }
        }],
        properties: {
          summary: 'elevation'
        }
      }]

      this.controlElevation.addData(fc)
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { mapRef, trayecto, distancia } = this.props
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(trayecto.color)
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    if (prevProps.trayecto.id !== trayecto.id) {
      this.coordenadas = this.getCoordinatesPerfil()
    }
    const coordenadas = this.coordenadas

    if (distancia > 0) {
      const chunks = turf.lineChunk(turf.lineString(coordenadas), distancia, { units: 'meters' })
      const line1 = chunks.features[0]
      line1.geometry.coordinates[line1.geometry.coordinates.length - 1].push(line1.geometry.coordinates[line1.geometry.coordinates.length - 2][2])
      const coordsLine2 = coordenadas.slice(line1.geometry.coordinates.length, coordenadas.length - 1)
      coordsLine2.unshift(line1.geometry.coordinates[line1.geometry.coordinates.length - 1])
      if (coordsLine2.length > 1) {
        const line2 = turf.lineString(coordsLine2)

        if (mapRef.current) {
          const map = mapRef.current.leafletElement
          const fc = [{
            type: 'FeatureCollection',
            features: [{
              ...line1,
              properties: {
                attributeType: 0
              }
            }, {
              ...line2,
              properties: {
                attributeType: 1
              }
            }],
            properties: {
              summary: 'elevation'
            }
          }]
          this.controlElevation.remove()
          this.elevationOptions.mappings.elevation[0].color = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', 1)'
          this.elevationOptions.mappings.elevation[1].color = 'rgba(' + rgb.r + ', ' + rgb.g + ', ' + rgb.b + ', .25)'
          this.controlElevation = L.control.heightgraph(this.elevationOptions).addTo(map)
          this.controlElevation.addData(fc)
        }
      }
    }
  }

  getCoordinatesPerfil () {
    const { trayecto } = this.props
    return trayecto.coordenadas_interpoladas.coordinates
  }

  componentWillUnmount () {
    const { mapRef } = this.props
    if (mapRef.current) {
      this.controlElevation.remove()
      const map = mapRef.current.leafletElement
      map.removeLayer(this.controlElevation)
    }
  }

  render () {
    return (
      <></>
    )
  }
}
