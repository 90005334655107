import React from 'react'
import './PublicBody.css'

export default function PublicBody (props) {
  return (
    <div className='visor-publico--buscar'>
      {props.children}
    </div>
  )
}
