/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_PROYECTO_BEGIN: 'FETCH_PROYECTO_BEGIN',
  FETCH_PROYECTO_SUCCESS: 'FETCH_PROYECTO_SUCCESS',
  FETCH_PROYECTO_ERROR: 'FETCH_PROYECTO_ERROR'
}

export function fetchPoryectoBegin () {
  return {
    type: ACTION_TYPES.FETCH_PROYECTO_BEGIN
  }
}

function fetchPoryectoSuccess (proyecto) {
  return {
    type: ACTION_TYPES.FETCH_PROYECTO_SUCCESS,
    proyecto: proyecto
  }
}

function fetchProyectoError (error) {
  return {
    type: ACTION_TYPES.FETCH_PROYECTO_ERROR,
    error: error
  }
}

export function fetchRemoteProyecto (id, fields = []) {
  return function (dispath) {
    dispath(fetchPoryectoBegin())

    const url = new URL(API_BASE_URL + 'proyecto/' + id + '/')
    url.searchParams.append('fields', fields.join(','))

    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res) {
          dispath(fetchPoryectoSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispath(fetchProyectoError(error))
      })
  }
}
