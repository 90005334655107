import React from 'react'
import notFoundImg from '../../lib/images/defaultmap-movilidad.png'
import { Trans } from 'react-i18next'

const MapaTraficoItem = ({ mapaTrafico }) => {
  return (
    <>
      {mapaTrafico.captura_2x1
        ? (
          <div
            className='recorrido-list__captura' style={{ backgroundImage: 'url(' + mapaTrafico.captura_2x1 + ')' }}
          >
            <div className='recorrido-list__recorrido-nombre'> {mapaTrafico.nombre}</div>
          </div>)
        : (
          <div className='recorrido-list__captura' style={{ backgroundImage: 'url(' + notFoundImg + ')' }}>
            <div className='recorrido-list__recorrido-nombre'> {mapaTrafico.nombre}</div>
          </div>
        )}
      <div className='personal-item__resumen pl-2 pr-2 pt-1 pb-1 font-weight-bold'>
        <span>
          <Trans>Ubicaciones</Trans>: {mapaTrafico.elementos_trafico.length}
        </span>
      </div>
    </>
  )
}

export default MapaTraficoItem
