/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_FONDOS_BEGIN: 'FETCH_FONDOS_BEGIN',
  FETCH_FONDOS_SUCCESS: 'FETCH_FONDOS_SUCCESS',
  FETCH_FONDOS_ERROR: 'FETCH_FONDOS_ERROR'
}

function fetchFondosBegin () {
  return {
    type: ACTION_TYPES.FETCH_FONDOS_BEGIN
  }
}

function fetchFondosSucess (fondos) {
  return {
    type: ACTION_TYPES.FETCH_FONDOS_SUCCESS,
    fondos: fondos
  }
}

function fetchFondosError (err) {
  return {
    type: ACTION_TYPES.FETCH_FONDOS_ERROR,
    error: err
  }
}

export function fetchRemoteFondos (fields = []) {
  return function (dispath) {
    dispath(fetchFondosBegin())

    const url = new URL(API_BASE_URL + 'capafondo/')
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }

    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispath(fetchFondosSucess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispath(fetchFondosError(error))
      })
  }
}
