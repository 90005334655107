import React from 'react'
import notFoundImg from '../../lib/images/defaultmap-montaje.png'
import { Trans } from 'react-i18next'

const MapaMontajeItem = ({ mapaMontaje }) => {
  return (
    <>
      {mapaMontaje.captura_2x1
        ? (
          <div
            className='recorrido-list__captura' style={{ backgroundImage: 'url(' + mapaMontaje.captura_2x1 + ')' }}
          >
            <div className='recorrido-list__recorrido-nombre'> {mapaMontaje.nombre}</div>
          </div>)
        : (
          <div className='recorrido-list__captura' style={{ backgroundImage: 'url(' + notFoundImg + ')' }}>
            <div className='recorrido-list__recorrido-nombre'> {mapaMontaje.nombre}</div>
          </div>
        )}
      <div
        className='personal-item__resumen pl-2 pr-2 pt-1 pb-1 font-weight-bold d-flex justify-content-between align-items-center'
      >
        <span>
          <Trans>Ubicaciones</Trans>: {mapaMontaje.elementos_montaje.length}
        </span>
        <span>
          <Trans>Usuarios</Trans>: {mapaMontaje.numero_usuarios}
        </span>
      </div>
    </>
  )
}

export default MapaMontajeItem
