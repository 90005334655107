/* global fetch */

import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_MAPAS_TRAFICO_BEGIN: 'FETCH_MAPAS_TRAFICO_BEGIN',
  FETCH_MAPAS_TRAFICO_SUCCESS: 'FETCH_MAPAS_TRAFICO_SUCCESS',
  FETCH_MAPAS_TRAFICO_ERROR: 'FETCH_MAPAS_TRAFICO_ERROR',
  FETCH_MAPA_TRAFICO_BEGIN: 'FETCH_MAPA_TRAFICO_BEGIN',
  FETCH_MAPA_TRAFICO_SUCCESS: 'FETCH_MAPA_TRAFICO_SUCCESS',
  FETCH_MAPA_TRAFICO_ERROR: 'FETCH_MAPA_TRAFICO_ERROR'
}

function fetchMapasTraficoBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_TRAFICO_BEGIN
  }
}

function fetchMapasTraficoSuccess (mapasTrafico) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_TRAFICO_SUCCESS,
    mapasTrafico
  }
}

function fetchMapasTraficoError (error) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_TRAFICO_ERROR,
    error
  }
}

export function fetchMapasTrafico (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchMapasTraficoBegin())
    const url = new URL(API_BASE_URL + 'trafico/')
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && Array.isArray(res)) {
          dispatch(fetchMapasTraficoSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchMapasTraficoError(err))
      })
  }
}

export function fetchMapaTraficoBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPA_TRAFICO_BEGIN
  }
}

function fetchMapaTraficoSuccess (mapaTrafico) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_TRAFICO_SUCCESS,
    mapaTrafico
  }
}

function fetchMapaTraficoError (error) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_TRAFICO_ERROR,
    error
  }
}

export function fetchMapaTrafico (id, fields = []) {
  return dispatch => {
    dispatch(fetchMapaTraficoBegin())
    const url = new URL(API_BASE_URL + 'trafico/' + id + '/')

    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    const key = new URLSearchParams(window.location.search).get('key')
    // Pasamos o parámetro key a API. Úsase a hora de xerar as capturas,
    // para poder ver mapas non publicados.
    if (key) {
      url.searchParams.append('key', key)
    }
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(fetchMapaTraficoSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchMapaTraficoError(err))
      })
  }
}
