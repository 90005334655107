/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_RECORRIDOS_BEGIN: 'FETCH_RECORRIDOS_BEGIN',
  FETCH_RECORRIDOS_SUCCESS: 'FETCH_RECORRIDOS_SUCCESS',
  FETCH_RECORRIDOS_ERROR: 'FETCH_RECORRIDOS_ERROR',
  FETCH_RECORRIDO_BEGIN: 'FETCH_RECORRIDO_BEGIN',
  FETCH_RECORRIDO_SUCCESS: 'FETCH_RECORRIDO_SUCCESS',
  FETCH_RECORRIDO_ERROR: 'FETCH_RECORRIDO_ERROR'
}

export function fetchRecorridosBegin () {
  return {
    type: ACTION_TYPES.FETCH_RECORRIDOS_BEGIN
  }
}

function fetchRecorridosSucess (recorridos) {
  return {
    type: ACTION_TYPES.FETCH_RECORRIDOS_SUCCESS,
    recorridos: recorridos
  }
}

function fetchRecorridosError (err) {
  return {
    type: ACTION_TYPES.FETCH_RECORRIDOS_ERROR,
    error: err
  }
}

export function fetchRemoteRecorridosMapa (mapaId, fields = [], filtros = {}) {
  return function (dispath) {
    dispath(fetchRecorridosBegin())

    const url = new URL(API_BASE_URL + 'recorrido/')
    const params = { ...filtros, mapa: mapaId }
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )

    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispath(fetchRecorridosSucess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispath(fetchRecorridosError(error))
      })
  }
}

export function fetchRecorridoBegin () {
  return {
    type: ACTION_TYPES.FETCH_RECORRIDO_BEGIN
  }
}

export function fetchRecorridoSucess (recorrido) {
  return {
    type: ACTION_TYPES.FETCH_RECORRIDO_SUCCESS,
    recorrido
  }
}

export function fetchRecorridoError (err) {
  return {
    type: ACTION_TYPES.FETCH_RECORRIDO_ERROR,
    error: err
  }
}

export function fetchRemoteRecorrido (id, fields = []) {
  return dispatch => {
    const url = new URL(API_BASE_URL + 'recorrido/' + id + '/')

    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    const key = new URLSearchParams(window.location.search).get('key')
    // Pasamos o parámetro key a API. Úsase a hora de xerar as capturas,
    // para poder ver recorridos non publicados.
    if (key) {
      url.searchParams.append('key', key)
    }

    dispatch(fetchRecorridoBegin)
    fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res) {
          dispatch(fetchRecorridoSucess(res))
        } else {
          throw new Error('Not found')
        }
        return res
      })
      .catch(fetchRecorridoError)
  }
}

export function fetchRemoteRecorridos (fields = [], params = {}) {
  return function (dispath) {
    dispath(fetchRecorridosBegin())

    const url = new URL(API_BASE_URL + 'recorrido/')
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispath(fetchRecorridosSucess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispath(fetchRecorridosError(error))
      })
  }
}
