import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/mapaMontaje'

export function mapasMontajeReducer (state = initialState.mapasMontaje, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPAS_MONTAJE_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }

    case ACTION_TYPES.FETCH_MAPAS_MONTAJE_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapasMontaje
      }

    case ACTION_TYPES.FETCH_MAPAS_MONTAJE_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}

export function mapaMontajeReducer (state = initialState.mapaMontaje, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPA_MONTAJE_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.FETCH_MAPA_MONTAJE_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapaMontaje
      }

    case ACTION_TYPES.FETCH_MAPA_MONTAJE_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}
