import React from 'react'
import { Card } from 'react-bootstrap'
import { Trans } from 'react-i18next'

import './CardLista.css'
import { Link } from 'react-router-dom'

const CardLista = ({ lista, recorridos, metrosToUnitsRecorrido }) => {
  return (
    <Link className='card-lista__link' to={'/lista/' + lista.id}>
      <Card className='card-lista'>
        <Card.Title><strong>{lista.nombre}</strong></Card.Title>
        <Card.Body>
          {lista.logo &&
            <Card.Img className='card-lista__imagen' variant='top' src={lista.logo} />}
          <div className='card-lista__datos'>
            {recorridos.map(rec =>
              <div key={rec.id}>
                <Link className='card-lista__fila d-flex' to={'/recorrido/' + rec.id}>
                  {rec.deporte &&
                    <div
                      className='rec-list-canal__icon'
                      dangerouslySetInnerHTML={{ __html: rec.deporte.icono_svg }}
                    />}
                  <div>
                    <span className='rec-list-canal__nombre-proyecto'>{rec.proyecto_nombre}</span><br />
                    <strong>{rec.nombre}</strong>
                    <br />
                    <span className='card-lista__distancia'>{metrosToUnitsRecorrido(rec, rec.distancia_metros)}</span>
                  </div>
                </Link>
              </div>
            )}
          </div>
        </Card.Body>
        <Card.Footer>
          <Trans>Actualizada por última vez: </Trans>{new Date(lista.date_mod).toLocaleString()}
        </Card.Footer>
      </Card>
    </Link>
  )
}

export default CardLista
