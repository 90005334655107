import React from 'react'

import './ListPdisRecorridoSector.css'

const ListPdisRecorridosSector = props => {
  const puntos = props.sector.pdis_recorrido.concat(props.sector.puntos_km.map(pk => pk.punto))
  puntos.sort((a, b) => props.getDistanciaPuntoKm(a) - props.getDistanciaPuntoKm(b))
  let pdis = []
  props.sector.pdis_recorrido.forEach(pdi => {
    for (const i in props.sector.esquemas) {
      if (props.sector.esquemas[i].trayecto.id === pdi.trayecto_ancla) {
        for (let j = 1; j <= props.sector.esquemas[i].repeticiones; j++) {
          pdis.push({
            punto: pdi,
            distancia: props.getDistanciaPdisSector(pdi, props.sector, props.sector.esquemas[i], j),
            paso: j
          })
        }
      }
    }
  })
  props.sector.puntos_km.forEach(pk => {
    pdis.push({
      punto: pk.punto,
      distancia: props.getDistanciaPuntoKm(pk.punto, true)
    })
  })
  const ptsAntesFiltro = pdis.length
  pdis = pdis
    .filter(p => {
      const nombreCoincide = new RegExp([props.filtroPdis].join('|'), 'i').test(p.punto.nombre)
      const serviciosCoinciden = p.punto.servicios.filter(s =>
        new RegExp([props.filtroPdis].join('|'), 'i').test(s.nombre))
      return nombreCoincide || serviciosCoinciden.length
    })
    .sort((a, b) => a.distancia - b.distancia)
  return (
    <>
      {ptsAntesFiltro > 10 &&
        <input
          className='form-control mb-3' type='text'
          placeholder='Filtrar' defaultValue={props.filtroPdis}
          onChange={props.onChangeFiltroPdis}
        />}
      {pdis.map((p, i) =>
        <li key={i}>
          <button className='btn-pdi-recorrido' onClick={() => props.onClickPdi(p.punto, props.mapRef)}>
            <div>
              <div className='nombre-pdi'>
                <div
                  className='icono-servicio mr-2'
                  style={{
                    backgroundColor: p.punto.color,
                    color: p.punto.color_icono
                  }}
                >
                  {p.punto.bloque.es_pk
                    ? (
                      <span>
                        {
                          parseFloat(p.distancia / 1000) % 1 === 0
                            ? parseFloat(p.distancia / 1000).toFixed(0)
                            : parseFloat(p.distancia / 1000).toFixed(1)
                        }
                      </span>
                    )
                    : (
                      <i
                        className={
                          p.punto.icono.prefijo_fuente + ' ' + p.punto.icono.prefijo_fuente + '-' + p.punto.icono.icono
                        }
                      />
                    )}
                </div>
                {p.punto.nombre}
              </div>
              {p.punto.servicios.length > 0 &&
                <ul className='lista-servicios-pdis'>
                  {p.punto.servicios.length <= 3
                    ? (
                      <>
                        {p.punto.servicios.map(srv =>
                          <li
                            className='icono-servicio' key={srv.id}
                            style={{ backgroundColor: srv.color_fondo, color: srv.color_icono }}
                          >
                            <i
                              className={
                                srv.icono ? srv.icono.prefijo_fuente + ' ' +
                                  srv.icono.prefijo_fuente + '-' + srv.icono.icono : ''
                              }
                            />
                          </li>
                        )}
                      </>)
                    : (
                      <>
                        {p.punto.servicios.map((srv, i) => i < 2 &&
                          <li
                            className='icono-servicio' key={srv.id}
                            style={{ backgroundColor: srv.color_fondo, color: srv.color_icono }}
                          >
                            <i
                              className={
                                srv.icono ? srv.icono.prefijo_fuente + ' ' +
                                  srv.icono.prefijo_fuente + '-' + srv.icono.icono : ''
                              }
                              title={srv.nombre}
                            />
                          </li>
                        )}
                        <li className='icono-servicio icono-mas-servicio'>
                          <i className='mdi mdi-plus' />
                        </li>
                      </>)}
                </ul>}
            </div>
            <span className='distancia-pdi'>{props.metrosToUnitsRecorrido(p.distancia)}</span>
          </button>
        </li>
      )}
    </>
  )
}

export default ListPdisRecorridosSector
