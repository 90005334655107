/* global fetch */
import { API_BASE_URL } from '../constants/api'
import getCsrfToken from '../lib/csrf'

export const ACTION_TYPES = {
  FETCH_NOTIFICACIONES_BEGIN: 'FETCH_NOTIFICACIONES_BEGIN',
  FETCH_NOTIFICACIONES_SUCCESS: 'FETCH_NOTIFICACIONES_SUCCESS',
  FETCH_NOTIFICACIONES_ERROR: 'FETCH_NOTIFICACIONES_ERROR',
  UPDATE_NOTIFICACION_BEGIN: 'UPDATE_NOTIFICACION_BEGIN',
  UPDATE_NOTIFICACION_SUCCESS: 'UPDATE_NOTIFICACION_SUCCESS',
  UPDATE_NOTIFICACION_ERROR: 'UPDATE_NOTIFICACION_ERROR'
}

function fetchNotificacionesBegin () {
  return {
    type: ACTION_TYPES.FETCH_NOTIFICACIONES_BEGIN
  }
}

function fetchNotificacionesSuccess (notificaciones) {
  return {
    type: ACTION_TYPES.FETCH_NOTIFICACIONES_SUCCESS,
    notificaciones
  }
}

function fetchNotificacionesError (error) {
  return {
    type: ACTION_TYPES.FETCH_NOTIFICACIONES_ERROR,
    error
  }
}

export default function fetchNotificaciones (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchNotificacionesBegin())
    const url = new URL(API_BASE_URL + 'notificacion/')
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchNotificacionesSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchNotificacionesError(err))
      })
  }
}

function updateNotificacionBegin () {
  return {
    type: ACTION_TYPES.UPDATE_NOTIFICACION_BEGIN
  }
}

function updateNotificacionSuccess (notificacion) {
  return {
    type: ACTION_TYPES.UPDATE_NOTIFICACION_SUCCESS,
    notificacion
  }
}

function updateNotificacionError (error) {
  return {
    type: ACTION_TYPES.UPDATE_NOTIFICACION_ERROR,
    error
  }
}

export function updateNotificacion (idNotificacion, opciones) {
  return dispatch => {
    dispatch(updateNotificacionBegin())
    const url = new URL(API_BASE_URL + 'notificacion/' + idNotificacion + '/')
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken()
      },
      credentials: 'include',
      body: JSON.stringify(opciones)
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(updateNotificacionSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(updateNotificacionError(err))
      })
  }
}
