import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/montajeElementos'
import polyline from '@mapbox/polyline'
import * as turf from '@turf/turf'

export default function montajeElementosReducer (state = initialState.montajeElementos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_ELEMENTOS_MONTAJE_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }

    case ACTION_TYPES.FETCH_ELEMENTOS_MONTAJE_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.elementosMontaje.map(em => {
          if (em.elemento.bloque.tipo === 'tr') {
            const line = polyline.decode(em.elemento.linea_codificada)
            em.elemento.coordenadas = turf.lineString(line).geometry
          }
          return em
        })
      }

    case ACTION_TYPES.FETCH_ELEMENTOS_MONTAJE_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}
