import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/mapaTrafico'

export function mapasTraficoReducer (state = initialState.mapasTrafico, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPAS_TRAFICO_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }

    case ACTION_TYPES.FETCH_MAPAS_TRAFICO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapasTrafico
      }

    case ACTION_TYPES.FETCH_MAPAS_TRAFICO_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}

export function mapaTraficoReducer (state = initialState.mapaTrafico, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPA_TRAFICO_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        error: null,
        data: null
      }

    case ACTION_TYPES.FETCH_MAPA_TRAFICO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapaTrafico
      }

    case ACTION_TYPES.FETCH_MAPA_TRAFICO_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}
