import React from 'react'
import './MapaModal.css'

export default function MapaModal ({ titulo, handleClickCerrar, children, className, onClickVisibilidadPdis, visibilidadPdis }) {
  // console.info(props.children.reduce((a, b) => a && b))
  // console.info(className)
  return (
    <div className={`modal-dialog modal-dialog-scrollable mapa-modal  ${className || ''}`}>
      <div className='mapa-submodal modal-content'>
        <div className='mapa-submodal__head d-flex justify-content-between p-1'>
          <div className='mapa-submodal__titulo'>{titulo}</div>
          <div className='mapa-submodal__close'>
            {onClickVisibilidadPdis &&
              <button onClick={onClickVisibilidadPdis}>
                {visibilidadPdis
                  ? <i className='fa fa-eye' />
                  : <i className='fa fa-eye-slash' />}
              </button>}
            {handleClickCerrar &&
              <button onClick={handleClickCerrar}>
                <i className='fas fa-times' />
              </button>}
          </div>
        </div>
        <div className='mapa-submodal__body modal-body'>
          {children}
        </div>
      </div>
    </div>
  )
}
