/* global fetch */

import { API_BASE_URL } from '../constants/api'
import getCsrfToken from '../lib/csrf'

export const ACTION_TYPES = {
  CREATE_CONTACTO_BEGIN: 'CREATE_CONTACTO_BEGIN',
  CREATE_CONTACTO_SUCCESS: 'CREATE_CONTACTO_SUCCESS',
  CREATE_CONTACTO_ERROR: 'CREATE_CONTACTO_ERROR',
  DELETE_CONTACTO_BEGIN: 'DELETE_CONTACTO_BEGIN',
  DELETE_CONTACTO_SUCCESS: 'DELETE_CONTACTO_SUCCESS',
  DELETE_CONTACTO_ERROR: 'DELETE_CONTACTO_ERROR',
  FETCH_CONTACTOS_BEGIN: 'FETCH_CONTACTOS_BEGIN',
  FETCH_CONTACTOS_SUCCESS: 'FETCH_CONTACTOS_SUCCESS',
  FETCH_CONTACTOS_ERROR: 'FETCH_CONTACTOS_ERROR',
  UPDATE_CONTACTO_BEGIN: 'UPDATE_CONTACTO_BEGIN',
  UPDATE_CONTACTO_SUCCESS: 'UPDATE_CONTACTO_SUCCESS',
  UPDATE_CONTACTO_ERROR: 'UPDATE_CONTACTO_ERROR'
}

function createContactoBegin () {
  return {
    type: ACTION_TYPES.CREATE_CONTACTO_BEGIN
  }
}

function createContactoSuccess (contacto) {
  return {
    type: ACTION_TYPES.CREATE_CONTACTO_SUCCESS,
    contacto
  }
}

function createContactoError (error) {
  return {
    type: ACTION_TYPES.CREATE_CONTACTO_ERROR,
    error
  }
}

export function createContacto (opciones) {
  return dispatch => {
    dispatch(createContactoBegin())
    const url = API_BASE_URL + 'contacto/'
    return fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken()
      },
      credentials: 'include',
      body: JSON.stringify(opciones)
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(createContactoSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispatch(createContactoError(error))
      })
  }
}

function deleteContactoBegin () {
  return {
    type: ACTION_TYPES.DELETE_CONTACTO_BEGIN
  }
}

function deleteContactoSuccess (contacto) {
  return {
    type: ACTION_TYPES.DELETE_CONTACTO_SUCCESS,
    contacto
  }
}

function deleteContactoError (error) {
  return {
    type: ACTION_TYPES.DELETE_CONTACTO_ERROR,
    error
  }
}

export function deleteContacto (contacto, params = {}) {
  return dispatch => {
    dispatch(deleteContactoBegin())
    const url = new URL(API_BASE_URL + 'contacto/' + contacto.id + '/')
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'X-CSRFToken': getCsrfToken()
      },
      credentials: 'include'
    })
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.status === 204) {
          dispatch(deleteContactoSuccess(contacto))
        } else {
          throw new Error('Not Found')
        }
      })
      .catch(error => {
        dispatch(deleteContactoError(error))
      })
  }
}

function fetchContactosBegin () {
  return {
    type: ACTION_TYPES.FETCH_CONTACTOS_BEGIN
  }
}

function fetchContactosSusccess (contactos) {
  return {
    type: ACTION_TYPES.FETCH_CONTACTOS_SUCCESS,
    contactos
  }
}

function fetchContactosError (error) {
  return {
    type: ACTION_TYPES.FETCH_CONTACTOS_ERROR,
    error
  }
}

export function fetchContactos (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchContactosBegin())
    const url = new URL(API_BASE_URL + 'contacto/')
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchContactosSusccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispatch(fetchContactosError(error))
      })
  }
}

function updateContactoBegin () {
  return {
    type: ACTION_TYPES.UPDATE_CONTACTO_BEGIN
  }
}

function updateContactoSuccess (contacto) {
  return {
    type: ACTION_TYPES.UPDATE_CONTACTO_SUCCESS,
    contacto
  }
}

function updateContactoError (error) {
  return {
    type: ACTION_TYPES.UPDATE_CONTACTO_ERROR,
    error
  }
}

export function updateContacto (idContacto, opciones) {
  return dispatch => {
    dispatch(updateContactoBegin())
    const url = new URL(API_BASE_URL + 'contacto/' + idContacto + '/')
    return fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRFToken': getCsrfToken()
      },
      credentials: 'include',
      body: JSON.stringify(opciones)
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(updateContactoSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(updateContactoError(err))
      })
  }
}
