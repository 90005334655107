import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/visorMontaje'

export default function visorMontajeReducer (state = initialState.visorMontaje, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_LAST_SCREEN:
      return {
        ...state,
        lastScreen: action.screen
      }

    case ACTION_TYPES.SET_LOCATION:
      if (action.location) action.location.id = state.location ? state.location.id + 1 : 0
      return {
        ...state,
        location: action.location,
        localizacionDescentrada: !action.location ? false : state.localizacionDescentrada
      }

    case ACTION_TYPES.LOCALIZACION_DESCENTRADA_ON:
      return {
        ...state,
        localizacionDescentrada: true
      }

    case ACTION_TYPES.LOCALIZACION_DESCENTRADA_OFF:
      return {
        ...state,
        localizacionDescentrada: false
      }

    case ACTION_TYPES.ADD_CAPA_OCULTA:
      return {
        ...state,
        capasOcultas: state.capasOcultas.concat(action.capa)
      }

    case ACTION_TYPES.REMOVE_CAPA_OCULTA:
      return {
        ...state,
        capasOcultas: state.capasOcultas.filter(c => c.id !== action.capa.id)
      }

    case ACTION_TYPES.RESET_CAPAS_OCULTAS:
      return {
        ...state,
        capasOcultas: []
      }

    case ACTION_TYPES.SET_MAP_BOUNDS:
      return {
        ...state,
        bounds: action.bounds
      }

    case ACTION_TYPES.COMPARTIR_MOSTRAR:
      return {
        ...state,
        compartirVisible: true
      }

    case ACTION_TYPES.COMPARTIR_OCULTAR:
      return {
        ...state,
        compartirVisible: false
      }

    case ACTION_TYPES.MODAL_DESCRIPCION_OCULTAR:
      return {
        ...state,
        modalDescripcionVisible: false
      }

    case ACTION_TYPES.MODAL_DESCRIPCION_MOSTRAR:
      return {
        ...state,
        modalDescripcionVisible: true
      }

    case ACTION_TYPES.SELECCIONAR_CAPA_FONDO:
      return {
        ...state,
        capaFondo: action.capa
      }

    case ACTION_TYPES.SUBMENU_HERRAMIENTAS_MOSTRAR:
      return {
        ...state,
        submenuHerramientasVisible: true
      }

    case ACTION_TYPES.SUBMENU_HERRAMIENTAS_OCULTAR:
      return {
        ...state,
        submenuHerramientasVisible: false
      }

    case ACTION_TYPES.MODAL_CAMBIAR_VISTA_MOSTRAR:
      return {
        ...state,
        modalCambiarVistaVisible: true
      }

    case ACTION_TYPES.MODAL_CAMBIAR_VISTA_OCULTAR:
      return {
        ...state,
        modalCambiarVistaVisible: false
      }

    case ACTION_TYPES.SET_PDI_FOCUSED:
      return {
        ...state,
        pdiFocused: action.pdi
      }

    case ACTION_TYPES.SET_PDI_DESTACADO:
      return {
        ...state,
        pdiDestacado: action.pdi
      }

    case ACTION_TYPES.MODAL_PDIS_MOSTRAR:
      return {
        ...state,
        modalPdisVisible: true,
        filtroPdis: ''
      }

    case ACTION_TYPES.MODAL_PDIS_OCULTAR:
      return {
        ...state,
        modalPdisVisible: false
      }

    case ACTION_TYPES.MAP_ZOOM_CHANGED:
      return {
        ...state,
        mapZoom: action.zoom
      }

    case ACTION_TYPES.SET_FILTRO_PDIS:
      return {
        ...state,
        filtroPdis: action.filtro
      }

    case ACTION_TYPES.MAPA_PDIS_MOSTRAR:
      return {
        ...state,
        mapaPdisVisible: true
      }

    case ACTION_TYPES.MAPA_PDIS_OCULTAR:
      return {
        ...state,
        mapaPdisVisible: false
      }

    case ACTION_TYPES.MODAL_PERSONAL_MOSTRAR:
      return {
        ...state,
        modalPersonalVisible: true
      }

    case ACTION_TYPES.MODAL_PERSONAL_OCULTAR:
      return {
        ...state,
        modalPersonalVisible: false
      }

    case ACTION_TYPES.SET_FILTRO_MODAL_PERSONAL:
      return {
        ...state,
        filtroModalPersonal: action.filtro
      }

    default:
      return {
        ...state
      }
  }
}
