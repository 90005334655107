import React from 'react'
import MapaTraficoItem from './MapaTraficoItem'
import { Link } from 'react-router-dom'

const MapaTraficoList = ({ mapasTrafico }) => {
  return (
    <ul className='recorrido-list scroll-vertical'>
      {mapasTrafico.map(mt =>
        <li key={mt.id}>
          <Link className='recorrido-list__contenido' to={'/trafico/' + mt.id}>
            <MapaTraficoItem mapaTrafico={mt} />
          </Link>
        </li>
      )}
    </ul>
  )
}

export default MapaTraficoList
