import React from 'react'
import '@turf/turf'
import { withTranslation } from 'react-i18next'
import MapaModal from './MapaModal'

import './MapaModalEsquema.css'

function MapaModalEsquema (props) {
  let distanciaTotal = 0.0
  const { recorrido } = props
  const sectores = recorrido.sectores

  sectores.forEach(s => { distanciaTotal += Math.round(s.distancia_metros); return 0 })

  const getNameDificultad = dificultad => {
    const { t } = props
    switch (dificultad) {
      case 'mf':
        return t('Muy fácil')
      case 'f':
        return t('Fácil')
      case 'm':
        return t('Moderado')
      case 'd':
        return t('Difícil')
      default:
        return t('Muy difícil')
    }
  }

  const getTipoDificultad = dificultad => {
    switch (dificultad) {
      case 'mf':
        return (
          <>
            <i className='fas fa-circle recorrido-list-dificultad muyfacil mr-1' />
            {[1, 2, 3, 4].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad muyfacil mr-1' />
            )}
          </>
        )
      case 'f':
        return (
          <>
            {[1, 2].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad facil mr-1' />
            )}
            {[1, 2, 3].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad facil mr-1' />
            )}
          </>
        )
      case 'm':
        return (
          <>
            {[1, 2, 3].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad moderado mr-1' />
            )}
            {[1, 2].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad moderado mr-1' />
            )}
          </>
        )
      case 'd':
        return (
          <>
            {[1, 2, 3, 4].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad dificil mr-1' />
            )}
            <i className='far fa-circle recorrido-list-dificultad dificil mr-1' />
          </>
        )
      default:
        return (
          <>
            {[1, 2, 3, 4, 5].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad muydificil mr-1' />
            )}
          </>
        )
    }
  }

  const getDesnivelAcumulado = sector => {
    let acum = 0
    for (const i in sector.esquemas) {
      acum += sector.esquemas[i].trayecto.desnivel_acumulado_positivo * sector.esquemas[i].repeticiones
    }
    return acum
  }

  const getDesnivelAcumuladoNegativo = sector => {
    let acum = 0
    for (const i in sector.esquemas) {
      acum += sector.esquemas[i].trayecto.desnivel_acumulado_negativo * sector.esquemas[i].repeticiones
    }
    return acum
  }

  const getColorTexto = color => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    const brillo = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
    return brillo > 75 ? '#000' : '#fff'
  }
  const esquemaSimple = recorrido.sectores.length === 1 && recorrido.sectores[0].esquemas.length === 1 &&
    recorrido.sectores[0].esquemas[0].repeticiones === 1
  return (
    <MapaModal {...props}>
      <div className='esquema'>
        {recorrido.sectores.length > 1 ? (
          sectores.map(sector => (
            <React.Fragment key={sector.id}>
              <div
                className='esquema__sector-titulo d-flex justify-content-between align-items-center mt-4 font-weight-bold'
                style={{ backgroundColor: sector.color, color: getColorTexto(sector.color) }}
                key={sector.id}
                onClick={() => props.onClickSector(sector)}
              >
                <div className='esquema__color' colSpan={2}>
                  {(sector.deporte && sector.deporte.icono_svg) &&
                    <span
                      className='esquema__icono'
                      dangerouslySetInnerHTML={{ __html: sector.deporte.icono_svg }}
                    />}
                </div>
                <div className='esquema__nombre'>
                  {sector.nombre}
                </div>
                <div className='esquema__dist text-lowercase'>
                  {props.metrosToUnitsRecorrido(sector.distancia_metros)}
                </div>
              </div>

              <div className='mt-2'>
                {sector.esquemas.map(esquema => (
                  <div
                    className='d-flex justify-content-between align-items-center esquema__div'
                    key={esquema.id} onClick={() => props.onClickEsquema(esquema)}
                  >
                    <div className='esquema__nombre'>
                      <div
                        className='esquema__badge mr-3'
                        style={{ backgroundColor: esquema.trayecto.color, color: getColorTexto(esquema.trayecto.color) }}
                      >
                        <span className='esquema__rep text-lowercase'> x{esquema.repeticiones}</span>
                      </div>
                      <span> {esquema.trayecto.nombre}</span>
                    </div>
                    <div className='esquema__dist text-lowercase'>
                      {esquema.repeticiones}<span>x </span>
                      {props.metrosToUnitsRecorrido(esquema.trayecto.distancia_homologada ||
                        esquema.trayecto.distancia_calculada)}
                    </div>
                  </div>
                ))}
                <div className='esquema__sector-info mt-3'>
                  <div className='d-flex justify-content-between'>
                    <div>
                      <strong><i className='mdi mdi-trending-up' /> </strong>
                      <span className='text-lowercase'>
                        {getDesnivelAcumulado(sector).toFixed(1)} m
                      </span>
                    </div>
                    <div>
                      <strong><i className='mdi mdi-trending-down' /> </strong>
                      <span className='text-lowercase'>
                        {getDesnivelAcumuladoNegativo(sector).toFixed(1)} m
                      </span>
                    </div>
                    <div>
                      <strong><i className='mdi mdi-format-vertical-align-top' /> </strong>
                      <span className='text-lowercase'>
                        {Math.max(...sector.trayectos.map(t => t.altura_maxima)).toFixed(1)} m
                      </span>
                    </div>
                    <div>
                      <strong><i className='mdi mdi-format-vertical-align-bottom' /> </strong>
                      <span className='text-lowercase'>
                        {Math.min(...sector.trayectos.map(t => t.altura_minima)).toFixed(1)} m
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        ) : (
          <div className='esquema__body'>
            {!esquemaSimple && recorrido.sectores[0].esquemas.map(esquema => (
              <div
                className='d-flex esquema__div justify-content-between'
                key={esquema.id} onClick={() => props.onClickEsquema(esquema)}
              >
                <div className='esquema__color'>
                  <div
                    className='esquema__badge'
                    style={{ backgroundColor: esquema.trayecto.color, color: getColorTexto(esquema.trayecto.color) }}
                  >
                    <span className='esquema__rep text-lowercase'>
                      x{esquema.repeticiones}
                    </span>
                  </div>
                </div>
                <div className='esquema__nombre'>
                  {esquema.trayecto.nombre}
                </div>
                <div className='esquema__rep2 text-lowercase'>
                  &nbsp;
                </div>
                <div className='esquema__dist text-lowercase'>
                  {esquema.repeticiones}<span>x </span>
                  {props.metrosToUnitsRecorrido(esquema.trayecto.distancia_homologada ||
                    esquema.trayecto.distancia_calculada)}
                </div>
              </div>
            ))}
          </div>
        )}
        <div className={'esquema__foot mt-3 pt-2' + (esquemaSimple ? ' esquema__foot-simple' : '')}>
          <div className='float-left mr-2 esquema__deporte p-1'>
            <span dangerouslySetInnerHTML={{ __html: recorrido.deporte_icono }} />
          </div>
          <div className='d-flex justify-content-between esquema__totales pr-1 pl-1'>
            <span>
              <i className='mdi mdi-arrow-expand-horizontal mr-1' />
              <span className='font-weight-bold'>
                {props.metrosToUnitsRecorrido(distanciaTotal)}
              </span>
            </span>
            {recorrido.dificultad &&
              <span title={getNameDificultad(recorrido.dificultad)}>
                {getTipoDificultad(recorrido.dificultad)}
              </span>}
            {recorrido.superficie &&
              <span>
                <span className='font-weight-bold'>
                  {recorrido.superficie}
                </span>
              </span>}
          </div>
          <div className='d-flex justify-content-between esquema__totales esquema__totales--altitudes mt-1 pr-1 pl-1'>
            <span>
              <i className='mdi mdi-format-vertical-align-top mr-1' />
              <span className='font-weight-bold'>
                {Math.max(...sectores.map(s =>
                  Math.max(...s.trayectos.map(t => t.altura_maxima)))).toFixed(1)} m
              </span>
            </span>
            <span>
              <i className='mdi mdi-format-vertical-align-bottom mr-1' />
              <span className='font-weight-bold'>
                {Math.min(...sectores.map(s =>
                  Math.min(...s.trayectos.map(t => t.altura_minima)))).toFixed(1)} m
              </span>
            </span>
            {recorrido.sectores.length === 1 &&
              <span>
                <i className='mdi mdi-trending-up mr-1' />
                <span className='font-weight-bold'>
                  {getDesnivelAcumulado(sectores[0]).toFixed(1)} m
                </span>
              </span>}
            {recorrido.sectores.length === 1 &&
              <span>
                <i className='mdi mdi-trending-down mr-1' />
                <span className='font-weight-bold'>
                  {getDesnivelAcumuladoNegativo(sectores[0]).toFixed(1)} m
                </span>
              </span>}
          </div>
        </div>
      </div>
      {recorrido.descripcion &&
        <div className='esquema__totales text-left'>
          {recorrido.descripcion}
        </div>}
    </MapaModal>
  )
}

export default withTranslation()(MapaModalEsquema)
