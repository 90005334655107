/* global fetch */

import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_MAPA_MONTAJE_BEGIN: 'FETCH_MAPA_MONTAJE_BEGIN',
  FETCH_MAPA_MONTAJE_SUCCESS: 'FETCH_MAPA_MONTAJE_SUCCESS',
  FETCH_MAPA_MONTAJE_ERROR: 'FETCH_MAPA_MONTAJE_ERROR',
  FETCH_MAPAS_MONTAJE_BEGIN: 'FETCH_MAPAS_MONTAJE_BEGIN',
  FETCH_MAPAS_MONTAJE_SUCCESS: 'FETCH_MAPAS_MONTAJE_SUCCESS',
  FETCH_MAPAS_MONTAJE_ERROR: 'FETCH_MAPAS_MONTAJE_ERROR'
}

function fetchMapasMontajeBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_MONTAJE_BEGIN
  }
}

function fetchMapasMontajeSuccess (mapasMontaje) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_MONTAJE_SUCCESS,
    mapasMontaje
  }
}

function fetchMapasMontajeError (error) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_MONTAJE_ERROR,
    error
  }
}

export function fetchMapasMontaje (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchMapasMontajeBegin())
    const url = new URL(API_BASE_URL + 'montaje/')
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchMapasMontajeSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchMapasMontajeError(err))
      })
  }
}

export function fetchMapaMontajeBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPA_MONTAJE_BEGIN
  }
}

function fetchMapaMontajeSuccess (mapaMontaje) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_MONTAJE_SUCCESS,
    mapaMontaje
  }
}

function fetchMapaMontajeError (error) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_MONTAJE_ERROR,
    error
  }
}
export function fetchMapaMontaje (id, fields = {}) {
  return dispatch => {
    dispatch(fetchMapaMontajeBegin())
    const url = new URL(API_BASE_URL + 'montaje/' + id + '/')
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    const key = new URLSearchParams(window.location.search).get('key')

    if (key) {
      url.searchParams.append('key', key)
    }
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(fetchMapaMontajeSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchMapaMontajeError(err))
      })
  }
}
