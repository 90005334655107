import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'

import PublicBody from '../components/PublicBody'
import HeaderPublico from '../components/PublicHeader'
import {
  ReactiveBase,
  SelectedFilters, ReactiveList
} from '@appbaseio/reactivesearch'
import SearchHit from '../components/algolia/SearchHit'
import FiltrosPopup from '../components/algolia/FiltrosPopup'
import HerramientasBusqueda from '../components/algolia/HerramientasBusqueda'
import { ELASTIC } from '../constants/api'
import { actions as visorActions } from '../actions/visor'
import { SCREENS } from '../constants/state'
import { fetchRemoteUsuario } from '../actions/usuario'
import Footer from '../components/Footer'
import fetchNotificaciones from '../actions/notificacion'

class BuscarScreen extends React.Component {
  componentDidMount () {
    const { dispatch } = this.props
    dispatch(fetchRemoteUsuario())
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { user, dispatch } = this.props
    if (user && !prevProps.user) {
      dispatch(fetchNotificaciones({ usuario: user.id }))
      this.intervalNoti = setInterval(() => {
        dispatch(fetchNotificaciones({ usuario: user.id }))
      }, 30 * 1000)
    }
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(fetchRemoteUsuario())
    dispatch(visorActions.setLastScreen(SCREENS.BUSCAR))
    clearInterval(this.intervalNoti)
  }

  mostrarFiltros () {
    if (this.refs.filtros) {
      this.refs.filtros.classList.toggle('mostrar')
    }
  }

  render () {
    const { user, notificaciones } = this.props

    return (
      <>
        <Helmet>
          <html lang='es' />
          <title>Encuentra tu recorrido - RaceMapp</title>
          <meta
            name='description'
            content='Encuentra el recorrido de tu prueba, interactivo y fácil.'
          />
        </Helmet>
        <PublicBody>
          <HeaderPublico user={user} notificaciones={notificaciones} />

          <main className='container px-2 px-sm-4 mb-5'>
            <ReactiveBase
              app={ELASTIC.INDEX}
              url={`https://${ELASTIC.SERVER}:${ELASTIC.PORT}`}
              enableAppbase={false}
              headers={{ Authorization: 'ApiKey ' + ELASTIC.KEY }}
            >
              <FiltrosPopup ref='filtros' handleMostrarFiltros={this.mostrarFiltros.bind(this)} />
              <HerramientasBusqueda handleMostrarFiltros={this.mostrarFiltros.bind(this)} />

              <SelectedFilters />

              <ReactiveList
                componentId='result'
                title='Results'
                className='buscador'
                dataField='model'
                from={0}
                size={100}
                pagination={false}
                loader='...Cargando...'
                react={{
                  and: ['searchbox', 'deportes', 'distancias']
                }}
                defaultQuery={() => (
                  { query: { match: { es_visible: true } } }
                )}
                render={({ data }) => (
                  <div className='ais-Hits'>
                    <ul className='ais-Hits-list'>
                      {data.map(item => (
                        <li
                          key={item.id}
                          className='ais-Hits-item'
                        >
                          <SearchHit hit={item} />
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
              />
            </ReactiveBase>

          </main>
          <Footer user={user} />
        </PublicBody>
      </>
    )
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    user: state.usuario.data,
    notificaciones: state.notificaciones.data
  }
}

export default connect(mapStateToProps)(BuscarScreen)
