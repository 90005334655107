import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/sector'
import polyline from '@mapbox/polyline'
import * as turf from '@turf/turf'

export function sectorReducer (state = initialState.sector, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_SECTOR_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }
    case ACTION_TYPES.FETCH_SECTOR_SUCCESS: {
      const data = action.sector
      data.trayectos.forEach(tr => {
        const line = polyline.decode(tr.linea_codificada)
        tr.coordenadas = turf.lineString(line).geometry
        const lineInterpolada = polyline.decode(tr.linea_interpolada_codificada).map((pt, i) =>
          [...pt, tr.elevaciones[i]])
        tr.coordenadas_interpoladas = turf.lineString(lineInterpolada).geometry
      })
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: data
      }
    }
    case ACTION_TYPES.FETCH_SECTOR_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    case ACTION_TYPES.SET_SECTOR:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.sector
      }
    default:
      return state
  }
}
