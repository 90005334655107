import React from 'react'
import MapaModal from './MapaModal'
import { withTranslation, Trans } from 'react-i18next'
import * as turf from '@turf/turf'

import './MapaModalPdis.css'

function MapaModalPdis (props) {
  const capas = []
  props.puntos.forEach(pt => {
    if (pt.bloque.visible_listados) {
      if (capas.map(c => c.id).includes(pt.bloque.capa.id)) {
        const capa = capas.filter(c => c.id === pt.bloque.capa.id)[0]
        capa.puntos.push(pt)
      } else {
        capas.push(pt.bloque.capa)
        capas[capas.length - 1].puntos = [pt]
      }
    }
  })
  const capasFiltradas = capas.filter(c => {
    const nombreCoincide = new RegExp([props.filtroPdis].join('|'), 'i').test(c.nombre)
    const puntosFiltrados = c.puntos.filter(p => new RegExp([props.filtroPdis].join('|'), 'i').test(p.nombre))
    if (!nombreCoincide) {
      c.puntos = puntosFiltrados
    }
    return nombreCoincide || puntosFiltrados.length
  })
    .sort((a, b) => a.orden - b.orden)
  return (
    <MapaModal {...props}>
      {props.puntos.length > 10 &&
        <input
          className='form-control mb-3' type='text'
          placeholder='Filtrar' defaultValue={props.filtroPdis}
          onChange={props.onChangeFiltroPdis}
        />}
      <ul className='mapa-submodal__lista mapa-submodal__lista--pdis'>
        {capasFiltradas.map(c =>
          <li key={c.id} className='card mb-3'>
            <div className='card-header'>
              <h6 className='mb-0'>
                <i className={c.icono + ' mr-2'} />
                {c.nombre}
                <button
                  className='float-right mapa-submodal__btn-visibilidad'
                  onClick={() => props.onClickVisibilidad(c)}
                  title={props.capasOcultas.map(co => co.id).includes(c.id) ? props.t('Capa oculta') : props.t('Capa visible')}
                >
                  {props.capasOcultas.map(co => co.id).includes(c.id)
                    ? <i className='fas fa-eye-slash' />
                    : <i className='fas fa-eye' />}
                </button>
              </h6>
            </div>
            <ul className='list-group'>
              {c.puntos.sort((a, b) =>
                a.agrupacion_orden !== b.agrupacion_orden
                  ? a.agrupacion_orden - b.agrupacion_orden : a.orden_editor - b.orden_editor
              ).map(pt =>
                <li
                  key={pt.id} className='list-group-item text-left d-flex justify-content-between align-items-center'
                  onClick={() => props.onClickPdi(pt, props.mapRef)}
                >
                  <span className='d-flex align-items-center'>
                    {pt.icono
                      ? (
                        <div
                          className='icono-servicio mr-2'
                          style={{
                            backgroundColor: pt.bloque.tipo === 'pl' ? pt.bloque.props_defecto.color_icono : pt.bloque.props_defecto.color,
                            color: pt.bloque.props_defecto.color_fondo_icono
                          }}
                        >
                          <i className={pt.icono.prefijo_fuente + ' ' + pt.icono.prefijo_fuente + '-' + pt.icono.icono} />
                        </div>
                      )
                      : (
                        <div
                          className='icono-servicio mr-2'
                          style={{ backgroundColor: pt.bloque.props_defecto.color, color: '#fff' }}
                        >
                          <i className={pt.bloque.icono_descripcion} />
                        </div>
                      )}
                    {pt.nombre}
                  </span>
                  {pt.bloque.es_contable &&
                    <span>
                      {pt.unidades} <Trans>unidades</Trans>
                    </span>}
                  {pt.bloque.es_medible &&
                    <>
                      {pt.bloque.tipo === 'ln' &&
                        <span className='text-lowercase'>
                          {Math.ceil(turf.length(pt.coordenadas, { units: 'meters' })) + ' m'}
                        </span>}
                      {pt.bloque.tipo === 'pl' &&
                        <span className='text-lowercase'>
                          {turf.area(pt.coordenadas).toFixed(2) + ' m²'}
                        </span>}
                    </>}
                </li>
              )}
            </ul>
          </li>
        )}
      </ul>
    </MapaModal>
  )
}

export default withTranslation()(MapaModalPdis)
