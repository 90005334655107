import React, { Component } from 'react'
import PropTypes from 'prop-types'
import noMapaFound from '../lib/images/captura-not-found-1200x1200.jpg'

export default class ImageFallback extends Component {
  constructor (props) {
    super(props)

    this.state = {
      src: props.src,
      errored: false
    }
  }

  onError () {
    if (!this.state.errored) {
      this.setState({
        src: this.props.fallbackSrc,
        errored: true
      })
    }
  }

  render () {
    const { src } = this.state
    const {
      src: _1,
      fallbackSrc: _2,
      ...props
    } = this.props

    return (
      <img
        src={src}
        onError={this.onError.bind(this)}
        alt={props.alt}
        {...props}
      />
    )
  }
}

ImageFallback.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string
}

export class ImgMapaFallback extends ImageFallback {
  constructor (props) {
    super(props)

    this.state = {
      src: props.src ? props.src : noMapaFound,
      errored: false
    }
  }

  onError () {
    if (!this.state.errored) {
      this.setState({
        src: this.props.fallbackSrc ? this.props.fallbackSrc : noMapaFound,
        errored: true
      })
    }
  }
}
