import React, { useEffect, useRef } from 'react'
import { Marker, Polygon } from 'react-leaflet'
import { connect } from 'react-redux'
import * as turf from '@turf/turf'
import { Icon } from '../../lib/leaflet.awesome-markers-svg'
import * as L from 'leaflet'

function Poligono (props) {
  const refPoligono = React.createRef()
  const markerPos = turf.centroid(props.coordenadas)

  const prevPdiFocused = usePrevious(props.pdiFocused)

  useEffect(() => {
    if (props.pdiFocused && props.pdiFocused.id === props.id && (!prevPdiFocused || prevPdiFocused.id !== props.id)) {
      const bounds = L.geoJSON(props.coordenadas).getBounds()
      props.mapRef.current.leafletElement.flyToBounds(bounds)
    }
  })
  const icon = new Icon({
    iconSize: [props.icono.fondo.ancho, props.icono.fondo.alto],
    iconAnchor: [props.icono.fondo.centro_x, props.icono.fondo.centro_y],
    icon: props.icono.icono,
    iconColor: props.bloque.props_defecto.color_fondo_icono,
    prefix: props.icono.prefijo_fuente,
    markerColor: props.bloque.props_defecto.color_icono,
    svg: props.icono.fondo.svg,
    fontSizeIcon: props.icono.fondo.tamano_fuente_icono,
    classFondo: ' icono-' + props.icono.fondo.nombre,
    destacado: props.pdiFocused && props.pdiFocused.id === props.id
  })

  return (
    <>
      <Polygon
        positions={props.coordenadas.coordinates[0].map(row => [row[1], row[0]])}
        color={props.color}
        weight={props.grosor_linea * (props.pdiFocused && props.pdiFocused.id === props.id ? 2 : 1)}
        fillOpacity={props.opacidad_fondo}
        dashArray={props.bloque.patron.patron}
        ref={refPoligono}
        onClick={() => props.onClick(props)}
      >
        {props.children}
      </Polygon>
      <Marker
        position={[markerPos.geometry.coordinates[1], markerPos.geometry.coordinates[0]]}
        icon={icon}
        onClick={() => props.onClick(props)}
      >
        {props.children}
      </Marker>
    </>
  )
}

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default connect()(Poligono)
