import React from 'react'
import './MapaModalBackdrop.css'

export default function MapaModalBackdrop ({ children, className }) {
  // console.info(props.children.reduce((a, b) => a && b))
  return (
    <div className={`mapa-modal__wrapper ${className || ''}`}>
      {children.reduce((a, b) => a || b) &&
      children}
    </div>
  )
}
