import React from 'react'
import { Circle, Map, Marker } from 'react-leaflet'
import Control from 'react-leaflet-control'
import { Link } from 'react-router-dom'
import i18n from 'i18next'
import { Trans, withTranslation } from 'react-i18next'
import * as turf from '@turf/turf'

import Punto from './Punto'
import Linea from './Linea'
import Trayecto from './Trayecto'
import Poligono from './Poligono'
import SubmenuAjustesVisuales from './SubmenuAjustesVisuales'
import SubmenuPdis from './SubmenuPdis'
import MapaModalBackdrop from './MapaModalBackdrop'
import MapaModalEsquema from './MapaModalEsquema'
import MapaModalPdis from './MapaModalPdis'
import MapaModalPdiSeleccionado from './MapaModalPdiSeleccionado'
import MapaModalCambiarVista from './MapaModalCambiarVista'
import MapaModalPerfiles from './MapaModalPerfiles'
import CapaMapa from '../../components/CapaMapa'
import MapaModalPdisRecorrido from './MapaModalPdisRecorrido'
import SubmenuHerramientas from './SubmenuHerramientas'
import SubmenuVelocidad from './SubmenuVelocidad'
import InfoEsquemAnimacion from './InfoEsquemaAnimacion'
import PerfilEsquema from './PerfilEsquema'
import AnimacionInfoPdi from './AnimacionInfoPdi'
import PopupPdiFocused from './PopupPdiFocused'

import './Mapa.css'

class Mapa extends React.Component {
  constructor (props) {
    super(props)
    this.mapRef = React.createRef()
    this.controlRef = React.createRef()
  }

  componentDidMount () {
    const map = this.mapRef.current ? this.mapRef.current.leafletElement : null
    if (map) this.props.handleMapZoom(map)
  }

  // componentDidUpdate (prevProps, prevState, snapshot) {
  //     const {visor} = this.props
  //
  //     if (prevProps.visor && !prevProps.visor.bounds && visor && visor.bounds) {
  //         console.info('FIT BOUNDS')
  //     }
  //
  //
  // }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { visor } = this.props
    if (!visor.animando && prevProps.visor.animando) {
      setTimeout(() => {
        this.fitBounds()
      }, 500)
    }
  }

  fitBounds () {
    const mapRef = this.mapRef.current
    if (mapRef) {
      mapRef.leafletElement.flyToBounds(this.props.visor.bounds)
    }
  }

  getLinkMapaSiguiente () {
    const { recorrido, recorridos, proyecto, listaId } = this.props
    const siguiente = recorridos.filter(r => r.orden > recorrido.orden)
    if (siguiente && siguiente.length) {
      const r = siguiente[0]
      return '/recorrido/' + r.id + (listaId ? '/' + listaId : '-' + proyecto.slug + '-' + r.slug)
    }
  }

  getLinkMapaAnterior () {
    const { recorrido, recorridos, proyecto, listaId } = this.props
    const anterior = recorridos.filter(r => r.orden < recorrido.orden)
    // console.info(recorrido)
    // console.info(recorridos)
    if (anterior && anterior.length) {
      const r = anterior[anterior.length - 1]
      return '/recorrido/' + r.id + (listaId ? '/' + listaId : '-' + proyecto.slug + '-' + r.slug)
    }
  }

  goMapaNumero () {
    const { recorrido, recorridos, proyecto, listaId } = this.props
    const anterior = recorridos.filter(r => r.orden < recorrido.orden)
    if (anterior && anterior.length) {
      return '/recorrido/' + anterior[anterior.length - 1].id + (listaId ? '/' + listaId : '-' + proyecto.slug + '-' + anterior.slug)
    }
  }

  clickCentrarMapa () {
    this.fitBounds()
    this.props.cerrarMenus()
  }

  getDistanciaPuntoKm (punto, enMetros) {
    const { recorrido } = this.props
    if (punto.bloque.es_pk) {
      for (const i in recorrido.sectores) {
        const pk = recorrido.sectores[i].puntos_km.filter(p => p.punto.id === punto.id)
        if (pk.length) {
          let distancia = pk[0].distancia_oficial
          if (enMetros) {
            switch (recorrido.unidad_distancia) {
              case 'km':
                return distancia * 1000
              case 'ft':
                return turf.convertLength(distancia, 'feet', 'meters')
              case 'mi':
                return turf.convertLength(distancia, 'miles', 'meters')
              default:
                return distancia
            }
          }
          if (recorrido.unidad_distancia === 'm') {
            distancia = distancia / 1000
          } else if (recorrido.unidad_distancia === 'ft') {
            distancia = turf.convertLength(distancia, 'feet', 'miles')
          }
          return parseFloat(distancia) % 1 === 0
            ? parseFloat(distancia).toFixed(0) : parseFloat(distancia).toFixed(1)
        }
      }
      return null
    } else {
      return null
    }
  }

  handleClickBackdrop (e) {
    const controlDom = this.controlRef.current.parentNode.parentNode
    if (e.target.parentNode.parentNode === controlDom || e.target.parentNode === controlDom) {
      this.props.cerrarMenus()
    }
  }

  getDistanciaPdisSector (punto, sector, esquema, paso) {
    const iTra = sector.trayectos.map(t => t.id).indexOf(esquema.trayecto.id)
    let distMetros = 0
    for (const i in sector.esquemas) {
      if (sector.esquemas[i].id !== esquema.id && sector.esquemas[i].orden < esquema.orden) {
        distMetros += sector.esquemas[i].distancia_metros
      } else if (sector.esquemas[i].id === esquema.id) {
        distMetros += sector.trayectos[iTra].distancia_metros * (paso - 1)
        const lineSlice = turf.lineSlice(
          sector.trayectos[iTra].coordenadas.coordinates[0], punto.coordenadas.coordinates, sector.trayectos[iTra].coordenadas
        )
        const distPuntoCalc = turf.length(lineSlice, { units: 'meters' })
        distMetros += distPuntoCalc * sector.trayectos[iTra].distancia_metros / sector.trayectos[iTra].distancia_calculada
      }
    }
    return distMetros
  }

  isVisible (elemento, deRecorrido) {
    const { visor, recorrido } = this.props
    const map = this.mapRef.current ? this.mapRef.current.leafletElement : null
    if (visor.animando) {
      return (elemento.bloque.visible_animacion !== false && elemento.bloque.capa.visible_animacion) ||
        elemento.bloque.visible_animacion
    }
    if (visor.pdiFocused && visor.pdiFocused.id === elemento.id) return true
    if (map && !visor.capasOcultas.map(c => c.id).includes(elemento.bloque.capa.id)) {
      switch (elemento.bloque.tipo_especial) {
        case 'me':
        case 'sa':
        case 'sm':
        case 'is':
        case 'fs':
        case 'sc':
          return true
        default:
          if (visor.animando) return true
          if (elemento.bloque.es_pk) {
            if (['m', 'km'].includes(recorrido.unidad_distancia)) {
              const distPk = this.getDistanciaPuntoKm(elemento, true)
              return (distPk / 1000) % 5 === 0 ? true : visor.mapZoom > 14
            } else {
              const distPk = this.getDistanciaPuntoKm(elemento)
              return (distPk) % 5 === 0 ? true : visor.mapZoom > 14
            }
          } else {
            if (deRecorrido) {
              return visor.mapZoom > 14
            } else {
              return visor.mapZoom > (elemento.bloque.zoom ? elemento.bloque.zoom : elemento.bloque.capa.zoom)
            }
          }
      }
    } else {
      return false
    }
  }

  getDistanciaPdi (pdi) {
    const { recorrido } = this.props
    if (pdi.bloque.es_pk) {
      let pks = []
      recorrido.sectores.forEach(s => {
        pks = pks.concat(s.puntos_km)
      })
      const pk = pks.filter(pk => pk.punto.id === pdi.id)
      return pk.length > 0 ? [this.props.toFixed(pk[0].distancia_oficial, 2) + ' ' + recorrido.unidad_distancia] : []
    } else {
      return this.getPasosDistanciasPdi(pdi)
    }
  }

  getPasosDistanciasPdi (pdi) {
    const { recorrido } = this.props
    const pasosDistancia = []
    recorrido.sectores.forEach(sec => {
      let distAcum = 0
      sec.esquemas.forEach(esq => {
        if (esq.trayecto.id === pdi.trayecto_ancla) {
          const trayecto = sec.trayectos.filter(tr => tr.id === esq.trayecto.id)
          if (trayecto.length) {
            const tramoIni = turf.lineSlice(
              trayecto[0].coordenadas.coordinates[0], pdi.coordenadas.coordinates, trayecto[0].coordenadas
            )
            const distIniTrayecto = turf.length(
              tramoIni, { units: 'meters' }
            ) * (trayecto[0].distancia_metros / trayecto[0].distancia_calculada)
            for (let i = 1; i <= esq.repeticiones; i++) {
              const distPaso = distAcum + distIniTrayecto
              pasosDistancia.push(distPaso)
              distAcum += esq.trayecto.distancia_metros
            }
          }
        } else {
          distAcum += esq.trayecto.distancia_metros * esq.repeticiones
        }
      })
    })
    return pasosDistancia.map(paso => this.props.metrosToUnitsRecorrido(paso))
  }

  getAltiudPunto (pdi) {
    const { recorrido } = this.props
    const trayectos = []
    recorrido.sectores.map(s => s.trayectos).flat(1).forEach(item => {
      // indexOf parece que non funciona en este caso..
      if (!trayectos.find(o => o.id === item.id)) {
        trayectos.push(item)
      }
    })

    const trayecto = trayectos.filter(tr => tr.id === pdi.trayecto_ancla)
    if (trayecto.length) {
      const puntoCercano = turf.nearestPointOnLine(trayecto[0].coordenadas_interpoladas, pdi.coordenadas)
      return trayecto[0].coordenadas_interpoladas.coordinates[puntoCercano.properties.index][2]
    }

    return null
  }

  isTrayectoVisible (trayecto) {
    const { visor } = this.props
    return !visor.animando || (visor.esquemaEnAnimacion && visor.esquemaEnAnimacion.trayecto.id === trayecto.id) ||
      visor.trayectosYaAnimados.includes(trayecto.id)
  }

  getSectorEsquemaInfo () {
    const { visor, recorrido } = this.props
    if (recorrido.sectores.length === 1) {
      return null
    } else {
      return recorrido.sectores.filter(s => s.id === visor.esquemaEnAnimacion.sector)[0]
    }
  }

  getDeporteAnim () {
    const { visor, recorrido } = this.props
    if (recorrido.sectores.length > 1 && visor.esquemaEnAnimacion) {
      const sector = recorrido.sectores.filter(s => s.id === visor.esquemaEnAnimacion.sector)
      if (sector.length > 0 && sector[0].deporte) {
        return sector[0].deporte.icono_svg
      } else {
        return recorrido.deporte_icono
      }
    } else {
      return recorrido.deporte_icono
    }
  }

  getSiguientePdi (pdi) {
    const { visor, pdisProyecto, lineas, poligonos } = this.props
    if (visor.indexPdiOrden !== null && !isNaN(visor.indexPdiOrden)) {
      return visor.pdiOrden[visor.indexPdiOrden + 1]
    } else {
      const pdis = pdisProyecto.concat(lineas, poligonos)
      const pdisOrdenados = pdis.sort((a, b) =>
        a.bloque.capa.orden !== b.bloque.capa.orden
          ? a.bloque.capa.orden - b.bloque.capa.orden
          : (a.agrupacion_orden !== b.agrupacion_orden)
            ? a.agrupacion_orden - b.agrupacion_orden
            : a.orden_editor - b.orden_editor
      )
      return pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) + 1]
    }
  }

  getAnteriorPdi (pdi) {
    const { visor, pdisProyecto, lineas, poligonos } = this.props
    if (visor.indexPdiOrden !== null && !isNaN(visor.indexPdiOrden)) {
      return visor.pdiOrden[visor.indexPdiOrden - 1]
    } else {
      const pdis = pdisProyecto.concat(lineas, poligonos)
      const pdisOrdenados = pdis.sort((a, b) =>
        a.bloque.capa.orden !== b.bloque.capa.orden
          ? a.bloque.capa.orden - b.bloque.capa.orden
          : (a.agrupacion_orden !== b.agrupacion_orden)
            ? a.agrupacion_orden - b.agrupacion_orden
            : a.orden_editor - b.orden_editor
      )
      return pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) - 1]
    }
  }

  render () {
    const {
      t, recorridos, recorrido, mapa, visor, mostrarBackdrop,
      capasFondo, proyecto, pdisProyecto, pdisRecorrido, lineas, poligonos, sectoresPdis, listaId
    } = this.props

    let linkMapaAnterior = null
    let linkMapaSiguiente = null
    // console.info(this.props)
    // console.info('MAPA.RENDER(', visor)
    // console.info('RECORRIDO: ', recorrido)

    if (recorrido && mapa && visor && visor.bounds) {
      if (recorridos.length) {
        // console.info('DRAW')
        // console.info([recorrido, recorridos.length, mapa, mapas.length])
        linkMapaAnterior = this.getLinkMapaAnterior()
        linkMapaSiguiente = this.getLinkMapaSiguiente()

        if (this.controlRef.current) {
          const controlDom = this.controlRef.current.parentNode.parentNode

          // Añadese así para asegurarnos de que solo se executa unha vez.
          // Se non para cada vez que se executa "render" estaríamos engadindo un listerner.
          if (controlDom.getAttribute('listener') !== 'true') {
            controlDom.setAttribute('listener', 'true')
            // controlDom.addEventListener('click', evt => { evt.stopPropagation() })
            controlDom.addEventListener('click', this.handleClickBackdrop.bind(this), false)
          }
          // controlDom.classList.add('control-expanded')
          if (mostrarBackdrop) {
            controlDom.classList.add('control-expanded')
            // const leafletPane = controlDom.parent.parent.children
          } else {
            controlDom.classList.remove('control-expanded')
          }
        }
      }

      // Os trayectos poden estar repetidos en un sector,
      // polo que os simplificamos eliminando duplicados
      const trayectos = []
      recorrido.sectores.map(s => s.trayectos).flat(1).forEach(item => {
        // indexOf parece que non funciona en este caso..
        if (!trayectos.find(o => o.id === item.id)) {
          trayectos.push(item)
        }
      })
      const fondo = visor.capaFondo || recorrido.capa_fondo
      return (
        <Map
          minZoom={4}
          tap={false}
          maxZoom={20}
          center={[0, 0]} zoom={4} ref={this.mapRef}
          zoomControl={false} bounds={visor.bounds} scrollWheelZoom={!mostrarBackdrop}
          worldCopyJump onZoom={e => this.props.handleMapZoom(e.target)}
          onMove={this.props.handleMoveMap}
        >
          <CapaMapa capa={fondo} map={this.mapRef} />
          {visor.location &&
            <>
              <Marker position={visor.location.latlng} />
              <Circle center={visor.location.latlng} radius={visor.location.accuracy} />
            </>}
          {trayectos.map(tra => this.isTrayectoVisible(tra) &&
            <Trayecto
              key={tra.id}
              {...tra}
              animando={visor.animando && (visor.esquemaEnAnimacion && visor.esquemaEnAnimacion.trayecto.id === tra.id)}
              distanciaAnimacion={visor.distanciaTrayectoAnimada}
              mapRef={this.mapRef}
              zoomAnimacion={visor.zoomAnimacion}
              deporte={this.getDeporteAnim()}
            />
          )}
          {pdisRecorrido.map(p => ['sa', 'me', 'sm'].includes(p.bloque.tipo_especial) &&
            (!visor.animando || this.props.isPuntoVisibleAnimacion(p, this.mapRef)) &&
              <Punto
                key={p.id} distancia={this.getDistanciaPuntoKm(p)} {...p}
                onClick={this.props.handleClickPdiMapa} pdiFocused={visor.pdiFocused}
                mapRef={this.mapRef}
              />
          )}
          {visor.mapaPdisRecorridoVisible &&
            <>
              {pdisRecorrido.map(p => !['sa', 'me', 'sm'].includes(p.bloque.tipo_especial) &&
                (!visor.animando || this.props.isPuntoVisibleAnimacion(p, this.mapRef)) &&
                this.isVisible(p, true) &&
                  <Punto
                    key={p.id} distancia={this.getDistanciaPuntoKm(p)} {...p}
                    onClick={this.props.handleClickPdiMapa} pdiFocused={visor.pdiFocused}
                    mapRef={this.mapRef}
                  />
              )}
            </>}
          {visor.mapaPdisProyectoVisible &&
            <>
              {lineas.map(l => this.isVisible(l) &&
                <Linea
                  key={l.id} {...l}
                  onClick={this.props.handleClickPdiMapa}
                  pdiFocused={visor.pdiFocused}
                  mapRef={this.mapRef}
                />
              )}
              {pdisProyecto.map(p => this.isVisible(p) &&
                <Punto
                  key={p.id} {...p}
                  onClick={this.props.handleClickPdiMapa}
                  pdiFocused={visor.pdiFocused}
                  mapRef={this.mapRef}
                />
              )}
              {poligonos.map(p => this.isVisible(p) &&
                <Poligono
                  key={p.id} {...p}
                  onClick={this.props.handleClickPdiMapa}
                  pdiFocused={visor.pdiFocused}
                  mapRef={this.mapRef}
                />
              )}
            </>}
          {!visor.animando && recorridos.length > 1 &&
            <Control position='topright'>
              {linkMapaAnterior ? (
                <Link
                  className='mapa-paginador__left'
                  to={linkMapaAnterior}
                >
                &lsaquo;
                </Link>
              ) : (
                <div className='mapa-paginador__left'>&lsaquo;</div>
              )}
              <ul className='mapa-paginador__paginas'>
                {recorridos.map(r => {
                  let className = 'mapa-paginador__pagina'
                  if (r.id === recorrido.id) {
                    className += ' mapa-paginador__pagina--activa'
                  }
                  return (
                    <li key={r.id} className='mapa-paginador__pagina-li'>
                      <Link
                        className={className}
                        to={`/recorrido/${r.id}` + (listaId ? `/${listaId}` : `-${proyecto.slug}-${r.slug}`)}
                      >
                        <Trans>Recorrido</Trans> {r.nombre}
                      </Link>
                    </li>
                  )
                })}
              </ul>
              {linkMapaSiguiente ? (
                <Link
                  className='mapa-paginador__right'
                  to={linkMapaSiguiente}
                >
                &rsaquo;
                </Link>
              ) : (
                <div className='mapa-paginador__right'>&rsaquo;</div>
              )}
            </Control>}
          {visor.animando && visor.esquemaEnAnimacion &&
            <Control position='topright'>
              <InfoEsquemAnimacion
                esquema={visor.esquemaEnAnimacion}
                sector={this.getSectorEsquemaInfo()}
                perfilPublicado={this.getSectorEsquemaInfo() ? this.getSectorEsquemaInfo().perfil_publicado : recorrido.sectores[0].perfil_publicado}
                distancia={visor.distanciaTrayectoAnimada}
                metrosToUntis={this.props.metrosToUnitsRecorrido}
              />
              {((this.getSectorEsquemaInfo() && this.getSectorEsquemaInfo().perfil_publicado) || recorrido.sectores[0].perfil_publicado) &&
                <PerfilEsquema
                  mapRef={this.mapRef}
                  esquema={visor.esquemaEnAnimacion}
                  trayecto={this.props.getTrayectoEsquemaAnim()}
                  distancia={visor.distanciaTrayectoAnimada}
                />}
            </Control>}
          <Control position='bottomleft'>
            {visor.animando && visor.puntosAnimados.length > 0 &&
              <AnimacionInfoPdi {...visor.puntosAnimados[visor.puntosAnimados.length - 1]} />}
            {mostrarBackdrop &&
              <MapaModalBackdrop className={visor.modalPerfilSeleccionado ? 'perfil__wrapper' : ''}>
                {visor.modalEsquemaVisible &&
                  <MapaModalEsquema
                    className='aparece-arriba'
                    recorrido={recorrido}
                    titulo={i18n.t('Resumen del recorrido')}
                    handleClickCerrar={this.props.cerrarMenus}
                    onClickEsquema={this.props.onClickEsquema}
                    onClickSector={this.props.onClickSector}
                    metrosToUnitsRecorrido={this.props.metrosToUnitsRecorrido}
                  />}
                {visor.modalPdisProyectoVisible &&
                  <MapaModalPdis
                    className='aparece-abajo'
                    puntos={[...pdisProyecto, ...poligonos, ...lineas]}
                    titulo={i18n.t('PDIs de proyecto')}
                    handleClickCerrar={this.props.cerrarMenus}
                    onClickPdi={this.props.handleClickPdiLista}
                    mapRef={this.mapRef}
                    filtroPdis={visor.filtroPdis}
                    onChangeFiltroPdis={this.props.handleChangeFiltroPdis}
                    capasOcultas={visor.capasOcultas}
                    onClickVisibilidad={this.props.handleClickVisibilidadCapa}
                    onClickVisibilidadPdis={this.props.handleClickMapaOcultarPdisProyecto}
                    visibilidadPdis={visor.mapaPdisProyectoVisible}
                  />}
                {visor.modalPdisVisible &&
                  <MapaModalPdisRecorrido
                    className='aparece-abajo'
                    sectoresPdis={sectoresPdis}
                    titulo={i18n.t('PDIs de recorrido')}
                    handleClickCerrar={this.props.cerrarMenus}
                    onClickPdi={this.props.handleClickPdiLista}
                    mapRef={this.mapRef}
                    getDistanciaPuntoKm={this.getDistanciaPuntoKm.bind(this)}
                    pdisRecorrido
                    getDistanciaPdisSector={this.getDistanciaPdisSector.bind(this)}
                    metrosToUnitsRecorrido={this.props.metrosToUnitsRecorrido}
                    filtroPdis={visor.filtroPdisRecorrido}
                    onChangeFiltroPdis={this.props.handleChangeFiltroPdisRecorrido}
                    onClickVisibilidadPdis={this.props.handleClickMapaOcultarPdisRecorrido}
                    visibilidadPdis={visor.mapaPdisRecorridoVisible}
                  />}
                {visor.modalPdiSeleccionado &&
                  <MapaModalPdiSeleccionado
                    className='aparece-abajo'
                    {...visor.modalPdiSeleccionado}
                    handleClickCerrar={this.props.cerrarMenus}
                  />}
                {visor.modalCambiarVistaVisible &&
                  <MapaModalCambiarVista
                    className='aparece-abajo'
                    capasFondo={capasFondo}
                    onClickCapaFondo={this.props.handleClickSeleccionarCapaFondo}
                    titulo={i18n.t('Seleccionar capa de fondo')}
                    handleClickCerrar={this.props.cerrarMenus}
                    fondoActivo={fondo}
                  />}
                {visor.modalPerfilVisible &&
                  <MapaModalPerfiles
                    className='aparece-abajo'
                    titulo={i18n.t('Seleccionar perfil')}
                    handleClickCerrar={this.props.cerrarMenus}
                    onClickPerfil={this.props.handleClickModalSeleccionarPerfil}
                    sectores={recorrido.sectores}
                    proyecto={proyecto}
                    recorrido={recorrido}
                  />}
                {visor.pdiDestacado &&
                  <MapaModalPdiSeleccionado
                    {...visor.pdiDestacado}
                    onClickCerrar={this.props.handleClickCerrarPdiDestacado}
                    className='modal-km__modal aparece-abajo'
                    getAltiudPunto={this.getAltiudPunto.bind(this)}
                    getPasosDistanciasPdi={this.getDistanciaPdi.bind(this)}
                    toFixed={this.props.toFixed}
                  />}
              </MapaModalBackdrop>}
            {visor.location && visor.localizacionDescentrada &&
              <div className='d-flex justify-content-end'>
                <button onClick={() => this.props.handleClickRecentrar(this.mapRef)} className='btn btn-primary mr-4'>
                  <i className='fas fa-location-arrow mr-1' />
                  <Trans>Centrar</Trans>
                </button>
              </div>}
            {visor.pdiFocused &&
              <PopupPdiFocused
                {...visor.pdiFocused}
                getAltiudPunto={this.getAltiudPunto.bind(this)}
                getPasosDistanciasPdi={this.getDistanciaPdi.bind(this)}
                onClickPdiDetalle={this.props.handleClickPdiDestacado}
                onClickCerrar={this.props.handleClickCerrarPdiFocused}
                getSiguientePdi={this.getSiguientePdi.bind(this)}
                getAnteriorPdi={this.getAnteriorPdi.bind(this)}
                onClickPrevPdi={this.props.handleClickPrevPdi}
                onClickSigPdi={this.props.handleClickSigPdi}
                onClickDescargar={this.props.handleClickDescargarLinea}
              />}
            <div className='mapa-botones' ref={this.controlRef}>
              {!visor.animando &&
                <>
                  {visor.submenuAjustesVisualesVisible &&
                    <SubmenuAjustesVisuales
                      visor={visor}
                      fitBounds={this.clickCentrarMapa.bind(this)}
                      mapRef={this.mapRef}
                      onClickCambiarVista={this.props.handleClickCambiarVista}
                      onClickLocate={this.props.handleClickLocate}
                      onClickLocateStop={this.props.handleClickLocateStop}
                    />}
                  {visor.submenuPdisVisible &&
                    <SubmenuPdis
                      visor={visor}
                      onClickMostrarPdisRecorrido={this.props.handleClickMostrarPdisRecorrido}
                      onClickMostrarPdisProyecto={this.props.handleClickMostrarPdisProyecto}
                      onClickMapaOcultarPdisProyecto={this.props.handleClickMapaOcultarPdisProyecto}
                      onClickMapaOcultarPdisRecorrido={this.props.handleClickMapaOcultarPdisRecorrido}
                    />}
                  {visor.submenuHerramientasVisible &&
                    <SubmenuHerramientas
                      mapRef={this.mapRef}
                      onClickDescargarTrack={this.props.handleClickDescargarTrack}
                      onClickAnimacion={this.props.handleClickAnimacion}
                      onClickCambiarVista={this.props.handleClickCambiarVista}
                      canalId={proyecto.canal_id}
                      fitBounds={this.clickCentrarMapa.bind(this)}
                    />}
                  <ul className='btn-group boton-visor__group text-center'>
                    {this.props.handleClickHerramientas &&
                      <li className='btn boton-visor boton-visor--small'>
                        <button onClick={this.props.handleClickHerramientas} title={t('Herramientas')}>
                          <i className='fas fa-tools' />
                        </button>
                      </li>}
                    {this.props.handleClickPdis && (lineas.length > 0 || pdisProyecto.length > 0 || poligonos.length > 0) &&
                      <li className='btn boton-visor boton-visor--small'>
                        <button onClick={this.props.handleClickMostrarPdisProyecto} title={t('Puntos de Interés')}>
                          <i className='fas fa-map-marker-alt' />
                        </button>
                      </li>}
                    {this.props.handleClickPdis && this.props.sectoresPdis.length > 0 &&
                      <li className='btn boton-visor boton-visor--small'>
                        <button onClick={this.props.handleClickMostrarPdisRecorrido} title={t('Puntos de Recorrido')}>
                          <i className='fas rmp-pr' />
                        </button>
                      </li>}
                    {recorrido.sectores.reduce((a, b) => a || b.perfil_publicado, false) && this.props.handleClickMenuPerfil &&
                      <li className='btn boton-visor boton-visor--small'>
                        <button onClick={this.props.handleClickMenuPerfil} title={t('Perfiles')}>
                          <i className='fas fa-chart-area' />
                        </button>
                      </li>}
                    {this.props.handleClickAnimacion &&
                      <li className='btn boton-visor boton-visor--small'>
                        <button onClick={() => this.props.handleClickAnimacion(this.mapRef)} title={t('Animación')}>
                          <i className='fas fa-play' />
                        </button>
                      </li>}
                    {!visor.location
                      ? (
                        <li className='btn boton-visor boton-visor--small' title={t('Centrar en mi')}>
                          <button onClick={() => this.props.handleClickLocate(this.mapRef)}>
                            <i className='fas fa-compass' />
                          </button>
                        </li>
                      ) : (
                        <li className='btn boton-visor boton-visor--small boton-visor--activo' title={t('Parar localización')}>
                          <button onClick={() => this.props.handleClickLocateStop(this.mapRef)}>
                            <span className='fa-stack fa-2x'>
                              <i className='fas fa-compass fa-stack-1x' />
                              <i className='fas fa-ban fa-stack-2x' />
                            </span>
                          </button>
                        </li>
                      )}
                  </ul>
                </>}
              {visor.animando &&
                <>
                  {visor.submenuVelocidadVisible &&
                    <SubmenuVelocidad onClickMultiplicado={this.props.handleClickMultiplicadorVelocidad} />}
                  <ul className='btn-group boton-visor__group text-center'>
                    <li className='btn boton-visor boton-visor--small'>
                      <button
                        onClick={() => this.props.handleClickReiniciarAnimacion(this.mapRef)}
                        title={t('Volver a inicio')}
                      >
                        <i className='fas fa-undo' />
                      </button>
                    </li>
                    <li className='btn boton-visor boton-visor--small' title={t('Parar')}>
                      <button onClick={this.props.handleClickStopAnimation}>
                        <i className='fas fa-stop' />
                      </button>
                    </li>
                    <li className='btn boton-visor boton-visor--small'>
                      <button onClick={e => this.props.handleClickPauseAnimation(e)}>
                        {visor.animacionPausada
                          ? <i className='fas fa-play' title={t('Iniciar')} />
                          : <i className='fas fa-pause' title={t('Parar')} />}
                      </button>
                    </li>
                    <li className={'btn boton-visor boton-visor--small' + (visor.animacionBucle ? ' boton-visor--activo' : '')}>
                      <button onClick={this.props.handleClickAnimacionBucle} title={t('Poner en bucle')}>
                        <i className='fas fa-retweet' />
                      </button>
                    </li>
                    <li className='btn boton-visor boton-visor--small'>
                      <button onClick={this.props.handleClickVelocidadAnimacion} title={t('Velocidad de animación')}>
                        <i className='boton-visor__icon-texto'>{visor.multiplicadorVelocidadAnimacion} x</i>
                      </button>
                    </li>
                  </ul>
                </>}
            </div>
          </Control>
        </Map>
      )
    } else {
      return null
    }
  }
}

export default withTranslation()(Mapa)
