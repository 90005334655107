import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { rootReducer } from '../reducers/index'
import { initialState } from '../constants/state'

const middlewares = [thunk]

// export default function configureStore() {
//     return createStore(rootReducer, initialState, applyMiddleware(...middlewares));
// }

const store = createStore(
  rootReducer,
  initialState,
  applyMiddleware(...middlewares)
)

export default store
