import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/categoriaPortada'

export default function categoriasPortadaReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CATEGORIAS_PORTADA_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        error: null,
        data: null
      }

    case ACTION_TYPES.FETCH_CATEGORIAS_PORTADA_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    case ACTION_TYPES.FETCH_CATEGORIAS_PORTADA_SUCCESS:
      return {
        ...state,
        data: action.categorias,
        status: REQUEST_STATUS.SUCCEEDED
      }

    default:
      return {
        ...state
      }
  }
}
