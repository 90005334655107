import React from 'react'
import { Navbar, NavDropdown, Button } from 'react-bootstrap'
import { Trans } from 'react-i18next'

import logo from '../lib/logos/logo-public.svg'
import langES from '../lib/logos/lang-es.svg'
import langEN from '../lib/logos/lang-en.svg'

import './PublicHeader.css'
import { SERVER_DOMAIN } from '../constants/api'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import Notificacion from './visor/Notificacion'

export default function (props) {
  return (
    <>
      <Navbar
        collapseOnSelect
        expand='md'
        bg='dark'
        variant='dark'
        sticky='top'
        className={(props.portada ? 'portada ' : '') + 'justify-content-between'}
      >
        <div className='container'>
          {props.languages ? (
            <form method='post' action={`${SERVER_DOMAIN}/i18n/setlang/`}>
              <ul id='idiomas' className='d-flex my-md-2'>
                <li className='idioma  selected'>
                  <button type='submit' name='language' value='es'>
                    <img src={langES} className='bandera' alt='Seleccionar Español' />
                    <span className='texto'><Trans>español</Trans></span>
                  </button>
                </li>
                <li className='idioma '>
                  <button type='submit' name='language' value='en'>
                    <img src={langEN} className='bandera' alt='Seleccionar Inglés' />
                    <span className='texto'><Trans>English</Trans></span>
                  </button>
                </li>
              </ul>
            </form>
          ) : (
            <Navbar.Brand>
              <Link to='/'>
                <img
                  alt=''
                  src={logo}
                  className='d-inline-block align-top logo'
                />
              </Link>
            </Navbar.Brand>
          )}
          {props.user && props.notificaciones &&
            <div>
              {props.notificaciones.filter(n => !n.leida).length > 0 &&
                <span className='d-block d-md-none home__badge-notificacion home__badge-notificacion-mobile position-absolute badge badge-info'>
                  {props.notificaciones.filter(n => !n.leida).length}
                </span>}
            </div>}
          <Navbar.Toggle aria-controls='responsive-navbar-nav' />
          <Navbar.Collapse id='responsive-navbar-nav'>
            {props.user ? (
              <ul className='navbar-nav ml-auto'>
                <form method='post' action={`${SERVER_DOMAIN}/i18n/setlang/`}>
                  <NavDropdown
                    className='nav-link nav-link-icon px-md-0'
                    title={
                      <>
                        <i className='fas fa-globe fa-2x' />
                        <div className='texto'><Trans>IDIOMA</Trans></div>
                      </>
                    }
                  >
                    <NavDropdown.Item
                      as={Button}
                      variant='secondary'
                      className='dropdown-item-recorridos home__list-language d-md-flex align-items-center'
                      type='submit' name='language' value='es'
                    >
                      <img className='home__img-lang mr-2 text-left' src={langES} alt='Castellano' />
                      <Trans>Castellano</Trans>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Button}
                      variant='secondary'
                      className='dropdown-item-recorridos home__list-language d-md-flex align-items-center'
                      type='submit' name='language' value='en'
                    >
                      <img className='home__img-lang mr-2 text-left' src={langEN} alt='English' />
                      <Trans>Inglés</Trans>
                    </NavDropdown.Item>
                  </NavDropdown>
                </form>
                <li className='nav-item active'>
                  <a className='nav-link nav-link-icon px-md-0' href='/ayuda/'>
                    <i className='fas fa-2x fa-question-circle' />
                    <div className='texto'><Trans>AYUDA</Trans></div>
                  </a>
                </li>

                <li className='nav-item active'>
                  <a className='nav-link nav-link-icon px-md-0' href='/proyecto/'>
                    <i className='fas fa-2x fa-box' />
                    <div className='texto'><Trans>PROYECTOS</Trans></div>
                  </a>
                </li>
                <NavDropdown
                  className='nav-link nav-link-icon'
                  alignRight
                  disabled={props.notificaciones.length === 0}
                  title={
                    <div className='home__notificaciones'>
                      <i className='fas fa-bell fa-2x' />
                      <div className='texto'><Trans>NOTIFICACIONES</Trans>
                        {props.notificaciones.filter(n => !n.leida).length > 0 &&
                          <span className='home__badge-notificacion position-absolute ml-3 badge badge-info'>
                            {props.notificaciones.filter(n => !n.leida).length}
                          </span>}
                      </div>
                    </div>
                  }
                >
                  {props.notificaciones.sort((a, b) => b.id - a.id).map(n =>
                    <NavDropdown.Item
                      key={n.id} as='div' className={'notificacion' + (n.leida ? ' notificacion--leida' : '')}
                    >
                      <Notificacion notificacion={n} yo={props.user} />
                    </NavDropdown.Item>
                  )}
                </NavDropdown>
                <NavDropdown
                  className='nav-link nav-link-icon' alignRight title={
                    <div className=''>
                      <FontAwesomeIcon className='fas fa-2x' icon={faUser} />
                      <div className='texto'><Trans>PERFIL</Trans></div>
                    </div>
                  }
                >
                  <NavDropdown.Item href='/proyecto/' className='dropdown-item-recorridos'>
                    <i className='fas fa-box mr-2' />
                    <Trans>Mis proyectos</Trans>
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/usuario/subscripciones/' className='dropdown-item-recorridos'>
                    <i className='fas fa-newspaper mr-2' />
                    <Trans>Mis publicaciones</Trans>
                  </NavDropdown.Item>
                  <NavDropdown.Item className='dropdown-item-recorridos' as={Link} to='/contactos'>
                    <i className='fas fa-mail-bulk mr-2' />
                    <Trans>Mis contactos</Trans>
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href='/usuario/perfil/' className='dropdown-item-recorridos'>
                    <i className='fas fa-user-edit mr-2' /><Trans>Editar mis datos</Trans>
                  </NavDropdown.Item>
                  <NavDropdown.Item href='/usuario/salir/' className='dropdown-item-recorridos'>
                    <i className='fas fa-power-off mr-2' /><Trans>Desconectarse</Trans>
                  </NavDropdown.Item>
                </NavDropdown>
              </ul>

            ) : (

              <ul className='navbar-nav ml-auto'>
                <form method='post' action={`${SERVER_DOMAIN}/i18n/setlang/`}>
                  <NavDropdown
                    className='nav-link nav-link-icon' alignRight
                    title={
                      <>
                        <i className='home__icono-idiomas fas fa-globe fa-2x' />
                        <div className='texto'><Trans>IDIOMA</Trans></div>
                      </>
                    }
                  >
                    <NavDropdown.Item
                      as={Button}
                      variant='secondary'
                      className='dropdown-item-recorridos text-uppercase home__list-language d-flex justify-content-center justify-content-md-start'
                      type='submit' name='language' value='es'
                    >
                      <img className='home__img-lang mr-2' src={langES} alt='Castellano' />
                      <Trans>Castellano</Trans>
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Button}
                      variant='secondary'
                      className='dropdown-item-recorridos text-uppercase home__list-language d-flex justify-content-center justify-content-md-start'
                      type='submit' name='language' value='en'
                    >
                      <img className='home__img-lang mr-2' src={langEN} alt='English' />
                      <Trans>Inglés</Trans>
                    </NavDropdown.Item>
                  </NavDropdown>
                </form>
                <li className='nav-item active'>
                  <a className='nav-link nav-link-icon px-md-0' href='/ayuda/'>
                    <i className='fas fa-2x fa-question-circle' />
                    <div className='texto'><Trans>AYUDA</Trans></div>
                  </a>
                </li>
                <li className='nav-item active'>
                  <a className='nav-link nav-link-icon px-md-0' href='/usuario/registro/'>
                    <i className='fas fa-2x fa-marker' />
                    <div className='texto'><Trans>REGISTRAME</Trans></div>
                  </a>
                </li>
                <li className='nav-item active px-md-0'>
                  <a className='nav-link nav-link-icon' href='/usuario/entrar/'>
                    <i className='fas fa-2x fa-user' />
                    <div className='texto'><Trans>ENTRAR</Trans></div>
                  </a>
                </li>

              </ul>
            )}
          </Navbar.Collapse>
        </div>
      </Navbar>
    </>
  )
}
