/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_LINEAS_BEGIN: 'FETCH_LINEAS_BEGIN',
  FETCH_LINEAS_SUCCESS: 'FETCH_LINEAS_SUCCESS',
  FETCH_LINEAS_ERROR: 'FETCH_LINEAS_ERROR'
}

function fetchLineasBegin () {
  return {
    type: ACTION_TYPES.FETCH_LINEAS_BEGIN
  }
}

function fetchLineasSuccess (lineas) {
  return {
    type: ACTION_TYPES.FETCH_LINEAS_SUCCESS,
    lineas: lineas
  }
}

function fetchLineasError (err) {
  return {
    type: ACTION_TYPES.FETCH_LINEAS_ERROR,
    error: err
  }
}

export function fetchRemoteLineas (params, fields = []) {
  return dispatch => {
    dispatch(fetchLineasBegin())
    if (!('agrupacion__proyecto' in params)) {
      dispatch(fetchLineasError('Debe especificarse un proyecto_id'))
    } else {
      const url = new URL(API_BASE_URL + 'linea/')
      if (fields && fields.length) {
        params.fields = fields.join(',')
      }
      Object.keys(params).forEach(
        key => url.searchParams.append(key, params[key])
      )
      return fetch(url, {
        credentials: 'include'
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw res.error
          } else if (res && res.length) {
            dispatch(fetchLineasSuccess(res))
          } else {
            throw new Error('Not found')
          }
        })
        .catch(error => {
          dispatch(fetchLineasError(error))
        })
    }
  }
}
