// Métodos de leaflet.awesome-markers sobreescritos para que funcionen svg's
import * as L from 'leaflet'

require('leaflet.awesome-markers')

export const Icon = L.AwesomeMarkers.Icon.extend({
  createIcon: function () {
    const div = document.createElement('div')
    const options = this.options

    if (options.bgPos) {
      div.style.backgroundPosition =
        (-options.bgPos.x) + 'px ' + (-options.bgPos.y) + 'px'
    }

    this._setIconStyles(div, ' icon-' + options.markerColor)

    if (options.icon || options.text) {
      div.innerHTML += '<div class="marcador__recuadro">' + this._createInner() + '</div>'
    }

    div.classList.add('marcador__wrapper')
    if (options.classFondo) {
      div.classList += (options.classFondo)
    }

    if (options.destacado) {
      div.classList += ' destacado'
    }

    return div
  },

  _createInner: function () {
    let iconClass
    let iconSpinClass = ''
    let iconColorClass = ''
    let iconColorStyle = ''
    const options = this.options

    if (options.icon && options.icon.slice(0, options.prefix.length + 1) === options.prefix + '-') {
      iconClass = options.icon
    } else {
      iconClass = options.prefix + '-' + options.icon
    }

    if (options.spin && typeof options.spinClass === 'string') {
      iconSpinClass = options.spinClass
    }

    if (options.iconColor) {
      if (options.iconColor === 'white' || options.iconColor === 'black') {
        iconColorClass = 'icon-' + options.iconColor
      } else {
        iconColorStyle = 'style=\'color: ' + options.iconColor + ';\''
      }
    }

    if (options.text) {
      return this.getText()
    }

    return '<i ' + iconColorStyle + ' class=\'marcador__icono ' +
      options.extraClasses + ' ' + options.prefix + ' ' + iconClass +
      ' ' + iconSpinClass + ' ' + iconColorClass + '\'></i>'
  },

  getText () {
    const options = this.options
    const number = parseFloat(options.text)
    if (isNaN(number)) return '<i style="color: ' + options.iconColor + '" class="icon--text">' + options.text + '</i>'
    else {
      const desborde = number % 1
      let fontSize
      if (number.toFixed(1).length > 4) {
        fontSize = options.fontSizeText * 3 / (number.toFixed(1).length - 1)
      }
      const style = 'style=" color: ' + options.iconColor + ';' + (fontSize ? 'font-size: ' + fontSize + 'px"' : '"')
      if (desborde === 0) {
        return '<i ' + style + ' class="icon--text">' + options.text + '</i>'
      } else {
        return '<i ' + style + ' class="icon--text">' + Math.floor(number) +
          '<span style="font-size: ' + this.getFontSizeTextDecimales(fontSize) + 'px">.' +
          (desborde * 10).toFixed(0) + '</span></i>'
      }
    }
  },

  getFontSizeTextDecimales (fontSize) {
    if (fontSize) {
      return fontSize * 0.75
    } else if (this.options.fontSizeText) {
      return this.options.fontSizeText * 0.75
    } else {
      return 6
    }
  },

  _setIconStyles: function (img, name) {
    const options = this.options
    let size = L.point(options[name === 'shadow' ? 'shadowSize' : 'iconSize'])
    let anchor

    if (name === 'shadow') {
      anchor = L.point(options.shadowAnchor || options.iconAnchor)
    } else {
      anchor = L.point(options.iconAnchor)
    }

    if (!anchor && size) {
      anchor = size.divideBy(2, true)
    }

    if (name !== 'shadow') {
      img.innerHTML += options.svg
      img.style.fill = options.markerColor
    } else {
      img.className = 'awesome-marker-' + name + ' ' + options.className
    }

    // img.className = 'awesome-marker-' + name + ' ' + options.className;

    if (options.destacado) {
      anchor = {
        x: anchor.x * 1.5,
        y: anchor.y * 1.5
      }

      size = {
        x: size.x * 1.5,
        y: size.y * 1.5
      }
    }
    if (anchor) {
      img.style.marginLeft = (-anchor.x) + 'px'
      img.style.marginTop = (-anchor.y) + 'px'
    }

    if (size) {
      img.style.width = size.x + 'px'
      img.style.height = size.y + 'px'
    }
  }
})
