import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import { fetchRemoteUsuario } from '../actions/usuario'
import { fetchContactos } from '../actions/contacto'
import { SERVER_DOMAIN } from '../constants/api'
import PublicBody from '../components/PublicBody'
import HeaderPublico from '../components/PublicHeader'
import FichaContacto from '../components/contacto/FichaContacto'

import './MisContactosScreen.css'
import fetchNotificaciones from '../actions/notificacion'

class MisContactosScreen extends Component {
  componentDidMount () {
    const { usuario, dispatch } = this.props
    if (!usuario) {
      dispatch(fetchRemoteUsuario())
    } else {
      dispatch(fetchContactos({ user: usuario.id }))
    }
    this.setState({
      email: false
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { usuario, dispatch } = this.props
    if (!prevProps.usuario && usuario) {
      dispatch(fetchContactos({ user: usuario.id }))
      dispatch(fetchNotificaciones({ usuario: usuario.id }))
      this.intervalNoti = setInterval(() => {
        dispatch(fetchNotificaciones({ usuario: usuario.id }))
      }, 30 * 1000)
    }
  }

  componentWillUnmount () {
    clearInterval(this.intervalNoti)
  }

  render () {
    const { usuario, usuarioError, contactos, notificaciones } = this.props
    if (!usuario && usuarioError) {
      return <div><h5><Trans>Error: necesitas <a href={SERVER_DOMAIN + '/usuario/entrar/'}>iniciar sesion</a></Trans></h5></div>
    }
    return (
      <PublicBody>
        <HeaderPublico user={usuario} notificaciones={notificaciones} />
        <div className='contactos container mt-5'>
          <div className='row'>
            <div className='col-12 col-lg-6 my-3'>
              <div className='background-light p-4 '>
                <h3><i className='fas fa-user-friends px-2' /><Trans>Mis contactos</Trans></h3>
                {contactos.filter(c => c.estado === 'a').length > 0
                  ? (
                    <ul>
                      {contactos.filter(c => c.estado === 'a').map(c =>
                        <li key={c.id}>
                          <FichaContacto
                            yo={usuario} usuario={c.user_1 === usuario.id ? c.user_2_obj : c.user_1_obj} contactos={contactos}
                          />
                        </li>
                      )}
                    </ul>
                  ) : <p><Trans>Aún no se ha añadido ningún contacto</Trans></p>}
              </div>
            </div>
            <div className='col-12 col-lg-6 my-3'>
              <div className='background-dark p-4 '>
                <h3><i className='fas fa-comment-medical px-2' /><Trans>Invitaciones enviadas</Trans></h3>
                {contactos.filter(c => c.user_1 === usuario.id && (c.estado === 'p' || c.estado === 'r')).length > 0
                  ? (
                    <ul>
                      {contactos.filter(c => c.user_1 === usuario.id && (c.estado === 'p' || c.estado === 'r')).map(c =>
                        <li key={c.id}>
                          <FichaContacto yo={usuario} usuario={c.user_2_obj} contactos={contactos} />
                        </li>
                      )}
                    </ul>
                  ) : <p><Trans>Aún no has enviado ninguna invitación</Trans></p>}
              </div>
            </div>
            <div className='col-12 col-lg-6 my-3'>
              <div className='background-dark p-4 '>
                <h3><i className='fas fa-mail-bulk px-2' /><Trans>Invitaciones recibidas</Trans></h3>
                {contactos.filter(c => c.user_2 === usuario.id && (c.estado === 'p' || c.estado === 'r')).length > 0
                  ? (
                    <ul>
                      {contactos.filter(c => c.user_2 === usuario.id && (c.estado === 'p' || c.estado === 'r')).sort((a, b) => a.estado > b.estado ? 1 : -1).map(c =>
                        <li key={c.id}>
                          <FichaContacto yo={usuario} usuario={c.user_1_obj} contactos={contactos} />
                        </li>
                      )}
                    </ul>
                  ) : <p><Trans>No tienes ninguna invitación pendiente</Trans></p>}
              </div>
            </div>
            <Link
              to='/contactos/add'
              className='btn btn-secondary position-fixed p-3 rounded-circle contactos__btn-mas d-flex justify-content-center align-items-center'
            >
              <i className='fas fa-plus fa-2x' />
            </Link>
          </div>
        </div>
      </PublicBody>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.data,
  usuarioError: state.usuario.error,
  contactos: state.contactos.data,
  notificaciones: state.notificaciones.data
})

export default connect(mapStateToProps)(MisContactosScreen)
