/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_POLIGONOS_BEGIN: 'FETCH_POLIGONOS_BEGIN',
  FETCH_POLIGONOS_SUCCESS: 'FETCH_POLIGONOS_SUCCESS',
  FETCH_POLIGONOS_ERROR: 'FETCH_POLIGONOS_ERROR'
}

function fetchPolignosBegin () {
  return {
    type: ACTION_TYPES.FETCH_POLIGONOS_BEGIN
  }
}

function fetchPoligonosSuccess (poligonos) {
  return {
    type: ACTION_TYPES.FETCH_POLIGONOS_SUCCESS,
    poligonos: poligonos
  }
}

function fetchPoligonosError (err) {
  return {
    type: ACTION_TYPES.FETCH_POLIGONOS_ERROR,
    error: err
  }
}

export function fetchRemotePoligonos (params, fields = []) {
  return dispatch => {
    dispatch(fetchPolignosBegin())
    if (!('agrupacion__proyecto' in params)) {
      dispatch(fetchPoligonosError('Debe especificarse un proyecto_id'))
    } else {
      const url = new URL(API_BASE_URL + 'poligono/')
      if (fields && fields.length) {
        params.fields = fields.join(',')
      }
      Object.keys(params).forEach(
        key => url.searchParams.append(key, params[key])
      )
      return fetch(url, {
        credentials: 'include'
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw res.error
          } else if (res && res.length) {
            dispatch(fetchPoligonosSuccess(res))
          } else {
            throw new Error('Not found')
          }
        })
        .catch(error => {
          dispatch(fetchPoligonosError(error))
        })
    }
  }
}
