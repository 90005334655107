import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/dom'

export function domReducer (state = initialState.dom, action) {
  switch (action.type) {
    case ACTION_TYPES.DOM_CARGADO:
      return {
        ...state,
        cargado: true
      }
    case ACTION_TYPES.DOM_SET_EN_IFRAME:
      return {
        ...state,
        estaEnIframe: action.iframe
      }
    case ACTION_TYPES.DOM_SET_START_PERFIL:
      return {
        ...state,
        iniciadoEnPerfil: action.start
      }
    case ACTION_TYPES.DOM_SET_START_RECORRIDO:
      return {
        ...state,
        iniciadoEnRecorrido: action.start
      }
    case ACTION_TYPES.DOM_SET_START_PROYECTO:
      return {
        ...state,
        iniciadoEnProyecto: action.start
      }
    default:
      return state
  }
}
