import { combineReducers } from 'redux'
import visorReducer from './visor'
import { usuarioReducer, usuariosBuscadosReducer } from './usuario'
import { proyectoReducer } from './proyecto'
import { mapaReducer, mapasReducer } from './mapa'
import { recorridoReducer, recorridosReducer } from './recorrido'
import { fondosReducer } from './fondos'
import { sectorReducer } from './sector'
import { pdisProyectoReducer } from './pdi'
import { lineasReducer } from './lineas'
import { poligonosReducer } from './poligonos'
import { canalReducer } from './canal'
import { listaReducer } from './lista'
import { visorListaReducer } from './visorLista'
import perfilReducer from './perfil'
import encargoReducer from './encargo'
import categoriasPortadaReducer from './categoriaPortada'
import visitaRecorridoReducer from './visitaRecorrido'
import { domReducer } from './dom'
import mapaPortadaReducer from './mapaPortada'
import {
  contactoActualizadoReducer,
  contactoCreadoReducer,
  contactoEliminadoReducer,
  contactosReducer
} from './contacto'
import { notificacionesReducer, notificacionUpdateReducer } from './notificacion'
import { mapasVoluntariosReducer, mapaVoluntariosReducer } from './voluntarios'
import { voluntariosElementosReducer } from './voluntariosElementos'
import visorPersonalReducer from './visorPersonal'
import proyectoScreenReducer from './proyectoScreen'
import { mapasTraficoReducer, mapaTraficoReducer } from './mapaTrafico'
import visorTraficoReducer from './visorTrafico'
import traficoElementosReducer from './traficoElementos'
import { mapaMontajeReducer, mapasMontajeReducer } from './mapaMontaje'
import visorMontajeReducer from './visorMontaje'
import montajeElementosReducer from './montajeElementos'
import portadaReducer from './portada'

export const rootReducer = combineReducers({
  dom: domReducer,
  usuario: usuarioReducer,
  proyecto: proyectoReducer,
  mapa: mapaReducer,
  mapas: mapasReducer,
  recorridos: recorridosReducer,
  recorrido: recorridoReducer,
  fondos: fondosReducer,
  sector: sectorReducer,
  pdisProyecto: pdisProyectoReducer,
  lineas: lineasReducer,
  poligonos: poligonosReducer,
  canal: canalReducer,
  lista: listaReducer,
  visorLista: visorListaReducer,
  visor: visorReducer,
  perfil: perfilReducer,
  createEncargo: encargoReducer,
  categoriasPortada: categoriasPortadaReducer,
  visitaRecorrido: visitaRecorridoReducer,
  usuariosBuscados: usuariosBuscadosReducer,
  contactoCreado: contactoCreadoReducer,
  contactoEliminado: contactoEliminadoReducer,
  contactos: contactosReducer,
  contactoActualizado: contactoActualizadoReducer,
  notificaciones: notificacionesReducer,
  mapaPortada: mapaPortadaReducer,
  mapaVoluntarios: mapaVoluntariosReducer,
  mapasVoluntarios: mapasVoluntariosReducer,
  voluntariosElementos: voluntariosElementosReducer,
  visorPersonal: visorPersonalReducer,
  proyectoScreen: proyectoScreenReducer,
  notificacionUpdate: notificacionUpdateReducer,
  mapasTrafico: mapasTraficoReducer,
  mapaTrafico: mapaTraficoReducer,
  visorTrafico: visorTraficoReducer,
  traficoElementos: traficoElementosReducer,
  mapasMontaje: mapasMontajeReducer,
  mapaMontaje: mapaMontajeReducer,
  visorMontaje: visorMontajeReducer,
  montajeElementos: montajeElementosReducer,
  portada: portadaReducer
})
