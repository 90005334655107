import React from 'react'
import MapaModal from '../visor/MapaModal'
import { Trans } from 'react-i18next'
import * as turf from '@turf/turf'

const MapaModalDescripcion = props => {
  const bloques = []
  props.elementos.forEach(el => {
    if (!bloques.map(b => b.id).includes(el.bloque.id)) {
      bloques.push(el.bloque)
    }
  })
  bloques.sort((a, b) => a.capa.id === b.capa.id ? a.orden - b.orden : a.capa.orden - b.capa.orden)
  bloques.forEach(b => {
    const usuarios = props.elementos.filter(e => e.bloque.id === b.id).map(e => e.usuarios).flat()
    b.usuarios = usuarios.filter((us, i) => usuarios.map(user => user.id).indexOf(us.id) === i)
  })
  return (
    <MapaModal {...props}>
      <div className='container'>
        <div className='row'>
          <div className='col-12 p-0'>
            <ul className='list-group list-group-flush modal-descripcion__lista-ubicaciones'>
              {bloques.map(bl =>
                <li key={bl.id} className='list-group-item text-left d-flex'>
                  <div>
                    <div className='modal-descripcion__icono-bloques d-flex align-items-center justify-content-center'>
                      <i className={bl.icono_descripcion} />
                    </div>
                  </div>
                  <div className='ml-3'>
                    <h6 className='font-weight-bold m-0'>{bl.nombre}</h6>
                    <div>
                      <p className='m-0'>
                        <Trans>Ubicaciones</Trans>: {props.elementos.filter(el => el.bloque.id === bl.id).length}
                      </p>
                      {bl.es_contable &&
                        <p className='m-0'>
                          <Trans>Nº de unidades</Trans>: {props.elementos.filter(el => el.bloque.id === bl.id).reduce((ac, current) => ac + current.unidades, 0)}
                        </p>}
                      {bl.es_medible &&
                        <p className='m-0'>
                          {bl.tipo === 'ln' &&
                            <>
                              <Trans>Distancia</Trans>: {Math.ceil(props.elementos.filter(el =>
                                el.bloque.id === bl.id).reduce((ac, current) =>
                                ac + turf.length(current.coordenadas, { units: 'meters' }), 0))}
                              &nbsp;m
                            </>}
                          {bl.tipo === 'pl' &&
                            <>
                              <Trans>Superficie</Trans>: {props.elementos.filter(el => el.bloque.id === bl.id).reduce((ac, current) => ac + turf.area(current.coordenadas), 0).toFixed(2)}
                              &nbsp;m²
                            </>}
                        </p>}
                      {props.usuarios && bl.usuarios.length > 0 &&
                        <p className='m-0'>
                          <Trans>Usuarios</Trans>: {bl.usuarios.length}
                        </p>}
                    </div>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
        {props.mapaVoluntarios.descripcion &&
          <div className='row'>
            <div className='col-12 mt-4'>
              <p className='text-left modal-descripcion__descripcion'>{props.mapaVoluntarios.descripcion}</p>
            </div>
          </div>}
      </div>
    </MapaModal>
  )
}

export default MapaModalDescripcion
