import React from 'react'

import './InfoEsquemaAnimacion.css'

const InfoEsquemAnimacion = props => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(props.esquema.trayecto.color)
  const rgb = {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  }
  const brillo = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
  const progreso = (props.distancia / props.esquema.trayecto.distancia_calculada) * 100
  return (
    <div className={'info-esquema' + (!props.perfilPublicado ? ' info-esquema--sin-perfil' : '')}>
      <div
        className='info-esquema__background'
        style={{ backgroundColor: props.esquema.trayecto.color, width: progreso + '%' }}
      />
      <div
        className='card'
        style={{
          backgroundColor: 'rgba(' + rgb.r + ',' + rgb.g + ',' + rgb.b + ', .5)',
          color: (brillo > 75 ? '#000' : '#fff')
        }}
      >
        <div className='card-header'>
          {props.sector &&
            <h6 className='text-center'>
              {(props.sector.deporte && props.sector.deporte.icono_svg) &&
                <span
                  className='info-esquema__sector-icono mr-2'
                  dangerouslySetInnerHTML={{ __html: props.sector.deporte.icono_svg }}
                />}
              {props.sector.nombre}
            </h6>}
          <h4>
            {props.esquema.repeticiones !== 1 ? <span className='text-lowercase'>{props.esquema.repeticiones + ' x '}</span> : ''}
            {props.esquema.trayecto.nombre}
            <span className='info-esquema__dist text-right text-lowercase'>
              {props.metrosToUntis(props.esquema.trayecto.distancia_metros)}
            </span>
          </h4>
        </div>
      </div>
    </div>
  )
}

export default InfoEsquemAnimacion
