import React, { Component } from 'react'
import { Trans } from 'react-i18next'
import { connect } from 'react-redux'

import { createContacto, deleteContacto, updateContacto } from '../../actions/contacto'

import './FichaContacto.css'
import { SERVER_DOMAIN } from '../../constants/api'

class FichaContacto extends Component {
  onClickInvitar () {
    const { usuario, yo, dispatch } = this.props
    const opciones = {
      user_1: yo.id,
      user_2: usuario.id
    }
    dispatch(createContacto(opciones))
  }

  onClickRevocarInvitacion () {
    const { usuario, yo, dispatch, contactos } = this.props
    const contacto = contactos.filter(c =>
      (c.user_1 === yo.id && c.user_2 === usuario.id) || (c.user_1 === usuario.id && c.user_2 === yo.id))
    if (contacto.length) {
      dispatch(deleteContacto(contacto[0]))
    } else {
      window.alert('Ha ocurrido un error')
    }
  }

  onClickAceptar () {
    const { usuario, yo, dispatch, contactos } = this.props
    const opciones = {
      user_1: usuario.id,
      user_2: yo.id,
      estado: 'a'
    }
    const contacto = contactos.filter(c => c.user_1 === usuario.id && c.user_2 === yo.id)
    if (contacto.length) {
      dispatch(updateContacto(contacto[0].id, opciones))
    } else {
      window.alert('Ha ocurrido un error')
    }
  }

  onClickRechazar () {
    const { usuario, yo, dispatch, contactos } = this.props
    const opciones = {
      user_1: usuario.id,
      user_2: yo.id,
      estado: 'r'
    }
    const contacto = contactos.filter(c => c.user_1 === usuario.id && c.user_2 === yo.id)
    if (contacto.length) {
      dispatch(updateContacto(contacto[0].id, opciones))
    } else {
      window.alert('Ha ocurrido un error')
    }
  }

  getFechaContacto () {
    const { contactos, usuario, yo } = this.props
    const contacto = contactos.filter(c =>
      (c.user_1 === yo.id && c.user_2 === usuario.id) || (c.user_1 === usuario.id && c.user_2 === yo.id))
    if (contacto.length) {
      return new Date(contacto[0].date_mod).toLocaleDateString()
    }
    return null
  }

  render () {
    const { usuario, contactos, className } = this.props

    return (
      <div className={'ficha-contacto mt-2 p-2 ' + (className || '')}>
        <div className='d-flex w-100 align-items-center'>
          {usuario.avatar
            ? (
              <img
                src={SERVER_DOMAIN + usuario.avatar}
                alt='Avatar'
                className='rounded-circle ficha-contacto__avatar mr-1 ml-2'
              />
            ) : (
              <div>
                <span className='fa-2x fa-stack'>
                  <i className='fas fa-circle fa-stack-2x' />
                  <i className='fas fa-user fa-stack-1x fa-inverse' />
                </span>
              </div>
            )}
          <div className='ml-2 d-flex justify-content-between w-100'>
            {!contactos.map(c => c.user_1).includes(usuario.id) && !contactos.map(c => c.user_2).includes(usuario.id) &&
              <>
                <div>
                  <div className='contacto_nombre'><strong>{usuario.first_name} {usuario.last_name}</strong></div>
                </div>
                <div className='text-left'>
                  <button onClick={this.onClickInvitar.bind(this)} title='Invitar' className='btn btn-primary'>
                    <i className='fas fa-comment-medical fa-2x p-1' />
                  </button>
                </div>
              </>}
            {contactos.map(c => c.estado !== 'a' && c.user_2).includes(usuario.id) &&
              <>
                <div>
                  <div className='contacto_nombre'><strong>{usuario.first_name} {usuario.last_name}</strong></div>
                  <Trans>Invitación enviada</Trans>
                </div>
                <button onClick={this.onClickRevocarInvitacion.bind(this)} title='Revocar invitación' className='btn btn-primary ml-2'>
                  <i className='fas fa-ban fa-2x p-1' />
                </button>
              </>}
            {(contactos.map(c => c.estado === 'a' && c.user_1).includes(usuario.id) ||
              contactos.map(c => c.estado === 'a' && c.user_2).includes(usuario.id)) &&
                <>
                  <div>
                    <div className='contacto_nombre'><strong>{usuario.first_name} {usuario.last_name}</strong></div>
                    <div>
                      <Trans>En contactos desde el</Trans> {this.getFechaContacto()}
                    </div>
                  </div>
                  <div>
                    <button onClick={this.onClickRevocarInvitacion.bind(this)} title='Eliminar' className='btn btn-primary ml-2'>
                      <i className='fas fa-trash-alt fa-2x p-1' />
                    </button>
                  </div>
                </>}
            {contactos.map(c => c.estado === 'p' && c.user_1).includes(usuario.id) &&
              <>
                <div>
                  <div className='contacto_nombre'><strong>{usuario.first_name} {usuario.last_name}</strong></div>
                  <Trans>Invitación recibida</Trans>
                </div>
                <div>
                  <button onClick={this.onClickAceptar.bind(this)} className='btn btn-primary ml-1 mr-1' title='Aceptar'>
                    <i className='fas fa-check' />
                  </button>
                  <button onClick={this.onClickRechazar.bind(this)} className='btn btn-secondary' title='Rechazar'>
                    <i className='fas fa-times' />
                  </button>
                </div>
              </>}
            {contactos.map(c => c.estado === 'r' && c.user_1).includes(usuario.id) &&
              <>
                <div>
                  <div className='contacto_nombre'><strong>{usuario.first_name} {usuario.last_name}</strong></div>
                  <Trans>Ya has rechazado esta invitación, pero aún puedes aceptarla </Trans>
                </div>
                <button onClick={this.onClickAceptar.bind(this)} className='btn btn-primary ml-1'>
                  <i className='fas fa-check' />
                </button>
              </>}
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(FichaContacto)
