/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_MAPA_VOLUNTARIOS_BEGIN: 'FETCH_MAPA_VOLUNTARIOS_BEGIN',
  FETCH_MAPA_VOLUNTARIOS_SUCCESS: 'FETCH_MAPA_VOLUNTARIOS_SUCCESS',
  FETCH_MAPA_VOLUNTARIOS_ERROR: 'FETCH_MAPA_VOLUNTARIOS_ERROR',
  FETCH_MAPAS_VOLUNTARIOS_BEGIN: 'FETCH_MAPAS_VOLUNTARIOS_BEGIN',
  FETCH_MAPAS_VOLUNTARIOS_SUCCESS: 'FETCH_MAPAS_VOLUNTARIOS_SUCCESS',
  FETCH_MAPAS_VOLUNTARIOS_ERROR: 'FETCH_MAPAS_VOLUNTARIOS_ERROR'
}

export function fetchMapaVoluntariosBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPA_VOLUNTARIOS_BEGIN
  }
}

function fetchMapaVoluntariosSuccess (mapaVoluntarios) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_VOLUNTARIOS_SUCCESS,
    mapaVoluntarios
  }
}

function fetchMapaVoluntariosError (error) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_VOLUNTARIOS_ERROR,
    error
  }
}

export function fetchMapaVoluntarios (id, fields = []) {
  return dispatch => {
    dispatch(fetchMapaVoluntariosBegin())
    const url = new URL(API_BASE_URL + 'voluntarios/' + id + '/')

    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    const key = new URLSearchParams(window.location.search).get('key')
    // Pasamos o parámetro key a API. Úsase a hora de xerar as capturas,
    // para poder ver mapas non publicados.
    if (key) {
      url.searchParams.append('key', key)
    }

    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(fetchMapaVoluntariosSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchMapaVoluntariosError(err))
      })
  }
}

function fetchMapasVoluntariosBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_VOLUNTARIOS_BEGIN
  }
}

function fetchMapasVoluntariosSuccess (mapasVoluntarios) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_VOLUNTARIOS_SUCCESS,
    mapasVoluntarios
  }
}

function fetchMapasVoluntariosError (error) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_VOLUNTARIOS_SUCCESS,
    error
  }
}

export function fetchMapasVoluntarios (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchMapasVoluntariosBegin())
    const url = new URL(API_BASE_URL + 'voluntarios/')
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )

    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchMapasVoluntariosSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchMapasVoluntariosError(err))
      })
  }
}
