import React from 'react'
import notFoundImg from '../../lib/images/defaultmap-personal.jpg'
import { Trans } from 'react-i18next'

import './MapaPersonalItem.css'

const MapaPersonalItem = ({ mapaPersonal }) => {
  return (
    <>
      {mapaPersonal.captura_2x1
        ? (
          <div
            className='recorrido-list__captura' style={{ backgroundImage: 'url(' + mapaPersonal.captura_2x1 + ')' }}
          >
            <div className='recorrido-list__recorrido-nombre'> {mapaPersonal.nombre}</div>
          </div>)
        : (
          <div className='recorrido-list__captura' style={{ backgroundImage: 'url(' + notFoundImg + ')' }}>
            <div className='recorrido-list__recorrido-nombre'> {mapaPersonal.nombre}</div>
          </div>
        )}
      <div
        className='personal-item__resumen pl-2 pr-2 pt-1 pb-1 font-weight-bold d-flex justify-content-between align-items-center'
      >
        <span>
          <Trans>Ubicaciones</Trans>: {mapaPersonal.elementos_voluntarios.length}
        </span>
        <span>
          <Trans>Usuarios</Trans>: {mapaPersonal.numero_usuarios}
        </span>
      </div>
    </>
  )
}

export default MapaPersonalItem
