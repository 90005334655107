import React from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

const SubmenuHerramientas = props => {
  return (
    <div className='mapa-submenu sub-heramientas aparece-abajo'>
      <ul className='btn-group boton-visor__group'>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={() => props.onClickCambiarVista()} title={props.t('Cambiar fondo')}>
            <i className='fa fa-layer-group' />
          </button>
        </li>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={() => props.fitBounds()} title={props.t('Ajustar mapa a contenido')}>
            <i className='fas fa-crosshairs' />
          </button>
        </li>
        {props.onClickDescargarTrack &&
          <li className='btn boton-visor boton-visor--small'>
            <button onClick={props.onClickDescargarTrack} title={props.t('Descargar track .gpx')}>
              <i className='fas fa-download' />
            </button>
          </li>}
        {props.canalId &&
          <li className='btn boton-visor boton-visor--small'>
            <Link to={'/canal/' + props.canalId} title={props.t('Ir al canal del usuario')}>
              <i className='mdi mdi-account-reactivate' />
            </Link>
          </li>}
      </ul>
    </div>
  )
}

export default withTranslation()(SubmenuHerramientas)
