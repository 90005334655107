import * as d3 from 'd3'
import React from 'react'
import * as turf from '@turf/turf'
import { Library } from '@observablehq/stdlib/dist/stdlib'

import './Grafico.css'
import logoBlanco from '../../lib/images/logo-racemapp-hor-blanco.png'

class Grafico extends React.Component {
  constructor (props) {
    super(props)
    this.nodeRef = React.createRef()
    this.scale = null
  }

  componentDidMount () {
    const { puntosKm } = this.props
    // LLamada a D3
    this.createChart(this.nodeRef, puntosKm.filter(pk => pk.punto.servicios.length > 0))
    window.onresize = () => {
      d3.select(this.nodeRef.current).select('svg').remove()
      this.pdisSvg = []
      this.createChart(this.nodeRef, puntosKm.filter(pk => pk.punto.servicios.length > 0))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { perfil, sector, puntosKm } = this.props
    if (sector.id !== prevProps.sector.id) {
      d3.select(this.nodeRef.current).select('svg').remove()
      this.pdisSvg = []
      this.createChart(this.nodeRef, puntosKm.filter(pk => pk.punto.servicios.length > 0))
    }
    if (prevProps.perfil.pdiOrden[prevProps.perfil.indexPdiSeleccionado]) {
      this.updateDeselecionado(prevProps.perfil.pdiOrden[prevProps.perfil.indexPdiSeleccionado])
    }
    if (perfil.pdiOrden[perfil.indexPdiSeleccionado]) {
      this.updateSeleccionado(perfil.pdiOrden[perfil.indexPdiSeleccionado])
    }
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const { perfil, sector } = this.props
    if ((isNaN(perfil.indexPdiSeleccionado) && !isNaN(nextProps.perfil.indexPdiSeleccionado)) || (!isNaN(nextProps.perfil.indexPdiSeleccionado) && nextProps.perfil.indexPdiSeleccionado !== perfil.indexPdiSeleccionado)) {
      return true
    }
    if (sector.id !== nextProps.sector.id) {
      return true
    }
    return false
  }

  createChart (ref, pks) {
    const { sector, pdis, perfil, unidad } = this.props

    const margin = { top: 25, right: 25, bottom: 30, left: 50 }
    if (!d3.select(ref.current).node()) {
      return false
    }
    let parentWidth = d3.select(ref.current).node().getBoundingClientRect().width
    if (!window.inIframe && window.innerWidth < 700 && window.screen.orientation &&
      (window.screen.orientation.type === 'portrait' || window.screen.orientation.type === 'portrait-primary')
    ) {
      parentWidth = d3.select(ref.current).node().getBoundingClientRect().height
    }
    const width = parentWidth - margin.left - margin.right
    this.width = width
    let height = Math.floor(parentWidth * 6 / 19) - margin.top - margin.bottom
    height = height > 100 ? height : 100
    const zoomed = (event) => {
      d3.select('.grafico__unidad-x').remove()
      this.scale = event
      this.xz = event.transform.rescaleX(x)
      graphLines.forEach(o => {
        o.path.attr('d', o.graphLine(o.data, this.xz))
      })
      gx.call(xAxis, this.xz)
      this.updatePdis()
    }

    const zoom = d3.zoom()
      .scaleExtent([1, 10])
      .extent([[0, 0], [width, height]])
      .translateExtent([[0, -Infinity], [width, Infinity]])
      .on('zoom', zoomed)

    const svg = d3.select(ref.current)
      .append('svg')
      .attr('class', 'grafico__svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')

    const library = new Library()
    const clip = library.DOM.uid('clip')

    svg.append('clipPath')
      .attr('id', clip.id)
      .append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', width)
      .attr('height', height)

    let distanciaTotal = 0
    let minYAbs, maxYAbs
    const graphLines = []
    let last
    for (const k in sector.esquemas) {
      const esq = sector.esquemas[k]
      const trayecto = sector.trayectos.filter(tr => tr.id === esq.trayecto.id)
      if (trayecto.length) {
        for (let j = 0; j < esq.repeticiones; j++) {
          const graphData = []

          const coordinates = trayecto[0].coordenadas_interpoladas.coordinates
          const lineString = turf.lineString(coordinates)
          // Transformamos as coordenadas en pares de distancia e altura.
          last = last || coordinates[0]
          let maxY = last[2]
          let minY = last[2]

          graphData.push([distanciaTotal, last[2]])
          const distTrayecto = turf.length(turf.lineString(coordinates), { units: 'meters' })
          let distLocal = 0
          for (let i = 1; i < coordinates.length; i++) {
            const p = coordinates[i]
            const distTramo = turf.distance([last[0], last[1]], [p[0], p[1]], { units: 'meters' })
            let distCorrexida = distTramo * trayecto[0].distancia_metros / distTrayecto
            if (distLocal + distCorrexida > trayecto[0].distancia_metros) {
              distCorrexida = trayecto[0].distancia_metros - distLocal
            }
            distLocal += distCorrexida
            distanciaTotal += this.metrosToUnidad(distCorrexida)
            graphData.push([
              distanciaTotal, p[2]
            ])
            last = p
            if (p[2] > maxY) {
              maxY = p[2]
            }
            if (p[2] < minY) {
              minY = p[2]
            }
          }
          let marginY = (maxY - minY) * 0.5 > 100 ? 100 : (maxY - minY) * 0.5
          if (maxY - minY < 100) {
            marginY = (maxY - minY) * 5 > 100 ? 100 : (maxY - minY) * 5
            maxY = minY + 150
          } else if (maxY - minY < 300) {
            marginY = (maxY - minY) > 100 ? 100 : (maxY - minY)
            maxY = minY + 500
          } else if (maxY - minY < 700) {
            maxY = minY + 900
          }
          minY = minY < 50 ? 0 : (maxY - minY < 50 ? maxY - 50 : (minY - marginY > 0 ? minY - marginY : 0))
          maxYAbs = maxYAbs && maxYAbs > maxY ? maxYAbs : maxY
          minYAbs = minYAbs && minYAbs < minY ? minYAbs : minY

          graphLines.push({
            id: esq.id + '-' + j,
            lineString,
            trayecto: trayecto[0],
            data: graphData
          })
        }
      }
    }
    const maxX = distanciaTotal

    // Rangos e Escalas:
    // Usamos o doble da altura, xa que na parte superior imos
    // colocar información sobre os puntos kilométricos.
    const x = d3.scaleLinear().domain([0, maxX]).range([0, width])
    this.x = x
    this.xz = x
    const y = d3.scaleLinear().domain([minYAbs, maxYAbs + maxYAbs - minYAbs]).range([height, 0])

    this.pdisSvg = []

    graphLines.forEach(o => {
      // Creamos o gráfico
      const graphLine = (data, x) => d3.area()
        .curve(d3.curveLinear)
        .x(d => x(d[0]))
        .y1(d => y(d[1]))
        .y0(y(minYAbs))(data)

      // Añadimos o gráfico
      const path = svg.append('path')
        .attr('clip-path', clip)
        .attr('class', 'grafico__area')
        .attr('d', graphLine(o.data, x))
        .style('fill', o.trayecto.color)
        .style('stroke', o.trayecto.color)

      o.graphLine = graphLine
      o.path = path
    })

    perfil.pdiOrden.forEach((idPt, i) => {
      let pt = pdis.filter(pdi => pdi.id === idPt)
      if (pt.length === 0) {
        pt = pks.map(pk => pk.punto).filter(p => p.id === idPt)
      }
      if (pt.length > 0) {
        const gl = graphLines.filter(gl => gl.trayecto.id === pt[0].trayecto_ancla)
        const ant = this.pdisSvg.filter(pdi => pdi.id === idPt)
        let offset = 0
        for (let j = 0; j < graphLines.map(gl => gl.id).indexOf(gl[ant.length].id); j++) {
          offset += this.metrosToUnidad(graphLines[j].trayecto.distancia_metros)
        }
        const pdiSvg = this.creaPdi(
          pt[0], gl[ant.length].lineString, svg, x, y, minYAbs, maxYAbs,
          gl[ant.length].data, width, i, offset, gl[ant.length].trayecto
        )
        this.pdisSvg.push(pdiSvg)
      }
    })

    const xAxis = (g, x) => g
      .attr('transform', `translate(0,${height})`)
      .call(d3.axisBottom(x).ticks(width / 100).tickSizeOuter(0).tickFormat(x => this.toFixed(x, 2)))
      .call(g => g.select('.tick:last-of-type text').clone()
        .attr('class', 'grafico__unidad-x')
        .attr('x', 15)
        .attr('y', minYAbs + 9)
        .attr('text-anchor', 'start')
        .attr('font-weight', 'bold')
        .text(unidad))

    const gx = svg.append('g')
      .attr('class', 'grafico__ejex')
      .call(xAxis, x)

    const yAxis = (g, y) => g
      .call(d3.axisLeft(y).ticks(6))
      .call(g => g.select('.tick:last-of-type text').clone()
        .attr('x', -15)
        .attr('y', -10)
        .attr('text-anchor', 'start')
        .attr('font-weight', 'bold')
        .text('m'))

    svg.append('g')
      .attr('class', 'grafico__ejey')
      .call(yAxis, y)

    svg.call(zoom)
      .transition()
      .duration(750)

    if (this.scale) {
      zoomed(this.scale)
    }
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  updateSeleccionado (idPdi) {
    const pdis = this.pdisSvg.filter(pdi => pdi.id === idPdi)
    pdis.forEach(pdi => {
      pdi.line
        .transition(0.1)
        .attr('y2', this.topMax)
      pdi.g
        .transition(0.1)
        .attr('transform',
          'translate(' + (this.xz(pdi.distanciaX) - 70) + ', ' +
          (this.topMax - 40) + ')')
        .style('display', 'initial')
      pdi.divPdi
        .transition(0.1)
        .attr('class', 'pdi__icono pdi__icono--seleccionado')
      if (this.x(pdi.distanciaX) > this.width - 20) {
        pdi.divPdi.style('margin-left',
          27)
      }
    })
  }

  metrosToUnidad (dist) {
    const { unidad } = this.props
    switch (unidad) {
      case 'km':
        return dist / 1000
      case 'mi':
        return turf.convertLength(dist, 'meters', 'miles')
      case 'ft':
        return turf.convertLength(dist, 'meters', 'feet')
      default:
        return dist
    }
  }

  updateDeselecionado (idPdi) {
    const pdis = this.pdisSvg.filter(pdi => pdi.id === idPdi)
    pdis.forEach(pdi => {
      pdi.line
        .transition(0.1)
        .attr('y2', this.topMin)
      pdi.g
        .transition(0.1)
        .attr('transform',
          'translate(' + (this.xz(pdi.distanciaX) - 70) + ', ' +
          (this.topMin - 40) + ')')
        .style('display', 'initial')
      pdi.divPdi
        .transition(0.1)
        .attr('class', 'pdi__icono')
      if (this.x(pdi.distanciaX) > this.width - 15) {
        pdi.divPdi.style('margin-left', 42)
      }
    })
  }

  updatePdis () {
    const { perfil } = this.props
    this.pdisSvg.forEach(pdiSvg => {
      if (this.xz(pdiSvg.distanciaX) < 0 || this.xz(pdiSvg.distanciaX) > this.width) {
        pdiSvg.line.style('display', 'none')
        pdiSvg.g.style('display', 'none')
      } else {
        pdiSvg.line
          .attr('x1', this.xz(pdiSvg.distanciaX))
          .attr('x2', this.xz(pdiSvg.distanciaX))
          .style('display', 'initial')
        if (!isNaN(perfil.indexPdiSeleccionado) && perfil.pdiOrden[perfil.indexPdiSeleccionado] === pdiSvg.id) {
          pdiSvg.g
            .attr('transform',
              'translate(' + (this.xz(pdiSvg.distanciaX) - 70) + ', ' +
              (this.topMax - 40) + ')')
            .style('display', 'initial')
        } else {
          pdiSvg.g
            .attr('transform',
              'translate(' + (this.xz(pdiSvg.distanciaX) - 70) + ', ' +
              (this.topMin - 40) + ')')
            .style('display', 'initial')
        }
      }
    })
  }

  creaPdi (pdi, lineString, svg, x, y, minY, maxY, graphData, width, index, offset, trayecto) {
    const { perfil } = this.props
    const lineSlice = turf.lineSlice(lineString.geometry.coordinates[0], pdi.coordenadas, lineString)
    if (lineSlice) {
      const distancia = turf.length(lineSlice, { units: 'meters' })
      const distCorrect = this.metrosToUnidad(distancia * trayecto.distancia_metros / trayecto.distancia_calculada) + offset
      const distGraphTramo = graphData[graphData.length - 1][0] - graphData[0][0]
      let distNorm = distCorrect * distGraphTramo / this.metrosToUnidad(trayecto.distancia_metros)
      if (distNorm > graphData[graphData.length - 1][0]) {
        distNorm = graphData[graphData.length - 1][0]
      }
      const distanciaX = distNorm
      const top = perfil.pdiOrden[perfil.indexPdiSeleccionado] === pdi.id ? 5 : 55
      this.topMax = 5
      this.topMin = 55

      const line = svg.append('line')
        .attr('x1', x(distanciaX))
        .attr('x2', x(distanciaX))
        .attr('y1', y(minY))
        .attr('y2', top)
        .attr('class', 'pdi__linea')

      const g = svg.append('g')
        .attr('width', 140)
        .attr('height', 40)
        .attr('fill', 'transparent')
        .attr('transform', 'translate(' + (x(distanciaX) - 70) + ', ' + (top - 40) + ')')
      const foreign = g.append('foreignObject')
        .attr('y', 10)
        .attr('width', 140)
        .attr('height', 45)
        .attr('class', 'pdi__foreign')
      const divPdi = foreign.append('xhtml:div')
        .append('div')
        .attr('class', 'pdi__icono' + (perfil.pdiOrden[perfil.indexPdiSeleccionado] === pdi.id ? ' pdi__icono--seleccionado' : ''))
        .style('background-color', pdi.color)
        .style('color', pdi.color_icono)
        .on('click', () => this.props.onClickPunto(index))

      this.dibujaIconoPdi(divPdi, pdi)

      if (x(distanciaX) < 15) {
        foreign.select('div').select('div')
          .style('margin-left', (69 - x(distanciaX)) + 'px')
      } else if (x(distanciaX) > width - 15) {
        foreign.select('div').select('div')
          .style('margin-left', 42)
      }

      return {
        line,
        g,
        distanciaX,
        top,
        id: pdi.id,
        divPdi,
        index
      }
    }
    return false
  }

  dibujaIconoPdi (divPdi, pdi) {
    if (!['n', 'pk'].includes(pdi.bloque.tipo_especial)) {
      divPdi.append('i')
        .attr('class', pdi.icono.prefijo_fuente + ' ' + pdi.icono.prefijo_fuente + '-' + pdi.icono.icono)
    } else if (pdi.servicios.length === 1) {
      divPdi.style('background-color', pdi.servicios[0].color_fondo)
      divPdi.style('color', pdi.servicios[0].color_icono)
      divPdi.append('i')
        .attr(
          'class',
          pdi.servicios[0].icono.prefijo_fuente + ' ' +
          pdi.servicios[0].icono.prefijo_fuente + '-' +
          pdi.servicios[0].icono.icono
        )
    } else {
      divPdi.style('background-color', '#FFCC00')
      divPdi.style('color', '#000')
      divPdi.append('i')
        .attr('class', 'rmp rmp-pr-serv')
    }
  }

  getUnidadTurf () {
    const { unidad } = this.props
    switch (unidad) {
      case 'km':
        return 'kilometers'
      case 'mi':
        return 'miles'
      case 'ft':
        return 'feet'
      default:
        return 'meters'
    }
  }

  render () {
    return (
      // Nos basta con un sencillo DIV.
      // Tan sólo queremos su referencia.
      <div className='grafico__container' ref={this.nodeRef}>
        <img className='perfil_mosca' src={logoBlanco} alt='logo mosca' />
      </div>
    )
  }
}

export default Grafico
