export const ACTION_TYPES = {
  SET_CURRENT_MAPA: 'SET_CURRENT_MAPA'
}

function setCurrentMapa (mapa = null) {
  return {
    type: ACTION_TYPES.SET_CURRENT_MAPA,
    mapa
  }
}

export const actions = {
  setCurrentMapa
}
