import { ACTION_TYPES } from '../actions/poligonos'
import { initialState, REQUEST_STATUS } from '../constants/state'

export function poligonosReducer (state = initialState.poligonos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_POLIGONOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }
    case ACTION_TYPES.FETCH_POLIGONOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.poligonos
      }
    case ACTION_TYPES.FETCH_POLIGONOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return {
        ...state
      }
  }
}
