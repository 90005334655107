/* global fetch */
import { API_BASE_URL } from '../constants/api'
import getCsrfToken from '../lib/csrf'

export const ACTION_TYPES = {
  CREATE_ENCARGO_BEGIN: 'CREATE_ENCARGO_BEGIN',
  CREATE_ENCARGO_SUCCESS: 'CREATE_ENCARGO_SUCCESS',
  CREATE_ENCARGO_ERROR: 'CREATE_ENCARGO_ERROR',
  RESET_ENCARGO: 'RESET_ENCARGO'
}

function createEncargoBegin () {
  return {
    type: ACTION_TYPES.CREATE_ENCARGO_BEGIN
  }
}

function createEncargoSuccess (encargo) {
  return {
    type: ACTION_TYPES.CREATE_ENCARGO_SUCCESS,
    encargo
  }
}

function createEncargoError (error) {
  return {
    type: ACTION_TYPES.CREATE_ENCARGO_ERROR,
    error
  }
}

function resetEncargo () {
  return {
    type: ACTION_TYPES.RESET_ENCARGO
  }
}

export const actions = {
  resetEncargo
}

export function createEncargo (formData) {
  return dispatch => {
    dispatch(createEncargoBegin())
    const url = API_BASE_URL + 'encargo/'
    return fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRFToken': getCsrfToken()
      },
      credentials: 'include',
      body: formData
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(createEncargoSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(createEncargoError(err))
      })
  }
}
