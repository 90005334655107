export const ACTION_TYPES = {
  RECORRIDO_MOUSEOVER: 'RECORRIDO_MOUSEOVER',
  RECORRIDO_MOUSEOUT: 'RECORRIDO_MOUSEOUT',
  COMPARTIR_MOSTRAR: 'COMPARTIR_MOSTRAR',
  COMPARTIR_OCULTAR: 'COMPARTIR_OCULTAR'
}

function recorridoMouseover (recorrido) {
  return {
    type: ACTION_TYPES.RECORRIDO_MOUSEOVER,
    recorrido
  }
}

function recorridoMouseout () {
  return {
    type: ACTION_TYPES.RECORRIDO_MOUSEOUT
  }
}

function compartirMostrar () {
  return {
    type: ACTION_TYPES.COMPARTIR_MOSTRAR
  }
}

function compartirOcultar () {
  return {
    type: ACTION_TYPES.COMPARTIR_OCULTAR
  }
}

export const actions = {
  recorridoMouseover,
  recorridoMouseout,
  compartirOcultar,
  compartirMostrar
}

export default actions
