import React from 'react'
import { Trans } from 'react-i18next'
import Logo from '../lib/logos/logo-public.svg'

import './Footer.css'
import { Link } from 'react-router-dom'
import { SERVER_DOMAIN } from '../constants/api'
import { Button } from 'react-bootstrap'

const Footer = props => {
  return (
    <div className='footer pt-3'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-8 col-md-12'>
            <div className='row'>
              <div className='col-lg-3 col-sm-6'>
                <ul className='footer__list'>
                  <li><Link to='/'><strong><Trans>Idioma</Trans></strong></Link></li>
                  <form method='post' action={`${SERVER_DOMAIN}/i18n/setlang/`}>
                    <button
                      as={Button}
                      variant='secondary'
                      className='dropdown-item-recorridos text-uppercase home__list-language d-flex justify-content-center justify-content-md-start'
                      type='submit' name='language' value='es'
                    >
                      <Trans>Castellano</Trans>
                    </button>
                    <button
                      as={Button}
                      variant='secondary'
                      className='dropdown-item-recorridos text-uppercase home__list-language d-flex justify-content-center justify-content-md-start'
                      type='submit' name='language' value='en'
                    >
                      <Trans>Inglés</Trans>
                    </button>
                  </form>
                </ul>
              </div>
              <div className='col-lg-3 col-sm-6'>
                <ul className='footer__list'>
                  <li><a href='/ayuda/'><strong><Trans>Ayuda</Trans></strong></a></li>
                </ul>
              </div>
              {props.user
                ? (
                  <>
                    <div className='col-lg-3 col-sm-6'>
                      <ul className='footer__list'>
                        <li className='mb-2'><a href='/proyecto/'><strong><Trans>Proyectos</Trans></strong></a></li>
                        <li className='footer__list-item'><a href='/proyecto/'><Trans>Mis proyectos</Trans></a></li>
                        <li className='footer__list-item'><a href='/proyecto/add/'><Trans>Nuevo proyecto</Trans></a></li>
                        <li className='footer__list-item'><Link to='/pedido'><Trans>Encárganos tu proyecto</Trans></Link></li>
                      </ul>
                    </div>
                    <div className='col-lg-3 col-sm-6'>
                      <ul className='footer__list'>
                        <li className='mb-2'><strong><Trans>Usuario</Trans></strong></li>
                        <li className='footer__list-item'>
                          <a href='/usuario/subscripciones/'><Trans>Mis publicaciones</Trans></a>
                        </li>
                        <li className='footer__list-item'>
                          <Link to='/contactos'><Trans>Mis contactos</Trans></Link>
                        </li>
                        <li className='footer__list-item'>
                          <a href='/usuario/perfil/'><Trans>Editar mis datos</Trans></a>
                        </li>
                        <li className='footer__list-item'>
                          <a href='/usuario/salir/'><Trans>Desconectarse</Trans></a>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='col-lg-3 col-sm-6'>
                      <ul className='footer__list'>
                        <li className='mb-2'><a href='/usuario/registro/'><strong><Trans>Registrarse</Trans></strong></a></li>
                      </ul>
                    </div>
                    <div className='col-lg-3 col-sm-6'>
                      <ul className='footer__list'>
                        <li className='mb-2'><a href='/usuario/entrar/'><strong><Trans>Entrar</Trans></strong></a></li>
                      </ul>
                    </div>
                  </>
                )}
            </div>
          </div>
          <div className='col-lg-4 col-md-12'>
            <div className='float-lg-right'>
              <a href='https://facebook.com/racemapp2021' target='_blank' rel='noreferrer noopener'>
                <span className='fa-stack fa-2x mb-2'>
                  <i className='fas fa-circle fa-stack-2x' />
                  <i className='fab fa-facebook fa-stack-1x fa-inverse' />
                </span>
              </a>
              <a href='https://twitter.com/racemapp' target='_blank' rel='noreferrer noopener'>
                <span className='fa-stack fa-2x mb-2'>
                  <i className='fas fa-circle fa-stack-2x' />
                  <i className='fab fa-twitter fa-stack-1x fa-inverse' />
                </span>
              </a>
              <a href='https://instagram.com/racemapp' target='_blank' rel='noreferrer noopener'>
                <span className='fa-stack fa-2x mb-2'>
                  <i className='fas fa-circle fa-stack-2x' />
                  <i className='fab fa-instagram fa-stack-1x fa-inverse' />
                </span>
              </a>
              <a href='https://www.youtube.com/channel/UCXa7xoQ6zkDWGHBQZJhnGCg' target='_blank' rel='noreferrer noopener'>
                <span className='fa-stack fa-2x mb-2'>
                  <i className='fas fa-circle fa-stack-2x' />
                  <i className='fab fa-youtube fa-stack-1x fa-inverse' />
                </span>
              </a>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-lg-6 col-sm-12 d-flex align-items-end order-lg-0 order-1'>
            <p>
              Copyright © 2021&nbsp; | &nbsp;<a href='/politica-de-privacidad/'><Trans>Nota Legal</Trans></a>&nbsp; | &nbsp;<a href='/informacion-proteccion-datos/'><Trans>Protección de datos</Trans></a>
            </p>
          </div>
          <div className='col-lg-6 col-sm-12 order-lg-1 order-0'>
            <img className='w-50 float-lg-right mt-5 mb-5' src={Logo} alt='Brand' />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Footer
