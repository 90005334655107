import React from 'react'
import MapaModal from './MapaModal'

import './MapaModalCambiarVista.css'

export default function MapaModalCambiarVista (props) {
  return (
    <MapaModal {...props}>
      <ul className='modal-cambiar-vista'>
        {props.capasFondo.filter(o => !o.transparencia).map(capa => (
          <li
            key={capa.id}
            className={'modal-cambiar-vista__item ' + (props.fondoActivo.id === capa.id ? 'seleccionada' : '')}
          >
            <button
              className='modal-cambiar-vista__btn'
              onClick={() => props.onClickCapaFondo(capa)}
            >
              {capa.nombre}
            </button>
          </li>
        ))}
      </ul>
    </MapaModal>
  )
}
