import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/voluntariosElementos'
import polyline from '@mapbox/polyline'
import * as turf from '@turf/turf'

export function voluntariosElementosReducer (state = initialState.voluntariosElementos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_VOLUNTARIOS_ELEMENTOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.FETCH_VOLUNTARIOS_ELEMENTOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.voluntariosElementos.map(ve => {
          if (ve.elemento.bloque.tipo === 'tr') {
            const line = polyline.decode(ve.elemento.linea_codificada)
            ve.elemento.coordenadas = turf.lineString(line).geometry
          }
          return ve
        })
      }

    case ACTION_TYPES.FETCH_VOLUNTARIOS_ELEMENTOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}
