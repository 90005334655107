import React from 'react'
import { connect } from 'react-redux'
import * as turf from '@turf/turf'
import { Helmet } from 'react-helmet'
import { Trans, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import './ProyectoScreen.css'
import NotFound from '../components/NotFound'
import TopBar from '../components/visor/TopBar'
import Compartir from '../components/Compartir'
import { fetchRemoteMapas } from '../actions/mapa'
import { actions as visorActions } from '../actions/visor'
import { REQUEST_STATUS, SCREENS, TIPOS_MAPAS_NOMBRES } from '../constants/state'
import RecorridoList from '../components/visor/RecorridoList'
import { fetchRemoteRecorridosMapa } from '../actions/recorrido'
import TopHorizontalSlider from '../components/visor/TopHorizontalSlider'
import { fetchPoryectoBegin, fetchRemoteProyecto } from '../actions/proyecto'
import { actions as domActions } from '../actions/dom'
import { actions as proyectoActions } from '../actions/proyectoScreen'
import { fetchMapasVoluntarios } from '../actions/voluntarios'
import MapaPersonalList from '../components/visorPersonal/MapaPersonalList'
import MapaTraficoList from '../components/trafico/MapaTraficoList'
import { fetchMapasTrafico } from '../actions/mapaTrafico'
import MapaMontajeList from '../components/montaje/MapaMontajeList'
import { fetchMapasMontaje } from '../actions/mapaMontaje'

class ProyectoScreen extends React.Component {
  constructor (props) {
    super(props)
    this.handleDomLoad = this._handleDomLoad.bind(this)
  }

  _handleDomLoad () {
    const { dispatch } = this.props
    dispatch(domActions.domSetCargado())
    dispatch(domActions.domSetStartProyecto(true))
  }

  componentDidMount () {
    const { dispatch, match, dom } = this.props

    if (!dom.cargado) {
      window.addEventListener('load', this.handleDomLoad)
    }

    dispatch(fetchPoryectoBegin())

    if (match && match.params && match.params.id) {
      dispatch(fetchRemoteProyecto(
        match.params.id, ['id', 'nombre', 'slug', 'descripcion', 'logo', 'canal_id', 'canal_nombre', 'oficial']
      ))
      dispatch(fetchRemoteMapas(
        match.params.id, ['id', 'publicado', 'tipo__predeterminado', 'tipo']
      ))
    }
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const { dispatch, mapasCargados, mapas } = this.props

    if (!prevProps.mapasCargados && mapasCargados) {
      const mapa = mapas.filter(o => o.tipo__predeterminado)
      if (mapa.length) {
        dispatch(proyectoActions.setCurrentMapa(mapa[0]))
        dispatch(fetchRemoteRecorridosMapa(
          mapa[0].id, [
            'id', 'nombre', 'slug', 'descripcion', 'sectores', 'orden', 'captura', 'distancia_metros', 'dificultad',
            'superficie', 'unidad_distancia', 'deporte_icono', 'captura_2x1', 'oficial'
          ], {
            visible: true
          }
        ))
      }
      const otrosMapas = mapas.filter(o => !o.tipo__predeterminado)
      otrosMapas.forEach(m => {
        switch (m.tipo.nombre) {
          case TIPOS_MAPAS_NOMBRES.STAFF:
            dispatch(fetchMapasVoluntarios({ mapa: m.id }))
            break
          case TIPOS_MAPAS_NOMBRES.TRAFICO:
            dispatch(fetchMapasTrafico({ mapa: m.id }))
            break
          case TIPOS_MAPAS_NOMBRES.MONTAJE:
            dispatch(fetchMapasMontaje({ mapa: m.id }))
            break
          default:
            break
        }
      })
    }
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(visorActions.setLastScreen(SCREENS.PROYECTO))

    window.removeEventListener('load', this.handleDomLoad)
  }

  handleClickCompartirMostrar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirMostrar())
  }

  handleClickCompartirOcultar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirOcultar())
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  metrosToUnitsRecorrido (recorrido, distancia) {
    switch (recorrido.unidad_distancia) {
      case 'km':
        return this.toFixed((distancia / 1000), 2) + ' km'
      case 'mi':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'miles'), 2) + ' mi'
      case 'ft':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'feet'), 0) + ' ft'
      default:
        return this.toFixed(distancia, 0) + ' m'
    }
  }

  handleClickOtroMapa (mapa) {
    const { dispatch } = this.props
    dispatch(proyectoActions.setCurrentMapa(mapa))
  }

  handleClickMapaAnterior () {
    const { proyectoScreen, mapas, dispatch } = this.props
    const index = mapas.map(m => m.id).indexOf(proyectoScreen.currentMapa.id)
    if (index > 0) {
      dispatch(proyectoActions.setCurrentMapa(mapas[index - 1]))
    }
  }

  handleClickMapaSiguiente () {
    const { proyectoScreen, mapas, dispatch } = this.props
    const index = mapas.map(m => m.id).indexOf(proyectoScreen.currentMapa.id)
    if (index < mapas.length - 1) {
      dispatch(proyectoActions.setCurrentMapa(mapas[index + 1]))
    }
  }

  render () {
    const {
      proyecto, proyectoError, recorridos, mapas, visor, dom, t, proyectoScreen, mapasVoluntarios, mapasTrafico,
      mapasMontaje
    } = this.props

    if (proyectoError) {
      return <NotFound error={proyecto.error} />
    }

    if (visor.compartirVisible) {
      return <Compartir clickCerrar={this.handleClickCompartirOcultar.bind(this)} />
    } else {
      // console.info(recorridos)
      const recoImage = recorridos.find(e => e.captura_2x1 !== '')
      // console.info(recorridos)
      // console.info(proyecto)
      return (
        <div className='visor__layout'>
          <Helmet>
            <html lang='es' />
            <title>{t(`Mapas interactivos en ${proyecto ? proyecto.nombre : '...'} - RaceMapp`)}</title>
            <meta name='description' content={`${proyecto ? proyecto.descripcion : ''}`} />
            <meta property='og:title' content={t(`Mapas interactivos en ${proyecto ? proyecto.nombre : '...'} - RaceMapp`)} />
            <meta property='og:description' content={`${proyecto ? proyecto.descripcion : ''}`} />
            <meta property='og:url' content={window.location.href} />
            {(recoImage) &&
              <meta property='og:image' content={recoImage.captura_2x1} />}
          </Helmet>
          <TopBar
            titulo={proyecto ? proyecto.nombre : ''}
            oficial={proyecto && proyecto.oficial}
            backLink='/buscar'
            mostrarCerrar={!dom.estaEnIframe}
            linkHomeEnabled
            handleClickCompartir={this.handleClickCompartirMostrar.bind(this)}
          />
          <div className='proyecto__contenido'>
            {proyecto &&
              <>
                {proyecto.logo &&
                  <div className='proyecto__info d-flex align-items-center justify-content-center mt-4'>
                    <div className='proyecto__avatar'>
                      <img src={proyecto.logo} alt='Poryecto logo' />
                    </div>
                  </div>}
                {proyecto.canal_id &&
                  <div className='text-center mt-1'>
                    <Link to={/canal/ + proyecto.canal_id} className='proyecto__link-canal'>
                      <i className='mdi mdi-account-reactivate mr-1' />
                      <Trans>Ir al canal</Trans>: <strong>{proyecto.canal_nombre}</strong>
                    </Link>
                  </div>}
              </>}
            <div className='proyecto__lista mt-2'>
              {proyectoScreen.currentMapa
                ? (
                  <>
                    {mapas.length > 1 &&
                      <TopHorizontalSlider
                        mapa={proyectoScreen.currentMapa}
                        mapas={mapas}
                        handleClickOtroMapa={this.handleClickOtroMapa.bind(this)}
                        handleClickMapaAnterior={this.handleClickMapaAnterior.bind(this)}
                        handleClickMapaSiguiente={this.handleClickMapaSiguiente.bind(this)}
                      />}
                    {proyectoScreen.currentMapa.tipo.nombre === TIPOS_MAPAS_NOMBRES.RECORRIDOS &&
                      <RecorridoList
                        proyecto={proyecto}
                        recorridos={recorridos}
                        metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
                      />}
                    {proyectoScreen.currentMapa.tipo.nombre === TIPOS_MAPAS_NOMBRES.STAFF &&
                      <MapaPersonalList mapasPersonal={mapasVoluntarios} />}
                    {proyectoScreen.currentMapa.tipo.nombre === TIPOS_MAPAS_NOMBRES.TRAFICO &&
                      <MapaTraficoList mapasTrafico={mapasTrafico} />}
                    {proyectoScreen.currentMapa.tipo.nombre === TIPOS_MAPAS_NOMBRES.MONTAJE &&
                      <MapaMontajeList mapasMontaje={mapasMontaje} />}
                  </>
                ) : <div>Cargando...</div>}
            </div>
          </div>
        </div>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    mapas: state.mapas.data,
    mapasCargados: state.mapas.status === REQUEST_STATUS.SUCCEEDED,
    proyecto: state.proyecto.data,
    proyectoError: state.proyecto.error,
    recorrido: state.recorrido,
    recorridos: state.recorridos.data,
    visor: state.visor,
    dom: state.dom,
    proyectoScreen: state.proyectoScreen,
    mapasVoluntarios: state.mapasVoluntarios.data,
    mapasTrafico: state.mapasTrafico.data,
    mapasMontaje: state.mapasMontaje.data
  }
}

export default connect(mapStateToProps)(withTranslation()(ProyectoScreen))
