import React from 'react'
import { Trans, withTranslation } from 'react-i18next'
import * as turf from '@turf/turf'

import './PopupPdiFocused.css'
import { SERVER_DOMAIN } from '../../constants/api'

const PopupPdiFocused = props => {
  const pdiSig = props.getSiguientePdi(props)
  const pdiAnt = props.getAnteriorPdi(props)
  let comoLlegarQuery = null
  switch (props.bloque.tipo) {
    case 'ln':
    case 'tr':
      comoLlegarQuery = props.coordenadas.coordinates[0][1] + ',' + props.coordenadas.coordinates[0][0]
      break
    case 'pt':
      comoLlegarQuery = props.coordenadas.coordinates[1] + ',' + props.coordenadas.coordinates[0]
      break
    default: {
      const centroid = turf.centroid(props.coordenadas)
      comoLlegarQuery = centroid.geometry.coordinates[1] + ',' + centroid.geometry.coordinates[0]
    }
  }
  return (
    <div className='pdi-focused m-auto position-relative'>
      <button className='position-absolute pdi-focused__cerrar' onClick={props.onClickCerrar}>
        <i className='fas fa-times' />
      </button>
      <div className='justify-content-center d-flex align-items-center p-2'>
        <div className='card-body p-2'>
          <h6 className='text-left m-0'>
            {props.bloque.capa.nombre}
          </h6>
          <h5 className='card-title font-weight-bold m-0 text-left mr-2'>
            {props.nombre}
          </h5>
          {props.trayecto_ancla && props.getAltiudPunto && props.getPasosDistanciasPdi &&
            <div className='card-text text-lowercase text-left'>
              <i className='mdi mdi-format-vertical-align-top mr-1' />
              {props.getAltiudPunto(props).toFixed(0) + ' m'}
              &nbsp;
              <strong>
                <i className='mdi mdi-arrow-expand-horizontal mr-1' />
                {props.getPasosDistanciasPdi(props).map((dist, i) => i === 0 ? dist : ' - ' + dist)}
              </strong>
            </div>}
          {props.bloque.tipo !== 'pt' &&
            <div className='card-text text-center mt-1 d-flex justify-content-center align-items-center'>
              <Trans>Color:</Trans>
              <div className='pdi-focused__color ml-2 rounded' style={{ backgroundColor: props.color }} />
            </div>}
          {props.servicios &&
            <div className='mt-1 text-center d-inline-flex'>
              {props.servicios.length <= 5
                ? (
                  <>
                    {props.servicios.map((s, i) =>
                      <div
                        key={i + '-' + s.id}
                        className='mr-1 pdi-focused__servicio d-flex justify-content-center align-items-center'
                        style={{
                          backgroundColor: s.color_fondo,
                          color: s.color_icono
                        }}
                      >
                        <i className={s.icono.prefijo_fuente + ' ' + s.icono.prefijo_fuente + '-' + s.icono.icono} />
                      </div>
                    )}
                  </>
                ) : (
                  <>
                    {props.servicios.slice(0, 4).map((s, i) =>
                      <div
                        key={i + '-' + s.id}
                        className='mr-1 pdi-focused__servicio d-flex justify-content-center align-items-center'
                        style={{
                          backgroundColor: s.color_fondo,
                          color: s.color_icono
                        }}
                      >
                        <i className={s.icono.prefijo_fuente + ' ' + s.icono.prefijo_fuente + '-' + s.icono.icono} />
                      </div>
                    )}
                    <div
                      key={props.id}
                      className='mr-1 pdi-focused__servicio pdi-focused__servicio--mas d-flex justify-content-center align-items-center'
                      onClick={() => props.onClickPdiDetalle(props)}
                    >
                      <i className='fas fa-plus' />
                    </div>
                  </>
                )}
            </div>}
          {props.bloque.es_contable &&
            <div className='card-text mt-1'>
              <Trans>Unidades</Trans>: {props.unidades}
            </div>}
          {props.bloque.es_medible &&
            <div className='card-text mt-1'>
              {props.bloque.tipo === 'ln' &&
                <>
                  <Trans>Distancia</Trans>:
                  <span className='text-lowercase'>
                    &nbsp;{Math.ceil(turf.length(props.coordenadas, { units: 'meters' })) + ' m'}
                  </span>
                </>}
              {props.bloque.tipo === 'pl' &&
                <>
                  <Trans>Superficie</Trans>:
                  <span className='text-lowercase'>
                    &nbsp;{turf.area(props.coordenadas).toFixed(2) + ' m²'}
                  </span>
                </>}
            </div>}
          {props.descripcion
            ? (
              <div className='card-text mt-1 pdi-focused__descripcion text-left'>
                <span className='mr-1'>
                  {props.descripcion.substring(0, 95) + (props.descripcion.length > 95 ? ' ...' : '')}
                </span>
                {((props.servicios && props.servicios.length > 5) || props.descripcion.length > 95 || props.imagen || props.url_embebida) &&
                  <button
                    className='btn btn-sm btn-dark py-0 px-1 pdi-focused__btn'
                    onClick={() => props.onClickPdiDetalle(props)}
                  >
                    <i className='fas fa-plus-circle mr-1' /><Trans>Ver más</Trans>
                  </button>}
              </div>
            ) : (
              <>
                {((props.servicios && props.servicios.length > 5) || props.imagen || props.url_embebida) &&
                  <div className='card-text mt-1 pdi-focused__descripcion text-left'>
                    <button
                      className='btn btn-sm btn-dark py-0 px-1 pdi-focused__btn'
                      onClick={() => props.onClickPdiDetalle(props)}
                    >
                      <i className='fas fa-plus-circle mr-1' /><Trans>Ver más</Trans>
                    </button>
                  </div>}
              </>
            )}
          {props.personal && props.usuarios.length > 0 &&
            <ul className='list-group mt-2'>
              {props.usuarios.map(u =>
                <li key={u.id} className='pt-1 pb-1 d-flex align-items-center pdi-focused__user-item'>
                  {u.avatar
                    ? (
                      <img
                        className='pdi-focused__avatar-user rounded-circle mr-2'
                        src={SERVER_DOMAIN + u.avatar}
                        alt='Avatar'
                      />
                    ) : <i className='fas fa-user-circle fa-3x mr-2' />}
                  {u.first_name}
                </li>
              )}
            </ul>}
          <div className='card-text mt-2'>
            {pdiAnt &&
              <button
                className='btn btn-sm btn-dark pdi-focused__btn pdi-focused__btn--footer mr-1'
                title={props.t('Ver anterior')}
                onClick={() => props.onClickPrevPdi(props)}
              >
                <i className='fas fa-chevron-left' />
              </button>}
            {props.bloque.como_llegar &&
              <a
                target='_blank' rel='noopener noreferrer'
                href={'https://maps.google.com/maps?q=' + comoLlegarQuery}
                className='btn btn-sm btn-dark pdi-focused__btn pdi-focused__btn--footer mr-1'
              >
                <i className='fas fa-directions mr-1' />
                <Trans>Como llegar</Trans>
              </a>}
            {props.bloque.tipo === 'ln' && props.bloque.descargable &&
              <button
                className='btn btn-sm btn-dark pdi-focused__btn pdi-focused__btn--footer mr-1'
                onClick={() => props.onClickDescargar(props)}
              >
                <i className='fas fa-download mr-1' />
                <Trans>Descargar</Trans>
              </button>}
            {pdiSig &&
              <button
                className='btn btn-sm btn-dark pdi-focused__btn pdi-focused__btn--footer mr-1'
                title={props.t('Ver siguiente')}
                onClick={() => props.onClickSigPdi(props)}
              >
                <i className='fas fa-chevron-right' />
              </button>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withTranslation()(PopupPdiFocused)
