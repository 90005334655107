import React from 'react'
import { connect } from 'react-redux'

import TopBar from '../components/visor/TopBar'
import { actions as visorActions } from '../actions/visor'
import MapaModalPerfiles from '../components/visor/MapaModalPerfiles'
import { fetchRecorridoBegin, fetchRemoteRecorrido } from '../actions/recorrido'
import { REQUEST_STATUS, SCREENS } from '../constants/state'
import { fetchRemoteMapa } from '../actions/mapa'
import { fetchRemoteProyecto } from '../actions/proyecto'

class RecorridoPerfilesScreen extends React.Component {
  componentDidMount () {
    const { match, dispatch, recorrido, sector } = this.props

    if (!recorrido || recorrido.id !== parseInt(match.params.id) || !sector) {
      dispatch(fetchRecorridoBegin())

      if (match && match.params) {
        dispatch(fetchRemoteRecorrido(match.params.id))
      }
    }
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const {
      dispatch, recorridoCargado, recorrido, mapa, mapaCargado
    } = this.props

    if (!prevProps.recorridoCargado && recorridoCargado) {
      dispatch(fetchRemoteMapa(recorrido.mapa))
    }
    if (!prevProps.mapaCargado && mapaCargado) {
      dispatch(fetchRemoteProyecto(mapa.proyecto))
    }
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(visorActions.setLastScreen(SCREENS.PROYECTO_PERFILES))
  }

  handleClickCompartirMostrar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirMostrar())
  }

  handleClickCompartirOcultar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirOcultar())
  }

  render () {
    const { proyecto, recorrido } = this.props

    if (proyecto && recorrido) {
      return (
        <div className='visor__layout '>
          <TopBar
            titulo={proyecto ? proyecto.nombre : ''}
            backLink={`/proyecto/${proyecto.id}-${proyecto.slug}`}
            mostrarCerrar
          />
          <div className='visor__main visor__main--no-footer'>
            <MapaModalPerfiles
              className='perfiles-lista'
              titulo='Perfiles'
              sectores={recorrido ? recorrido.sectores : []}
              onClickCerrar={null}
              proyecto={proyecto}
              recorrido={recorrido}
            />
          </div>
        </div>
      )
    }
    return null
  }
}

const mapStateToProps = state => {
  return {
    dom: state.dom,
    error: state.error,
    proyecto: state.proyecto.data,
    mapa: state.mapa.data,
    mapaCargado: state.mapa.status === REQUEST_STATUS.SUCCEEDED,
    recorrido: state.recorrido.data,
    recorridoCargado: state.recorrido.status === REQUEST_STATUS.SUCCEEDED,
    sector: state.sector.data
  }
}

export default connect(mapStateToProps)(RecorridoPerfilesScreen)
