import React from 'react'
import { withTranslation } from 'react-i18next'

export const SubmenuAjustesVisuales = React.forwardRef((props, ref) => {
  return (
    <div className='mapa-submenu sub-heramientas aparece-abajo' ref={ref}>
      <ul className='btn-group boton-visor__group'>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={() => props.fitBounds()} title={props.t('Ajustar mapa a contenido')}>
            <i className='fas fa-crosshairs' />
          </button>
        </li>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={() => props.onClickCambiarVista()} title={props.t('Cambiar fondo')}>
            <i className='fa fa-layer-group' />
          </button>
        </li>
        {!props.visor.location
          ? (
            <li className='btn boton-visor boton-visor--small' title={props.t('Centrar en mi')}>
              <button onClick={() => props.onClickLocate(props.mapRef)}>
                <i className='fas fa-compass' />
              </button>
            </li>
          ) : (
            <li className='btn boton-visor boton-visor--small' title={props.t('Centrar en mi')}>
              <button onClick={() => props.onClickLocateStop(props.mapRef)}>
                <span className='fa-stack fa-2x'>
                  <i className='fas fa-compass fa-stack-1x' />
                  <i className='fas fa-ban fa-stack-2x' />
                </span>
              </button>
            </li>
          )}
        {/* <li className='btn boton-visor boton-visor--small'> */}
        {/*  <button onClick={props.onClickMapaOcultarPdisProyecto}> */}
        {/*    {props.visor && props.visor.mapaPdisProyectoVisible ? ( */}
        {/*      <> */}
        {/*        <span className='fa-stack fa-2x' title={props.t('Ocultar puntos de interés')}> */}
        {/*          <i className='fas fa-map-marker-alt fa-stack-1x' /> */}
        {/*          <i className='fas fa-ban fa-stack-2x' /> */}
        {/*        </span> */}
        {/*      </> */}
        {/*    ) : ( */}
        {/*      <> */}
        {/*        <i className='fas fa-map-marker-alt' title={props.t('Mostrar puntos de interés')} /> */}
        {/*      </> */}
        {/*    )} */}
        {/*  </button> */}
        {/* </li> */}
        {/* <li className='btn boton-visor boton-visor--small'> */}
        {/*  <button onClick={props.onClickMapaOcultarPdisRecorrido}> */}
        {/*    {props.visor && props.visor.mapaPdisRecorridoVisible ? ( */}
        {/*      <> */}
        {/*        <span className='fa-stack fa-2x' title={props.t('Ocultar puntos de recorrido')}> */}
        {/*          <i className='fas rmp-pr fa-stack-1x' style={{ marginTop: 0.5 + 'em' }} /> */}
        {/*          <i className='fas fa-ban fa-stack-2x' /> */}
        {/*        </span> */}
        {/*      </> */}
        {/*    ) : ( */}
        {/*      <> */}
        {/*        <i className='rmp-pr' title={props.t('Mostrar puntos de recorrido')} /> */}
        {/*      </> */}
        {/*    )} */}
        {/*  </button> */}
        {/* </li> */}
      </ul>
    </div>
  )
})

export default withTranslation()(SubmenuAjustesVisuales)
