import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/visorLista'

export function visorListaReducer (state = initialState.visorLista, action) {
  switch (action.type) {
    case ACTION_TYPES.RECORRIDO_MOUSEOVER:
      return {
        ...state,
        recorridoHovered: action.recorrido
      }
    case ACTION_TYPES.RECORRIDO_MOUSEOUT:
      return {
        ...state,
        recorridoHovered: false
      }
    case ACTION_TYPES.COMPARTIR_OCULTAR:
      return {
        ...state,
        compartirVisible: false
      }
    case ACTION_TYPES.COMPARTIR_MOSTRAR:
      return {
        ...state,
        compartirVisible: true
      }
    default:
      return {
        ...state
      }
  }
}
