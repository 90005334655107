import React from 'react'
import { withTranslation } from 'react-i18next'
import { Accordion } from 'react-bootstrap'

import MapaModal from '../visor/MapaModal'
import { SERVER_DOMAIN } from '../../constants/api'

import './MapaModalPersonal.css'

const MapaModalPersonal = props => {
  return (
    <MapaModal {...props}>
      <input
        className='form-control'
        placeholder={props.t('Filtrar')}
        value={props.filtroPersonal}
        onChange={e => props.onChangeFiltroPersonal(e)}
      />
      <ul className='list-group mt-2 modal-usuarios__lista text-left font-weight-bold'>
        {props.usuarios.filter(u => new RegExp([props.filtroPersonal].join('|'), 'i').test(u.first_name)).map(u =>
          <li key={u.id} className='list-group-item mb-0'>
            <Accordion>
              <Accordion.Toggle as='div' className='d-flex align-items-center moda-usuarios__toggle' eventKey={0}>
                {u.avatar
                  ? (
                    <img
                      src={SERVER_DOMAIN + u.avatar}
                      alt='Avatar'
                      className='rounded-circle mr-2 modal-usuarios__avatar'
                    />
                  ) : (
                    <div>
                      <span className='fa-2x fa-stack modal-usuarios__icon'>
                        <i className='fas fa-circle fa-stack-2x' />
                        <i className='fas fa-user fa-stack-1x fa-inverse' />
                      </span>
                    </div>
                  )}
                {u.first_name}
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={0}>
                <ul
                  className='modal-usuarios__lista modal-usuarios__lista--elementos list-group'
                  id={'lista-elementos-' + u.id}
                >
                  {props.elementos.filter(e => e.usuarios.map(us => us.id).includes(u.id)).map(e =>
                    <li
                      key={e.id}
                      className='list-group-item d-flex align-items-center border-0 pl-5'
                      onClick={() => props.onClickPdi(e, props.mapRef)}
                    >
                      {e.icono
                        ? (
                          <div
                            className='icono-servicio mr-3'
                            style={{
                              backgroundColor: e.bloque.tipo === 'pl'
                                ? e.bloque.props_defecto.color_icono : e.bloque.props_defecto.color,
                              color: e.bloque.props_defecto.color_fondo_icono
                            }}
                          >
                            <i className={e.icono.prefijo_fuente + ' ' + e.icono.prefijo_fuente + '-' + e.icono.icono} />
                          </div>
                        )
                        : (
                          <div
                            className='icono-servicio mr-3'
                            style={{ backgroundColor: e.bloque.props_defecto.color, color: '#fff' }}
                          >
                            <i className={e.bloque.icono_descripcion} />
                          </div>
                        )}
                      {e.nombre}
                    </li>
                  )}
                </ul>
              </Accordion.Collapse>
            </Accordion>
          </li>
        )}
      </ul>
    </MapaModal>
  )
}

export default withTranslation()(MapaModalPersonal)
