export const ACTION_TYPES = {
  SET_PORTADA_VIDEO: 'SET_PORTADA_VIDEO'
}

function setPortadaVideo (video) {
  return {
    type: ACTION_TYPES.SET_PORTADA_VIDEO,
    video
  }
}

export const actions = {
  setPortadaVideo
}
