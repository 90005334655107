import React from 'react'
import { Link } from 'react-router-dom'

import MapaPersonalItem from './MapaPersonalItem'

const MapaPersonalList = ({ mapasPersonal }) => {
  return (
    <ul className='recorrido-list scroll-vertical'>
      {mapasPersonal.map(m =>
        <li key={m.id}>
          <Link className='recorrido-list__contenido' to={'/personal/' + m.id}>
            <MapaPersonalItem mapaPersonal={m} />
          </Link>
        </li>
      )}
    </ul>
  )
}

export default MapaPersonalList
