/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_MAPAS_BEGIN: 'FETCH_MAPAS_BEGIN',
  FETCH_MAPAS_SUCCESS: 'FETCH_MAPAS_SUCCESS',
  FETCH_MAPAS_ERROR: 'FETCH_MAPAS_ERROR',
  FETCH_MAPA_BEGIN: 'FETCH_MAPA_BEGIN',
  FETCH_MAPA_SUCCESS: 'FETCH_MAPA_SUCCESS',
  FETCH_MAPA_ERROR: 'FETCH_MAPA_ERROR'
}

function fetchMapasBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_BEGIN
  }
}

function fetchMapasSucess (mapas) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_SUCCESS,
    mapas
  }
}

function fetchMapasError (err) {
  return {
    type: ACTION_TYPES.FETCH_MAPAS_ERROR,
    error: err
  }
}

export function fetchRemoteMapas (proyectoId, fields = []) {
  return function (dispatch) {
    dispatch(fetchMapasBegin())

    const url = new URL(API_BASE_URL + 'mapa/')
    const params = { proyecto: proyectoId }
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )

    return fetch(url, {
      credentials: 'include'
    }).then(res => res.json()).then(res => {
      if (res.error) {
        throw res.error
      } else if (res && res.length) {
        dispatch(fetchMapasSucess(res))
      } else {
        throw new Error('Not found')
      }
    }).catch(error => {
      dispatch(fetchMapasError(error))
    })
  }
}

export function fetchMapaBegin () {
  return {
    type: ACTION_TYPES.FETCH_MAPA_BEGIN
  }
}

function fetchMapaSucess (mapa) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_SUCCESS,
    mapa: mapa
  }
}

function fetchMapaError (err) {
  return {
    type: ACTION_TYPES.FETCH_MAPA_ERROR,
    error: err
  }
}

export function fetchRemoteMapa (id, fields = []) {
  return dispatch => {
    const url = new URL(API_BASE_URL + 'mapa/' + id + '/')

    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }

    dispatch(fetchMapaBegin)
    fetch(url, {
      credentials: 'include'
    }).then(res => res.json()).then(res => {
      if (res.error) {
        throw res.error
      } else if (res) {
        dispatch(fetchMapaSucess(res))
      } else {
        throw new Error('Not found')
      }
    }).catch(error => {
      dispatch(fetchMapaError(error))
    })
  }
}
