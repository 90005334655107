import React from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'

import PublicBody from '../components/PublicBody'
import HeaderPublico from '../components/PublicHeader'
// import {fetchUsuario} from '../fetchactions/usuario';
import logoVertical from '../lib/logos/logo-vertical.svg'
import info01 from '../lib/images/portada/info01.svg'
import info02 from '../lib/images/portada/info02.svg'
import info03 from '../lib/images/portada/info03.svg'
import info04 from '../lib/images/portada/info04.svg'
import info05 from '../lib/images/portada/info05.svg'
import info06 from '../lib/images/portada/info06.svg'
import info07 from '../lib/images/portada/info07.svg'
import { fetchRemoteUsuario } from '../actions/usuario'
import { actions as visorActions } from '../actions/visor'
import { actions as mapaPortadaActions } from '../actions/mapaPortada'
import { REQUEST_STATUS, SCREENS } from '../constants/state'
import MapaPortada from '../components/home/MapaPortada'
import { fetchRemoteRecorrido, fetchRemoteRecorridos, fetchRecorridoSucess } from '../actions/recorrido'
import Footer from '../components/Footer'
import { fetchRemoteCategoriasPortada } from '../actions/categoriaPortada'
import { actions as portadaActions } from '../actions/portada'
import RecorridoItem from '../components/RecorridoItem'

import './HomeScreen.css'
import { Link, Redirect } from 'react-router-dom'
import * as turf from '@turf/turf'
import { Spinner } from 'react-bootstrap'
import fetchNotificaciones from '../actions/notificacion'
import VideosPortada from '../components/home/VideosPortada'

class HomeScreen extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      redirect: false
    }
  }

  componentDidMount () {
    const { dispatch, categoriasPortadaCargadas } = this.props
    this.setState({
      redirect: false
    })
    dispatch(fetchRemoteUsuario())
    if (!categoriasPortadaCargadas) {
      dispatch(fetchRemoteCategoriasPortada())
    }
    dispatch(fetchRemoteRecorridos([
      'id', 'nombre', 'centro_geometrico', 'deporte'
    ], { visible: true }))
    dispatch(mapaPortadaActions.setRecorridoSeleccionado())
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { user, dispatch } = this.props
    if (user && !prevProps.user) {
      dispatch(fetchNotificaciones({ usuario: user.id }))
      this.intervalNoti = setInterval(() => {
        dispatch(fetchNotificaciones({ usuario: user.id }))
      }, 30 * 1000)
    }
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(visorActions.setLastScreen(SCREENS.HOME))
    clearInterval(this.intervalNoti)
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  metrosToUnitsRecorrido (recorrido, distancia) {
    switch (recorrido.unidad_distancia) {
      case 'km':
        return this.toFixed((distancia / 1000), 2) + ' km'
      case 'mi':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'miles'), 2) + ' mi'
      case 'ft':
        return turf.convertLength(distancia, 'meters', 'feet').toFixed(0) + ' ft'
      default:
        return distancia.toFixed(0) + ' m'
    }
  }

  handleClickRecorridoMapa (recorridoId) {
    const { dispatch } = this.props
    dispatch(mapaPortadaActions.setRecorridoSeleccionado(recorridoId))
    if (recorridoId) {
      dispatch(fetchRemoteRecorrido(recorridoId))
    } else {
      dispatch(fetchRecorridoSucess(null))
    }
  }

  handleClickVideo (video) {
    const { dispatch } = this.props
    dispatch(portadaActions.setPortadaVideo(video))
  }

  render () {
    const {
      user, recorridos, categoriasPortada, t, mapaPortada,
      recorrido, notificaciones, portada
    } = this.props
    // console.info(recorrido)

    if (this.state.redirect) {
      return <Redirect to='/buscar' />
    }

    return (
      <PublicBody>
        <HeaderPublico user={user} notificaciones={notificaciones} />
        <div className='d-none justify-content-center background-dark'>
          <img src={logoVertical} className='logo logo-portada px-5 mb-4' alt='Logo' />
        </div>
        <div className='portada-descripcion d-none justify-content-center background-light'>
          <div className='container p-5 text-center' style={{ maxWidth: '900px' }}>
            <Trans i18nKey='msg-portada'>
              <strong> RACEMAPP.COM</strong> es una solución para <strong> organizaciones</strong> con la que
              conseguirás
              <strong> crear mapas interactivos</strong> de forma rápida y sencilla, permitiéndote
              <strong> explicar y geolocalizar</strong> los recorridos de tus eventos o actividades con toda la
              información
              que tus usuarios necesitan:
              <strong>
                traza o importa tracks, mide trayectos, destaca puntos de
                interés o servicios, marca puntos kilométricos
              </strong>
              ... Es una herramienta
              <strong> muy intuitiva </strong>
              que
              se adapta a todo tipo de recorridos de cualquier <strong> deporte o nivel</strong>.
            </Trans>
          </div>
        </div>

        <div className='d-flex justify-content-center background-dark py-5'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-12 col-md-9 col-lg-6 text-center'>
                <p><Trans>Busca entre los recorridos creados por nuestros usuarios</Trans></p>
                <form
                  className='mr-auto mb-4 justify-content-center'
                  onClick={() => {
                    this.setState({
                      redirect: true
                    })
                  }}
                  onChange={() => {
                    this.setState({
                      redirect: true
                    })
                  }}
                >
                  <input
                    className='form-control mr-sm-2 mb-2 mb-sm-0 '
                    type='buscar' placeholder={t('Buscar')}
                    aria-label='Search'
                  />
                </form>
              </div>
            </div>
            <div className='row justify-content-center'>
              <div className='col-12 d-flex justify-content-center'>
                <MapaPortada
                  recorrido={recorrido ? recorrido.data : null}
                  recorridos={recorridos}
                  portada
                  recorridoSeleccionado={mapaPortada.recorridoSeleccionado}
                  onClickRecorridoMapa={this.handleClickRecorridoMapa.bind(this)}
                  metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
                />
              </div>
            </div>
            <VideosPortada
              portada={portada}
              handleClick={this.handleClickVideo.bind(this)}
            />
            {categoriasPortada
              ? (
                <>
                  {categoriasPortada.map(cp =>
                    <React.Fragment key={cp.id}>
                      <div className='row'>
                        <div className='col-12'>
                          <h2 className='portada-titular'>
                            {cp.titulo}
                          </h2>
                        </div>
                      </div>
                      <div className='masonry'>
                        {cp.mapas.map(m =>
                          <div key={m.id} className='article'>
                            <div className='home__recorrido p-2'>
                              <Link className='home__recorrido-link' to={'/recorrido/' + m.recorrido.id}>
                                <RecorridoItem
                                  recorrido={m.recorrido} proyecto
                                  metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
                                />
                              </Link>
                            </div>
                          </div>
                        )}
                      </div>
                    </React.Fragment>
                  )}
                </>
              ) : (
                <>
                  <div className='row'>
                    <div className='col-12'>
                      <h2 className='portada-titular'>
                        <Spinner className='spinner-carga spinner-carga-sm' animation='border' role='status'>
                          <span className='sr-only'><Trans>Cargando...</Trans></span>
                        </Spinner>
                      </h2>
                    </div>
                  </div>
                  <div className='masonry'>
                    {[0, 1, 2, 3, 4, 5].map(m =>
                      <div key={m} className='article'>
                        <div className='home__recorrido home__recorrido--cargando p-2 d-flex justify-content-center'>
                          <Spinner className='spinner-carga' animation='border' role='status'>
                            <span className='sr-only'><Trans>Cargando...</Trans></span>
                          </Spinner>
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}
            <div className='row justify-content-center'>
              <div className='row'>
                <div className='col-12 d-flex justify-content-center mt-3'>
                  <Link to='/buscar' className='btn btn-amarillo'>
                    <i className='fas fa-search mr-2' />
                    <Trans>Ver más proyectos</Trans>
                  </Link>
                </div>
                <div className='col-12'>
                  <h2 className='portada-titular'>
                    <i className='fas fa-drafting-compass' />
                    <Trans>Creamos tus recorridos</Trans>
                  </h2>
                </div>
                <div className='col-12 d-flex justify-content-center align-items-center pt-3'>
                  <i className='fas fa-pencil-ruler fa-7x' />
                </div>
                <div className='col-12 col-sm-6 offset-sm-3 offset-0 pt-3 pb-3 text-center portada-texto-encargo'>
                  <p>
                    <Trans>
                      msg-encargo
                    </Trans>
                  </p>
                </div>
                <div className='col-12 d-flex justify-content-center align-items-center'>
                  <Link to='/pedido' className='btn btn-primary mt-3'><Trans>Haz aquí tu pedido</Trans></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='portada-info d-flex p-4 p-sm-5 justify-content-center background-light'>
          <div className='info-item info-item-top'>
            <div className='info-contenedor-imagen info-contenedor-imagen-top'>
              <img className='info-imagen' alt='' src={info07} />
            </div>
            <div className='info-texto info-texto-top pl-0 pl-sm-3'>
              <div className='info-titulo'>
                <Trans>Crea, integra y comparte</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='crea-integra-comparte-descr'>
                  Crea tus recorridos e intégralos en tu web, compártelos en tus redes
                  sociales y publícalos en nuestro directorio de proyectos de turrecorrido.com.
                </Trans>
              </div>
            </div>
          </div>
          <div className='info-item'>
            <div className='info-contenedor-imagen'>
              <img className='info-imagen' alt='' src={info02} />
            </div>
            <div className='info-texto pl-0 pl-sm-3'>
              <div className='info-titulo'>
                <Trans>Aprovecha toda la potencia de nuestro editor</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='aprovecha-nuestro-editor-descr'>
                  Diseña tus recorridos de forma profesional con una <strong>herramienta innovadora </strong>
                  que responderá a todas tus necesidades.
                </Trans>
              </div>
            </div>
          </div>
          <div className='info-item reverse'>
            <div className='info-texto text-center text-sm-right pr-0 pr-sm-3'>
              <div className='info-titulo'>
                <Trans>Pinta o importa tus tracks</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='pinta-importa-tracks-desc'>
                  Puedes trazar tus trayectos en nuestra aplicación o si ya tienes trabajo hecho puedes
                  <strong>importar tus tracks</strong> fácilmente desde tu editor y disfrutar de todas
                  las herramientas que te permitirán gestionarlos de una forma sencilla e intuitiva.
                </Trans>
              </div>
            </div>
            <div className='info-contenedor-imagen'>
              <img className='info-imagen' alt='' src={info01} />
            </div>
          </div>
          <div className='info-item'>
            <div className='info-contenedor-imagen'>
              <img className='info-imagen' alt='' src={info03} />
            </div>
            <div className='info-texto pl-0 pl-sm-3'>
              <div className='info-titulo'>
                <Trans>Mide y planifica</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='mide-planifica-descr'>
                  Ahorra trabajo y tiempo. Desde el editor de tu cuenta de usuario
                  podrás hacer todas las mediciones o pruebas que desees antes de empezar con el trabajo
                  de campo.
                </Trans>
              </div>
            </div>
          </div>
          <div className='info-item reverse'>
            <div className='info-texto text-center text-sm-right pr-0 pr-sm-3'>
              <div className='info-titulo'>
                <Trans>PDIs y puntos kilométricos</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='pdis-puntos-km-descr'>
                  Podrás geolocalizar todos los <strong>puntos de interés</strong> que desees para
                  ayudar a usuarios, público y colaboradores a orientarse desde.
                </Trans>
              </div>
            </div>
            <div className='info-contenedor-imagen'>
              <img className='info-imagen' alt='' src={info04} />
            </div>
          </div>
          <div className='info-item'>
            <div className='info-contenedor-imagen'>
              <img className='info-imagen' alt='' src={info05} />
            </div>
            <div className='info-texto pl-0 pl-sm-3'>
              <div className='info-titulo'>
                <Trans>Altimetrías</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='altimetrias-descr'>
                  El perfil de tu recorrido ya no será un problema... Cada vez que
                  crees un recorrido podrás generar <strong>altimetrías o perfiles</strong> de forma
                  automatizada con todos los puntos de interés o puntos kilométricos que hayas asociado
                  al mismo.
                </Trans>
              </div>
            </div>
          </div>
          <div className='info-item reverse'>
            <div className='info-texto text-center text-sm-right pr-0 pr-sm-3'>
              <div className='info-titulo'>
                <Trans>Sectores en multideporte</Trans>
              </div>
              <div className='info-cuerpo'>
                <Trans i18nKey='sectores-multideporte-descr'>
                  Si tu recorrido se complica no es problema.... esta aplicación es la solución ideal
                  para los deportes combinados como el <strong>triatlon, duatlon, raids</strong>.
                </Trans>
              </div>
            </div>
            <div className='info-contenedor-imagen'>
              <img className='info-imagen' alt='' src={info06} />
            </div>
          </div>
        </div>
        <Footer user={user} />
      </PublicBody>
    )
  }
}

const mapStateToProps = state => {
  return {
    // error: state.error,
    user: state.usuario.data,
    recorridos: state.recorridos.data,
    categoriasPortada: state.categoriasPortada.data,
    categoriasPortadaCargadas: state.categoriasPortada.status === REQUEST_STATUS.SUCCEEDED,
    mapaPortada: state.mapaPortada,
    recorrido: state.recorrido,
    notificaciones: state.notificaciones.data,
    portada: state.portada
  }
}

export default withTranslation()(connect(mapStateToProps)(HomeScreen))
