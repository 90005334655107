import React from 'react'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Trans, withTranslation } from 'react-i18next'
import * as L from 'leaflet'
import { Spinner } from 'react-bootstrap'

import { actions as visorActions } from '../actions/visor'

import TopBar from '../components/visor/TopBar'
import Mapa from '../components/visor/Mapa'
import Compartir from '../components/Compartir'
import NotFound from '../components/NotFound'
import {
  fetchRecorridoBegin, fetchRemoteRecorrido, fetchRemoteRecorridos, fetchRemoteRecorridosMapa
} from '../actions/recorrido'
import { REQUEST_STATUS, SCREENS } from '../constants/state'
import { fetchRemoteMapa } from '../actions/mapa'
import { fetchRemoteProyecto } from '../actions/proyecto'
import { fetchRemoteFondos } from '../actions/fondo'
import { fetchRemotePdisProyecto } from '../actions/pdi'
import { fetchRemoteLineas } from '../actions/lineas'
import { fetchRemotePoligonos } from '../actions/poligonos'
import * as turf from '@turf/turf'
import { fetchRemoteLista } from '../actions/lista'
import GestorAnimacion from '../components/visor/GestorAnimacion'
import { updateVisitas } from '../actions/visitaRecorrido'
import { actions as domActions } from '../actions/dom'
import EnObras from '../components/visor/EnObras'
import { fetchRemoteUsuario } from '../actions/usuario'

export class MapaInteractivoScreenClass extends React.Component {
  constructor (props) {
    super(props)
    this.handleDomLoad = this._handleDomLoad.bind(this)
  }

  _handleDomLoad () {
    const { dispatch } = this.props
    dispatch(domActions.domSetCargado())
    dispatch(domActions.domSetStartRecorrido(true))
  }

  componentDidMount () {
    const { match, dispatch, sector, recorrido, recorridos, dom } = this.props

    if (!dom.cargado) {
      window.addEventListener('load', this.handleDomLoad)
    }

    // Borramos as opcións de visualización dos menús
    this.cerrarMenus()

    dispatch(fetchRemoteUsuario())

    if (!recorrido || recorrido.id !== parseInt(match.params.id) || !sector || !recorridos || recorridos.length === 0) {
      // Borrar os mapas ó cargar, para que non mostre o anterior (que ainda pode estar no estado)
      dispatch(fetchRecorridoBegin())

      if (match && match.params && match.params.id) {
        dispatch(fetchRemoteRecorrido(match.params.id))
      }
    } else {
      const bounds = this.buildBounds(recorrido)
      dispatch(visorActions.setMapBounds(bounds))
    }
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const {
      dispatch, recorrido, recorridoCargado, mapa, mapaCargado, match
    } = this.props

    if (prevProps.match.params.id !== match.params.id) {
      // Cargamos un recorrido distinto!
      dispatch(fetchRecorridoBegin())
      // dispatch(fetchMapaBegin())
      // dispatch(fetchRecorridosBegin())
      dispatch(fetchRemoteRecorrido(match.params.id))
    }
    // @todo: optimizar a carga cando non se cambia de proxeto (sirven os mesmos recorridos e proyecto)
    // Cando se carga un recorrido
    if (!prevProps.recorridoCargado && recorridoCargado) {
      dispatch(updateVisitas(recorrido.id))
      const fields = ['id', 'nombre', 'slug', 'descripcion', 'sectores', 'orden']
      dispatch(fetchRemoteMapa(recorrido.mapa))
      if (match.params.idLista) {
        dispatch(fetchRemoteLista(match.params.idLista))
        dispatch(fetchRemoteRecorridos(fields, { listas__lista: match.params.idLista, visible: true }))
      } else {
        dispatch(fetchRemoteRecorridosMapa(recorrido.mapa, fields, { visible: true }))
      }
      const bounds = this.buildBounds(recorrido)
      dispatch(visorActions.setMapBounds(bounds))
    }
    // Ó finalizar todas as peticións importantes,
    // facemos as que non son indispensables para que se vexa a páxina +-...
    if ((!prevProps.mapaCargado && mapaCargado) || (prevProps.mapa && mapa && prevProps.mapa.id !== mapa.id)) {
      // Cargamos proyecto relacionado
      dispatch(fetchRemoteProyecto(mapa.proyecto))
      // Cargamos os pdis de proyecto (poden ser puntos, lineas ou poligonos)
      const params = { agrupacion__proyecto: mapa.proyecto, pdi_proyecto: true }
      const fieldsBase = ['id', 'nombre', 'descripcion', 'color', 'imagen', 'url_embebido', 'pdi_proyecto']
      const fieldsPdis = fieldsBase.concat(['icono', 'es_pk', 'limitado_a_trayecto'])
      dispatch(fetchRemotePdisProyecto(params, fieldsPdis))
      const fieldsLineas = fieldsBase.concat(['grosor', 'patron', 'opacidad', 'coordenadas'])
      dispatch(fetchRemoteLineas(params, fieldsLineas))
      const fieldsPoligonos = fieldsBase.concat(['opacidad_fondo', 'grosor_linea', 'patron_linea', 'coordenadas'])
      dispatch(fetchRemotePoligonos(params, fieldsPoligonos))
      dispatch(fetchRemoteFondos())
    }
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(visorActions.setLastScreen(SCREENS.MAPA))
    dispatch(visorActions.setLocation())
    dispatch(visorActions.resetCapasOcultas())

    window.removeEventListener('load', this.handleDomLoad)
  }

  buildBounds (recorrido) {
    const mapBounds = L.latLngBounds()

    if (recorrido.vista_inicial && recorrido.vista_inicial.coordinates.length) {
      const c = recorrido.vista_inicial.coordinates
      mapBounds.extend([c[0][1], c[0][0]])
      mapBounds.extend([c[1][1], c[1][0]])
    } else {
      recorrido.sectores.forEach(s => {
        s.trayectos.forEach(t => {
          mapBounds.extend(
            t.coordenadas.coordinates.map(row => [row[1], row[0]])
          )
        })
      })
      // ...
      recorrido.lineas.forEach(l => {
        mapBounds.extend(
          l.coordenadas.coordinates.map(row => [row[1], row[0]])
        )
      })
      // ...
      recorrido.poligonos.forEach(p => {
        mapBounds.extend(
          p.coordenadas.coordinates[0].map(row => [row[1], row[0]])
        )
      })
    }

    return mapBounds
  }

  cerrarMenus () {
    const { dispatch } = this.props
    dispatch(visorActions.modalEsquemaOcultar())
    dispatch(visorActions.modalPdisOcultar())
    dispatch(visorActions.submenuAjustesVisualesOcultar())
    dispatch(visorActions.submenuHerramientasOcultar())
    dispatch(visorActions.submenuPdisOcultar())
    dispatch(visorActions.modalPdiOcultar())
    dispatch(visorActions.modalCambiarVistaOcultar())
    dispatch(visorActions.modalPdisProyectoOcultar())
    dispatch(visorActions.modalPerfilesOcultar())
    dispatch(visorActions.submenuVelocidadOcultar())
    dispatch(visorActions.setPdiDestacado())
    dispatch(visorActions.setPdiFocused())
  }

  handleClickSectores () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalEsquemaVisible) {
      dispatch(visorActions.modalEsquemaOcultar())
    } else {
      dispatch(visorActions.modalEsquemaMostrar())
    }
  }

  handleClickMostrarPdisRecorrido () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalPdisVisible) {
      dispatch(visorActions.modalPdisOcultar())
    } else {
      dispatch(visorActions.modalPdisMostrar())
    }
  }

  handleClickMostrarPdisProyecto () {
    const { dispatch, visor } = this.props
    this.cerrarMenus()
    if (visor.modalPdisProyectoVisible) {
      dispatch(visorActions.modalPdisProyectoOcultar())
    } else {
      dispatch(visorActions.modalPdisProyectoMostrar())
    }
  }

  handleClickAjustesVisuales () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.submenuAjustesVisualesVisible) {
      dispatch(visorActions.submenuAjustesVisualesOcultar())
    } else {
      dispatch(visorActions.submenuAjustesVisualesMostrar())
    }
  }

  handleClickHerramientas () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.submenuHerramientasVisible) {
      dispatch(visorActions.submenuHerramientasOcultar())
    } else {
      dispatch(visorActions.submenuHerramientasMostrar())
    }
  }

  handleClickPdis () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.submenuPdisVisible) {
      dispatch(visorActions.submenuPdisOcultar())
    } else {
      dispatch(visorActions.submenuPdisMostrar())
    }
  }

  handleClickMapaOcultarPdisProyecto () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.mapaPdisProyectoVisible) {
      dispatch(visorActions.mapaPdisProyectoOcultar())
    } else {
      dispatch(visorActions.mapaPdisProyectoMostrar())
    }
  }

  handleClickMapaOcultarPdisRecorrido () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.mapaPdisRecorridoVisible) {
      dispatch(visorActions.mapaPdisRecorridoOcultar())
    } else {
      dispatch(visorActions.mapaPdisRecorridoMostrar())
    }
  }

  handleClickPdiMapa (pdi) {
    const { dispatch, visor } = this.props
    this.cerrarMenus()
    const indexActual = visor.pdiOrden.indexOf(pdi.id)
    dispatch(visorActions.setIndexPdiOrden(indexActual >= 0 ? indexActual : null))
    dispatch(visorActions.setPdiFocused(pdi))
  }

  handleClickPdiLista (pdi, mapRef) {
    const { dispatch, visor } = this.props
    this.cerrarMenus()
    if (mapRef.current.leafletElement) {
      if (pdi.coordenadas.type === 'Point') {
        mapRef.current.leafletElement.setView([pdi.coordenadas.coordinates[1], pdi.coordenadas.coordinates[0]], 18)
      } else {
        const geoJson = L.geoJSON(pdi.coordenadas)
        mapRef.current.leafletElement.fitBounds(geoJson.getBounds(), { maxZoom: 18 })
      }
    }
    const indexActual = visor.pdiOrden.indexOf(pdi.id)
    dispatch(visorActions.setIndexPdiOrden(indexActual >= 0 ? indexActual : null))
    dispatch(visorActions.setPdiFocused(pdi))
  }

  handleClickSigPdi (pdi) {
    const { dispatch, visor, pdisProyecto, lineas, poligonos } = this.props
    this.cerrarMenus()
    if (visor.indexPdiOrden !== null && !isNaN(visor.indexPdiOrden)) {
      const puntoSiguiente = this.getPdiById(visor.pdiOrden[visor.indexPdiOrden + 1])
      if (puntoSiguiente) {
        dispatch(visorActions.setIndexPdiOrden(visor.indexPdiOrden + 1))
        dispatch(visorActions.setPdiFocused(puntoSiguiente))
      }
    } else {
      const pdis = pdisProyecto.concat(lineas, poligonos)
      const pdisOrdenados = pdis.sort((a, b) =>
        a.bloque.capa.orden !== b.bloque.capa.orden
          ? a.bloque.capa.orden - b.bloque.capa.orden
          : (a.agrupacion_orden !== b.agrupacion_orden)
            ? a.agrupacion_orden - b.agrupacion_orden
            : a.orden_editor - b.orden_editor
      )
      dispatch(visorActions.setPdiFocused(pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) + 1]))
    }
  }

  handleClickPrevPdi (pdi) {
    const { dispatch, visor, pdisProyecto, lineas, poligonos } = this.props
    this.cerrarMenus()
    if (visor.indexPdiOrden !== null && !isNaN(visor.indexPdiOrden)) {
      const puntoSiguiente = this.getPdiById(visor.pdiOrden[visor.indexPdiOrden - 1])
      if (puntoSiguiente) {
        dispatch(visorActions.setIndexPdiOrden(visor.indexPdiOrden - 1))
        dispatch(visorActions.setPdiFocused(puntoSiguiente))
      }
    } else {
      const pdis = pdisProyecto.concat(lineas, poligonos)
      const pdisOrdenados = pdis.sort((a, b) =>
        a.bloque.capa.orden !== b.bloque.capa.orden
          ? a.bloque.capa.orden - b.bloque.capa.orden
          : (a.agrupacion_orden !== b.agrupacion_orden)
            ? a.agrupacion_orden - b.agrupacion_orden
            : a.orden_editor - b.orden_editor
      )
      dispatch(visorActions.setPdiFocused(pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) - 1]))
    }
  }

  handleClickCerrarPdiFocused () {
    const { dispatch } = this.props
    dispatch(visorActions.setPdiFocused(null))
  }

  getPdiById (id) {
    const { recorrido } = this.props
    for (const i in recorrido.sectores) {
      const indexPdi = recorrido.sectores[i].pdis_recorrido.map(pdi => pdi.id).indexOf(id)
      if (indexPdi !== -1) {
        return recorrido.sectores[i].pdis_recorrido[indexPdi]
      }
      const indexPk = recorrido.sectores[i].puntos_km.map(pk => pk.punto.id).indexOf(id)
      if (indexPk !== -1) {
        return recorrido.sectores[i].puntos_km[indexPk].punto
      }
    }
    return false
  }

  handleClickCompartirMostrar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirMostrar())
  }

  handleClickCompartirOcultar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirOcultar())
    this.cerrarMenus()
  }

  handleClickCambiarVista () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalCambiarVistaVisible) {
      dispatch(visorActions.modalCambiarVistaOcultar())
    } else {
      dispatch(visorActions.modalCambiarVistaMostrar())
    }
  }

  handleClickSeleccionarCapaFondo (capa) {
    const { dispatch } = this.props
    dispatch(visorActions.seleccionarCapaFondo(capa))
    this.cerrarMenus()
  }

  handleClickMenuModalPerfil () {
    const { dispatch, visor, recorrido } = this.props
    const perfilesVisible = recorrido.sectores.filter(s => s.perfil_publicado)
    if (perfilesVisible.length === 1) {
      this.props.history.push(
        `/perfil/${perfilesVisible[0].id}-${recorrido.slug}`
      )
    } else {
      this.cerrarMenus()
      if (visor.modalPerfilVisible) {
        dispatch(visorActions.modalPerfilesOcultar())
      } else {
        dispatch(visorActions.modalPerfilesMostrar())
      }
    }
  }

  handleClickModalSeleccionarPerfil (perfil) {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.modalPerfilSeleccionar(perfil))
  }

  handleClickEsquema (esquema) {
    const { recorrido, dispatch } = this.props
    const mapBounds = L.latLngBounds()

    this.cerrarMenus()
    recorrido.sectores.forEach(s => {
      s.trayectos.forEach(t => {
        if (t.id === esquema.trayecto.id) {
          mapBounds.extend(
            t.coordenadas.coordinates.map(row => [row[1], row[0]])
          )
        }
      })
    })

    dispatch(visorActions.setMapBounds(mapBounds))
  }

  handleClickSector (sector) {
    const { dispatch } = this.props
    const mapBounds = L.latLngBounds()

    this.cerrarMenus()
    sector.trayectos.forEach(t => {
      mapBounds.extend(
        t.coordenadas.coordinates.map(row => [row[1], row[0]])
      )
    })

    dispatch(visorActions.setMapBounds(mapBounds))
  }

  handleClickDescargarTrack () {
    const { proyecto, recorrido } = this.props
    this.cerrarMenus()
    let lineTotal
    let puntos = []
    recorrido.sectores.forEach(sec => {
      sec.esquemas.forEach(esq => {
        puntos = []
        const trayecto = sec.trayectos.filter(t => t.id === esq.trayecto.id)
        if (trayecto[0]) {
          if (!lineTotal) {
            lineTotal = turf.lineString(trayecto[0].coordenadas_interpoladas.coordinates, {
              name: proyecto.nombre + ' - ' + recorrido.nombre
            })
            for (let i = 1; i < esq.repeticiones; i++) {
              lineTotal.geometry.coordinates = lineTotal.geometry.coordinates.concat(trayecto[0].coordenadas.coordinates)
            }
          } else {
            for (let i = 0; i < esq.repeticiones; i++) {
              lineTotal.geometry.coordinates = lineTotal.geometry.coordinates.concat(trayecto[0].coordenadas.coordinates)
            }
          }
        }
      })
      puntos = puntos.concat(
        sec.pdis_recorrido.map(pdi => turf.point(pdi.coordenadas.coordinates, { name: pdi.nombre })),
        sec.puntos_km.map(pk => turf.point(pk.punto.coordenadas.coordinates, { name: pk.punto.nombre }))
      )
      const features = turf.featureCollection([lineTotal, ...puntos], {
        name: proyecto.nombre + '-' + recorrido.nombre
      })
      const togpx = require('togpx')
      // eslint-disable-next-line no-undef
      const blob = new Blob([togpx(features)], { type: 'application/gpx+xml' })

      const a = document.createElement('a')
      if (recorrido.sectores.length > 1) {
        a.download = (proyecto.nombre + '-' + recorrido.nombre + '-' + sec.nombre + '.gpx').replace(/\s/g, '_')
      } else {
        a.download = (proyecto.nombre + '-' + recorrido.nombre + '.gpx').replace(/\s/g, '_')
      }
      a.href = URL.createObjectURL(blob)
      a.dataset.downloadurl = ['application/gpx+xml', a.download, a.href].join(':')
      a.style.display = 'none'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      setTimeout(() => URL.revokeObjectURL(a.href), 1500)
    })
  }

  handleClickAnimacion (mapRef) {
    const { dispatch } = this.props
    this.cerrarMenus()
    if (mapRef.current) {
      this.initAnimacion(mapRef)
      mapRef.current.leafletElement.stopLocate()
      dispatch(visorActions.setLocation())
    } else {
      dispatch(visorActions.animacionOn())
    }
  }

  handleClickStopAnimation () {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.animacionOff())
  }

  handleClickPauseAnimation (e) {
    const { dispatch, visor } = this.props
    this.cerrarMenus()
    e.target.blur()
    if (visor.animacionPausada) {
      dispatch(visorActions.animacionPausadaOff())
    } else {
      dispatch(visorActions.animacionPausadaOn())
    }
  }

  handleClickVelocidadAnimacion () {
    const { dispatch, visor } = this.props
    this.cerrarMenus()
    if (visor.submenuVelocidadVisible) {
      dispatch(visorActions.submenuVelocidadOcultar())
    } else {
      dispatch(visorActions.submenuVelocidadMostrar())
    }
  }

  handleClickMultiplicadorVelocidad (multiplicador) {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.setMultiplicadorVelocidadAnim(multiplicador))
  }

  handleClickReiniciarAnimacion (mapRef) {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.animacionPausadaOn())
    dispatch(visorActions.animacionOff())
    if (mapRef.current) {
      this.initAnimacion(mapRef)
    } else {
      dispatch(visorActions.animacionOn())
    }
  }

  handleClickAnimacionBucle () {
    const { dispatch, visor } = this.props
    this.cerrarMenus()
    if (visor.animacionBucle) {
      dispatch(visorActions.animacionBucleOff())
    } else {
      dispatch(visorActions.animacionBucleOn())
    }
  }

  handleChangeFiltroPdis (e) {
    const { dispatch } = this.props
    dispatch(visorActions.setFiltroPdis(e.target.value))
  }

  handleChangeFiltroPdisRecorrido (e) {
    const { dispatch } = this.props
    dispatch(visorActions.setFiltroPdisRecorrido(e.target.value))
  }

  handleClickPdiDestacado (pdi) {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.setPdiDestacado(pdi))
  }

  handleClickCerrarPdiDestacado () {
    this.cerrarMenus()
  }

  handleClickDescargarLinea (linea) {
    const togpx = require('togpx')
    // eslint-disable-next-line no-undef
    const blob = new Blob([togpx(turf.lineString(linea.coordenadas.coordinates))], { type: 'application/gpx+xml' })

    const a = document.createElement('a')
    a.download = (linea.nombre + '.gpx').replace(/\s/g, '_')
    a.href = URL.createObjectURL(blob)
    a.dataset.downloadurl = ['application/gpx+xml', a.download, a.href].join(':')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setTimeout(() => URL.revokeObjectURL(a.href), 1500)
  }

  initAnimacion (mapRef) {
    const { recorrido, visor, dispatch } = this.props
    const map = mapRef.current.leafletElement
    const primerTrayecto = recorrido.sectores[0].trayectos.filter(tr =>
      tr.id === recorrido.sectores[0].esquemas[0].trayecto.id)
    if (primerTrayecto.length > 0) {
      map.flyTo([...primerTrayecto[0].coordenadas.coordinates[0]].reverse(), visor.zoomAnimacion)
      map.once('zoomend', () => {
        dispatch(visorActions.animacionOn(true))
      })
    }
  }

  handleMapZooom (map) {
    const { dispatch } = this.props
    dispatch(visorActions.mapZoomChanged(map._zoom))
    // this.handleClickCerrarPdiFocused()
  }

  handleClickMap () {
    this.handleClickCerrarPdiFocused()
  }

  handleMoveMap (e) {
    const { visor, dispatch } = this.props
    if (!e.flyTo && visor.location && !e.target.getBounds().contains(visor.location.bounds)) {
      dispatch(visorActions.localizacionDescentradaOn())
    }
  }

  handleClickRecentrar (mapRef) {
    const { visor, dispatch } = this.props
    dispatch(visorActions.localizacionDescentradaOff())
    if (visor.location) {
      mapRef.current.leafletElement.flyToBounds(visor.location.bounds, { maxZoom: 18 })
    }
  }

  handleClickLocate (mapRef) {
    this.cerrarMenus()
    if (mapRef.current.leafletElement) {
      const map = mapRef.current.leafletElement
      map.locate({ enableHighAccuracy: true, watch: true })
      map.on('locationfound', this.onLocationFound.bind(this))
      map.on('locationerror', e => {
        window.alert(e.message)
      })
    }
  }

  onLocationFound (e) {
    const { dispatch, visor } = this.props
    if (!visor.localizacionDescentrada) e.target.flyToBounds(e.bounds, { maxZoom: 18 })
    dispatch(visorActions.setLocation(e))
  }

  handleClickLocateStop (mapRef) {
    const { dispatch } = this.props
    this.cerrarMenus()
    if (mapRef.current.leafletElement) {
      const map = mapRef.current.leafletElement
      map.stopLocate()
      map.off('locationfound')
      map.off('locationerror')
      dispatch(visorActions.setLocation())
    }
  }

  handleClickVisibilidadCapa (capa) {
    const { dispatch, visor } = this.props
    if (visor.capasOcultas.map(c => c.id).includes(capa.id)) {
      dispatch(visorActions.removeCapaOculta(capa))
    } else {
      dispatch(visorActions.addCapaOculta(capa))
    }
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  metrosToUnitsRecorrido (distancia) {
    const { recorrido } = this.props
    switch (recorrido.unidad_distancia) {
      case 'km':
        return this.toFixed((distancia / 1000), 2) + ' km'
      case 'mi':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'miles'), 2) + ' mi'
      case 'ft':
        return turf.convertLength(distancia, 'meters', 'feet').toFixed(0) + ' ft'
      default:
        return distancia.toFixed(0) + ' m'
    }
  }

  getTrayectoEsquemaAnim () {
    const { visor, recorrido } = this.props
    for (const i in recorrido.sectores) {
      if (visor.esquemaEnAnimacion && recorrido.sectores[i].id === visor.esquemaEnAnimacion.sector) {
        const trayecto = recorrido.sectores[i].trayectos.filter(tr => tr.id === visor.esquemaEnAnimacion.trayecto.id)
        if (trayecto[0]) {
          return trayecto[0]
        }
      }
    }
    return null
  }

  isPuntoVisibleAnimacion (punto, mapRef) {
    const { visor } = this.props
    if (punto.bloque.es_pk && visor.esquemaEnAnimacion && visor.esquemaEnAnimacion.repeticiones > 1) {
      return false
    }
    return visor.puntosAnimados.map(p => p.id).includes(punto.id)
  }

  render () {
    const {
      recorrido, recorridoError, mapa, proyecto, recorridos, visor, fondos, pdisProyecto, lineas, poligonos,
      listaCargada, lista, dom, t, usuario
    } = this.props

    if (recorridoError) {
      return <NotFound error={recorrido.error} />
    }
    if (recorrido && !recorrido.visible && (!usuario || (proyecto && proyecto.user_id !== usuario.id))) {
      return <EnObras />
    }

    // console.info(recorrido)
    if (visor.compartirVisible) {
      return <Compartir clickCerrar={this.handleClickCompartirOcultar.bind(this)} insertarActivo />
    } else {
      if (!recorrido || !proyecto) {
        return (
          <div className='fondo-carga'>
            <Spinner className='spinner-carga' animation='border' role='status'>
              <span className='sr-only'><Trans>Cargando...</Trans></span>
            </Spinner>
          </div>
        )
      }

      let pdisRecorrido = []
      const sectoresPdis = []
      if (recorrido && recorrido.visible) {
        recorrido.sectores.forEach(s => {
          if (s.pdis_recorrido.length || s.puntos_km.length) {
            sectoresPdis.push(s)
          }
        })
        recorrido.sectores.forEach(sector => {
          const idsTr = sector.trayectos.map(tr => tr.id)
          pdisRecorrido = pdisRecorrido.concat(sector.pdis_recorrido.filter(p =>
            idsTr.includes(p.trayecto_ancla)).concat(sector.puntos_km.map(p => p.punto)))
        })
      }
      const mostrarBackdrop = visor.modalEsquemaVisible ||
        visor.modalPdisVisible ||
        visor.submenuPdisVisible ||
        visor.submenuHerramientasVisible ||
        visor.submenuAjustesVisualesVisible ||
        visor.modalPdiSeleccionado ||
        visor.modalCambiarVistaVisible ||
        visor.modalPdisProyectoVisible ||
        visor.modalPerfilVisible ||
        visor.submenuVelocidadVisible ||
        visor.pdiDestacado
        // visor.modalPerfilSeleccionado

      let ogImage = '/og_image.png'
      if (recorrido && recorrido.captura_2x1_textos) {
        ogImage = recorrido.captura_2x1_textos
      }

      if (listaCargada) {
        recorridos.forEach(rec => {
          const listaMapa = lista.mapas.filter(mapa => mapa.recorrido === rec.id)
          if (listaMapa.length) {
            rec.orden = listaMapa[0].orden
          }
          if (recorrido.id === rec.id) {
            recorrido.orden = listaMapa[0].orden
          }
        })
        recorridos.sort((a, b) => a.orden - b.orden)
      }
      let mostrarCerrar = !mostrarBackdrop
      if (mostrarCerrar) {
        if (dom.estaEnIframe) {
          mostrarCerrar = false
          if (!dom.iniciadoEnRecorrido) {
            mostrarCerrar = true
          }
        }
      }

      const visibleSoloUsuario = !recorrido.visible || !recorrido.subscripcion_activa ||
        !recorrido.subscripcion_activa.activa || new Date(recorrido.subscripcion_activa.fin) < Date.now()

      return (
        <>
          {(!recorrido.subscripcion_activa ||
            !recorrido.subscripcion_activa.activa ||
            new Date(recorrido.subscripcion_activa.fin)) < Date.now() &&
              <div className='alert alert-danger mb-0'>
                <i className='fas fa-info-circle mr-3' />
                <strong><Trans>Este recorrido no está publicado, solo tu puedes verlo</Trans></strong>
              </div>}
          {recorrido.subscripcion_activa && recorrido.subscripcion_activa.activa && !recorrido.visible &&
            <div className='alert alert-danger mb-0'>
              <i className='fas fa-info-circle mr-3' />
              <strong><Trans>Este recorrido está oculto para el público, solo es visible para ti</Trans></strong>
            </div>}
          <div className={'main-grid--recorrido' + (visibleSoloUsuario ? ' main-grid--recorrido--privado' : '')}>
            <Helmet>
              <html lang='es' />
              <title>{t(`${recorrido.nombre} en ${proyecto.nombre} - RaceMapp`)}</title>
              <meta name='description' content={recorrido.meta_descripcion} />
              <meta property='og:url' content={window.location.href} />
              <meta property='og:type' content='article' />
              <meta property='og:title' content={t(`${recorrido.nombre} en ${proyecto.nombre} - RaceMapp`)} />
              <meta property='og:description' content={recorrido.meta_descripcion} />
              <meta property='og:image' content={ogImage} />
            </Helmet>
            <TopBar
              titulo={proyecto.nombre}
              oficial={recorrido.oficial}
              subtitulo={recorrido ? recorrido.nombre : ''}
              backLink={listaCargada ? `/lista/${lista.id}` : `/proyecto/${proyecto.id}-${proyecto.slug}`}
              sectores={recorrido ? recorrido.sectores : []}
              onClickSetores={this.handleClickSectores.bind(this)}
              metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
              handleClickCompartir={dom.estaEnIframe ? null : this.handleClickCompartirMostrar.bind(this)}
              mostrarCerrar={mostrarCerrar}
              linkHomeEnabled
            />
            <Mapa
              mapa={mapa}
              capasFondo={fondos}
              recorrido={recorrido}
              recorridos={recorridos}
              proyecto={proyecto}
              pdisProyecto={pdisProyecto}
              pdisRecorrido={pdisRecorrido || []}
              sectoresPdis={sectoresPdis || []}
              lineas={lineas || []}
              poligonos={poligonos || []}
              backLink={!window.inIframe ? `/proyecto/${proyecto.id}-${proyecto.slug}` : null}
              visor={visor}
              mostrarBackdrop={mostrarBackdrop}
              cerrarMenus={this.cerrarMenus.bind(this)}
              onClickEsquema={this.handleClickEsquema.bind(this)}
              onClickSector={this.handleClickSector.bind(this)}
              handleClickMenuPerfil={this.handleClickMenuModalPerfil.bind(this)}
              handleClickModalSeleccionarPerfil={this.handleClickModalSeleccionarPerfil.bind(this)}
              handleClickMostrarPdisRecorrido={this.handleClickMostrarPdisRecorrido.bind(this)}
              handleClickMostrarPdisProyecto={this.handleClickMostrarPdisProyecto.bind(this)}
              handleClickAjustesVisuales={this.handleClickAjustesVisuales.bind(this)}
              handleClickHerramientas={this.handleClickHerramientas.bind(this)}
              handleClickCambiarVista={this.handleClickCambiarVista.bind(this)}
              handleClickSeleccionarCapaFondo={this.handleClickSeleccionarCapaFondo.bind(this)}
              handleClickPdis={this.handleClickPdis.bind(this)}
              handleClickMapaOcultarPdisProyecto={this.handleClickMapaOcultarPdisProyecto.bind(this)}
              handleClickMapaOcultarPdisRecorrido={this.handleClickMapaOcultarPdisRecorrido.bind(this)}
              handleClickPdiMapa={this.handleClickPdiMapa.bind(this)}
              handleClickCompartir={dom.estaEnIframe ? null : this.handleClickCompartirMostrar.bind(this)}
              handleClickPdiLista={this.handleClickPdiLista.bind(this)}
              handleClickDescargarTrack={this.handleClickDescargarTrack.bind(this)}
              handleClickPrevPdi={this.handleClickPrevPdi.bind(this)}
              handleClickSigPdi={this.handleClickSigPdi.bind(this)}
              handleClickAnimacion={this.handleClickAnimacion.bind(this)}
              handleClickStopAnimation={this.handleClickStopAnimation.bind(this)}
              handleClickPauseAnimation={this.handleClickPauseAnimation.bind(this)}
              handleClickVelocidadAnimacion={this.handleClickVelocidadAnimacion.bind(this)}
              handleClickMultiplicadorVelocidad={this.handleClickMultiplicadorVelocidad.bind(this)}
              handleClickReiniciarAnimacion={this.handleClickReiniciarAnimacion.bind(this)}
              handleClickAnimacionBucle={this.handleClickAnimacionBucle.bind(this)}
              handleChangeFiltroPdis={this.handleChangeFiltroPdis.bind(this)}
              handleChangeFiltroPdisRecorrido={this.handleChangeFiltroPdisRecorrido.bind(this)}
              handleClickPdiDestacado={this.handleClickPdiDestacado.bind(this)}
              handleClickCerrarPdiDestacado={this.handleClickCerrarPdiDestacado.bind(this)}
              handleClickDescargarLinea={this.handleClickDescargarLinea.bind(this)}
              handleClickCerrarPdiFocused={this.handleClickCerrarPdiFocused.bind(this)}
              handleClickLocate={this.handleClickLocate.bind(this)}
              handleClickLocateStop={this.handleClickLocateStop.bind(this)}
              handleClickVisibilidadCapa={this.handleClickVisibilidadCapa.bind(this)}
              handleClickRecentrar={this.handleClickRecentrar.bind(this)}
              metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
              handleMapZoom={this.handleMapZooom.bind(this)}
              handleClickMap={this.handleClickMap.bind(this)}
              handleMoveMap={this.handleMoveMap.bind(this)}
              listaId={this.props.match.params.idLista}
              toFixed={this.toFixed.bind(this)}
              isPuntoVisibleAnimacion={this.isPuntoVisibleAnimacion.bind(this)}
              getTrayectoEsquemaAnim={this.getTrayectoEsquemaAnim.bind(this)}
            />
            {visor.animando &&
              <GestorAnimacion />}
            {/* <Footer backLink={`/recorrido/${proyecto.id}`}/> */}
          </div>
        </>
      )
    }
  }
}

const mapStateToProps = state => {
  return {
    dom: state.dom,
    visor: state.visor,
    error: state.error,
    recorrido: state.recorrido.data,
    recorridoError: state.recorrido.error,
    recorridoCargado: state.recorrido.status === REQUEST_STATUS.SUCCEEDED,
    mapa: state.mapa.data,
    mapaCargado: state.mapa.status === REQUEST_STATUS.SUCCEEDED,
    lista: state.lista.data,
    listaCargada: state.lista.status === REQUEST_STATUS.SUCCEEDED,
    proyecto: state.proyecto.data,
    recorridos: state.recorridos.data,
    fondos: state.fondos.data,
    pdisProyecto: state.pdisProyecto.data,
    lineas: state.lineas.data,
    poligonos: state.poligonos.data,
    sector: state.sector.data,
    usuario: state.usuario.data
  }
}

export default connect(mapStateToProps)(withTranslation()(MapaInteractivoScreenClass))
