/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_CATEGORIAS_PORTADA_BEGIN: 'FETCH_CATEGORIAS_PORTADA_BEGIN',
  FETCH_CATEGORIAS_PORTADA_SUCCESS: 'FETCH_CATEGORIAS_PORTADA_SUCCESS',
  FETCH_CATEGORIAS_PORTADA_ERROR: 'FETCH_CATEGORIAS_PORTADA_ERROR'
}

function fetchCategoriasPortadaBegin () {
  return {
    type: ACTION_TYPES.FETCH_CATEGORIAS_PORTADA_BEGIN
  }
}

function fetchCategoriasPortadaSuccess (categorias) {
  return {
    type: ACTION_TYPES.FETCH_CATEGORIAS_PORTADA_SUCCESS,
    categorias
  }
}

function fetchCategoriasPortadaError (error) {
  return {
    type: ACTION_TYPES.FETCH_CATEGORIAS_PORTADA_ERROR,
    error
  }
}

export function fetchRemoteCategoriasPortada (fields = []) {
  return dispatch => {
    dispatch(fetchCategoriasPortadaBegin())
    const url = API_BASE_URL + 'categoria-portada/'
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 200) {
          return res.json()
        } else {
          dispatch(fetchCategoriasPortadaError(res.statusText))
        }
      })
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res) {
          dispatch(fetchCategoriasPortadaSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchCategoriasPortadaError(err))
      })
  }
}
