import React, { useEffect, useRef } from 'react'
import { Marker, Polyline } from 'react-leaflet'
import { connect } from 'react-redux'
import LineaFlechas from './LineaFlechas'
import * as L from 'leaflet'
import { Icon } from '../../lib/leaflet.awesome-markers-svg'

function Linea (props) {
  const refLinea = React.createRef()

  const prevPdiFocused = usePrevious(props.pdiFocused)

  useEffect(() => {
    if (props.pdiFocused && props.pdiFocused.id === props.id && (!prevPdiFocused || prevPdiFocused.id !== props.id)) {
      const bounds = L.geoJSON(props.coordenadas).getBounds()
      props.mapRef.current.leafletElement.flyToBounds(bounds)
    }
  })

  const fondoSvg = '<?xml version="1.0" encoding="UTF-8"?><svg width="32" height="32" version="1.1" viewBox="0 0 26.458 26.458" xmlns="http://www.w3.org/2000/svg"> <path d="m12.287 25.965c-6.9401-10.061-8.2284-13.096-8.2284-16.794 0-5.0649 4.1059-9.1709 9.1709-9.1709 5.0649 0 9.1709 4.1059 9.1709 9.1709 0 3.6976-1.2882 6.7326-8.2284 16.794-0.45544 0.65791-1.4296 0.65787-1.885 0z" stroke-width=".047765"/></svg>'
  const icon = new Icon({
    iconSize: [42, 42],
    iconAnchor: [20, 20],
    icon: 'circle',
    iconColor: '#fff',
    prefix: 'fa',
    markerColor: props.color,
    svg: fondoSvg,
    fontSizeIcon: 12,
    classFondo: ' icono-punto-kilometrico',
    destacado: props.pdiFocused && props.pdiFocused.id === props.id
  })

  if (props.bloque.patron.flechas) {
    return (
      <>
        <LineaFlechas linea={props} refLinea={refLinea} focused={props.pdiFocused && props.pdiFocused.id === props.id} />
        {props.bloque.es_flujo &&
          <Marker
            position={[props.coordenadas.coordinates[0][1], props.coordenadas.coordinates[0][0]]}
            onClick={() => props.onClick(props)}
            icon={icon}
          />}
      </>
    )
  }

  return (
    <>
      <Polyline
        color={props.color}
        weight={props.grosor * (props.pdiFocused && props.pdiFocused.id === props.id ? 2 : 1)}
        ref={refLinea}
        dashArray={props.bloque.patron.patron} opacity={props.opacidad}
        positions={props.coordenadas.coordinates.map(row => [row[1], row[0]])}
        onClick={() => props.onClick(props)}
      />
      {props.bloque.es_flujo &&
        <Marker
          position={[props.coordenadas.coordinates[0][1], props.coordenadas.coordinates[0][0]]}
          onClick={() => props.onClick(props)}
        />}
    </>
  )
}

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default connect()(Linea)
