import React from 'react'

export default function ({ error }) {
  return (
    <div>
      <h1>Se ha producido un error</h1>
      {error && <h2>{error}</h2>}
    </div>
  )
}
