import React, { Component } from 'react'
import { Map, TileLayer, Marker, Popup } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { Trans, withTranslation } from 'react-i18next'
import * as turf from '@turf/turf'
import * as L from 'leaflet'

import 'react-leaflet-markercluster/dist/styles.min.css'
import './MapaPortada.css'
import { Link } from 'react-router-dom'
import { SERVER_DOMAIN } from '../../constants/api'
import { Spinner } from 'react-bootstrap'

class MapaPortada extends Component {
  constructor (props) {
    super(props)

    this.mapRef = React.createRef()
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { recorrido } = this.props
    if (this.mapRef.current) {
      const map = this.mapRef.current.leafletElement
      if (recorrido && (!prevProps.recorrido || recorrido.id !== prevProps.recorrido.id)) {
        map.flyTo(
          L.latLng(
            recorrido.centro_geometrico.coordinates[1],
            recorrido.centro_geometrico.coordinates[0]
          ), map.getZoom() > 13 ? map.getZoom() : 13
        )
      }
      if (!prevProps.bounds && this.props.bounds) {
        map.flyToBounds(this.props.bounds)
      }
      if (!prevProps.recorridoHovered && this.props.recorridoHovered) {
        map.flyTo(
          L.latLng(
            this.props.recorridoHovered.centro_geometrico.coordinates[1],
            this.props.recorridoHovered.centro_geometrico.coordinates[0]
          ), map.getZoom() > 15 ? map.getZoom() : 15
        )
      }
    }
  }

  getNameDificultad (dificultad) {
    const { t } = this.props
    switch (dificultad) {
      case 'mf':
        return t('Muy fácil')
      case 'f':
        return t('Fácil')
      case 'm':
        return t('Moderado')
      case 'd':
        return t('Difícil')
      default:
        return t('Muy difícil')
    }
  }

  getTipoDificultad (dificultad) {
    switch (dificultad) {
      case 'mf':
        return (
          <>
            <i className='fas fa-circle recorrido-list-dificultad muyfacil mr-1' />
            {[1, 2, 3, 4].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad muyfacil mr-1' />
            )}
          </>
        )
      case 'f':
        return (
          <>
            {[1, 2].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad facil mr-1' />
            )}
            {[1, 2, 3].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad facil mr-1' />
            )}
          </>
        )
      case 'm':
        return (
          <>
            {[1, 2, 3].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad moderado mr-1' />
            )}
            {[1, 2].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad moderado mr-1' />
            )}
          </>
        )
      case 'd':
        return (
          <>
            {[1, 2, 3, 4].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad dificil mr-1' />
            )}
            <i className='far fa-circle recorrido-list-dificultad dificil mr-1' />
          </>
        )
      default:
        return (
          <>
            {[1, 2, 3, 4, 5].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad muydificil mr-1' />
            )}
          </>
        )
    }
  }

  getAlturaMaxima (sector) {
    return Math.max(...sector.trayectos.map(tr => tr.altura_maxima)).toFixed(0) + ' m'
  }

  getAlturaMinima (sector) {
    return Math.min(...sector.trayectos.map(tr => tr.altura_minima)).toFixed(0) + ' m'
  }

  getDesnivelPositivo (sector) {
    let desnivel = 0
    sector.esquemas.forEach(esq => {
      desnivel += esq.trayecto.desnivel_acumulado_positivo * esq.repeticiones
    })
    return desnivel.toFixed(0) + ' m'
  }

  getDesnivelNegativo (sector) {
    let desnivel = 0
    sector.esquemas.forEach(esq => {
      desnivel += esq.trayecto.desnivel_acumulado_negativo * esq.repeticiones
    })
    return desnivel.toFixed(0) + ' m'
  }

  render () {
    const { recorridoSeleccionado, portada, recorridoHovered, recorrido } = this.props
    // console.info(recorrido)

    const recorridos = this.props.recorridos.map(rec => {
      if (rec.distancia_metros) {
        let distancia = rec.distancia_metros.toFixed(0) + ' m'
        if (rec.unidad_distancia === 'km') {
          distancia = (rec.distancia_metros / 1000).toFixed(2) + ' km'
        } else if (rec.unidad_distancia === 'mi') {
          distancia = turf.convertLength(
            rec.distancia_metros, 'meters', 'miles'
          ).toFixed(2) + ' mi'
        } else if (rec.unidad_distancia === 'ft') {
          distancia = turf.convertLength(
            rec.distancia_metros, 'meters', 'feet'
          ).toFixed(0) + ' ft'
        }
        return {
          ...rec,
          distancia
        }
      } else {
        return {
          ...rec,
          distancia: 0
        }
      }
    })
    return (
      <div className='mapa-portada__wrapper mt-2'>
        <Map
          minZoom={2} center={[0, 0]} zoom={2} worldCopyJump tap={false} ref={this.mapRef}
          className={'mapa-portada__container' + (recorridoSeleccionado ? ' mapa-portada__container--collapsed' : '')}
        >
          <TileLayer
            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
            attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributor'
            maxNativeZoom={18} retina='@2x' detectRetina
          />
          <MarkerClusterGroup>
            {recorridos.map(rec => rec.centro_geometrico && ((rec.deporte && rec.deporte.icono_svg) ? (
              <Marker
                key={rec.id}
                position={[rec.centro_geometrico.coordinates[1], rec.centro_geometrico.coordinates[0]]}
                icon={L.divIcon({
                  html: rec.deporte.icono_svg,
                  className: (recorridoHovered && recorridoHovered.id === rec.id) ||
                  (recorridoSeleccionado && recorridoSeleccionado.id === rec.id)
                    ? 'icono-mapa-deporte highlated'
                    : 'icono-mapa-deporte'
                })}
                onClick={portada ? () => this.props.onClickRecorridoMapa(rec.id) : undefined}
              >
                {!this.props.portada && <PopupMapaPortada rec={rec} lista={this.props.lista} />}
              </Marker>
            ) : (
              <Marker
                key={rec.id}
                position={[rec.centro_geometrico.coordinates[1], rec.centro_geometrico.coordinates[0]]}
                onClick={portada ? () => this.props.onClickRecorridoMapa(rec.id) : undefined}
              >
                {!this.props.portada && <PopupMapaPortada rec={rec} lista={this.props.lista} />}
              </Marker>
            )
            ))}
          </MarkerClusterGroup>
        </Map>
        {portada &&
          <div
            className={'mapa-portada__recorrido-detalle' +
              (recorridoSeleccionado ? ' mapa-portada__recorrido-detalle--abierto' : '')}
          >
            {recorridoSeleccionado &&
              recorrido ? (
                <>
                  {recorrido.proyecto_logo &&
                    <div className='mapa-portada__logo-proyecto m-auto rounded-circle'>
                      <img
                        className='rounded-circle'
                        src={SERVER_DOMAIN + recorrido.proyecto_logo} alt={recorrido.nombre}
                      />
                    </div>}
                  <div className='mapa-portada__recorrido-cabecera'>
                    <h6 className='mapa-portada-list__proyecto-nombre mb-0'>{recorrido.proyecto_nombre}</h6>
                    <h5 className='mapa-portada__recorrido-nombre font-weight-bold mb-0'>
                      {recorrido.nombre}
                    </h5>
                    <button onClick={() => this.props.onClickRecorridoMapa()} className='mapa_portada__cerrar-detalle'>
                      <i className='fas fa-times' />
                    </button>
                  </div>
                  {recorrido.sectores.length === 1
                    ? (
                      <div className='mapa-portada__recorrido-datos mt-2'>
                        <div
                          className='mapa-portada__recorrido-deporte float-left mr-1'
                          dangerouslySetInnerHTML={{ __html: recorrido.deporte ? recorrido.deporte.icono_svg : '' }}
                        />
                        <div className='mapa-portada__recorrido-datos-arriba d-flex justify-content-between pr-1 pl-1'>
                          <span>
                            <i className='mdi mdi-arrow-expand-horizontal mr-1' />
                            <strong>
                              {this.props.metrosToUnitsRecorrido(
                                recorrido, recorrido.distancia_metros
                                  ? recorrido.distancia_metros
                                  : recorrido.sectores[0].distancia_metros
                              )}
                            </strong>
                          </span>
                          {recorrido.dificultad &&
                            <span title={this.getNameDificultad(recorrido.dificultad)}>
                              <strong>{this.getTipoDificultad(recorrido.dificultad)}</strong>
                            </span>}
                          {recorrido.superficie &&
                            <span><strong>{recorrido.superficie}</strong></span>}
                        </div>
                        <div className='mapa-portada__recorrido-datos-abajo d-flex justify-content-between pr-1 pl-1'>
                          <span>
                            <i className='mdi mdi-format-vertical-align-top' />
                            <strong>{this.getAlturaMaxima(recorrido.sectores[0])}</strong>
                          </span>
                          <span>
                            <i className='mdi mdi-format-vertical-align-bottom' />
                            <strong>{this.getAlturaMinima(recorrido.sectores[0])}</strong>
                          </span>
                          <span>
                            <i className='mdi mdi-trending-up' />
                            <strong>{this.getDesnivelPositivo(recorrido.sectores[0])}</strong>
                          </span>
                          <span>
                            <i className='mdi mdi-trending-down' />
                            <strong>{this.getDesnivelNegativo(recorrido.sectores[0])}</strong>
                          </span>
                        </div>
                      </div>
                    ) : (
                      <>
                        {recorrido.sectores.map(sec =>
                          <div key={sec.id} className='mapa-portada__recorrido-datos mt-2'>
                            {sec.deporte &&
                              <div
                                className='mapa-portada__recorrido-deporte float-left mr-1'
                                dangerouslySetInnerHTML={{ __html: sec.deporte.icono_svg }}
                              />}
                            <div className='mapa-portada__recorrido-datos-arriba d-flex justify-content-center pr-1 pl-1'>
                              <span>
                                <i className='mdi mdi-arrow-expand-horizontal mr-1' />
                                <strong>
                                  {this.props.metrosToUnitsRecorrido(recorrido, sec.distancia_metros)}
                                </strong>
                              </span>
                            </div>
                            <div className='mapa-portada__recorrido-datos-abajo d-flex justify-content-between pr-1 pl-1'>
                              <span>
                                <i className='mdi mdi-format-vertical-align-top' />
                                <strong>{this.getAlturaMaxima(sec)}</strong>
                              </span>
                              <span>
                                <i className='mdi mdi-format-vertical-align-bottom' />
                                <strong>{this.getAlturaMinima(sec)}</strong>
                              </span>
                              <span>
                                <i className='mdi mdi-trending-up' />
                                <strong>{this.getDesnivelPositivo(sec)}</strong>
                              </span>
                              <span>
                                <i className='mdi mdi-trending-down' />
                                <strong>{this.getDesnivelNegativo(sec)}</strong>
                              </span>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  {recorrido.descripcion &&
                    <p className='mapa-portada__recorrido-descripcion mt-2'>
                      {recorrido.descripcion}
                    </p>}
                  <div className='d-flex justify-content-center align-items-center mt-1'>
                    <a className='btn btn-secondary' href={'/recorrido/' + recorrido.id}>
                      <i className='fas fa-search-location mr-1' />
                      <Trans>Ver</Trans>
                    </a>
                  </div>
                </>
              ) : (
                <Spinner className='spinner-carga spinner-carga-sm' animation='border' role='status'>
                  <span className='sr-only'><Trans>Cargando...</Trans></span>
                </Spinner>
              )}
          </div>}
      </div>
    )
  }
}

const PopupMapaPortada = ({ rec, lista }) => {
  return (
    <Popup>
      <div className='contenedor-popup'>
        <div className='titulo-proyecto'><strong>{rec.proyecto_nombre}</strong></div>
        <h5 className='titulo'>{rec.nombre}</h5>
        <div className='descripcion'>
          <Trans>Distancia</Trans>: {rec.distancia}
        </div>
        <Link
          to={'/recorrido/' + rec.id + (lista ? '/' + lista.id : '')}
          className='btn btn-primary btn-como-llegar'
        >
          <Trans>Ver</Trans>
        </Link>
      </div>
    </Popup>
  )
}

export default withTranslation()(MapaPortada)
