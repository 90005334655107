import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/traficoElementos'
import polyline from '@mapbox/polyline'
import * as turf from '@turf/turf'

export default function traficoElementosReducer (state = initialState.traficoElementos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_TRAFICO_ELEMENTOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }

    case ACTION_TYPES.FETCH_TRAFICO_ELEMENTOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.traficoElementos.map(te => {
          if (te.elemento.bloque.tipo === 'tr') {
            const line = polyline.decode(te.elemento.linea_codificada)
            te.elemento.coordenadas = turf.lineString(line).geometry
          }
          return te
        })
      }

    case ACTION_TYPES.FETCH_TRAFICO_ELEMENTOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}
