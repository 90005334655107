import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/fondo'

export function fondosReducer (state = initialState.fondos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_FONDOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }
    case ACTION_TYPES.FETCH_FONDOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.fondos
      }
    case ACTION_TYPES.FETCH_FONDOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return state
  }
}
