import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import * as L from 'leaflet'
import * as turf from '@turf/turf'

import { actions as domActions } from '../actions/dom'
import { fetchRemoteUsuario } from '../actions/usuario'
import { fetchMapaMontaje, fetchMapaMontajeBegin, fetchMapasMontaje } from '../actions/mapaMontaje'
import { REQUEST_STATUS, SCREENS, TIPOS_MAPAS_NOMBRES } from '../constants/state'
import { fetchRemoteMapa } from '../actions/mapa'
import { fetchRemoteProyecto } from '../actions/proyecto'
import { fetchRemoteFondos } from '../actions/fondo'
import { actions as visorActions } from '../actions/visorMontaje'
import { fetchElementosMontaje } from '../actions/montajeElementos'
import NotFound from '../components/NotFound'
import EnObras from '../components/visor/EnObras'
import Compartir from '../components/Compartir'
import { Spinner } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import TopBar from '../components/visor/TopBar'
import Mapa from '../components/visorMontaje/Mapa'
import iconoMapa from '../lib/images/iconoCabecera/logo-montaje.png'

class MapaMontajeScreen extends Component {
  constructor (props) {
    super(props)
    this.handleDomLoad = this._handleDomLoad.bind(this)
  }

  _handleDomLoad () {
    const { dispatch } = this.props
    dispatch(domActions.domSetCargado())
    dispatch(domActions.domSetStartRecorrido(true))
  }

  componentDidMount () {
    const { dispatch, dom, mapaMontaje, match } = this.props

    if (!dom.cargado) {
      window.addEventListener('load', this.handleDomLoad)
    }

    // Borramos as opcións de visualización dos menús
    this.cerrarMenus()

    dispatch(fetchRemoteUsuario())

    if (!mapaMontaje || mapaMontaje.id !== parseInt(match.params.id)) {
      dispatch(fetchMapaMontajeBegin())
      if (match && match.params && match.params.id) {
        dispatch(fetchMapaMontaje(match.params.id))
      }
    } else {
      const bounds = this.buildBounds()
      dispatch(visorActions.setMapBounds(bounds))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const {
      dispatch, mapaMontaje, mapaMontajeCargado, match, mapa, mapaCargado, montajeElementosCargados
    } = this.props

    if (prevProps.match.params.id !== match.params.id) {
      dispatch(fetchMapaMontajeBegin())
      dispatch(fetchMapaMontaje(match.params.id))
    }

    if (!prevProps.mapaMontajeCargado && mapaMontajeCargado) {
      // @todo update visitas
      dispatch(fetchRemoteMapa(mapaMontaje.mapa))
      dispatch(fetchMapasMontaje({ mapa: mapaMontaje.mapa }))
      dispatch(fetchElementosMontaje({ montaje: mapaMontaje.id }))
    }

    // Ó finalizar todas as peticións importantes,
    // facemos as que non son indispensables para que se vexa a páxina +-...
    if ((!prevProps.mapaCargado && mapaCargado) || (prevProps.mapa && mapa && prevProps.mapa.id !== mapa.id)) {
      dispatch(fetchRemoteProyecto(mapa.proyecto))
      dispatch(fetchRemoteFondos())
    }

    if (!prevProps.montajeElementosCargados && montajeElementosCargados) {
      const bounds = this.buildBounds()
      dispatch(visorActions.setMapBounds(bounds))
    }
  }

  componentWillUnmount () {
    const { dispatch } = this.props
    dispatch(visorActions.setLastScreen(SCREENS.MAPA))
    dispatch(visorActions.setLocation())
    dispatch(visorActions.resetCapasOcultas())

    window.removeEventListener('load', this.handleDomLoad)
  }

  cerrarMenus () {
    const { dispatch } = this.props
    dispatch(visorActions.modalDescripcionOcultar())
    dispatch(visorActions.modalCambiarVistaOcultar())
    dispatch(visorActions.submenuHerramientasOcultar())
    dispatch(visorActions.setPdiDestacado())
    dispatch(visorActions.modalPdisOcultar())
    dispatch(visorActions.modalPersonalOcultar())
  }

  buildBounds () {
    const { montajeElementos } = this.props
    const mapBounds = L.latLngBounds()
    if (montajeElementos) {
      montajeElementos.map(me => me.elemento).forEach(e => {
        switch (e.bloque.tipo) {
          case 'tr':
          case 'ln':
            mapBounds.extend(
              e.coordenadas.coordinates.map(row => [row[1], row[0]])
            )
            break
          case 'pl':
            mapBounds.extend(
              e.coordenadas.coordinates[0].map(row => [row[1], row[0]])
            )
            break
          case 'pt':
            mapBounds.extend([e.coordenadas.coordinates[1], e.coordenadas.coordinates[0]])
            break
          default:
            break
        }
      })
    }
    return mapBounds
  }

  handleClickDescripcion () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalDescripcionVisible) {
      dispatch(visorActions.modalDescripcionOcultar())
    } else {
      dispatch(visorActions.modalDescripcionMostrar())
    }
  }

  handleClickCompartirMostrar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirMostrar())
  }

  handleClickCompartirOcultar () {
    const { dispatch } = this.props
    dispatch(visorActions.compartirOcultar())
    this.cerrarMenus()
  }

  handleClickHerramientas () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.submenuHerramientasVisible) {
      dispatch(visorActions.submenuHerramientasOcultar())
    } else {
      dispatch(visorActions.submenuHerramientasMostrar())
    }
  }

  handleClickCambiarVista () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalCambiarVistaVisible) {
      dispatch(visorActions.modalCambiarVistaOcultar())
    } else {
      dispatch(visorActions.modalCambiarVistaMostrar())
    }
  }

  handleClickSeleccionarCapaFondo (capa) {
    const { dispatch } = this.props
    dispatch(visorActions.seleccionarCapaFondo(capa))
    this.cerrarMenus()
  }

  handleClickLocate (mapRef) {
    this.cerrarMenus()
    if (mapRef.current.leafletElement) {
      const map = mapRef.current.leafletElement
      map.locate({ enableHighAccuracy: true, watch: true })
      map.on('locationfound', this.onLocationFound.bind(this))
      map.on('locationerror', e => {
        window.alert(e.message)
      })
    }
  }

  onLocationFound (e) {
    const { dispatch, visor } = this.props
    if (!visor.localizacionDescentrada) e.target.flyToBounds(e.bounds, { maxZoom: 18 })
    dispatch(visorActions.setLocation(e))
  }

  handleClickLocateStop (mapRef) {
    const { dispatch } = this.props
    this.cerrarMenus()
    if (mapRef.current.leafletElement) {
      const map = mapRef.current.leafletElement
      map.stopLocate()
      map.off('locationfound')
      map.off('locationerror')
      dispatch(visorActions.setLocation())
    }
  }

  handleMoveMap (e) {
    const { visor, dispatch } = this.props
    if (!e.flyTo && visor.location && !e.target.getBounds().contains(visor.location.bounds)) {
      dispatch(visorActions.localizacionDescentradaOn())
    }
  }

  handleClickRecentrar (mapRef) {
    const { visor, dispatch } = this.props
    dispatch(visorActions.localizacionDescentradaOff())
    if (visor.location) {
      mapRef.current.leafletElement.flyToBounds(visor.location.bounds, { maxZoom: 18 })
    }
  }

  handleClickPdiMapa (pdi) {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.setPdiFocused(pdi))
  }

  handleClickPdiDestacado (pdi) {
    const { dispatch } = this.props
    this.cerrarMenus()
    dispatch(visorActions.setPdiDestacado(pdi))
  }

  handleClickCerrarPdiFocused () {
    const { dispatch } = this.props
    dispatch(visorActions.setPdiFocused(null))
  }

  handleClickPrevPdi (pdi) {
    const { dispatch, montajeElementos } = this.props
    this.cerrarMenus()
    const elementos = montajeElementos.map(me => me.elemento)
    const pdisOrdenados = elementos.sort((a, b) =>
      a.bloque.capa.orden !== b.bloque.capa.orden
        ? a.bloque.capa.orden - b.bloque.capa.orden
        : (a.agrupacion_orden !== b.agrupacion_orden)
          ? a.agrupacion_orden - b.agrupacion_orden : a.orden_editor - b.orden_editor
    )
    dispatch(visorActions.setPdiFocused(pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) - 1]))
  }

  handleClickSigPdi (pdi) {
    const { dispatch, montajeElementos } = this.props
    this.cerrarMenus()
    const elementos = montajeElementos.map(me => me.elemento)
    const pdisOrdenados = elementos.sort((a, b) =>
      a.bloque.capa.orden !== b.bloque.capa.orden
        ? a.bloque.capa.orden - b.bloque.capa.orden
        : (a.agrupacion_orden !== b.agrupacion_orden)
          ? a.agrupacion_orden - b.agrupacion_orden : a.orden_editor - b.orden_editor
    )
    dispatch(visorActions.setPdiFocused(pdisOrdenados[pdisOrdenados.map(p => p.id).indexOf(pdi.id) + 1]))
  }

  handleClickDescargarLinea (linea) {
    const togpx = require('togpx')
    // eslint-disable-next-line no-undef
    const blob = new Blob([togpx(turf.lineString(linea.coordenadas.coordinates))], { type: 'application/gpx+xml' })

    const a = document.createElement('a')
    a.download = (linea.nombre + '.gpx').replace(/\s/g, '_')
    a.href = URL.createObjectURL(blob)
    a.dataset.downloadurl = ['application/gpx+xml', a.download, a.href].join(':')
    a.style.display = 'none'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    setTimeout(() => URL.revokeObjectURL(a.href), 1500)
  }

  handleClickPdis () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalPdisVisible) {
      dispatch(visorActions.modalPdisOcultar())
    } else {
      dispatch(visorActions.setFiltroPdis(''))
      dispatch(visorActions.modalPdisMostrar())
    }
  }

  handleClickPdiLista (pdi, mapRef) {
    const { dispatch } = this.props
    this.cerrarMenus()
    if (mapRef.current.leafletElement) {
      if (pdi.coordenadas.type === 'Point') {
        mapRef.current.leafletElement.setView([pdi.coordenadas.coordinates[1], pdi.coordenadas.coordinates[0]], 18)
      } else {
        const geoJson = L.geoJSON(pdi.coordenadas)
        mapRef.current.leafletElement.fitBounds(geoJson.getBounds(), { maxZoom: 18 })
      }
    }
    dispatch(visorActions.setPdiFocused(pdi))
  }

  handleClickVisibilidadCapa (capa) {
    const { dispatch, visor } = this.props
    if (visor.capasOcultas.map(c => c.id).includes(capa.id)) {
      dispatch(visorActions.removeCapaOculta(capa))
    } else {
      dispatch(visorActions.addCapaOculta(capa))
    }
  }

  handleMapZoom (map) {
    const { dispatch } = this.props
    dispatch(visorActions.mapZoomChanged(map._zoom))
  }

  handleLoadMap (map) {
    const { dispatch } = this.props
    dispatch(visorActions.mapZoomChanged(map._zoom))
  }

  handleChangeFiltroPdis (e) {
    const { dispatch } = this.props
    dispatch(visorActions.setFiltroPdis(e.target.value))
  }

  handleChangeFiltroPersonal (e) {
    const { dispatch } = this.props
    dispatch(visorActions.setFiltroModalPersonal(e.target.value))
  }

  handleClickMapaOcultarPdis () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.mapaPdisVisible) {
      dispatch(visorActions.mapaPdisOcultar())
    } else {
      dispatch(visorActions.setFiltroModalPersonal(''))
      dispatch(visorActions.mapaPdisMostrar())
    }
  }

  handleClickPersonal () {
    const { dispatch, visor } = this.props

    this.cerrarMenus()
    if (visor.modalPersonalVisible) {
      dispatch(visorActions.modalPersonalOcultar())
    } else {
      dispatch(visorActions.modalPersonalMostrar())
    }
  }

  render () {
    const {
      t, mapaMontaje, mapasMontaje, mapaMontajeError, montajeElementos, visor, proyecto, dom, mapa,
      fondos, usuario, montajeElementosCargados, fondosCargados
    } = this.props

    if (mapaMontajeError) {
      return <NotFound error={mapaMontajeError} />
    }

    if (mapaMontaje && !mapaMontaje.visible && (!usuario || (proyecto && proyecto.user_id !== usuario.id))) {
      return <EnObras />
    }

    if (visor.compartirVisible) {
      return <Compartir clickCerrar={this.handleClickCompartirOcultar.bind(this)} insertarActivo />
    } else {
      if (!mapaMontaje || !proyecto || !montajeElementosCargados || !fondosCargados) {
        return (
          <div className='fondo-carga'>
            <Spinner className='spinner-carga' animation='border' role='status'>
              <span className='sr-only'><Trans>Cargando...</Trans></span>
            </Spinner>
          </div>
        )
      }

      const mostrarBackdrop = visor.modalDescripcionVisible ||
        visor.submenuHerramientasVisible ||
        visor.modalCambiarVistaVisible ||
        visor.pdiDestacado ||
        visor.modalPdisVisible ||
        visor.modalPersonalVisible

      let mostrarCerrar = !mostrarBackdrop
      if (mostrarCerrar) {
        if (dom.estaEnIframe) {
          mostrarCerrar = !dom.iniciadoEnRecorrido
        }
      }
      let ogImage = '/og_image.png'
      if (proyecto && proyecto.logo) {
        ogImage = proyecto.logo
      } else if (mapaMontaje && mapaMontaje.captura_2x1_textos) {
        ogImage = mapaMontaje.captura_2x1_textos
      }

      return (
        <div className='main-grid--recorrido'>
          <Helmet>
            <html lang='es' />
            <title>{t(`${mapaMontaje.nombre} en ${proyecto.nombre} - RaceMapp`)}</title>
            <meta name='description' content={mapaMontaje.meta_descripcion} />
            <meta property='og:url' content={window.location.href} />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={t(`${mapaMontaje.nombre} en ${proyecto.nombre} - RaceMapp`)} />
            <meta property='og:description' content={mapaMontaje.meta_descripcion} />
            <meta property='og:image' content={ogImage} />
          </Helmet>
          <TopBar
            titulo={proyecto.nombre}
            subtitulo={mapaMontaje ? mapaMontaje.nombre : ''}
            backLink={`/proyecto/${proyecto.id}-${proyecto.slug}`}
            sectores={[]}
            onClickSetores={this.handleClickDescripcion.bind(this)}
            handleClickCompartir={dom.estaEnIframe ? null : this.handleClickCompartirMostrar.bind(this)}
            mostrarCerrar={mostrarCerrar}
            linkHomeEnabled
            iconoMapa={iconoMapa}
            tipoMapa={TIPOS_MAPAS_NOMBRES.MONTAJE}
          />
          <Mapa
            mapa={mapa}
            capasFondo={fondos}
            mapaMontaje={mapaMontaje}
            mapasMontaje={mapasMontaje}
            proyecto={proyecto}
            backLink={!window.inIframe ? `/proyecto/${proyecto.id}-${proyecto.slug}` : null}
            visor={visor}
            mostrarBackdrop={mostrarBackdrop}
            cerrarMenus={this.cerrarMenus.bind(this)}
            elementos={montajeElementos ? montajeElementos.map(me => me.elemento) : []}
            handleClickHerramientas={this.handleClickHerramientas.bind(this)}
            handleClickCambiarVista={this.handleClickCambiarVista.bind(this)}
            handleClickSeleccionarCapaFondo={this.handleClickSeleccionarCapaFondo.bind(this)}
            handleClickLocate={this.handleClickLocate.bind(this)}
            handleClickLocateStop={this.handleClickLocateStop.bind(this)}
            handleMoveMap={this.handleMoveMap.bind(this)}
            handleClickRecentrar={this.handleClickRecentrar.bind(this)}
            handleClickPdiMapa={this.handleClickPdiMapa.bind(this)}
            handleClickPdiDestacado={this.handleClickPdiDestacado.bind(this)}
            handleClickCerrarPdiFocused={this.handleClickCerrarPdiFocused.bind(this)}
            handleClickPrevPdi={this.handleClickPrevPdi.bind(this)}
            handleClickSigPdi={this.handleClickSigPdi.bind(this)}
            handleClickDescargarLinea={this.handleClickDescargarLinea.bind(this)}
            handleClickPdis={this.handleClickPdis.bind(this)}
            handleClickPdiLista={this.handleClickPdiLista.bind(this)}
            handleClickVisibilidadCapa={this.handleClickVisibilidadCapa.bind(this)}
            handleMapZoom={this.handleMapZoom.bind(this)}
            handleLoadMap={this.handleLoadMap.bind(this)}
            handleChangeFiltroPdis={this.handleChangeFiltroPdis.bind(this)}
            handleClickMapaOcultarPdis={this.handleClickMapaOcultarPdis.bind(this)}
            handleClickPersonal={this.handleClickPersonal.bind(this)}
            handleChangeFiltroPersonal={this.handleChangeFiltroPersonal.bind(this)}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({
  dom: state.dom,
  usuario: state.usuario.data,
  mapaMontaje: state.mapaMontaje.data,
  mapaMontajeCargado: state.mapaMontaje.status === REQUEST_STATUS.SUCCEEDED,
  mapaMontajeError: state.mapaMontaje.error,
  mapasMontaje: state.mapasMontaje.data,
  mapa: state.mapa.data,
  mapaCargado: state.mapa.status === REQUEST_STATUS.SUCCEEDED,
  visor: state.visorMontaje,
  proyecto: state.proyecto.data,
  fondos: state.fondos.data,
  fondosCargados: state.fondos.status === REQUEST_STATUS.SUCCEEDED,
  montajeElementos: state.montajeElementos.data,
  montajeElementosCargados: state.montajeElementos.status === REQUEST_STATUS.SUCCEEDED
})

export default withTranslation()(connect(mapStateToProps)(MapaMontajeScreen))
