/* global fetch */

import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_TRAFICO_ELEMENTOS_BEGIN: 'FETCH_TRAFICO_ELEMENTOS_BEGIN',
  FETCH_TRAFICO_ELEMENTOS_SUCCESS: 'FETCH_TRAFICO_ELEMENTOS_SUCCESS',
  FETCH_TRAFICO_ELEMENTOS_ERROR: 'FETCH_TRAFICO_ELEMENTOS_ERROR'
}

function fetchTraficoElementosBegin () {
  return {
    type: ACTION_TYPES.FETCH_TRAFICO_ELEMENTOS_BEGIN
  }
}

function fetchTraficoElementosSuccess (traficoElementos) {
  return {
    type: ACTION_TYPES.FETCH_TRAFICO_ELEMENTOS_SUCCESS,
    traficoElementos
  }
}

function fetchTraficoElementosError (error) {
  return {
    type: ACTION_TYPES.FETCH_TRAFICO_ELEMENTOS_ERROR,
    error
  }
}

export function fetchTraficoElementos (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchTraficoElementosBegin())
    const url = new URL(API_BASE_URL + 'trafico-elemento/')
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchTraficoElementosSuccess(res))
        } else {
          throw new Error('Not Found')
        }
      })
      .catch(err => {
        console.log(err)
        dispatch(fetchTraficoElementosError(err))
      })
  }
}
