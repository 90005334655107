/* global fetch */

import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_ELEMENTOS_MONTAJE_BEGIN: 'FETCH_ELEMENTOS_MONTAJE_BEGIN',
  FETCH_ELEMENTOS_MONTAJE_SUCCESS: 'FETCH_ELEMENTOS_MONTAJE_SUCCESS',
  FETCH_ELEMENTOS_MONTAJE_ERROR: 'FETCH_ELEMENTOS_MONTAJE_ERROR'
}

function fetchElementosMontajeBegin () {
  return {
    type: ACTION_TYPES.FETCH_ELEMENTOS_MONTAJE_BEGIN
  }
}

function fetchElementosMontajeSuccess (elementosMontaje) {
  return {
    type: ACTION_TYPES.FETCH_ELEMENTOS_MONTAJE_SUCCESS,
    elementosMontaje
  }
}

function fetchElementosMontajeError (error) {
  return {
    type: ACTION_TYPES.FETCH_ELEMENTOS_MONTAJE_ERROR,
    error
  }
}

export function fetchElementosMontaje (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchElementosMontajeBegin())
    const url = new URL(API_BASE_URL + 'montaje-elemento/')
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.length) {
          dispatch(fetchElementosMontajeSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchElementosMontajeError(err))
      })
  }
}
