import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/contacto'

export function contactoCreadoReducer (state = initialState.contactoCreado, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_CONTACTO_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.CREATE_CONTACTO_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    case ACTION_TYPES.CREATE_CONTACTO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.contacto
      }

    default:
      return {
        ...state
      }
  }
}

export function contactoEliminadoReducer (state = initialState.contactoEliminado, action) {
  switch (action.type) {
    case ACTION_TYPES.DELETE_CONTACTO_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.DELETE_CONTACTO_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    case ACTION_TYPES.DELETE_CONTACTO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.contacto
      }

    default:
      return {
        ...state
      }
  }
}

export function contactosReducer (state = initialState.contactos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_CONTACTOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }

    case ACTION_TYPES.FETCH_CONTACTOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    case ACTION_TYPES.FETCH_CONTACTOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.contactos
      }

    case ACTION_TYPES.CREATE_CONTACTO_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.contacto)
      }

    case ACTION_TYPES.DELETE_CONTACTO_SUCCESS:
      return {
        ...state,
        data: state.data.filter(c => c.id !== action.contacto.id)
      }

    case ACTION_TYPES.UPDATE_CONTACTO_SUCCESS:
      return {
        ...state,
        data: state.data.filter(c => c.id !== action.contacto.id).concat(action.contacto)
      }

    default:
      return {
        ...state
      }
  }
}

export function contactoActualizadoReducer (state = initialState.contactoActualizado, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_CONTACTO_BEGIN:
      return {
        ...state,
        data: null,
        status: REQUEST_STATUS.LOADING,
        error: null
      }

    case ACTION_TYPES.UPDATE_CONTACTO_SUCCESS:
      return {
        ...state,
        data: action.contacto,
        status: REQUEST_STATUS.SUCCEEDED
      }

    case ACTION_TYPES.UPDATE_CONTACTO_ERROR:
      return {
        ...state,
        error: action.error,
        status: REQUEST_STATUS.FAILED
      }

    default:
      return {
        ...state
      }
  }
}
