import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/voluntarios'

export function mapaVoluntariosReducer (state = initialState.mapaVoluntarios, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPA_VOLUNTARIOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.FETCH_MAPA_VOLUNTARIOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapaVoluntarios
      }

    case ACTION_TYPES.FETCH_MAPA_VOLUNTARIOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }

    default:
      return {
        ...state
      }
  }
}

export function mapasVoluntariosReducer (state = initialState.mapasVoluntarios, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPAS_VOLUNTARIOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }

    case ACTION_TYPES.FETCH_MAPAS_VOLUNTARIOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapasVoluntarios
      }

    case ACTION_TYPES.FETCH_MAPAS_VOLUNTARIOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        data: action.error
      }

    default:
      return {
        ...state
      }
  }
}
