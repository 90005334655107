import React from 'react'
import { Link } from 'react-router-dom'
import { Trans, withTranslation } from 'react-i18next'

import logo from '../../lib/logos/logo-movil.png'
import checkAzul from '../../lib/images/Check_azul.png'
import './TopBar.css'

function TopBar (props) {
  const { titulo, subtitulo, backLink, sectores, linkHomeEnabled, t, iconoMapa, oficial } = props
  const link = backLink || '/'

  const getColorTexto = color => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    const brillo = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
    return brillo > 75 ? '#000' : '#fff'
  }

  const gotToFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      document.documentElement.requestFullscreen()
    }
  }

  const esquemaSimple = sectores && sectores.length === 1 && sectores[0].esquemas.length === 1 &&
    sectores[0].esquemas[0].repeticiones === 1

  // console.info(props.mostrarCerrar)
  return (
    <div className={'top-bar ' + props.className}>
      <div className='top-bar__left'>
        {linkHomeEnabled ? (
          window.inIframe ? (
            <a href={window.location.href} title={t('Ver en racemapp')} rel='noopener noreferrer' target='_blank'>
              <img src={logo} alt='logo' />
            </a>
          ) : (
            <Link to='/'><img src={logo} alt='logo' /></Link>
          )
        ) : (
          <img src={logo} alt='logo' />
        )}
        {iconoMapa &&
          <img className='top-bar__icono-mapa ml-2 ml-sm-3' src={iconoMapa} alt='Icono' title={props.tipoMapa} />}
      </div>
      {subtitulo ? (
        <div className='top-bar__center top-bar__center-rows'>
          <div className='top-bar__titulo'>
            {titulo || '...cargando...'}
            {oficial && <img className='top-bar__check-recorrido ml-2' src={checkAzul} alt='check' />}
          </div>
          <div className='top-bar__subtitulo'>{subtitulo}</div>

          {sectores && sectores.length &&
            <ul className='top-bar__esquema' onClick={props.onClickSetores}>
              {sectores.length > 1 ? (
                sectores.map(sector => (
                  <li key={sector.id}>
                    <span
                      className='top-bar__barrita top-bar__barrita--sector'
                      style={{ backgroundColor: sector.color, color: getColorTexto(sector.color) }}
                    >
                      {sector.nombre}
                    </span>
                  </li>
                ))
              ) : (
                sectores.map(sector => {
                  const items = sector.esquemas.map(
                    esquema => (
                      <li key={esquema.id}>
                        <div
                          className='top-bar__barrita'
                          style={{ backgroundColor: esquema.trayecto.color, color: getColorTexto(esquema.trayecto.color) }}
                        >
                          {!esquemaSimple &&
                            <span className='text-lowercase'>x{esquema.repeticiones}</span>}
                        </div>
                      </li>
                    ))
                  items.push(
                    <li key='distancia' className='text-lowercase'>
                      &nbsp;{props.metrosToUnitsRecorrido(sector.distancia_metros)}
                    </li>
                  )
                  return items
                })
              )}
            </ul>}
        </div>
      ) : (
        <div className='top-bar__center'>
          {titulo || <Trans>...cargando...</Trans>}
          {oficial && <img className='hit__check ml-2' src={checkAzul} alt='check' />}
        </div>
      )}

      <div className='top-bar__right'>
        {(window.inIframe && document.fullscreenEnabled)
          ? (
            <button className='btn btn-black top-bar__pantalla-completa' onClick={gotToFullScreen} title={t('Pantalla Completa')}>
              <i className='fa fa-expand fa-2x' />
            </button>
          ) : (
            <button className='btn btn-black top-bar__compartir' onClick={props.handleClickCompartir} title={t('Compartir')}>
              <i className='fas fa-share-alt fa-2x' />
            </button>
          )}
        {props.mostrarCerrar && <Link to={link}><i className='fas fa-times' /></Link>}
      </div>
      {subtitulo &&
        <div className='top-bar__desplegar' onClick={props.onClickSetores}>
          <i className='fas fa-caret-down' />
        </div>}
    </div>
  )
}

export default withTranslation()(TopBar)
