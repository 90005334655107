import React, { useEffect, useRef } from 'react'

import './AnimacionInfoPdis.css'

const AnimacionInfoPdi = punto => {
  const prevPunto = usePrevious(punto)
  const cambio = prevPunto && prevPunto.id !== punto.id
  return (
    <div
      className={'info-pdi__container d-flex justify-content-between align-items-center m-auto p-3 ' +
        (cambio && punto.servicios.length > 0 ? 'info-pdi__container--cambio' : '')}
    >
      <span className='info-pdi__nombre font-weight-bold'>{punto.nombre}</span>

      {punto.servicios.length > 0 &&
        <span className='info-pdis__servicios-list d-inline-flex'>
          {punto.servicios.map(s =>
            <div
              key={punto.id + '-' + s.id}
              className='info-pdis__servicio ml-1 d-flex justify-content-center align-items-center'
              style={{ backgroundColor: s.color_fondo, color: s.color_icono }}
            >
              <i className={s.icono.prefijo_fuente + ' ' + s.icono.prefijo_fuente + '-' + s.icono.icono} />
            </div>
          )}
        </span>}
    </div>
  )
}

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default AnimacionInfoPdi
