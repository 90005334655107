/* global fetch */

import { API_BASE_URL } from '../constants/api'
import getCsrfToken from '../lib/csrf'

export const ACTION_TYPES = {
  UPDATE_VISITAS_BEGIN: 'UPDATE_VISITAS_BEGIN',
  UPDATE_VISITAS_SUCCESS: 'UPDATE_VISITAS_SUCCESS',
  UPDATE_VISITAS_ERROR: 'UPDATE_VISITAS_ERROR'
}

function updateVisitasBegin () {
  return {
    type: ACTION_TYPES.UPDATE_VISITAS_BEGIN
  }
}

function updateVisitasSuccess (visita) {
  return {
    type: ACTION_TYPES.UPDATE_VISITAS_SUCCESS,
    visita
  }
}

function updateVisitasError (error) {
  return {
    type: ACTION_TYPES.UPDATE_VISITAS_ERROR,
    error
  }
}

export function updateVisitas (idRecorrido) {
  return dispatch => {
    dispatch(updateVisitasBegin())
    const url = API_BASE_URL + 'visita-recorrido/' + idRecorrido + '/'
    return fetch(url, {
      method: 'PUT',
      headers: {
        'X-CSRFToken': getCsrfToken()
      }
    })
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res && res.id) {
          dispatch(updateVisitasSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(updateVisitasError(err))
      })
  }
}
