import React from 'react'
import MapaModal from './MapaModal'
import { TabContainer, Nav, Tab } from 'react-bootstrap'
import ListPdisRecorridosSector from './ListPdisRecorridosSector'

import './MapaModalPdisRecorrido.css'

const MapaModalPdisRecorrido = props => {
  return (
    <MapaModal {...props}>
      <TabContainer defaultActiveKey={props.sectoresPdis[0].id}>
        {props.sectoresPdis.length > 1 &&
          <Nav className='container-tabs-sectores-pdis'>
            {props.sectoresPdis.map(sec =>
              <Nav.Item key={sec.id}>
                <Nav.Link className='tab-sectores-pdis' eventKey={sec.id}>{sec.nombre}</Nav.Link>
              </Nav.Item>
            )}
          </Nav>}
        {props.sectoresPdis.map(sec =>
          <Tab.Content key={sec.id}>
            <Tab.Pane eventKey={sec.id}>
              <ul className='mapa-submodal__lista mapa-submodal__lista--pdis'>
                <ListPdisRecorridosSector
                  onClickPdi={props.onClickPdi}
                  sector={sec}
                  mapRef={props.mapRef}
                  getDistanciaPuntoKm={props.getDistanciaPuntoKm}
                  getDistanciaPdisSector={props.getDistanciaPdisSector}
                  metrosToUnitsRecorrido={props.metrosToUnitsRecorrido}
                  onChangeFiltroPdis={props.onChangeFiltroPdis}
                  filtroPdis={props.filtroPdis}
                />
              </ul>
            </Tab.Pane>
          </Tab.Content>
        )}
      </TabContainer>
    </MapaModal>
  )
}

export default MapaModalPdisRecorrido
