/* global Blob */
import React from 'react'
import QRCode from 'qrcode.react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import {
  TwitterIcon, FacebookIcon, LinkedinIcon, WhatsappIcon,
  FacebookShareButton, TwitterShareButton, LinkedinShareButton, WhatsappShareButton
} from 'react-share'
import actions from '../actions/visor'

import iconoCode from '../lib/images/share/code.png'

import './Compartir.css'
import { bindActionCreators } from 'redux'

class Compartir extends React.Component {
  constructor (props) {
    super(props)
    this.escFunction = this.escFunction.bind(this)
    this.copiadoMsgRef = React.createRef()
    this.textareaRef = React.createRef()
    this.svgRef = React.createRef()
  }

  escFunction (event) {
    if (event.keyCode === 27) {
      // Do whatever when esc is pressed
      this.props.clickCerrar()
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.escFunction, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escFunction, false)
  }

  copyToClipboard () {
    const el = document.createElement('textarea')
    el.value = window.location
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)

    if (this.copiadoMsgRef.current) {
      this.copiadoMsgRef.current.classList.add('compartir__copiado--blink')
      window.setTimeout(() => {
        this.copiadoMsgRef.current.classList.remove('compartir__copiado--blink')
      }, 5000)
    }
  }

  textareaToClipboard () {
    if (this.textareaRef && this.textareaRef.current) {
      this.textareaRef.current.select()
      document.execCommand('copy')
      if (this.copiadoMsgRef.current) {
        this.copiadoMsgRef.current.classList.add('compartir__copiado--blink')
        window.setTimeout(() => {
          this.copiadoMsgRef.current.classList.remove('compartir__copiado--blink')
        }, 5000)
      }
    }
  }

  onClickInsertar () {
    this.props.insertarToggle()
  }

  render () {
    const location = window.location
    let { clickCerrar, insertarActivo, compartirInsertarVisible, t } = this.props
    if (!insertarActivo) {
      compartirInsertarVisible = false
    }

    const code = '<iframe src="' + location + '" frameborder=0 width=500 height=400 allow="fullscreen" allowfullscreen="allowfullscreen" ></iframe>'

    // @todo: customizar según https://github.com/nygardk/react-share
    return (
      <div className='compartir__wrapper'>
        <div className='compartir__header'>
          <div className='compartir__title'>
            <Trans>Compartir</Trans>:
          </div>
        </div>
        <div className=''>
          <button onClick={clickCerrar} className='compartir__close'>
            <i className='fas fa-times' />
          </button>
        </div>
        <ul className='compartir__ul'>
          {insertarActivo &&
            <li className='compartir__item'>
              <button
                className='react-share__ShareButton'
                style={{
                  backgroundColor: 'transparent',
                  border: 'medium none',
                  padding: '0px',
                  font: 'inherit',
                  color: 'inherit',
                  cursor: 'pointer'
                }}
                onClick={this.onClickInsertar.bind(this)}
              >
                <img
                  alt='Insertar'
                  src={iconoCode} align='insertar'
                  width={48} height={48}
                />
              </button>
              <div className='react-share__btn-label'>
                <Trans>Insertar</Trans>
              </div>
            </li>}
          <li className='compartir__item'>
            <TwitterShareButton url={location}>
              <TwitterIcon size={48} round />
            </TwitterShareButton>
            <div className='react-share__btn-label'>
              <Trans>Twitter</Trans>
            </div>
          </li>
          <li className='compartir__item'>
            <FacebookShareButton url={location}>
              <FacebookIcon size={48} round />
            </FacebookShareButton>
            <div className='react-share__btn-label'>
              <Trans>Facebook</Trans>
            </div>
          </li>
          <li className='compartir__item'>
            <LinkedinShareButton url={location}>
              <LinkedinIcon size={48} round />
            </LinkedinShareButton>
            <div className='react-share__btn-label'>
              <Trans>Linkedin</Trans>
            </div>
          </li>
          <li className='compartir__item'>
            <WhatsappShareButton url={location}>
              <WhatsappIcon size={48} round />
            </WhatsappShareButton>
            <div className='react-share__btn-label'>
              <Trans>Whatsapp</Trans>
            </div>
          </li>
        </ul>
        {compartirInsertarVisible ? (
          <div className='compartir__iframe-wrapper'>
            <div className='compartir__iframe-code'>
              <div className='compartir__code-title'>
                <Trans>Copia este código e insértalo en tu web.</Trans>
              </div>
              <textarea
                defaultValue={code}
                onClick={this.textareaToClipboard.bind(this)}
                ref={this.textareaRef}
              />
            </div>
            <div className='compartir__iframe'>
              <iframe
                title='share-preview'
                allow='fullscreen' allowFullScreen
                src={location} frameBorder={0} width={500} height={400}
              />
            </div>
          </div>
        ) : (
          <>
            <div className='compartir__link'>
              <input
                className='compartir__link-input'
                value={location} readOnly
              />
              <button
                className='compartir__link-btn'
                onClick={this.copyToClipboard.bind(this)}
              >
                <Trans>COPIAR</Trans>
              </button>
            </div>
            {location &&
              <div className='text-center my-3 my-md-5 d-flex align-content-center align-items-center'>
                <div className='compartir__qr ' ref={this.svgRef}>
                  <QRCode
                    title={t('Click para descargar el QR')}
                    value={'' + location} size={250} /* bgColor='#fc0' fgColor='#1a1a1a' */
                    level='Q'
                    renderAs='svg' includeMargin
                    imageSettings={{
                      src: 'https://racemapp.com/static/icons/icon-50.png',
                      x: null,
                      y: null,
                      height: 50,
                      width: 50,
                      excavate: true
                    }}
                  />
                  <div className='compartir__qr-hover d-flex justify-content-center flex-column'>
                    <div
                      onClick={() => {
                        const svg = this.svgRef.current.innerHTML
                        const blob = new Blob([svg.toString()])
                        const element = document.createElement('a')
                        element.download = 'racemapp-qr.svg'
                        element.href = window.URL.createObjectURL(blob)
                        element.click()
                        element.remove()
                      }}
                    >
                      <i className='fas fa-cloud-download-alt fa-3x' />
                      <p className='compartir__qr-label'><Trans>Descargar</Trans></p>
                    </div>
                  </div>
                </div>
              </div>}
          </>
        )}
        <div className='compartir__copiado' ref={this.copiadoMsgRef}>
          <Trans>Enlace copiado al portapapeles</Trans>
        </div>
      </div>
    )
  }
}

// export default Compartir
export default connect(
  state => ({ compartirInsertarVisible: state.visor.compartirInsertarVisible }),
  dispatch => bindActionCreators({
    insertarToggle: actions.compartirInsertarToggle
  }, dispatch)
)(withTranslation()(Compartir))
