import React, { Component } from 'react'
import { Marker, Polyline } from 'react-leaflet'
import * as turf from '@turf/turf'
import { Icon } from '../../lib/leaflet.awesome-markers-svg'

import './TrayectoAnimacion.css'

class TrayectoAnimacion extends Component {
  constructor (props) {
    super(props)
    this.contador = 0
    this.ultimaCooordenadaActualizada = [0, 0]
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { distanciaAnimacion } = this.props
    if (distanciaAnimacion !== prevProps.distanciaAnimacion) {
      const map = this.props.mapRef.current.leafletElement
      if (distanciaAnimacion === 0) {
        map.flyTo([...this.ultimaCoordenada].reverse(), this.props.zoomAnimacion)
      } else {
        map.setView([...this.ultimaCoordenada].reverse(), this.props.zoomAnimacion)
      }
    }
  }

  getIcon () {
    return new Icon({
      iconSize: [32, 55],
      iconAnchor: [16, 55],
      icon: '',
      iconColor: '#000',
      prefix: 'fa',
      markerColor: '#ffd62f',
      svg: '<svg xmlns="http://www.w3.org/2000/svg" width="32" height="55" viewBox="0 0 5.292 8.467">\n' +
        ' <g transform="translate(-21.544 -38.336)">\n' +
        '  <path transform="matrix(.26458 0 0 .26458 21.544 38.336)" d="m2.7402 0c-1.5178 0-2.7402 1.2225-2.7402 2.7402v14.52c0 1.5178 1.2225 2.7402 2.7402 2.7402h5.7598v12h2v-12h6.7598c1.5178 0 2.7402-1.2225 2.7402-2.7402v-14.52c0-1.5178-1.2225-2.7402-2.7402-2.7402h-14.52z"/>\n' +
        ' </g>\n' +
        '<path  d="M2.421 4.862h.45v3.611h-.45z"/>\n' +
        '</svg>',
      fontSizeIcon: 24,
      classFondo: ' icono-fondo-redondeado anim__icono-deporte',
      text: this.props.deporte
    })
  }

  render () {
    const { distanciaAnimacion, coordenadas, polyRef, color, grosor, animando } = this.props
    if (animando) {
      const lineSlice = distanciaAnimacion !== 0 ? turf.lineSliceAlong(
        coordenadas, 0, distanciaAnimacion, { units: 'meters' }
      ) : []
      this.ultimaCoordenada = !Array.isArray(lineSlice)
        ? [...lineSlice.geometry.coordinates[lineSlice.geometry.coordinates.length - 1]]
        : [...coordenadas.coordinates[0]]
      return (
        <>
          <Polyline
            ref={polyRef}
            color={color} opacity={1} weight={grosor}
            positions={!Array.isArray(lineSlice) ? lineSlice.geometry.coordinates.map(row => [row[1], row[0]]) : lineSlice}
          />
          <Marker position={[...this.ultimaCoordenada].reverse()} icon={this.getIcon()} />
        </>
      )
    } else {
      return <></>
    }
  }
}

export default TrayectoAnimacion
