import * as turf from '@turf/turf'
import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/perfil'
import { ACTION_TYPES as ACTION_TYPES_SEC } from '../actions/sector'

export default function perfilReducer (state = initialState.perfil, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_INDEX_PDI_SELECCIONADO:
      return {
        ...state,
        indexPdiSeleccionado: action.index
      }

    case ACTION_TYPES_SEC.SET_SECTOR:
    case ACTION_TYPES_SEC.FETCH_SECTOR_SUCCESS: {
      const orden = getOrdenPdis(action.sector)
      return {
        ...state,
        indexPdiSeleccionado: orden.length > 0 ? 0 : null,
        pdiOrden: orden
      }
    }

    case ACTION_TYPES.SELECCIONAR_PDI_DETALLE:
      return {
        ...state,
        pdiDetalle: action.pdi
      }

    case ACTION_TYPES.COMPARTIR_OCULTAR:
      return {
        ...state,
        compartirVisible: false
      }

    case ACTION_TYPES.COMPARTIR_MOSTRAR:
      return {
        ...state,
        compartirVisible: true
      }

    default:
      return {
        ...state
      }
  }
}

function getOrdenPdis (sector) {
  let orden = []
  let distAcum = 0
  sector.esquemas.forEach(esq => {
    const trayecto = sector.trayectos.filter(tr => tr.id === esq.trayecto.id)
    if (trayecto.length) {
      for (let i = 0; i < esq.repeticiones; i++) {
        const ordenEsquema = []
        const puntos = sector.pdis_recorrido.filter(pdi =>
          pdi.trayecto_ancla === esq.trayecto.id).concat(sector.puntos_km.map(pk => pk.punto).filter(p =>
          p.trayecto_ancla === esq.trayecto.id && p.servicios.length > 0))
        for (const i in puntos) {
          const p = puntos[i]
          const dist = turf.length(turf.lineSlice(
            trayecto[0].coordenadas.coordinates[0], p.coordenadas.coordinates, trayecto[0].coordenadas
          ), { units: 'meters' })
          ordenEsquema.push({ punto: p, dist: distAcum + dist })
        }
        orden = orden.concat(ordenEsquema)
        distAcum += esq.trayecto.distancia_metros
      }
    }
  })

  return orden.sort((a, b) => a.dist - b.dist).map(ptOrden => ptOrden.punto.id)
}
