import i18n from 'i18next'
import translationEN from './locales/en/translation.json'
import translationES from './locales/es/translation.json'

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
}

const LANGUAGE_CODE = window.LANGUAGE_CODE || 'es'

i18n.init({
  resources,
  fallbackLng: 'es',
  debug: false, // process.env.NODE_ENV !== 'production',
  lng: LANGUAGE_CODE
})

export default i18n
