import React from 'react'
import './ModalPuntoKm.css'
import MapaModalPdiSeleccionado from '../visor/MapaModalPdiSeleccionado'

export default function ModalPdiDetalle (props) {
  return (
    <div className='modal-km__wrapper'>
      <MapaModalPdiSeleccionado
        {...props.punto}
        handleClickCerrar={() => props.onClickPdiDetalle(null)}
        className='modal-km__modal'
        getAltiudPunto={props.getAltiudPunto}
        getPasosDistanciasPdi={props.getPasosDistanciasPdi}
        toFixed={props.toFixed}
      />
    </div>
  )
}
