import React from 'react'
import { Link } from 'react-router-dom'
import './PerfilConInfo.css'
import ModalPdiDetalle from './ModalPdiDetalle'
import { Trans, withTranslation } from 'react-i18next'
import Grafico from './Grafico'
import SliderPdis from './SliderPdis'
import imagotipoPq from '../../../src/lib/images/imagotipo-pq.png'

function PerfilConInfo (props) {
  // let distanciaTotal = props.sector.distancia_metros

  const alturaMaxima = Math.round(
    Math.max(...props.trayectos.map(t => t.altura_maxima))
  )
  const alturaMinima = Math.round(
    Math.min(...props.trayectos.map(t => t.altura_minima))
  )
  const desnivelPositivo = Math.round(
    props.trayectos.map(o => o.desnivel_acumulado_positivo).filter(o => o).reduce((a, b) => a + b, 0)
  )
  const desnivelNegativo = Math.round(
    props.trayectos.map(o => o.desnivel_acumulado_negativo).filter(o => o).reduce((a, b) => a + b, 0)
  )
  const sectoresDisp = props.recorrido.sectores.filter(s => s.perfil_publicado)
  const indexSector = sectoresDisp.map(s => s.id).indexOf(props.sector.id)

  return (
    <div className='perfil__wrapper'>
      <div className='perfil__grafico-sizer'>
        <div className='perfil__grafico'>
          <div className='perfil__topbar'>

            <h3 className='d-flex align-items-center'>
              {
                props.dom.estaEnIframe
                  ? (
                    <a href={window.location.href} title={props.t('Ver en racemapp')} rel='noopener noreferrer' target='_blank'>
                      <img className='perfil_logo' src={imagotipoPq} alt='logo' />
                    </a>
                  )
                  : <Link to='/'><img className='perfil_logo' src={imagotipoPq} alt='logo' /></Link>
              }
              <span className='perfil__proyectonombre'>{props.proyecto.nombre}</span>
              <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>
              <span className='perfil__recorridonombre'><strong>{props.recorrido.nombre}</strong></span>
            </h3>
            <div className='btn-group' role='group'>
              {props.handleFullScreen &&
                <button className='perfil__btn-fullscreen btn btn-sm' onClick={props.handleFullScreen}>
                  <i className='fas fa-expand' />
                </button>}
              {(props.handleClickMostrarCompartir && !props.dom.estaEnIframe) &&
                <button className='perfil__btn-share btn btn-sm' onClick={props.handleClickMostrarCompartir}>
                  <i className='fas fa-share-alt' />
                </button>}
              {(props.backLink && (!props.dom.estaEnIframe || !props.dom.iniciadoEnPerfil)) &&
                <Link className='perfil__btn-cerrar btn btn-sm' to={props.backLink}>
                  <i className='fas fa-times' />
                </Link>}
            </div>
          </div>
          <div className='perfil__tabla'>
            {props.recorrido.sectores.length > 1 &&
              <div className='row perfil__selectorsectores'>
                <div className='col-2'>
                  {indexSector > 0 &&
                    <button className='btn' onClick={props.handleClickSectorAnterior}>
                      <i className='fa fa-chevron-left' />
                    </button>}
                </div>
                <div className='col-8'>
                  <h5><strong>{props.sector.nombre}</strong></h5>
                </div>
                <div className='col-2'>
                  {indexSector < sectoresDisp.length - 1 &&
                    <button className='btn' onClick={props.handleClickSectorSiguiente}>
                      <i className='fa fa-chevron-right' />
                    </button>}
                </div>
              </div>}
            <div className='row perfil__datos d-flex justify-content-center'>
              <div className='col-6 d-flex justify-content-between'>
                <div className='d-inline-block'>
                  <i className='mdi mdi-trending-up' />
                  <span className='text-lowercase ml-1'><Trans>{{ desnivelPositivo }} m</Trans></span>
                </div>
                <div className='d-inline-block'>
                  <i className='mdi mdi-trending-down' />
                  <span className='text-lowercase ml-1'><Trans>{{ desnivelNegativo }} m</Trans></span>
                </div>
                <div className='d-inline-block'>
                  <i className='mdi mdi-format-vertical-align-top' />
                  <span className='text-lowercase ml-1'><Trans>{{ alturaMaxima }} m</Trans></span>
                </div>
                <div className='d-inline-block'>
                  <i className='mdi mdi-format-vertical-align-bottom' />
                  <span className='text-lowercase ml-1'><Trans>{{ alturaMinima }} m</Trans></span>
                </div>
              </div>
            </div>
          </div>
          <div className='perfil__sizer'>
            <Grafico
              sector={props.sector}
              pdis={props.sector.pdis_recorrido}
              puntosKm={props.sector.puntos_km}
              perfil={props.perfil}
              onClickPunto={props.handleClickPunto}
              unidad={props.recorrido.unidad_distancia}
            />
          </div>
          <div className='row'>
            <div className='col-12'>
              <SliderPdis
                pdis={props.sector.pdis_recorrido}
                pks={props.sector.puntos_km}
                perfil={props.perfil}
                onChangeSlider={props.handleClickPunto}
                getPasosDistanciasPdi={props.getPasosDistanciasPdi}
                getAltiudPunto={props.getAltiudPunto}
                onClickPdiDetalle={props.handleClickPdiDetalle}
              />
            </div>
          </div>
        </div>
        {props.perfil.pdiDetalle &&
          <ModalPdiDetalle
            punto={props.perfil.pdiDetalle}
            onClickPdiDetalle={props.handleClickPdiDetalle}
            getAltiudPunto={props.getAltiudPunto}
            getPasosDistanciasPdi={props.getPasosDistanciasPdi}
            toFixed={props.toFixed}
          />}
      </div>
    </div>
  )
}

export default withTranslation()(PerfilConInfo)
