/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_LISTA_BEGIN: 'FETCH_LISTA_BEGIN',
  FETCH_LISTA_SUCCESS: 'FETCH_LISTA_SUCCESS',
  FETCH_LISTA_ERROR: 'FETCH_LISTA_ERROR'
}

function fetchListaBegin () {
  return {
    type: ACTION_TYPES.FETCH_LISTA_BEGIN
  }
}

function fetchListaSuccess (lista) {
  return {
    type: ACTION_TYPES.FETCH_LISTA_SUCCESS,
    lista
  }
}

function fetchListaError (error) {
  return {
    type: ACTION_TYPES.FETCH_LISTA_ERROR,
    error
  }
}

export function fetchRemoteLista (id, fields = []) {
  return dispatch => {
    dispatch(fetchListaBegin())
    if (!id) {
      dispatch(fetchListaError('Debe especificarse un id de lista'))
    }
    const url = new URL(API_BASE_URL + 'lista/' + id + '/')
    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }
    return fetch(url, {
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 200) {
          return res.json()
        } else {
          dispatch(fetchListaError(res.statusText))
        }
      })
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res) {
          dispatch(fetchListaSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(err => {
        dispatch(fetchListaError(err))
      })
  }
}
