import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/portada'

export default function portadaReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_PORTADA_VIDEO:
      return {
        ...state,
        videoSeleccionado: action.video
      }

    default:
      return {
        ...state
      }
  }
}
