const dotenv = require('dotenv')
dotenv.config()

export const SERVER_DOMAIN = process.env.REACT_APP_SERVER_DOMAIN || 'http://localhost:8000'

export const FRONTEND_SERVER_DOMAIN = process.env.REACT_APP_FRONTEND_SERVER_DOMAIN || 'http://localhost:3000'

export const ELASTIC = {
  INDEX: process.env.REACT_APP_ELASTIC_INDEX || 'proyectos',
  SERVER: process.env.REACT_APP_ELASTIC_SERVER || '86e5e05868194a9a8f2157581c9fd027.europe-west3.gcp.cloud.es.io',
  PORT: process.env.REACT_APP_ELASTIC_PORT || 9243,
  KEY: process.env.REACT_APP_ELASTIC_KEY || 'aEp5bjJJZ0JTVjlZVlZ3UnhIQm06QnlWQlhCNmNUWm1PVHJzbUp3eXRtUQ=='
}

export const STRIPE_PUBLISHABLE_KEY = 'pk_test_51H4NFvHY8QkC6bmXbo44UDBztaWDuT74G1VY8L9FAG0tlKkYFnbLYlplKfTKHwfjTXLlKnqvouCjnsobmYFmxwAq00r2BpCk8F'

export const STRIPE_PRECIO_SUBSCRIPCION_ID = 'price_1H4QVIHY8QkC6bmXuq2XF9dq'
export const STRIPE_PRECIO_SUBSCRIPCION_PVP = 20
//
// if (process.env.NODE_ENV === 'production') {
//   SERVER_DOMAIN = 'https://test.racemapp.com'
//   FRONTEND_SERVER_DOMAIN = 'https://test.racemapp.com'
// }

export const API_BASE_URL = `${SERVER_DOMAIN}/api/1.0/`

export const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID || 'G-RDGDN5X7ZS'
