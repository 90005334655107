/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_CANAL_BEGIN: 'FETCH_CANAL_BEGIN',
  FETCH_CANAL_SUCCESS: 'FETCH_CANAL_SUCCESS',
  FETCH_CANAL_ERROR: 'FETCH_CANAL_ERROR'
}

function fetchCanalBegin () {
  return {
    type: ACTION_TYPES.FETCH_CANAL_BEGIN
  }
}

function fetchCanalSuccess (canal) {
  return {
    type: ACTION_TYPES.FETCH_CANAL_SUCCESS,
    canal
  }
}

function fetchCanalError (err) {
  return {
    type: ACTION_TYPES.FETCH_CANAL_ERROR,
    error: err
  }
}

export function fetchRemoteCanal (id, fields = []) {
  return dispatch => {
    dispatch(fetchCanalBegin())
    if (!id) {
      dispatch(fetchCanalError('Debe especificarse un id de canal'))
    } else {
      const url = new URL(API_BASE_URL + 'canal/' + id + '/')
      if (fields && fields.length) {
        url.searchParams.append('fields', fields.join(','))
      }
      return fetch(url, {
        credentials: 'include'
      })
        .then(res => {
          if (res.status === 200) {
            return res.json()
          } else {
            dispatch(fetchCanalError(res.statusText))
          }
        })
        .then(res => {
          if (res.error) {
            throw res.error
          } else if (res) {
            dispatch(fetchCanalSuccess(res))
          } else {
            throw new Error('Not found')
          }
        })
        .catch(err => {
          dispatch(fetchCanalError(err))
        })
    }
  }
}
