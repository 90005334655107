import React from 'react'
import { Link } from 'react-router-dom'
import MapaMontajeItem from './MapaMontajeItem'

const MapaMontajeList = ({ mapasMontaje }) => {
  return (
    <ul className='recorrido-list scroll-vertical'>
      {mapasMontaje.map(mm =>
        <li key={mm.id}>
          <Link className='recorrido-list__contenido' to={'/montaje/' + mm.id}>
            <MapaMontajeItem mapaMontaje={mm} />
          </Link>
        </li>
      )}
    </ul>
  )
}

export default MapaMontajeList
