import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/visitaRecorrido'

export default function visitaRecorridoReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.UPDATE_VISITAS_BEGIN:
      return {
        ...state,
        data: null,
        error: null,
        status: REQUEST_STATUS.LOADING
      }

    case ACTION_TYPES.UPDATE_VISITAS_SUCCESS:
      return {
        ...state,
        data: action.visita,
        status: REQUEST_STATUS.SUCCEEDED
      }

    case ACTION_TYPES.UPDATE_VISITAS_ERROR:
      return {
        ...state,
        error: action.error,
        status: REQUEST_STATUS.FAILED
      }

    default:
      return {
        ...state
      }
  }
}
