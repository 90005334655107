import { ACTION_TYPES } from '../actions/lineas'
import { initialState, REQUEST_STATUS } from '../constants/state'

export function lineasReducer (state = initialState.lineas, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_LINEAS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }
    case ACTION_TYPES.FETCH_LINEAS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.lineas
      }
    case ACTION_TYPES.FETCH_LINEAS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return {
        ...state
      }
  }
}
