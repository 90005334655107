import React from 'react'
import './FiltrosPopup.css'
import { Trans } from 'react-i18next'
import { MultiList } from '@appbaseio/reactivesearch'
import DynamicRange from '@appbaseio/reactivesearch/lib/components/range/DynamicRangeSlider'

export const FiltrosPopup = React.forwardRef((props, ref) => {
  return (
    <div ref={ref} className='filtros'>
      <i className='close-window fas fa-times text-right' onClick={props.handleMostrarFiltros} />
      <h4>Filtros</h4>
      <div className='scroll-zone'>
        <div className='form-row'>
          <div className='form-group'>
            <label className='col-form-label'><Trans>Deporte:</Trans></label>
            <MultiList
              componentId='deportes'
              dataField='deportes.raw'
              showSearch={false}
              showCheckbox
            />
          </div>
        </div>
        <div className='form-row'>
          <div className='form-group filtro-distancia'>
            <label className='col-form-label mb-2'><Trans>Distancia:</Trans></label>
            <DynamicRange
              dataField='distancias'
              componentId='distancias'
              defaultValue={(min, max) => (
                {
                  start: min,
                  end: Math.min(min + 5, max)
                }
              )}
              rangeLabels={(min, max) => (
                {
                  start: min,
                  end: max + ' km'
                }
              )}
              showHistogram={false}
              tooltipTrigger='always'
              renderTooltipData={data =>
                <span className='tooltip-filtro'>{data}</span>}
            />
          </div>
        </div>
      </div>
    </div>
  )
})

export default FiltrosPopup
