import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans, withTranslation } from 'react-i18next'
import NotFound from '../components/NotFound'
import { Helmet } from 'react-helmet'
import { Tabs, Tab } from 'react-bootstrap'
import * as turf from '@turf/turf'

import { fetchRemoteCanal } from '../actions/canal'
import { REQUEST_STATUS } from '../constants/state'
import HeaderPublico from '../components/PublicHeader'
import PublicBody from '../components/PublicBody'
import { fetchRemoteUsuario } from '../actions/usuario'
import { fetchRemoteRecorridos } from '../actions/recorrido'
import { SERVER_DOMAIN } from '../constants/api'
import RecorridoListCanal from '../components/canal/RecorridoListCanal'
import CardLista from '../components/canal/CardLista'

import './CanalScreen.css'

class CanalScreen extends Component {
  componentDidMount () {
    const { match, dispatch } = this.props

    dispatch(fetchRemoteUsuario())
    if (match && match.params && match.params.id) {
      dispatch(fetchRemoteCanal(match.params.id))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { dispatch, canal } = this.props

    if (!prevProps.canalCargado && this.props.canalCargado) {
      const fields = [
        'id', 'nombre', 'descripcion', 'unidad_distancia', 'captura', 'proyecto_nombre', 'deporte', 'sectores',
        'superficie', 'dificultad', 'distancia_metros', 'captura_2x1', 'deporte_icono'
      ]
      dispatch(fetchRemoteRecorridos(fields, { mapa__proyecto__user: canal.usuario.id, visible: true }))
    }
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  metrosToUnitsRecorrido (recorrido, distancia) {
    switch (recorrido.unidad_distancia) {
      case 'km':
        return this.toFixed((distancia / 1000), 2) + ' km'
      case 'mi':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'miles'), 2) + ' mi'
      case 'ft':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'feet'), 0) + ' ft'
      default:
        return this.toFixed(distancia, 0) + ' m'
    }
  }

  render () {
    const { canal, canalCargado, canalError, user, recorridos, t } = this.props

    if (!canalCargado && !canalError) {
      return <div><Trans>Cargando...</Trans></div>
    } else if (canalError) {
      return <NotFound error={canalError} />
    } else {
      return (
        <>
          <Helmet>
            <html lang='es' />
            <title>{t(`Canal ${canal.nombre} - RaceMapp`)}</title>
            <meta name='description' content={canal.descripcion} />
            <meta property='og:url' content={window.location.href} />
            <meta property='og:type' content='article' />
            <meta property='og:title' content={t(`Canal ${canal.nombre} - RaceMapp`)} />
            <meta property='og:description' content={canal.descripcion} />
            {canal.og_image &&
              <meta property='og:image' content={canal.og_image} />}
          </Helmet>
          <PublicBody>
            <HeaderPublico user={user} />
            {canal.imagen_portada &&
              <div className='canal-cabecera d-flex justify-content-center background-light'>
                <div
                  className='canal-cabecera__fondo' style={{ backgroundImage: 'url(' + canal.imagen_portada + ')' }}
                />
                <img className='canal-cabecera__imagen' src={canal.imagen_portada} alt='Imagen de portada' />
              </div>}
            <div className='canal-subcabecera'>
              {canal.usuario.avatar &&
                <div className='canal-subcabecera__avatar'>
                  <img src={SERVER_DOMAIN + canal.usuario.avatar} alt='Avatar usuario canal' />
                </div>}
              <div className='canal-subcabecera__titulo'>
                <h2>{canal.nombre}</h2>
                <p>{canal.usuario.username}</p>
              </div>
            </div>
            <div className='canal-tabs-container'>
              <Tabs defaultActiveKey='home'>
                <Tab eventKey='home' title={<Trans>Inicio</Trans>}>
                  <div className='container canal-tabs__container-tab'>
                    <div className='row'>
                      <div className='col-12 col-md-6 p-3 p-sm-5 lead'>
                        <p>{canal.descripcion}</p>
                      </div>
                      <div className='col-12 col-md-6'>
                        {canal.recorrido_destacado &&
                          <iframe
                            title='Recorrido destacado'
                            className='canal-tabs__recorrido-destacado'
                            src={'/recorrido/' + canal.recorrido_destacado}
                            frameBorder={0}
                          />}
                      </div>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='list' title={<Trans>Listas</Trans>}>
                  <div className='container canal-tabs__container-tab'>
                    <div className='masonry mt-5'>
                      {canal.listas.map(list =>
                        <div key={list.id} className='article'>
                          <CardLista
                            lista={list}
                            recorridos={recorridos.filter(rec =>
                              list.mapas.map(mapa => mapa.recorrido).includes(rec.id))}
                            metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </Tab>
                <Tab eventKey='recorridos' title={<Trans>Recorridos</Trans>}>
                  <RecorridoListCanal
                    recorridos={recorridos}
                    metrosToUnitsRecorrido={this.metrosToUnitsRecorrido.bind(this)}
                  />
                </Tab>
              </Tabs>
            </div>
          </PublicBody>
        </>
      )
    }
  }
}

const mapStateToProps = state => ({
  canal: state.canal.data,
  canalCargado: state.canal.status === REQUEST_STATUS.SUCCEEDED,
  canalError: state.canal.error,
  user: state.usuario.data,
  recorridos: state.recorridos.data
})

export default connect(mapStateToProps)(withTranslation()(CanalScreen))
