/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_PDIS_PROYECTO_BEGIN: 'FETCH_PDIS_PROYECTO_BEGIN',
  FETCH_PDIS_PROYECTO_SUCCESS: 'FETCH_PDIS_PROYECTO_SUCCESS',
  FETCH_PDIS_PROYECTO_ERROR: 'FETCH_PDIS_PROYECTO_ERROR'
}

function fetchPdisProyectoBegin () {
  return {
    type: ACTION_TYPES.FETCH_PDIS_PROYECTO_BEGIN
  }
}

function fetchPdisProyectoSucess (pdis) {
  return {
    type: ACTION_TYPES.FETCH_PDIS_PROYECTO_SUCCESS,
    pdis: pdis
  }
}

function fetchPdisProyectoError (err) {
  return {
    type: ACTION_TYPES.FETCH_PDIS_PROYECTO_ERROR,
    error: err
  }
}

export function fetchRemotePdisProyecto (params, fields = []) {
  return function (dispath) {
    dispath(fetchPdisProyectoBegin())
    if (!('agrupacion__proyecto' in params)) {
      dispath(fetchPdisProyectoError('Debe especificarse un proyecto_id'))
    } else {
      const url = new URL(API_BASE_URL + 'punto/')
      if (fields && fields.length) {
        params.fields = fields.join(',')
      }
      Object.keys(params).forEach(
        key => url.searchParams.append(key, params[key])
      )

      return fetch(url, {
        credentials: 'include'
      })
        .then(res => res.json())
        .then(res => {
          if (res.error) {
            throw res.error
          } else if (res && res.length) {
            dispath(fetchPdisProyectoSucess(res))
          } else {
            throw new Error('Not found')
          }
        })
        .catch(error => {
          dispath(fetchPdisProyectoError(error))
        })
    }
  }
}
