// Sobreescribir plugin mapkit mutant
import * as L from 'leaflet'

require('leaflet.mapkitmutant')

const MapkitMutant = L.MapkitMutant.extend({

  _onRegionChangeEnd: function (ev) {
    // console.log(ev.target.region.toString());
    if (!this._mutantCanvas) {
      this._mutantCanvas = this._mutantContainer.querySelector(
        'canvas.syrup-canvas'
      )
    }

    if (this._map && this._mutantCanvas) {
      const bounds = this._mapkitRectToLeafletBounds(
        this._mutant.visibleMapRect
      )

      // The mutant will take one frame to re-stitch its tiles, so
      // repositioning the mutant's overlay has to take place one frame
      // after the 'region-change-end' event, in order to avoid graphical
      // glitching.

      if (!this._canvasOverlay) {
        this._canvasOverlay = L.imageOverlay(null, bounds)

        // Hack the ImageOverlay's _image property so that it doesn't
        // create a HTMLImageElement
        var img = (this._canvasOverlay._image = L.DomUtil.create('div'))

        L.DomUtil.addClass(img, 'leaflet-image-layer')
        L.DomUtil.addClass(img, 'leaflet-zoom-animated')

        // Move the mutant's canvas out of its container, and into
        // the L.ImageOverlay's _image
        this._mutantCanvas.parentElement.removeChild(this._mutantCanvas)
        img.appendChild(this._mutantCanvas)

        this._canvasOverlay.addTo(this._map)
        this._updateOpacity()
      } else {
        this._canvasOverlay.setBounds(bounds)
      }
      this._mutantCanvas.style.width = '100%'
      this._mutantCanvas.style.height = '100%'
      this._mutantCanvas.style.position = 'absolute'

      if (this.options.debugRectangle) {
        if (!this.rectangle) {
          this.rectangle = L.rectangle(bounds, {
            fill: false
          }).addTo(this._map)
        } else {
          this.rectangle.setBounds(bounds)
        }
      }
    }
  }
})

export const mapkitMutant = (options) => {
  return new MapkitMutant(options)
}
