import React from 'react'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'

const NotificacionContacto = ({ notificacion, onClickNotificacion, yo }) => {
  return (
    <Link
      to='/contactos'
      onClick={onClickNotificacion}
    >
      {notificacion.object.estado === 'p' &&
        <><strong>{notificacion.object.user_1_obj.first_name}</strong> <Trans>te ha enviado una invitación</Trans></>}
      {notificacion.object.estado === 'a' &&
        <>
          {notificacion.object.user_2_obj.id === yo.id
            ? <><Trans>Has aceptado la invitación de</Trans> <strong>{notificacion.object.user_1_obj.first_name}</strong></>
            : <><strong>{notificacion.object.user_2_obj.first_name}</strong> <Trans>ha aceptado tu invitación</Trans></>}
        </>}
    </Link>
  )
}

export default NotificacionContacto
