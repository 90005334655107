import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/encargo'

export default function encargoReducer (state = initialState.createEncargo, action) {
  switch (action.type) {
    case ACTION_TYPES.CREATE_ENCARGO_BEGIN:
      return {
        ...state,
        data: null,
        error: null,
        status: REQUEST_STATUS.LOADING
      }

    case ACTION_TYPES.CREATE_ENCARGO_ERROR:
      return {
        ...state,
        error: action.error,
        status: REQUEST_STATUS.FAILED
      }

    case ACTION_TYPES.CREATE_ENCARGO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.encargo
      }

    case ACTION_TYPES.RESET_ENCARGO:
      return {
        ...state,
        status: REQUEST_STATUS.IDLE,
        data: null,
        error: null
      }

    default:
      return {
        ...state
      }
  }
}
