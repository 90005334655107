export const TIPOS_MAPAS_NOMBRES = {
  RECORRIDOS: 'Recorrido',
  STAFF: 'Personal',
  TRAFICO: 'Trafico',
  MONTAJE: 'Montaje'
}

export const REQUEST_STATUS = {
  IDLE: 'idle',
  LOADING: 'loading',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed'
}

export const SCREENS = {
  HOME: 'home',
  BUSCAR: 'buscar',
  PROYECTO: 'proyecto',
  PROYECTO_PERFIL: 'proyecto-perfil',
  PROYECTO_PERFILES: 'perfiles',
  MAPA: 'mapa',
  MAPA_PERFIL: 'mapa-perfil',
  MAPA_PERSONAL: 'personal'
}

export const MODELS_NOTIFICACION = {
  CONTACTO: 'contacto'
}

export const initialState = {
  usuario: {
    data: null,
    error: null,
    pendiente: false
  },
  dom: {
    cargado: false,
    // https://tommcfarlin.com/check-if-a-page-is-in-an-iframe/
    estaEnIframe: window.location !== window.parent.location,
    iniciadoEnProyecto: false,
    iniciadoEnRecorrido: false,
    iniciadoEnPerfil: false
  },
  visor: {
    compartirVisible: false,
    compartirInsertarVisible: false,
    modalEsquemaVisible: false,
    modalPdiSeleccionado: null,
    modalPdisVisible: false,
    modalPdisProyectoVisible: false,
    modalPerfilVisible: false,
    modalPuntosKmSeleccionado: null,
    submenuAjustesVisualesVisible: false,
    submenuHerramientasVisible: false,
    submenuPdisVisible: false,
    bounds: false,
    mapaPdisProyectoVisible: true,
    mapaPdisRecorridoVisible: true,
    modalCambiarVistaVisible: false,
    capaFondo: null,
    graficoDomain: null,
    graficoZoomDomain: null,
    lastScreen: null,
    pdiFocused: null,
    pdiOrden: [],
    indexPdiOrden: null,
    animando: false,
    animacionPausadaAuto: false,
    animacionPausada: false,
    esquemaEnAnimacion: null,
    repeticionEnAnimacion: null,
    distanciaTrayectoAnimada: 0,
    trayectosYaAnimados: [],
    zoomAnimacion: 16,
    velocidadAnimacion: 50, // Metros por frame
    multiplicadorVelocidadAnimacion: 1,
    submenuVelocidadVisible: false,
    animacionBucle: false,
    filtroPdis: '',
    filtroPdisRecorrido: '',
    pdiDestacado: null,
    puntosAnimados: [],
    distanciasPuntos: [],
    location: null,
    capasOcultas: [],
    localizacionDescentrada: false
  },
  proyecto: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  mapa: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  mapas: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  recorrido: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  recorridos: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  sector: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  fondos: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  pdisProyecto: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  lineas: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  poligonos: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  canal: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  lista: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  visorLista: {
    recorridoHovered: null,
    compartirVisible: false
  },
  perfil: {
    pdiOrden: [],
    indexPdiSeleccionado: null,
    pdiDetalle: null,
    compartirVisible: false
  },
  createEncargo: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  categoriasPortada: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  visitaRecorrido: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  mapaPortada: {
    recorridoSeleccionado: null
  },
  portada: {
    videoSeleccionado: 0,
    videos: [
      {
        titulo: 'RaceMapp: todos los recorridos y ubicaciones de tu evento a un solo click',
        descripcion: 'Te resumimos en menos de 3 minutos algunas de las funciones de RaceMapp. Esquemas de recorridos, puntos de interés y de recorrido, perfiles de desnivel, organización de personal, cortes de tráfico, cómo llegar a las ubicaciones...',
        url: 'https://player.vimeo.com/video/581128993?autoplay=1',
        img: '/static/img/video-portada-01.jpg'
      },
      {
        titulo: 'Puntos de interés',
        descripcion: 'Los puntos de interés te permiten identificar todas las ubicaciones que atletas, acompañantes y público necesitan conocer, con descripciones, fotografias y opción de ver como llegar hasta él abriendo la ubicación en Google Maps.',
        url: 'https://player.vimeo.com/video/609687502?autoplay=1',
        img: '/static/img/video-portada-pdis.jpg'
      },
      {
        titulo: 'Puntos de recorrido',
        descripcion: 'Tus recorridos al detalle, podrás marcar puntos kilométricos, avituallamiento, boxes, controles de cronometraje, obstáculos y cuaquier cosa que necesites identificar en un trayecto.',
        url: 'https://player.vimeo.com/video/609685561?autoplay=1',
        img: '/static/img/video-portada-pdrs.jpg'
      },
      {
        titulo: 'Como llegar',
        descripcion: 'El botón "Cómo llegar" que tienen todos los puntos de interés y de recorrido te permiten abrirlos en Google Maps para que puedas obtener indicaciones de cómo llegar a ellos desde tu ubicación actual.',
        url: 'https://player.vimeo.com/video/609687361?autoplay=1',
        img: '/static/img/video-portada-como-llegar.jpg'
      },
      {
        titulo: 'Creamos tu RaceMapp',
        descripcion: 'Si no puedes, o no quieres, crear y dar forma a tus proyectos, nosotros lo hacemos por ti. Pídenos presupuesto sin compromiso y te contestaremos lo mas rápido posible.',
        url: 'https://player.vimeo.com/video/609686103?autoplay=1',
        img: '/static/img/video-portada-creamos-tu-racemapp.jpg'
      },
      {
        titulo: 'Animación RaceMapp del Cross Internacional de Atapuerca',
        descripcion: 'RaceMapp permite ver con todo detalle el esquema de cualquier carrera por compleja que sea, facilitando a los atletas conocer cual será exactamente el recorrido que tendrán que afrontar.',
        url: 'https://player.vimeo.com/video/581141782?autoplay=1',
        img: '/static/img/video-portada-02.jpg'
      }
    ]
  },
  usuariosBuscados: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  contactoCreado: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  contactoEliminado: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  contactos: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  contactoActualizado: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  notificaciones: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  notificacionUpdate: {
    data: null,
    satatus: REQUEST_STATUS.IDLE,
    error: null
  },
  mapaVoluntarios: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  mapasVoluntarios: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  voluntariosElementos: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  visorPersonal: {
    lastScreen: null,
    bounds: null,
    location: null,
    capasOcultas: [],
    localizacionDescentrada: false,
    compartirVisible: false,
    modalDescripcionVisible: false,
    capaFondo: null,
    pdiFocused: null,
    submenuHerramientasVisible: false,
    modalCambiarVistaVisible: false,
    pdiDestacado: null,
    modalPdisVisible: false,
    filtroPdis: '',
    mapaPdisVisible: true,
    modalPersonalVisible: false,
    filtroModalPersonal: ''
  },
  proyectoScreen: {
    currentMapa: null
  },
  mapasTrafico: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  mapaTrafico: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  traficoElementos: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  visorTrafico: {
    lastScreen: null,
    bounds: null,
    location: null,
    capasOcultas: [],
    localizacionDescentrada: false,
    compartirVisible: false,
    modalDescripcionVisible: false,
    capaFondo: null,
    pdiFocused: null,
    submenuHerramientasVisible: false,
    modalCambiarVistaVisible: false,
    pdiDestacado: null,
    modalPdisVisible: false,
    filtroPdis: '',
    mapaPdisVisible: true
  },
  mapasMontaje: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  mapaMontaje: {
    data: null,
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  montajeElementos: {
    data: [],
    status: REQUEST_STATUS.IDLE,
    error: null
  },
  visorMontaje: {
    lastScreen: null,
    bounds: null,
    location: null,
    capasOcultas: [],
    localizacionDescentrada: false,
    compartirVisible: false,
    modalDescripcionVisible: false,
    capaFondo: null,
    pdiFocused: null,
    submenuHerramientasVisible: false,
    modalCambiarVistaVisible: false,
    pdiDestacado: null,
    modalPdisVisible: false,
    filtroPdis: '',
    mapaPdisVisible: true
  }
}
