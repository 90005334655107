import React, { Component } from 'react'
import { MODELS_NOTIFICACION } from '../../constants/state'
import NotificacionContacto from './NotificacionContacto'
import { connect } from 'react-redux'

import './Notificacion.css'
import { updateNotificacion } from '../../actions/notificacion'

class Notificacion extends Component {
  handleClickNotificacion () {
    const { dispatch, notificacion } = this.props
    dispatch(updateNotificacion(notificacion.id, {
      leida: true
    }))
  }

  render () {
    const { notificacion, yo } = this.props
    switch (notificacion.model) {
      case MODELS_NOTIFICACION.CONTACTO:
        return (
          <NotificacionContacto
            notificacion={notificacion}
            onClickNotificacion={this.handleClickNotificacion.bind(this)}
            yo={yo}
          />
        )
      default:
        return null
    }
  }
}

export default connect()(Notificacion)
