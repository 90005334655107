import { ACTION_TYPES } from '../actions/pdi'
import { initialState, REQUEST_STATUS } from '../constants/state'

export function pdisProyectoReducer (state = initialState.pdisProyecto, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PDIS_PROYECTO_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }
    case ACTION_TYPES.FETCH_PDIS_PROYECTO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.pdis
      }
    case ACTION_TYPES.FETCH_PDIS_PROYECTO_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return state
  }
}
