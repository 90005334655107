import React from 'react'
import * as turf from '@turf/turf'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import { Spinner } from 'react-bootstrap'
import { Trans, withTranslation } from 'react-i18next'

import { actions } from '../actions/perfil'
import NotFound from '../components/NotFound'
import Compartir from '../components/Compartir'
import { fetchRemoteMapa } from '../actions/mapa'
import { fetchRemoteProyecto } from '../actions/proyecto'
import { actions as visorActions } from '../actions/visor'
import { fetchRemoteRecorrido } from '../actions/recorrido'
import { REQUEST_STATUS, SCREENS } from '../constants/state'
import PerfilConInfo from '../components/perfil/PerfilConInfo'
import { fetchRemoteSector, fetchSectorBegin, actions as actionsSector } from '../actions/sector'
import { updateVisitas } from '../actions/visitaRecorrido'
import { actions as domActions } from '../actions/dom'

export class PerfilScreenClass extends React.Component {
  constructor (props) {
    super(props)
    this.handleDomLoad = this._handleDomLoad.bind(this)
  }

  _handleDomLoad () {
    const { dispatch } = this.props
    dispatch(domActions.domSetCargado())
    dispatch(domActions.domSetStartRecorrido(true))
  }

  componentDidMount () {
    const { match, dispatch, recorrido, dom } = this.props

    if (!dom.cargado) {
      window.addEventListener('load', this.handleDomLoad)
    }

    // Ó montar sempre reseteamos o estado de carga do sector
    // para que se inicie tamén a carga dos recursos relacionados
    if (!recorrido || !recorrido.sectores.map(s => s.id).includes(parseInt(match.params.id))) {
      dispatch(fetchSectorBegin())

      if (match && match.params) {
        dispatch(fetchRemoteSector(match.params.id, [], { completo: true, perfil_publicado: true }))
      }
    } else {
      dispatch(fetchSectorBegin())
      dispatch(actionsSector.setSector(recorrido.sectores.filter(s => s.id === parseInt(match.params.id))[0]))
    }
  }

  componentDidUpdate (prevProps, prevState, prevContext) {
    const {
      match, dispatch,
      sector, sectorCargado, recorrido, recorridoCargado,
      mapa, mapaCargado
    } = this.props

    if (prevProps.match.params.id !== match.params.id) {
      dispatch(fetchRemoteSector(match.params.id, [], { completo: true }))
    }
    if (!prevProps.sectorCargado && sectorCargado && (!recorrido || recorrido.id !== sector.recorrido)) {
      if (!recorridoCargado || recorrido.id !== sector.recorrido) {
        dispatch(fetchRemoteRecorrido(sector.recorrido))
      }
    }
    if (!prevProps.recorridoCargado && recorridoCargado) {
      dispatch(updateVisitas(recorrido.id))
      if (!prevProps.recorrido || (prevProps.recorrido.mapa !== recorrido.mapa)) {
        dispatch(fetchRemoteMapa(recorrido.mapa))
      }
    }
    if (!prevProps.mapaCargado && mapaCargado) {
      if (!prevProps.mapa || (prevProps.mapa.proyecto !== mapa.proyecto)) {
        dispatch(fetchRemoteProyecto(mapa.proyecto))
      }
    }
  }

  componentWillUnmount () {
    const { dispatch, visor } = this.props

    window.removeEventListener('load', this.handleDomLoad)

    if (visor.lastScreen === SCREENS.MAPA) {
      dispatch(visorActions.setLastScreen(SCREENS.MAPA_PERFIL))
    // } else if (visor.lastScreen === SCREENS.PROYECTO_PERFILES) {
    //   dispatch(visorActions.setLastScreen(SCREENS.PROYECTO_PERFIL))
    } else {
      dispatch(visorActions.setLastScreen(SCREENS.PROYECTO_PERFIL))
    }
    if (document.fullscreenElement ||
      document.mozFullScreenElement || document.webkitFullscreenElement || document.msFullscreenElement) {
      this.fullScreenOut()
    }
  }

  /**
   * A url a onde volver vai variar dependendo de:
   *  * Se accedemos desde o listado de proxectos e:
   *    * O recorrido ten varios sectores.
   *    * O recorrido ten un sector.
   *  * Se accedemos desde o mapa interativo.
   *
   * @returns {string}
   */
  getBackLink () {
    const { recorrido, proyecto, visor } = this.props

    if (visor.lastScreen === SCREENS.MAPA) {
      return `/recorrido/${recorrido.id}-${recorrido.slug}`
    } else {
      if (recorrido.sectores.length === 1) {
        return `/proyecto/${proyecto.id}-${proyecto.slug}`
      } else {
        return `/perfiles/${recorrido.id}`
      }
    }
  }

  getHelmetTags () {
    const { recorrido, proyecto, sector, t } = this.props

    let ogImage = '/og_image.png'
    if (sector && sector.captura_2x1) {
      ogImage = sector.captura_2x1
    }

    return (
      <Helmet>
        <html lang='es' />
        <title>
          {recorrido.sectores.length > 1
            ? t(`Perfil de ${sector.nombre} en ${recorrido.nombre}, ${proyecto.nombre} - RaceMapp`)
            : t(`Perfil de ${recorrido.nombre}, ${proyecto.nombre} - RaceMapp`)}
        </title>
        <meta name='description' content={recorrido.descripcion} />
        <meta property='og:url' content={window.location.href} />
        <meta property='og:type' content='article' />
        <meta property='og:title' content={t(`Perfil de ${sector.nombre} en ${recorrido.nombre}, ${proyecto.nombre} - RaceMapp`)} />
        <meta property='og:description' content={recorrido.descripcion} />
        <meta property='og:image' content={ogImage} />
      </Helmet>
    )
  }

  handleClickPdiDetalle (punto = null) {
    const { dispatch } = this.props
    dispatch(actions.seleccionarPdiDetalle(punto))
    document.getElementsByClassName('perfil__wrapper')[0].scrollTo(0, 0)
  }

  handleClickPunto (index) {
    const { dispatch } = this.props
    if (!isNaN(index)) {
      dispatch(actions.setIndexPdiSeleccionado(index))
    }
  }

  fullScreenIn () {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen()
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen()
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen()
    }
  }

  fullScreenOut () {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen()
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen()
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen()
    }
  }

  handleFullScreen () {
    if (!document.fullscreenElement &&
      !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
      this.fullScreenIn()
    } else {
      this.fullScreenOut()
    }
  }

  getPasosDistanciasPdi (pdi) {
    const { sector } = this.props
    const pasosDistancia = []
    let distAcum = 0
    sector.esquemas.forEach(esq => {
      if (esq.trayecto.id === pdi.trayecto_ancla) {
        const trayecto = sector.trayectos.filter(tr => tr.id === esq.trayecto.id)
        if (trayecto.length) {
          const tramoIni = turf.lineSlice(
            trayecto[0].coordenadas.coordinates[0], pdi.coordenadas.coordinates, trayecto[0].coordenadas
          )
          const distIniTrayecto = turf.length(
            tramoIni, { units: 'meters' }
          ) * (trayecto[0].distancia_metros / trayecto[0].distancia_calculada)
          for (let i = 1; i <= esq.repeticiones; i++) {
            const distPaso = distAcum + distIniTrayecto
            pasosDistancia.push(distPaso)
            distAcum += esq.trayecto.distancia_metros
          }
        }
      } else {
        distAcum += esq.trayecto.distancia_metros * esq.repeticiones
      }
    })
    return pasosDistancia.map(paso => this.metrosToUnitsRecorrido(paso))
  }

  metrosToUnitsRecorrido (distancia) {
    const { recorrido } = this.props
    switch (recorrido.unidad_distancia) {
      case 'km':
        return this.toFixed((distancia / 1000), 2) + ' km'
      case 'mi':
        return this.toFixed(turf.convertLength(distancia, 'meters', 'miles'), 2) + ' mi'
      case 'ft':
        return turf.convertLength(distancia, 'meters', 'feet').toFixed(0) + ' ft'
      default:
        return distancia.toFixed(0) + ' m'
    }
  }

  toFixed (val, digits) {
    return parseFloat(val.toFixed(digits))
  }

  getAltiudPunto (pdi) {
    const { sector } = this.props
    const trayectos = []
    sector.trayectos.forEach(item => {
      // indexOf parece que non funciona en este caso..
      if (!trayectos.find(o => o.id === item.id)) {
        trayectos.push(item)
      }
    })

    const trayecto = trayectos.filter(tr => tr.id === pdi.trayecto_ancla)
    if (trayecto.length) {
      const puntoCercano = turf.nearestPointOnLine(trayecto[0].coordenadas_interpoladas, pdi.coordenadas)
      return trayecto[0].coordenadas_interpoladas.coordinates[puntoCercano.properties.index][2]
    }

    return null
  }

  handleClickSectorAnterior () {
    const { recorrido, sector, dispatch } = this.props
    const indexSector = recorrido.sectores.map(s => s.id).indexOf(sector.id)
    dispatch(fetchSectorBegin())
    dispatch(actionsSector.setSector(recorrido.sectores[indexSector - 1]))
  }

  handleClickSectorSiguiente () {
    const { recorrido, sector, dispatch } = this.props
    const indexSector = recorrido.sectores.map(s => s.id).indexOf(sector.id)
    dispatch(fetchSectorBegin())
    dispatch(actionsSector.setSector(recorrido.sectores[indexSector + 1]))
  }

  handleClickMostrarCompartir () {
    const { dispatch } = this.props
    dispatch(actions.compartirMostrar())
  }

  handleClickOcultarCompartir () {
    const { dispatch } = this.props
    dispatch(actions.compartirOcultar())
  }

  render () {
    const {
      sector, recorrido, proyecto, proyectoError, visor, dispatch, perfil, dom
    } = this.props

    if (proyectoError || (sector && !sector.perfil_publicado) || (recorrido && !recorrido.publicado)) {
      return <NotFound error={proyectoError} />
    }

    if (perfil.compartirVisible) {
      return <Compartir clickCerrar={this.handleClickOcultarCompartir.bind(this)} insertarActivo />
    }

    if (sector && recorrido && proyecto) {
      // Os trayectos poden estar repetidos en un sector,
      // polo que os simplificamos eliminando duplicados
      const trayectos = []
      sector.trayectos.flat(1).forEach(item => {
        // indexOf parece que non funciona en este caso..
        if (!trayectos.find(o => o.id === item.id)) {
          trayectos.push(item)
        }
      })

      return (
        <>
          {this.getHelmetTags()}
          <div className={`visor__layout ${window.inIframe ? '' : 'visor__layout--force-landscape'}`}>
            <div className='visor__main visor__main--perfil'>
              <PerfilConInfo
                dom={dom}
                sector={sector}
                trayectos={trayectos}
                visor={visor}
                dispatch={dispatch}
                recorrido={recorrido}
                proyecto={proyecto}
                handleClickPdiDetalle={this.handleClickPdiDetalle.bind(this)}
                backLink={this.getBackLink()}
                perfil={perfil}
                handleClickPunto={this.handleClickPunto.bind(this)}
                handleFullScreen={this.handleFullScreen.bind(this)}
                getPasosDistanciasPdi={this.getPasosDistanciasPdi.bind(this)}
                getAltiudPunto={this.getAltiudPunto.bind(this)}
                handleClickSectorAnterior={this.handleClickSectorAnterior.bind(this)}
                handleClickSectorSiguiente={this.handleClickSectorSiguiente.bind(this)}
                handleClickMostrarCompartir={this.handleClickMostrarCompartir.bind(this)}
                toFixed={this.toFixed.bind(this)}
              />
            </div>
          </div>
        </>
      )
    }
    return (
      <div className='fondo-carga'>
        <Spinner className='spinner-carga' animation='border' role='status'>
          <span className='sr-only'><Trans>Cargando...</Trans></span>
        </Spinner>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    dom: state.dom,
    visor: state.visor,
    perfil: state.perfil,
    error: state.error,
    sector: state.sector.data,
    sectorCargado: state.sector.status === REQUEST_STATUS.SUCCEEDED,
    recorrido: state.recorrido.data,
    recorridoCargado: state.recorrido.status === REQUEST_STATUS.SUCCEEDED,
    mapa: state.mapa.data,
    mapaCargado: state.mapa.status === REQUEST_STATUS.SUCCEEDED,
    proyecto: state.proyecto.data,
    proyectoError: state.proyecto.error
  }
}

export default connect(mapStateToProps)(withTranslation()(PerfilScreenClass))
