import React from 'react'
import MapaModal from './MapaModal'

import './MapaModalPdiSeleccionado.css'
import { SERVER_DOMAIN } from '../../constants/api'

export default function MapaModalPdiSeleccionado (props) {
  const onClickCerrar = props.onClickCerrar ? props.onClickCerrar : props.handleClickCerrar
  return (
    <MapaModal
      handleClickCerrar={onClickCerrar}
      titulo={props.nombre}
      className={`mapa-modal-pdi ${props.className}`}
    >
      <div className='container'>
        {props.trayecto_ancla && !props.noRecorrido &&
          <div className='row'>
            <div className='col-12'>
              <span className='modal-pdi__altitud'>
                <strong>
                  <i className='mdi mdi-format-vertical-align-top mr-1' />
                  {props.getAltiudPunto(props).toFixed(0)} m
                </strong>
              </span>
              <i className='mdi mdi-arrow-expand-horizontal ml-3 mr-1' />
              {props.getPasosDistanciasPdi(props).map((dist, i) => i === 0 ? dist : ' - ' + dist)}
            </div>
          </div>}
        <div className='row mt-2'>
          {props.imagen &&
            <div className='col-6 modal-pdi__imagen'>
              <img src={props.imagen} alt={props.nombre} />
            </div>}
          <div className={props.imagen ? 'col-6' : 'col-12'}>
            <div className='row'>
              <div className='col-12 modal-pdi__servicios'>
                {props.servicios && props.servicios.map((s, i) =>
                  <div
                    key={i + '-' + s.id}
                    className='slider-pdis__servicio mr-1'
                    style={{
                      backgroundColor: s.color_fondo,
                      color: s.color_icono
                    }}
                  >
                    <i className={s.icono.prefijo_fuente + ' ' + s.icono.prefijo_fuente + '-' + s.icono.icono} />
                  </div>
                )}
              </div>
            </div>
            <div className='row'>
              <div className='col-12 modal-pdi__descripcion mt-4'>
                {props.descripcion}
              </div>
            </div>
            {props.personal && props.usuarios.length &&
              <div className='row'>
                <div className='col-12'>
                  <ul className='list-group'>
                    {props.usuarios.map(u =>
                      <li key={u.id} className='pt-1 pb-1 d-flex align-items-center pdi-focused__user-item'>
                        {u.avatar
                          ? (
                            <img
                              className='modal-pdi__avatar-user rounded-circle mr-2'
                              src={SERVER_DOMAIN + u.avatar}
                              alt='Avatar'
                            />
                          ) : <i className='fas fa-user-circle fa-3x mr-2' />}
                        {u.first_name}
                      </li>
                    )}
                  </ul>
                </div>
              </div>}
          </div>
        </div>
      </div>
    </MapaModal>
  )
}
