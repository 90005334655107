
export const ACTION_TYPES = {
  DOM_CARGADO: 'DOM_CARGADO',
  DOM_SET_EN_IFRAME: 'DOM_SET_EN_IFRAME',
  DOM_SET_START_PROYECTO: 'DOM_SET_START_PROYECTO',
  DOM_SET_START_RECORRIDO: 'DOM_SET_START_RECORRIDO',
  DOM_SET_START_PERFIL: 'DOM_SET_START_PERFIL'
}

function domSetCargado () {
  return {
    type: ACTION_TYPES.DOM_CARGADO
  }
}

function domSetEnIframe (iframe) {
  return {
    type: ACTION_TYPES.DOM_SET_EN_IFRAME,
    iframe
  }
}

function domSetStartProyecto (start) {
  return {
    type: ACTION_TYPES.DOM_SET_START_PROYECTO,
    start
  }
}

function domSetStartRecorrido (start) {
  return {
    type: ACTION_TYPES.DOM_SET_START_RECORRIDO,
    start
  }
}

function domSetStartPerfil (start) {
  return {
    type: ACTION_TYPES.DOM_SET_START_PERFIL,
    start
  }
}

export const actions = {
  domSetCargado,
  domSetEnIframe,
  domSetStartProyecto,
  domSetStartRecorrido,
  domSetStartPerfil
}
