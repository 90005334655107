export const ACTION_TYPES = {
  MODAL_ESQUEMA_MOSTRAR: 'MODAL_ESQUEMA_MOSTRAR',
  MODAL_ESQUEMA_OCULTAR: 'MODAL_ESQUEMA_OCULTAR',
  MODAL_PDIS_MOSTRAR: 'MODAL_PDIS_MOSTRAR',
  MODAL_PDIS_OCULTAR: 'MODAL_PDIS_OCULTAR',
  MODAL_PDIS_PROYECTO_MOSTRAR: 'MODAL_PDIS_PROYECTO_MOSTRAR',
  MODAL_PDIS_PROYECTO_OCULTAR: 'MODAL_PDIS_PROYECTO_OCULTAR',
  MODAL_PDI_SELECCIONADO_MOSTRAR: 'MODAL_PDI_SELECCIONADO_MOSTRAR',
  MODAL_PDI_SELECCIONADO_OCULTAR: 'MODAL_PDI_SELECCIONADO_OCULTAR',
  MODAL_CAMBIAR_VISTA_MOSTRAR: 'MODAL_CAMBIAR_VISTA_MOSTRAR',
  MODAL_CAMBIAR_VISTA_OCULTAR: 'MODAL_CAMBIAR_VISTA_OCULTAR',
  MODAL_PERFILES_MOSTRAR: 'MODAL_PERFILES_MOSTRAR',
  MODAL_PERFILES_OCULTAR: 'MODAL_PERFILES_OCULTAR',
  SUBMENU_AJUSTES_VISUALES_MOSTRAR: 'SUBMENU_AJUSTES_VISUALES_MOSTRAR',
  SUBMENU_AJUSTES_VISUALES_OCULTAR: 'SUBMENU_AJUSTES_VISUALES_OCULTAR',
  SUBMENU_HERRAMIENTAS_MOSTRAR: 'SUBMENU_HERRAMIENTAS_MOSTRAR',
  SUBMENU_HERRAMIENTAS_OCULTAR: 'SUBMENU_HERRAMIENTAS_OCULTAR',
  SUBMENU_PDIS_MOSTRAR: 'SUBMENU_PDIS_MOSTRAR',
  SUBMENU_PDIS_OCULTAR: 'SUBMENU_PDIS_OCULTAR',
  SET_MAP_BOUNDS: 'SET_MAP_BOUNDS',
  MAPA_PDIS_PROYECTO_MOSTRAR: 'MAPA_PDIS_PROYECTO_MOSTRAR',
  MAPA_PDIS_PROYECTO_OCULTAR: 'MAPA_PDIS_PROYECTO_OCULTAR',
  MAPA_PDIS_RECORRIDO_MOSTRAR: 'MAPA_PDIS_RECORRIDO_MOSTRAR',
  MAPA_PDIS_RECORRIDO_OCULTAR: 'MAPA_PDIS_RECORRIDO_OCULTAR',
  COMPARTIR_MOSTRAR: 'COMPARTIR_MOSTRAR',
  COMPARTIR_OCULTAR: 'COMPARTIR_OCULTAR',
  COMPARTIR_INSERTAR_TOGGLE: 'COMPARTIR_INSERTAR_TOGGLE',
  SELECCIONAR_CAPA_FONDO: 'SELECCIONAR_CAPA_FONDO',
  SELECCIONAR_PUNTO_KM: 'SELECCIONAR_PUNTO_KM',
  SET_GRAFICO_DOMAIN: 'SET_GRAFICO_DOMAIN',
  SET_GRAFICO_ZOOM_DOMAIN: 'SET_GRAFICO_ZOOM_DOMAIN',
  SET_LAST_SCREEN: 'SET_LAST_SCREEN',
  SET_PDI_FOCUSED: 'SET_PDI_FOCUSED',
  MAP_ZOOM_CHANGED: 'MAP_ZOOM_CHANGED',
  SET_INDEX_PDI_ORDEN: 'SET_INDEX_PDI_ORDEN',
  ANIMACION_ON: 'ANIMACION_ON',
  ANIMACION_OFF: 'ANIMACION_OFF',
  SET_ESQUEMA_ANIMACION: 'SET_ESQUEMA_ANIMACION',
  SET_REPETICION_ANIMACION: 'SET_REPETICION_ANIMACION',
  SET_DISTANCIA_ANIMADA: 'SET_DISTANCIA_ANIMADA',
  ADD_TRAYECTO_YA_ANIMADO: 'ADD_TRAYECTO_YA_ANIMADO',
  ANIMACION_PAUSADA_ON: 'ANIMACION_PAUSADA_ON',
  ANIMACION_PAUSADA_OFF: 'ANIMACION_PAUSADA_OFF',
  SET_MULTIPLICADOR_VELOCIDAD_ANIM: 'SET_MULTIPLICADOR_VELOCIDAD_ANIM',
  SUBMENU_VELOCIDAD_MOSTRAR: 'SUBMENU_VELOCIDAD_MOSTRAR',
  SUBMENU_VELOCIDAD_OCULTAR: 'SUBMENU_VELOCIDAD_OCULTAR',
  ANIMACION_BUCLE_ON: 'ANIMACION_BUCLE_ON',
  ANIMACION_BUCLE_OFF: 'ANIMACION_BUCLE_OFF',
  ANIMACION_PAUSADA_AUTO_ON: 'ANIMACION_PAUSADA_AUTO_ON',
  ANIMACION_PAUSADA_AUTO_OFF: 'ANIMACION_PAUSADA_AUTO_OFF',
  SET_FILTRO_PDIS: 'SET_FILTRO_PDIS',
  SET_FILTRO_PDIS_RECORRIDO: 'SET_FILTRO_PDIS_RECORRIDO',
  SET_PDI_DESTACADO: 'SET_PDI_DESTACADO',
  ADD_PUNTO_ANIMADO: 'ADD_PUNTO_ANIMADO',
  SET_DISTANCIAS_PUNTOS: 'SET_DISTANCIAS_PUNTOS',
  SET_SIGUIENTE_DISTANCIA_ANIMADA: 'SET_SIGUIENTE_DISTANCIA_ANIMADA',
  SET_LOCATION: 'SET_LOCATION',
  ADD_CAPA_OCULTA: 'ADD_CAPA_OCULTA',
  REMOVE_CAPA_OCULTA: 'REMOVE_CAPA_OCULTA',
  RESET_CAPAS_OCULTAS: 'RESET_CAPAS_OCULTAS',
  LOCALIZACION_DESCENTRADA_ON: 'LOCALIZACION_DESCENTRADA_ON',
  LOCALIZACION_DESCENTRADA_OFF: 'LOCALIZACION_DESCENTRADA_OFF'
}

function modalEsquemaMostrar () {
  return {
    type: ACTION_TYPES.MODAL_ESQUEMA_MOSTRAR
  }
}

function modalEsquemaOcultar () {
  return {
    type: ACTION_TYPES.MODAL_ESQUEMA_OCULTAR
  }
}

function modalPdisMostrar () {
  return {
    type: ACTION_TYPES.MODAL_PDIS_MOSTRAR
  }
}

function modalPdisOcultar () {
  return {
    type: ACTION_TYPES.MODAL_PDIS_OCULTAR
  }
}

function modalPdisProyectoMostrar () {
  return {
    type: ACTION_TYPES.MODAL_PDIS_PROYECTO_MOSTRAR
  }
}

function modalPdisProyectoOcultar () {
  return {
    type: ACTION_TYPES.MODAL_PDIS_PROYECTO_OCULTAR
  }
}

function modalPdiMostrar (pdi) {
  return {
    type: ACTION_TYPES.MODAL_PDI_SELECCIONADO_MOSTRAR,
    modalPdiSeleccionado: pdi
  }
}

function modalPdiOcultar () {
  return {
    type: ACTION_TYPES.MODAL_PDI_SELECCIONADO_OCULTAR,
    modalPdiSeleccionado: null
  }
}

function submenuAjustesVisualesMostrar () {
  return {
    type: ACTION_TYPES.SUBMENU_AJUSTES_VISUALES_MOSTRAR
  }
}

function submenuAjustesVisualesOcultar () {
  return {
    type: ACTION_TYPES.SUBMENU_AJUSTES_VISUALES_OCULTAR
  }
}

function submenuPdisMostrar () {
  return {
    type: ACTION_TYPES.SUBMENU_PDIS_MOSTRAR
  }
}

function submenuPdisOcultar () {
  return {
    type: ACTION_TYPES.SUBMENU_PDIS_OCULTAR
  }
}

function setMapBounds (bounds) {
  return {
    type: ACTION_TYPES.SET_MAP_BOUNDS,
    bounds: bounds
  }
}

function mapaPdisProyectoMostrar () {
  return {
    type: ACTION_TYPES.MAPA_PDIS_PROYECTO_MOSTRAR
  }
}

function mapaPdisProyectoOcultar () {
  return {
    type: ACTION_TYPES.MAPA_PDIS_PROYECTO_OCULTAR
  }
}

function mapaPdisRecorridoMostrar () {
  return {
    type: ACTION_TYPES.MAPA_PDIS_RECORRIDO_MOSTRAR
  }
}

function mapaPdisRecorridoOcultar () {
  return {
    type: ACTION_TYPES.MAPA_PDIS_RECORRIDO_OCULTAR
  }
}

function compartirMostrar () {
  return {
    type: ACTION_TYPES.COMPARTIR_MOSTRAR
  }
}

function compartirOcultar () {
  return {
    type: ACTION_TYPES.COMPARTIR_OCULTAR
  }
}

function compartirInsertarToggle () {
  return {
    type: ACTION_TYPES.COMPARTIR_INSERTAR_TOGGLE
  }
}

function modalCambiarVistaMostrar () {
  return {
    type: ACTION_TYPES.MODAL_CAMBIAR_VISTA_MOSTRAR
  }
}

function modalCambiarVistaOcultar () {
  return {
    type: ACTION_TYPES.MODAL_CAMBIAR_VISTA_OCULTAR
  }
}

function seleccionarCapaFondo (capa) {
  return {
    type: ACTION_TYPES.SELECCIONAR_CAPA_FONDO,
    capa
  }
}

function seleccionarPuntoKm (punto) {
  return {
    type: ACTION_TYPES.SELECCIONAR_PUNTO_KM,
    punto
  }
}

function modalPerfilesMostrar () {
  return {
    type: ACTION_TYPES.MODAL_PERFILES_MOSTRAR
  }
}

function modalPerfilesOcultar () {
  return {
    type: ACTION_TYPES.MODAL_PERFILES_OCULTAR
  }
}

function setGraficoDomain (domain) {
  return {
    type: ACTION_TYPES.SET_GRAFICO_DOMAIN,
    domain
  }
}

function setGraficoZoomDomain (domain) {
  return {
    type: ACTION_TYPES.SET_GRAFICO_ZOOM_DOMAIN,
    domain
  }
}

function setLastScreen (screen = null) {
  return {
    type: ACTION_TYPES.SET_LAST_SCREEN,
    lastScreen: screen
  }
}

function setPdiFocused (pdi) {
  return {
    type: ACTION_TYPES.SET_PDI_FOCUSED,
    pdi: pdi
  }
}

function mapZoomChanged (zoom) {
  return {
    type: ACTION_TYPES.MAP_ZOOM_CHANGED,
    zoom
  }
}

function submenuHerramientasMostrar () {
  return {
    type: ACTION_TYPES.SUBMENU_HERRAMIENTAS_MOSTRAR
  }
}

function submenuHerramientasOcultar () {
  return {
    type: ACTION_TYPES.SUBMENU_HERRAMIENTAS_OCULTAR
  }
}

function setIndexPdiOrden (index) {
  return {
    type: ACTION_TYPES.SET_INDEX_PDI_ORDEN,
    index
  }
}

function animacionOn (noPausa) {
  return {
    type: ACTION_TYPES.ANIMACION_ON,
    noPausa
  }
}

function animacionOff () {
  return {
    type: ACTION_TYPES.ANIMACION_OFF
  }
}

function setEsquemaAnimacion (esquema, velocidad, zoom) {
  return {
    type: ACTION_TYPES.SET_ESQUEMA_ANIMACION,
    esquema,
    velocidad,
    zoom
  }
}

function setRepeticionAnimacion (repeticion) {
  return {
    type: ACTION_TYPES.SET_REPETICION_ANIMACION,
    repeticion
  }
}

function setDistanciaAnimada (distancia) {
  return {
    type: ACTION_TYPES.SET_DISTANCIA_ANIMADA,
    distancia
  }
}

function addTrayectoYaAnimado (trayectoId) {
  return {
    type: ACTION_TYPES.ADD_TRAYECTO_YA_ANIMADO,
    trayectoId
  }
}

function animacionPausadaOn () {
  return {
    type: ACTION_TYPES.ANIMACION_PAUSADA_ON
  }
}

function animacionPausadaOff () {
  return {
    type: ACTION_TYPES.ANIMACION_PAUSADA_OFF
  }
}

function setMultiplicadorVelocidadAnim (multiplo) {
  return {
    type: ACTION_TYPES.SET_MULTIPLICADOR_VELOCIDAD_ANIM,
    multiplo
  }
}

function submenuVelocidadMostrar () {
  return {
    type: ACTION_TYPES.SUBMENU_VELOCIDAD_MOSTRAR
  }
}

function submenuVelocidadOcultar () {
  return {
    type: ACTION_TYPES.SUBMENU_VELOCIDAD_OCULTAR
  }
}

function animacionBucleOn () {
  return {
    type: ACTION_TYPES.ANIMACION_BUCLE_ON
  }
}

function animacionBucleOff () {
  return {
    type: ACTION_TYPES.ANIMACION_BUCLE_OFF
  }
}

function animacionPausadaAutoOn () {
  return {
    type: ACTION_TYPES.ANIMACION_PAUSADA_AUTO_ON
  }
}

function animacionPausadaAutoOff () {
  return {
    type: ACTION_TYPES.ANIMACION_PAUSADA_AUTO_OFF
  }
}

function setFiltroPdis (filtro) {
  return {
    type: ACTION_TYPES.SET_FILTRO_PDIS,
    filtro
  }
}

function setFiltroPdisRecorrido (filtro) {
  return {
    type: ACTION_TYPES.SET_FILTRO_PDIS_RECORRIDO,
    filtro
  }
}

function setPdiDestacado (pdi = null) {
  return {
    type: ACTION_TYPES.SET_PDI_DESTACADO,
    pdi
  }
}

function addPuntoAnimado (punto) {
  return {
    type: ACTION_TYPES.ADD_PUNTO_ANIMADO,
    punto
  }
}

function setDistanciasPuntos (distancias) {
  return {
    type: ACTION_TYPES.SET_DISTANCIAS_PUNTOS,
    distancias
  }
}

function setSiguienteDistanciaAnimada () {
  return {
    type: ACTION_TYPES.SET_SIGUIENTE_DISTANCIA_ANIMADA
  }
}

function setLocation (location) {
  return {
    type: ACTION_TYPES.SET_LOCATION,
    location
  }
}

function addCapaOculta (capa) {
  return {
    type: ACTION_TYPES.ADD_CAPA_OCULTA,
    capa
  }
}

function removeCapaOculta (capa) {
  return {
    type: ACTION_TYPES.REMOVE_CAPA_OCULTA,
    capa
  }
}

function resetCapasOcultas () {
  return {
    type: ACTION_TYPES.RESET_CAPAS_OCULTAS
  }
}

function localizacionDescentradaOn () {
  return {
    type: ACTION_TYPES.LOCALIZACION_DESCENTRADA_ON
  }
}

function localizacionDescentradaOff () {
  return {
    type: ACTION_TYPES.LOCALIZACION_DESCENTRADA_OFF
  }
}

export const actions = {
  modalEsquemaOcultar,
  modalEsquemaMostrar,
  modalPdisMostrar,
  modalPdisOcultar,
  modalPdisProyectoMostrar,
  modalPdisProyectoOcultar,
  modalPdiMostrar,
  modalPdiOcultar,
  submenuAjustesVisualesMostrar,
  submenuAjustesVisualesOcultar,
  submenuPdisMostrar,
  submenuPdisOcultar,
  setMapBounds,
  mapaPdisProyectoMostrar,
  mapaPdisProyectoOcultar,
  mapaPdisRecorridoMostrar,
  mapaPdisRecorridoOcultar,
  compartirMostrar,
  compartirOcultar,
  modalCambiarVistaMostrar,
  modalCambiarVistaOcultar,
  seleccionarCapaFondo,
  modalPerfilesMostrar,
  modalPerfilesOcultar,
  seleccionarPuntoKm,
  setGraficoDomain,
  setGraficoZoomDomain,
  compartirInsertarToggle,
  setLastScreen,
  setPdiFocused,
  mapZoomChanged,
  submenuHerramientasMostrar,
  submenuHerramientasOcultar,
  setIndexPdiOrden,
  animacionOn,
  animacionOff,
  setEsquemaAnimacion,
  setRepeticionAnimacion,
  setDistanciaAnimada,
  addTrayectoYaAnimado,
  animacionPausadaOn,
  animacionPausadaOff,
  setMultiplicadorVelocidadAnim,
  submenuVelocidadMostrar,
  submenuVelocidadOcultar,
  animacionBucleOn,
  animacionBucleOff,
  animacionPausadaAutoOn,
  animacionPausadaAutoOff,
  setFiltroPdis,
  setFiltroPdisRecorrido,
  setPdiDestacado,
  addPuntoAnimado,
  setDistanciasPuntos,
  setSiguienteDistanciaAnimada,
  setLocation,
  addCapaOculta,
  removeCapaOculta,
  resetCapasOcultas,
  localizacionDescentradaOn,
  localizacionDescentradaOff
}

export default actions
