import React from 'react'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import imgObras from '../../lib/images/EnConstruccion.png'

import './EnObras.css'

const EnObras = () => {
  return (
    <div className=' d-flex flex-column w-100 h-100 justify-content-center align-items-center position-absolute'>
      <Link className='position-absolute obras__cerrar' to='/buscar'><i className='fas fa-times fa-2x' /></Link>
      <img className='obras__img' src={imgObras} alt='En obras' />
      <h2 className='font-weight-bold text-center mt-4'><Trans>Estamos trabajando<br />en este recorrido!</Trans></h2>
    </div>
  )
}

export default EnObras
