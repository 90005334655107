import React, { Component } from 'react'
import { connect } from 'react-redux'

import HeaderPublico from '../components/PublicHeader'
import PublicBody from '../components/PublicBody'
import Footer from '../components/Footer'
import { Trans } from 'react-i18next'

import './PedidoScreen.css'
import { fetchRemoteUsuario } from '../actions/usuario'
import { REQUEST_STATUS } from '../constants/state'
import { actions, createEncargo } from '../actions/encargo'

class PedidoScreen extends Component {
  constructor (props) {
    super(props)
    this.refForm = React.createRef()
  }

  componentDidMount () {
    const { dispatch } = this.props

    dispatch(fetchRemoteUsuario())
    dispatch(actions.resetEncargo())
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { createEncargoSucceeded, createEncargoFailed, userError } = this.props
    if ((createEncargoSucceeded && !prevProps.createEncargoSucceeded) || (createEncargoFailed && !prevProps.createEncargoFailed)) {
      window.scrollTo(0, 0)
    }
    if (userError && !prevProps.userError) {
      window.location.href = '/usuario/entrar/?next=/pedido'
    }
  }

  validaForm () {
    const { dispatch, user } = this.props
    const nombreProyecto = document.getElementById('nombre-proyecto')
    const fichero = document.getElementById('fichero')
    if (!nombreProyecto.value || nombreProyecto.value === '') {
      window.alert('El nombre del proyecto es necesario')
      return
    }
    if (!fichero.files.length === 0) {
      window.alert('El fichero es necesario')
      return
    }

    // eslint-disable-next-line no-undef
    const formData = new FormData(this.refForm.current)
    formData.append('user', user.id)
    dispatch(createEncargo(formData))
  }

  render () {
    const { user, createEncargoSucceeded, createEncargoFailed } = this.props

    if (!user) {
      return <></>
    }
    return (
      <PublicBody>
        <HeaderPublico portada user={user} languages />
        {createEncargoSucceeded &&
          <div className='alert alert-success'>
            <Trans>El pedido ha sido registrado. En breve nos pondremos en contacto contigo.</Trans>
          </div>}
        {createEncargoFailed &&
          <div className='alert alert-danger'>
            <Trans>Se ha producido un error mientras intentábamos registrar tu pedido. Inténtalo de nuevo.</Trans>
          </div>}
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-3'>
              <h2><Trans>Encarganos aquí tu proyecto</Trans></h2>
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-flex align-items-center justify-content-center mt-5 mb-3'>
              <span className='fa fa-stack fa-3x pedido__icono'>
                <i className='fas fa-circle fa-stack-2x' />
                <i className='fas fa-pencil-ruler fa-stack-1x fa-inverse' />
              </span>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-8 offset-2 col-md-6 offset-md-3'>
              <p>
                <Trans>
                  msg-pedido
                </Trans>
              </p>
            </div>
          </div>
          <div className='row mb-5'>
            <div className='col-12 offset-0 col-md-8 offset-md-2'>
              <form className='pedido__form' ref={this.refForm} encType='multipart/form-data'>
                <div className='form-group'>
                  <label htmlFor='nombre-proyecto'><Trans>Nombre del proyecto *</Trans></label>
                  <input
                    type='text' className='form-control' id='nombre-proyecto'
                    aria-describedby='nombre-pro-help' name='nombre_proyecto' required
                  />
                  <small id='nombre-pro-help' className='form-text text-muted'>
                    <Trans>nombre evento</Trans>
                  </small>
                </div>
                <div className='form-group'>
                  <label htmlFor='telefono'><Trans>Número de telefono</Trans></label>
                  <input type='text' className='form-control' id='telefono' name='telefono' />
                </div>
                <div className='form-group'>
                  <label htmlFor='fichero'><Trans>Fichero *</Trans></label>
                  <input
                    type='file' className='form-control-file' id='fichero' name='fichero'
                    aria-describedby='help-fichero' required
                  />
                  <small id='fichero-help' className='form-text text-muted'>
                    <Trans>fichero ayuda</Trans>
                  </small>
                </div>
                <div className='form-group'>
                  <label htmlFor='descripcion'><Trans>Descripción</Trans></label>
                  <textarea className='form-control' id='descripcion' name='descripcion' rows='5' />
                </div>
              </form>
              <button onClick={this.validaForm.bind(this)} className='btn btn-secondary'><Trans>Enviar</Trans></button>
            </div>
          </div>
        </div>
        <Footer user={user} />
      </PublicBody>
    )
  }
}

const mapStateToProps = state => ({
  user: state.usuario.data,
  userError: state.usuario.error,
  createEncargoSucceeded: state.createEncargo.status === REQUEST_STATUS.SUCCEEDED,
  createEncargoFailed: state.createEncargo.status === REQUEST_STATUS.FAILED
})

export default connect(mapStateToProps)(PedidoScreen)
