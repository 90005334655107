import React from 'react'

const SubmenuVelocidad = props => {
  const velocidades = [4, 2, 1, 0.5, 0.25]
  return (
    <>
      {velocidades.map(v =>
        <div key={v} className='mapa-submenu sub-heramientas aparece-abajo'>
          <ul className='btn-group boton-visor__group'>
            <li className='btn boton-visor boton-visor--small'>
              <button onClick={() => props.onClickMultiplicado(v)}>
                <i className='boton-visor__icon-texto'>{v} x</i>
              </button>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

export default SubmenuVelocidad
