import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/mapaPortada'

export default function mapaPortadaReducer (state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_RECORRIDO_SELECCIONADO:
      return {
        ...state,
        recorridoSeleccionado: action.recorrido
      }

    default:
      return {
        ...state
      }
  }
}
