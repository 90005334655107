import React from 'react'
import notFoundImg from '../lib/images/defaultmap2.png'
import { withTranslation } from 'react-i18next'

import checkAzul from '../lib/images/Check_azul.png'

const RecorridoItem = ({ t, recorrido, metrosToUnitsRecorrido, proyecto }) => {
  const getAlturaMaxima = sec => Math.max(...sec.trayectos.map(tr => tr.altura_maxima)).toFixed(0) + ' m'
  const getAlturaMinima = sec => Math.min(...sec.trayectos.map(tr => tr.altura_minima)).toFixed(0) + ' m'
  const getDesnivelPositivo = sec => {
    let desnivel = 0
    sec.esquemas.forEach(esq => {
      desnivel += esq.trayecto.desnivel_acumulado_positivo * esq.repeticiones
    })
    return desnivel.toFixed(0) + ' m'
  }
  const getDesnivelNegativo = sec => {
    let desnivel = 0
    sec.esquemas.forEach(esq => {
      desnivel += esq.trayecto.desnivel_acumulado_negativo * esq.repeticiones
    })
    return desnivel.toFixed(0) + ' m'
  }

  const getNameDificultad = dificultad => {
    switch (dificultad) {
      case 'mf':
        return t('Muy fácil')
      case 'f':
        return t('Fácil')
      case 'm':
        return t('Moderado')
      case 'd':
        return t('Difícil')
      default:
        return t('Muy difícil')
    }
  }

  const getTipoDificultad = dificultad => {
    switch (dificultad) {
      case 'mf':
        return (
          <>
            <i className='fas fa-circle recorrido-list-dificultad muyfacil mr-1' />
            {[1, 2, 3, 4].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad muyfacil mr-1' />
            )}
          </>
        )
      case 'f':
        return (
          <>
            {[1, 2].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad facil mr-1' />
            )}
            {[1, 2, 3].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad facil mr-1' />
            )}
          </>
        )
      case 'm':
        return (
          <>
            {[1, 2, 3].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad moderado mr-1' />
            )}
            {[1, 2].map(i =>
              <i key={i} className='far fa-circle recorrido-list-dificultad moderado mr-1' />
            )}
          </>
        )
      case 'd':
        return (
          <>
            {[1, 2, 3, 4].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad dificil mr-1' />
            )}
            <i className='far fa-circle recorrido-list-dificultad dificil mr-1' />
          </>
        )
      default:
        return (
          <>
            {[1, 2, 3, 4, 5].map(i =>
              <i key={i} className='fas fa-circle recorrido-list-dificultad muydificil mr-1' />
            )}
          </>
        )
    }
  }

  const getColorTexto = color => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color)
    const rgb = {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    }
    const brillo = Math.round((rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000)
    return brillo > 75 ? '#000' : '#fff'
  }

  return (
    <>
      {recorrido.captura_2x1
        ? (
          <div
            className='recorrido-list__captura' style={{ backgroundImage: 'url(' + recorrido.captura_2x1 + ')' }}
          >
            {proyecto
              ? (
                <>
                  <div className='recorrido-list__proyecto-nombre'>{recorrido.proyecto_nombre}</div>
                  <div className='recorrido-list__recorrido-nombre recorrido-list__recorrido-nombre--canal'>
                    {recorrido.nombre}
                    {recorrido.oficial && <img className='ml-2 recorrido-list__check' src={checkAzul} alt='check' />}
                  </div>
                </>
              ) : (
                <div className='recorrido-list__recorrido-nombre'>
                  {recorrido.nombre}
                  {recorrido.oficial && <img className='ml-2 recorrido-list__check' src={checkAzul} alt='check' />}
                </div>
              )}
          </div>)
        : (
          <div className='recorrido-list__captura' style={{ backgroundImage: 'url(' + notFoundImg + ')' }}>
            {proyecto
              ? (
                <>
                  <div className='recorrido-list__proyecto-nombre'>{recorrido.proyecto_nombre}</div>
                  <div className='recorrido-list__recorrido-nombre recorrido-list__recorrido-nombre--canal'>
                    {recorrido.nombre}
                  </div>
                </>
              ) : <div className='recorrido-list__recorrido-nombre'> {recorrido.nombre}</div>}
          </div>
        )}
      {recorrido.sectores.length === 1
        ? (
          <>
            {(recorrido.sectores[0].esquemas.length > 1 || recorrido.sectores[0].esquemas.repeticiones > 1) &&
              <div className='recorrido-list__esquema'>
                {recorrido.sectores[0].esquemas.map(esquema =>
                  <div
                    className='recorrido-list__trayecto' key={esquema.id}
                    style={{
                      backgroundColor: esquema.trayecto.color,
                      color: getColorTexto(esquema.trayecto.color)
                    }}
                  >
                    x{esquema.repeticiones}
                  </div>
                )}
              </div>}
            <div className='recorrido-list__datos'>
              <div
                className='recorrido-list__deporte_icono'
                dangerouslySetInnerHTML={{ __html: recorrido.deporte_icono }}
              />
              <div className='recorrido-list__datos_arriba'>
                <span>
                  <i className='mdi mdi-arrow-expand-horizontal mr-1' />
                  <strong>
                    {metrosToUnitsRecorrido(
                      recorrido, recorrido.distancia_metros
                        ? recorrido.distancia_metros
                        : recorrido.sectores[0].distancia_metros
                    )}
                  </strong>
                </span>
                <span title={getNameDificultad(recorrido.dificultad)}>
                  <strong>{getTipoDificultad(recorrido.dificultad)}</strong>
                </span>
                {recorrido.superficie &&
                  <span><strong>{recorrido.superficie}</strong></span>}
              </div>
              <div className='recorrido-list__datos_abajo'>
                <span><i className='mdi mdi-format-vertical-align-top' />
                  {getAlturaMaxima(recorrido.sectores[0])}
                </span>
                <span><i className='mdi mdi-format-vertical-align-bottom' />
                  {getAlturaMinima(recorrido.sectores[0])}
                </span>
                <span><i className='mdi mdi-trending-up' />
                  {getDesnivelPositivo(recorrido.sectores[0])}
                </span>
                <span><i className='mdi mdi-trending-down' />
                  {getDesnivelNegativo(recorrido.sectores[0])}
                </span>
              </div>
            </div>
          </>
        )
        : (
          <>
            {recorrido.sectores.map(sector =>
              <div key={sector.id} className='recorrido-list__datos'>
                {sector.deporte
                  ? (
                    <div
                      className='recorrido-list__deporte_icono'
                      style={{ color: sector.color, backgroundColor: getColorTexto(sector.color) }}
                      dangerouslySetInnerHTML={{ __html: sector.deporte.icono_svg }}
                    />
                  ) : (
                    <div
                      className='recorrido-list__deporte_icono'
                      style={{ color: sector.color, backgroundColor: getColorTexto(sector.color) }}
                    >
                      <i className='recorrido-list__deporte_icono_default fas fa-asterisk' />
                    </div>
                  )}
                <div className='recorrido-list__datos_arriba'>
                  <span>
                    <i className='mdi mdi-arrow-expand-horizontal mr-1' /><strong>{metrosToUnitsRecorrido(recorrido, sector.distancia_metros)}</strong>
                  </span>
                </div>
                <div className='recorrido-list__datos_abajo'>
                  <span><i className='mdi mdi-format-vertical-align-top' />{getAlturaMaxima(sector)}</span>
                  <span><i className='mdi mdi-format-vertical-align-bottom' />{getAlturaMinima(sector)}</span>
                  <span><i className='mdi mdi-trending-up' />{getDesnivelPositivo(sector)}</span>
                  <span><i className='mdi mdi-trending-down' />{getDesnivelNegativo(sector)}</span>
                </div>
              </div>)}
          </>)}
    </>
  )
}

export default withTranslation()(RecorridoItem)
