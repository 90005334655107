import React from 'react'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import './RecorridoListCanal.css'
import RecorridoItem from '../RecorridoItem'

const RecorridoListCanal = ({ t, recorridos, metrosToUnitsRecorrido, onHoverRecorridoItem, lista }) => {
  return (
    <div className='container canal-tabs__container-tab'>
      <ul className='recorrido-list scroll-vertical'>
        {recorridos.map((rec, i) =>
          <li key={rec.id}>
            <Link
              to={'/recorrido/' + rec.id + (lista ? '/' + lista.id : '')}
              onMouseOver={onHoverRecorridoItem ? () => onHoverRecorridoItem(rec) : undefined}
              onMouseOut={onHoverRecorridoItem ? () => onHoverRecorridoItem() : undefined}
            >
              <RecorridoItem recorrido={rec} metrosToUnitsRecorrido={metrosToUnitsRecorrido} proyecto />
            </Link>
          </li>
        )}
      </ul>
    </div>
  )
}

export default withTranslation()(RecorridoListCanal)
