export const ACTION_TYPES = {
  SET_INDEX_PDI_SELECCIONADO: 'SET_INDEX_PDI_SELECCIONADO',
  SELECCIONAR_PDI_DETALLE: 'SELECCIONAR_PDI_DETALLE',
  COMPARTIR_MOSTRAR: 'COMPARTIR_MOSTRAR',
  COMPARTIR_OCULTAR: 'COMPARTIR_OCULTAR'
}

function setIndexPdiSeleccionado (index) {
  return {
    type: ACTION_TYPES.SET_INDEX_PDI_SELECCIONADO,
    index
  }
}

function seleccionarPdiDetalle (pdi) {
  return {
    type: ACTION_TYPES.SELECCIONAR_PDI_DETALLE,
    pdi
  }
}

function compartirMostrar () {
  return {
    type: ACTION_TYPES.COMPARTIR_MOSTRAR
  }
}

function compartirOcultar () {
  return {
    type: ACTION_TYPES.COMPARTIR_OCULTAR
  }
}

export const actions = {
  setIndexPdiSeleccionado,
  seleccionarPdiDetalle,
  compartirMostrar,
  compartirOcultar
}
