import { Component } from 'react'
import { mapkitMutant } from '../../lib/mapkitMutant'

export default class AppleLayer extends Component {
  componentDidMount () {
    const { layer, map, attribution, token } = this.props
    this.layer = mapkitMutant({
      // valid values for 'type' are 'default', 'satellite' and 'hybrid'
      type: layer,

      authorizationCallback: function (done) {
        done(token)
      },
      language: 'es',

      // For debugging purposes only. Displays a L.Rectangle on the
      // visible bounds ("region") of the mutant.
      debugRectangle: false,
      attribution: attribution
    })
    map.current.leafletElement.addLayer(this.layer)
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { map, layer, attribution, token } = this.props
    map.current.leafletElement.removeLayer(this.layer)
    this.layer = mapkitMutant({
      // valid values for 'type' are 'default', 'satellite' and 'hybrid'
      type: layer,

      authorizationCallback: function (done) {
        done(token)
      },
      language: 'es',

      // For debugging purposes only. Displays a L.Rectangle on the
      // visible bounds ("region") of the mutant.
      debugRectangle: false,
      attribution: attribution
    })
    map.current.leafletElement.addLayer(this.layer)
  }

  componentWillUnmount () {
    const { map } = this.props
    if (map.current) map.current.leafletElement.removeLayer(this.layer)
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    const { layer } = this.props
    if (layer !== nextProps.layer) return true
    else return false
  }

  render () {
    return null
  }
}
