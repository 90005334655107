/* global fetch */
import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_USUARIO_BEGIN: 'FETCH_USUARIO_BEGIN',
  FETCH_USUARIO_SUCCESS: 'FETCH_USUARIO_SUCCESS',
  FETCH_USUARIO_ERROR: 'FETCH_USUARIO_ERROR',
  FETCH_OTRO_USUARIO_BEGIN: 'FETCH_OTRO_USUARIO_BEGIN',
  FETCH_OTRO_USUARIO_SUCCESS: 'FETCH_OTRO_USUARIO_SUCCESS',
  FETCH_OTRO_USUARIO_ERROR: 'FETCH_OTRO_USUARIO_ERROR'
}

function fetchUsuarioError (error) {
  return {
    type: ACTION_TYPES.FETCH_USUARIO_ERROR,
    error: error,
    pendiente: false,
    data: null
  }
}

function fetchUsuarioSuccess (data) {
  return {
    type: ACTION_TYPES.FETCH_USUARIO_SUCCESS,
    data: data,
    pendiente: false,
    error: null
  }
}

function fetchUsuarioBegin () {
  return {
    type: ACTION_TYPES.FETCH_USUARIO_BEGIN,
    pendiente: true,
    error: null,
    data: null
  }
}

export function fetchRemoteUsuario () {
  return dispatch => {
    dispatch(fetchUsuarioBegin())
    return fetch(API_BASE_URL + 'usuario/yo/', {
      credentials: 'include'
    })
      .then(res => {
        if (res.status === 200) {
          return res.json()
            .then(data => dispatch(fetchUsuarioSuccess(data)))
        } else {
          return dispatch(fetchUsuarioError(res.status))
        }
      })
      .catch(error => {
        return dispatch(fetchUsuarioError(error))
      })
  }
}

function fetchOtroUsuarioBegin () {
  return {
    type: ACTION_TYPES.FETCH_OTRO_USUARIO_BEGIN
  }
}

function fetchOtroUsuarioSuccess (usuario) {
  return {
    type: ACTION_TYPES.FETCH_OTRO_USUARIO_SUCCESS,
    usuario
  }
}

function fetchOtroUsuarioError (error) {
  return {
    type: ACTION_TYPES.FETCH_OTRO_USUARIO_ERROR,
    error
  }
}

export default function fetchOtroUsuario (params = {}, fields = []) {
  return dispatch => {
    dispatch(fetchOtroUsuarioBegin())
    const url = new URL(API_BASE_URL + 'usuario/')
    if (fields && fields.length) {
      params.fields = fields.join(',')
    }
    Object.keys(params).forEach(
      key => url.searchParams.append(key, params[key])
    )
    return fetch(url)
      .then(res => res.json())
      .then(res => {
        if (res.error) {
          throw res.error
        } else if (res) {
          dispatch(fetchOtroUsuarioSuccess(res))
        } else {
          throw new Error('Not found')
        }
      })
      .catch(error => {
        dispatch(fetchOtroUsuarioError(error))
      })
  }
}
