import React, { Component } from 'react'
import { connect } from 'react-redux'
import actions from '../../actions/visor'
import * as turf from '@turf/turf'

class GestorAnimacion extends Component {
  constructor (props) {
    super(props)
    this.pausaTrayectos = 3000
    this.stopFpsMeter = false
  }

  componentDidMount () {
    const { dispatch, recorrido } = this.props
    this.indexSectorActual = 0
    this.calculateDistanciasPuntos()
    if (recorrido.sectores.length > 0 || recorrido.sectores[0].esquemas.length > 0) {
      const v = this.getVelocidadAnimacion(recorrido.sectores[this.indexSectorActual], recorrido.sectores[0].esquemas[0])
      const z = this.getZoomAnimacion(recorrido.sectores[this.indexSectorActual], recorrido.sectores[0].esquemas[0])
      dispatch(actions.setEsquemaAnimacion(recorrido.sectores[0].esquemas[0], v, z))
    } else {
      dispatch(actions.setEsquemaAnimacion(recorrido.sectores[0].esquemas[0]))
    }
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { visor, dispatch, recorrido } = this.props
    if (!visor.animacionPausada && !visor.animacionPausadaAuto) {
      this.gestionarPdisAnimados()
      if (visor.distanciaTrayectoAnimada < visor.esquemaEnAnimacion.trayecto.distancia_calculada) {
        if (visor.distanciaTrayectoAnimada === 0) {
          this.pausaEntreTrayectos()
        }
        window.requestAnimationFrame(() => {
          dispatch(actions.setSiguienteDistanciaAnimada())
        })
      } else {
        dispatch(actions.addTrayectoYaAnimado(visor.esquemaEnAnimacion.trayecto.id))
        const siguienteEsquema = this.getSiguienteEsquema()
        if (siguienteEsquema) {
          if (visor.esquemaEnAnimacion.sector !== siguienteEsquema.sector) {
            this.indexSectorActual++
          }
          const v = this.getVelocidadAnimacion(recorrido.sectores[this.indexSectorActual], siguienteEsquema)
          const z = this.getZoomAnimacion(recorrido.sectores[this.indexSectorActual], siguienteEsquema)
          dispatch(actions.setEsquemaAnimacion(siguienteEsquema, v, z))
        } else {
          dispatch(actions.animacionOff())
          if (visor.animacionBucle) {
            this.indexSectorActual = 0
            if (recorrido.sectores.length > 0 || recorrido.sectores[0].esquemas.length > 0) {
              const v = this.getVelocidadAnimacion(recorrido.sectores[0], recorrido.sectores[0].esquemas[0])
              const z = this.getZoomAnimacion(recorrido.sectores[0], recorrido.sectores[0].esquemas[0])
              dispatch(actions.setEsquemaAnimacion(recorrido.sectores[0].esquemas[0], v, z))
            } else {
              dispatch(actions.setEsquemaAnimacion(recorrido.sectores[0].esquemas[0]))
            }
            dispatch(actions.animacionOn(true))
            dispatch(actions.animacionPausadaAutoOn())
            setTimeout(() => {
              dispatch(actions.animacionPausadaAutoOff())
            }, this.pausaSectores)
          }
        }
      }
    }
  }

  gestionarPdisAnimados () {
    const { visor, dispatch } = this.props
    const puntosSinAnimar = visor.distanciasPuntos.filter(dp =>
      !visor.puntosAnimados.map(p => p.id).includes(dp.punto.id))
    for (const i in puntosSinAnimar) {
      if (visor.esquemaEnAnimacion.trayecto.id === puntosSinAnimar[i].punto.trayecto_ancla &&
        puntosSinAnimar[i].distancia <= visor.distanciaTrayectoAnimada) {
        if (puntosSinAnimar[i].punto.servicios.length > 0) {
          dispatch(actions.animacionPausadaAutoOn())
          setTimeout(() => {
            dispatch(actions.animacionPausadaAutoOff())
          }, 1500)
        }
        dispatch(actions.addPuntoAnimado(puntosSinAnimar[i].punto))
      }
    }
  }

  pausaEntreTrayectos () {
    const { dispatch } = this.props
    dispatch(actions.animacionPausadaAutoOn())
    setTimeout(() => {
      dispatch(actions.animacionPausadaAutoOff())
    }, this.pausaTrayectos)
  }

  getSiguienteEsquema () {
    const { recorrido, visor } = this.props
    for (let i = 0; i < recorrido.sectores.length; i++) {
      for (let j = 0; j < recorrido.sectores[i].esquemas.length; j++) {
        if (recorrido.sectores[i].esquemas[j].id === visor.esquemaEnAnimacion.id) {
          if (recorrido.sectores[i].esquemas[j + 1]) {
            return recorrido.sectores[i].esquemas[j + 1]
          } else if (recorrido.sectores[i + 1]) {
            return recorrido.sectores[i + 1].esquemas[0]
          }
        }
      }
    }
    return false
  }

  calculateDistanciasPuntos () {
    const { recorrido, dispatch } = this.props
    const distanciasPuntos = []
    recorrido.sectores.forEach(s => {
      s.puntos_km.forEach(pk => {
        const trayecto = s.trayectos.filter(tr => tr.id === pk.punto.trayecto_ancla)
        if (trayecto.length > 0) {
          const distancia = turf.length(
            turf.lineSlice(trayecto[0].coordenadas.coordinates[0], pk.punto.coordenadas, trayecto[0].coordenadas), {
              units: 'meters'
            })
          distanciasPuntos.push({
            punto: pk.punto,
            distancia
          })
        }
      })
      s.pdis_recorrido.forEach(p => {
        const trayecto = s.trayectos.filter(tr => tr.id === p.trayecto_ancla)
        if (trayecto.length > 0) {
          const distancia = turf.length(
            turf.lineSlice(trayecto[0].coordenadas.coordinates[0], p.coordenadas, trayecto[0].coordenadas), {
              units: 'meters'
            })
          distanciasPuntos.push({
            punto: p,
            distancia
          })
        }
      })
    })
    dispatch(actions.setDistanciasPuntos(distanciasPuntos))
  }

  getVelocidadAnimacion (sector, esquema) {
    const dist = esquema.distancia_metros // sector.esquemas.map(esq => esq.trayecto.distancia_metros).reduce((a, b) => a + b, 0)
    let velocidad = Math.floor(dist / 60 / 60)
    if (velocidad > 100) {
      velocidad = 100
    } else if (velocidad < 2.5) {
      velocidad = 2.5
    }
    if (window.innerWidth < 426 && velocidad > 35) {
      velocidad -= 30
    }
    return velocidad
  }

  getZoomAnimacion (sector, esquema) {
    let trayectos = []
    trayectos = trayectos.concat(sector.trayectos.filter(t => t.id === esquema.trayecto.id).map(tr => turf.envelope(tr.coordenadas)))
    let coords = []
    trayectos.forEach(trEnv => {
      trEnv.geometry.coordinates.forEach(c => {
        coords = coords.concat(c)
      })
    })
    const envelopeKm2 = turf.area(turf.envelope(turf.lineString(coords))) / 10000
    let zoom = 11
    if (envelopeKm2 > 100000000) {
      zoom = 11
    } else if (envelopeKm2 > 10000000) {
      zoom = 12
    } else if (envelopeKm2 > 1000000) {
      zoom = 13
    } else if (envelopeKm2 > 100000) {
      zoom = 14
    } else if (envelopeKm2 > 10000) {
      zoom = 15
    } else if (envelopeKm2 > 1000) {
      zoom = 16
    } else if (envelopeKm2 > 100) {
      zoom = 17
    } else if (envelopeKm2 > 10) {
      zoom = 18
    } else {
      zoom = 19
    }
    if (window.innerWidth < 426) {
      zoom -= 1
    }
    return zoom
  }

  render () {
    return <></>
  }
}

const mapStateToProps = state => ({
  visor: state.visor,
  recorrido: state.recorrido.data
})

export default connect(mapStateToProps)(GestorAnimacion)
