import React from 'react'
import { Link } from 'react-router-dom'
// import {Link} from 'react-router'
import './RecorridoList.css'
// import ImageFallback from '../ImgFallback'
import { withTranslation } from 'react-i18next'
import RecorridoItem from '../RecorridoItem'

function RecorridoList ({ t, proyecto, recorridos, metrosToUnitsRecorrido }) {
  // console.info([proyecto, recorridos, recorridos.length]
  if (proyecto && recorridos && recorridos.length) {
    return (
      <ul className='recorrido-list scroll-vertical'>
        {recorridos.map(recorrido => {
          const URL_MAPA = `/recorrido/${recorrido.id}-${proyecto.slug}-${recorrido.slug}`

          if (!recorrido.sectores) {
            return null
          }
          return (
            <li key={recorrido.id}>
              <Link to={URL_MAPA} className='recorrido-list__contenido'>
                <RecorridoItem recorrido={recorrido} metrosToUnitsRecorrido={metrosToUnitsRecorrido} />
              </Link>
            </li>
          )
        })}
      </ul>
    )
  } else {
    return <div className='recorrido-list' />
  }
}

export default withTranslation()(RecorridoList)
