import React, { Component } from 'react'
import { connect } from 'react-redux'

import { REQUEST_STATUS } from '../constants/state'
import { fetchRecorridoBegin, fetchRemoteRecorrido } from '../actions/recorrido'
import { Map } from 'react-leaflet'
import * as turf from '@turf/turf'
import * as L from 'leaflet'
import Trayecto from '../components/visor/Trayecto'

import './CapturaMapaScreen.css'

class CapturaMapaScreen extends Component {
  componentDidMount () {
    const { dispatch, match } = this.props
    dispatch(fetchRecorridoBegin())

    if (match && match.params && match.params.id) {
      dispatch(fetchRemoteRecorrido(match.params.id))
    }
  }

  render () {
    const { recorrido, recorridoCargado } = this.props
    if (recorridoCargado) {
      const trayectos = []
      recorrido.sectores.map(s => s.trayectos).flat(1).forEach(item => {
        // indexOf parece que non funciona en este caso..
        if (!trayectos.find(o => o.id === item.id)) {
          trayectos.push(item)
        }
      })
      const feCol = turf.featureCollection(trayectos.map(t => turf.lineString(t.coordenadas.coordinates)))
      const bounds = L.geoJSON(feCol).getBounds()
      return (
        <div className='mapa-fullscreen'>
          <Map
            center={[0, 0]} zoom={4} ref={this.mapRef}
            zoomControl={false}
            bounds={bounds}
          >
            {trayectos.map(t =>
              <Trayecto
                key={t.id}
                {...t}
                mapRef={this.mapRef}
              />
            )}
          </Map>
        </div>
      )
    } else {
      return <></>
    }
  }
}

const mapStateToProps = state => {
  return {
    error: state.error,
    recorrido: state.recorrido.data,
    recorridoCargado: state.recorrido.status === REQUEST_STATUS.SUCCEEDED
  }
}

export default connect(mapStateToProps)(CapturaMapaScreen)
