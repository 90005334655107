import React, { useEffect, useRef } from 'react'
import { Marker } from 'react-leaflet'
import { Icon } from '../../lib/leaflet.awesome-markers-svg'
import { connect } from 'react-redux'
import './Punto.css'

function Punto (props) {
  const refMarker = React.createRef()

  const prevPdiFocused = usePrevious(props.pdiFocused)

  useEffect(() => {
    if (props.pdiFocused && props.pdiFocused.id === props.id && (!prevPdiFocused || prevPdiFocused.id !== props.id)) {
      props.mapRef.current.leafletElement.flyTo([props.coordenadas.coordinates[1], props.coordenadas.coordinates[0]], 18)
    }
  })

  let classFondo = ' icono-' + props.icono.fondo.nombre
  if (props.servicios.length) {
    classFondo += ' con-servicios serv-' + props.servicios.length
  }
  if (props.distancia % 5 === 0) {
    classFondo += ' kmdiv5'
  }

  let icon = new Icon({
    iconSize: [props.icono.fondo.ancho, props.icono.fondo.alto],
    iconAnchor: [props.icono.fondo.centro_x, props.icono.fondo.centro_y],
    icon: props.icono.icono,
    iconColor: props.color_icono,
    prefix: props.icono.prefijo_fuente,
    markerColor: props.color,
    svg: props.icono.fondo.svg,
    fontSizeIcon: props.icono.fondo.tamano_fuente_icono,
    classFondo: classFondo,
    text: props.bloque.es_pk ? props.distancia : undefined,
    destacado: props.pdiFocused && props.pdiFocused.id === props.id
  })
  if (props.servicios.length === 1 && !props.bloque.es_pk && !props.bloque.automatico) {
    const servicio = props.servicios[0]

    classFondo = ' icono-' + servicio.icono.fondo.nombre
    if (props.servicios.length) {
      classFondo += ' con-servicios serv-' + props.servicios.length
    }

    icon = new Icon({
      iconSize: [servicio.icono.fondo.ancho, servicio.icono.fondo.alto],
      iconAnchor: [servicio.icono.fondo.centro_x, servicio.icono.fondo.centro_y],
      icon: servicio.icono.icono,
      iconColor: servicio.color_icono,
      prefix: servicio.icono.prefijo_fuente,
      markerColor: servicio.color_fondo,
      svg: servicio.icono.fondo.svg,
      fontSizeIcon: servicio.icono.fondo.tamano_fuente_icono,
      classFondo: classFondo,
      destacado: props.pdiFocused && props.pdiFocused.id === props.id
    })
  }

  const c = props.coordenadas.coordinates
  return (
    <Marker
      ref={refMarker}
      icon={icon}
      position={[c[1], c[0]]}
      onClick={() => props.onClick(props)}
    >
      {props.children}
    </Marker>
  )
}

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default connect()(Punto)
