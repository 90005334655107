/* global fetch */

import { API_BASE_URL } from '../constants/api'

export const ACTION_TYPES = {
  FETCH_SECTORES_BEGIN: 'FETCH_SECTORES_BEGIN',
  FETCH_SECTORES_SUCCESS: 'FETCH_SECTORES_SUCCESS',
  FETCH_SECTORES_ERROR: 'FETCH_SECTORES_ERROR',
  FETCH_SECTOR_BEGIN: 'FETCH_SECTOR_BEGIN',
  FETCH_SECTOR_SUCCESS: 'FETCH_SECTOR_SUCCESS',
  FETCH_SECTOR_ERROR: 'FETCH_SECTOR_ERROR',
  SET_SECTOR: 'SET_SECTOR'
}

export function fetchSectorBegin () {
  return {
    type: ACTION_TYPES.FETCH_SECTOR_BEGIN
  }
}

function fetchSectorSucess (sector) {
  return {
    type: ACTION_TYPES.FETCH_SECTOR_SUCCESS,
    sector: sector
  }
}

function fetchSectorError (err) {
  return {
    type: ACTION_TYPES.FETCH_SECTOR_ERROR,
    error: err
  }
}

export function fetchRemoteSector (id, fields = [], params = {}) {
  return dispatch => {
    const url = new URL(API_BASE_URL + 'sector/' + id + '/')

    for (const key in params) {
      url.searchParams.append(key, params[key])
    }

    if (fields && fields.length) {
      url.searchParams.append('fields', fields.join(','))
    }

    dispatch(fetchSectorBegin)
    fetch(url, {
      credentials: 'include'
    }).then(res => res.json()).then(res => {
      if (res.error) {
        throw res.error
      } else if (res) {
        dispatch(fetchSectorSucess(res))
      } else {
        throw new Error('Not found')
      }
    }).catch(error => {
      dispatch(fetchSectorError(error))
    })
  }
}

function setSector (sector) {
  return {
    type: ACTION_TYPES.SET_SECTOR,
    sector
  }
}

export const actions = {
  setSector
}
