import React from 'react'
import { render } from 'react-dom'
import { Helmet } from 'react-helmet'
import { I18nextProvider } from 'react-i18next'
import Provider from 'react-redux/es/components/Provider'
import { BrowserRouter as Router, Route } from 'react-router-dom'
import moment from 'moment'
import {} from 'moment/locale/es'
import * as ReactGA from 'react-ga'
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent'
// import {} from 'moment/locale/en'

import './index.css'
import * as serviceWorker from './serviceWorker'

import store from './store/configureStore'

import i18n from './i18n'
import { SERVER_DOMAIN, GOOGLE_ANALYTICS_ID } from './constants/api'

import HomeScreen from './screens/HomeScreen'
import BuscarScreen from './screens/BuscarScreen'
import ProyectoScreen from './screens/ProyectoScreen'
import PerfilScreen from './screens/PerfilScreen'
import MapaInteractivoScreen from './screens/MapaInteractivoScreen'
import RecorridoPerfilesScreen from './screens/RecorridoPerfilesScreen'
import CapturaMapaScreen from './screens/CapturaMapaScreen'
import CapturaPerfilScreen from './screens/CapturaPerfilScreen'
import CanalScreen from './screens/CanalScreen'
import ListaScreen from './screens/ListaScreen'
import PedidoScreen from './screens/PedidoScreen'
import ContactoAddScreen from './screens/ContactoAddScreen'
import MisContactosScreen from './screens/MisContactosScreen'
import MapaPersonalScreen from './screens/MapaPersonalScreen'
import MapaTraficoScreen from './screens/MapaTraficoScreen'
import MapaMontajeScreen from './screens/MapaMontajeScreen'

// Esto depende tamén do que cargemos en '/moment/locale/<loc>

const LANGUAGE_CODE = window.LANGUAGE_CODE || 'es'
moment.locale(LANGUAGE_CODE)

// https://tommcfarlin.com/check-if-a-page-is-in-an-iframe/
window.inIframe = window.location !== window.parent.location

// export const store = createStore()
// const history = syncHistoryWithStore(browserHistory, store);

// https://dev.to/ramonak/react-enable-google-analytics-after-a-user-grants-consent-5bg3
// Cookie consent
const handleAcceptAnalytics = () => {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID)
}

const handleDeclineAnalytics = () => {
  // remove google analytics cookies
  const cookies = document.cookie.split(';')
  cookies.forEach((cookie) => {
    cookie = cookie.trim()
    if (cookie.startsWith('_ga')) {
      Cookies.remove(cookie.split('=')[0])
    }
  })
  Cookies.remove('_gid')
}

// Cargamos google analytics sólo si se aceparon en sesións anteriores as suas cookies.
window.addEventListener('load', () => {
  const isConsent = getCookieConsentValue()
  if (isConsent === 'true') {
    handleAcceptAnalytics()
  }
})

render(
  <>
    <Helmet>
      <html lang='es' />
      <title>RaceMapp</title>
      <meta name='description' content='Encuentra el recorrido de tu prueba, interactivo y fácil.' />
    </Helmet>
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <Router>
          <Route exact path='/' component={HomeScreen} />
          <Route exact path='/buscar' component={BuscarScreen} />
          <Route exact path='/proyecto/:id(\d+)-**' component={ProyectoScreen} />
          <Route exact path='/recorrido/:id(\d+)-**' component={MapaInteractivoScreen} />
          <Route exact path='/recorrido/:id(\d+)-**/:idLista(\d+)' component={MapaInteractivoScreen} />
          <Route exact path='/recorrido/:id(\d+)' component={MapaInteractivoScreen} />
          <Route exact path='/recorrido/:id(\d+)/:idLista(\d+)' component={MapaInteractivoScreen} />
          <Route exact path='/perfiles/:id(\d+)' component={RecorridoPerfilesScreen} />
          <Route exact path='/perfil/:id(\d+)-**' component={PerfilScreen} />
          <Route exact path='/perfil/:id(\d+)' component={PerfilScreen} />
          <Route exact path='/canal/:id(\d+)' component={CanalScreen} />
          <Route exact path='/lista/:id(\d+)' component={ListaScreen} />
          <Route exact path='/pedido' component={PedidoScreen} />
          <Route exact path='/contactos' component={MisContactosScreen} />
          <Route exact path='/contactos/add' component={ContactoAddScreen} />
          <Route exact path='/personal/:id(\d+)' component={MapaPersonalScreen} />
          <Route exact path='/trafico/:id(\d+)' component={MapaTraficoScreen} />
          <Route exact path='/montaje/:id(\d+)' component={MapaMontajeScreen} />

          <Route exact path='/captura-mapa/:id(\d+)' component={CapturaMapaScreen} />
          <Route exact path='/captura-perfil/:id(\d+)' component={CapturaPerfilScreen} />
        </Router>
      </Provider>
      {(navigator.userAgent.indexOf('HeadlessChrome') === -1) &&
        <CookieConsent
          cookieName='consent'
          location='bottom'
          buttonText='Aceptar todas'
          onAccept={handleAcceptAnalytics}
          enableDeclineButton
          declineButtonClasses='btn-secondary '
          declineButtonText='Aceptar sólo las necesarias'
          onDecline={handleDeclineAnalytics}
          /* @todo: Meter este z-index en algun sitio? (1000 e o z-index máximo de leaflet) */
          style={{ zIndex: 1001 }}
          containerClasses='px-md-5 py-md-4'
          sameSite='none'
        >
          <p><b>Esta página web usa cookies</b></p>
          <p>
            RaceMapp utiliza las cookies necesarias para el correcto funcionamiento del sitio web.
            Es posible que además se utilicen otras categorías como las de estadísticas, para
            entender como interactúan los visitantes con las páginas reuniendo información de
            forma anónima (
            <a style={{ color: '#FFCC00' }} href='/politica-de-privacidad#info-cookies' target='_blank'>Mas información</a>
            ).
            <br />
            También puedes consultar nuestra
            <a style={{ color: '#FFCC00' }} href='/informacion-proteccion-datos'>política de privacidad</a>.
          </p>
        </CookieConsent>}
    </I18nextProvider>
  </>,
  document.getElementById('root')
)

// Cargamos o CSS para os iconos, que depende de SERVER_DOMAIN
const sheet = document.createElement('link')
sheet.rel = 'stylesheet'
sheet.href = SERVER_DOMAIN + '/cssiconos.css'
sheet.type = 'text/css'
document.head.appendChild(sheet)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister() // Para non interceptar as peticións reales ó servidor
// serviceWorker.register()
