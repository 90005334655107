import React from 'react'
import { connect } from 'react-redux'
import PerfilConInfo from '../components/perfil/PerfilConInfo'
import { PerfilScreenClass } from './PerfilScreen'
import { REQUEST_STATUS } from '../constants/state'
import NotFound from '../components/NotFound'
import Compartir from '../components/Compartir'
import { Spinner } from 'react-bootstrap'
import { Trans } from 'react-i18next'

import './CapturaPerfilScreen.css'

class CapturaPerfilScreen extends PerfilScreenClass {
  render () {
    const {
      sector, recorrido, proyecto, proyectoError, visor, dispatch, perfil
    } = this.props

    if (proyectoError) {
      return <NotFound error={proyectoError} />
    }

    if (perfil.compartirVisible) {
      return <Compartir clickCerrar={this.handleClickOcultarCompartir.bind(this)} />
    }

    if (sector && recorrido && proyecto) {
      // Os trayectos poden estar repetidos en un sector,
      // polo que os simplificamos eliminando duplicados
      const trayectos = []
      sector.trayectos.flat(1).forEach(item => {
        // indexOf parece que non funciona en este caso..
        if (!trayectos.find(o => o.id === item.id)) {
          trayectos.push(item)
        }
      })

      return (
        <div className='visor__layout visor__layout--captura'>
          <div className='visor__main visor__main--perfil'>
            <PerfilConInfo
              sector={sector}
              trayectos={trayectos}
              visor={visor}
              dispatch={dispatch}
              recorrido={recorrido}
              proyecto={proyecto}
              handleClickPdiDetalle={this.handleClickPdiDetalle.bind(this)}
              perfil={perfil}
              getPasosDistanciasPdi={this.getPasosDistanciasPdi.bind(this)}
              getAltiudPunto={this.getAltiudPunto.bind(this)}
              toFixed={this.toFixed.bind(this)}
            />
          </div>
        </div>
      )
    }
    return (
      <div className='fondo-carga'>
        <Spinner className='spinner-carga' animation='border' role='status'>
          <span className='sr-only'><Trans>Cargando...</Trans></span>
        </Spinner>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    visor: state.visor,
    perfil: state.perfil,
    error: state.error,
    sector: state.sector.data,
    sectorCargado: state.sector.status === REQUEST_STATUS.SUCCEEDED,
    recorrido: state.recorrido.data,
    recorridoCargado: state.recorrido.status === REQUEST_STATUS.SUCCEEDED,
    mapa: state.mapa.data,
    mapaCargado: state.mapa.status === REQUEST_STATUS.SUCCEEDED,
    proyecto: state.proyecto.data,
    proyectoError: state.proyecto.error
  }
}

export default connect(mapStateToProps)(CapturaPerfilScreen)
