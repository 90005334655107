import { ACTION_TYPES } from '../actions/mapa'
import { initialState, REQUEST_STATUS } from '../constants/state'

export function mapasReducer (state = initialState.mapas, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPAS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }
    case ACTION_TYPES.FETCH_MAPAS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapas
      }
    case ACTION_TYPES.FETCH_MAPAS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export function mapaReducer (state = initialState.mapa, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_MAPA_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }
    case ACTION_TYPES.FETCH_MAPA_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.mapa
      }
    case ACTION_TYPES.FETCH_MAPA_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return state
  }
}
