import { initialState } from '../constants/state'
import { ACTION_TYPES } from '../actions/proyectoScreen'

export default function proyectoScreenReducer (state = initialState.proyectoScreen, action) {
  switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_MAPA:
      return {
        ...state,
        currentMapa: action.mapa
      }

    default:
      return {
        ...state
      }
  }
}
