import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'

import fetchOtroUsuario, { fetchRemoteUsuario } from '../actions/usuario'
import { SERVER_DOMAIN } from '../constants/api'
import PublicBody from '../components/PublicBody'
import HeaderPublico from '../components/PublicHeader'
import { REQUEST_STATUS } from '../constants/state'
import FichaContacto from '../components/contacto/FichaContacto'
import { fetchContactos } from '../actions/contacto'
import fetchNotificaciones from '../actions/notificacion'

class ContactoAddScreen extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: false
    }
    this.refBuscar = React.createRef()
  }

  componentDidMount () {
    const { usuario, dispatch } = this.props
    if (!usuario) {
      dispatch(fetchRemoteUsuario())
    } else {
      dispatch(fetchContactos({ user: usuario.id }))
    }
    this.setState({
      email: false
    })
  }

  componentDidUpdate (prevProps, prevState, snapshot) {
    const { usuario, dispatch } = this.props
    if (!prevProps.usuario && usuario) {
      dispatch(fetchContactos({ user: usuario.id }))
      dispatch(fetchNotificaciones({ usuario: usuario.id }))
      this.intervalNoti = setInterval(() => {
        dispatch(fetchNotificaciones({ usuario: usuario.id }))
      }, 30 * 1000)
    }
  }

  componentWillUnmount () {
    clearInterval(this.intervalNoti)
  }

  onSubmitBuscar (e) {
    e.preventDefault()

    const { dispatch } = this.props
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (re.test(this.refBuscar.current.value.toLowerCase())) {
      this.setState({
        email: true
      })
      dispatch(fetchOtroUsuario({ email: this.refBuscar.current.value }))
    } else {
      this.setState({
        email: false
      })
      dispatch(fetchOtroUsuario({ first_name: this.refBuscar.current.value }))
    }
  }

  render () {
    const { usuario, usuarioError, usuariosBuscados, usuariosBuscadosTerminado, contactos, notificaciones } = this.props
    if (!usuario && usuarioError) {
      return <div><h5><Trans>Error: necesitas <a href={SERVER_DOMAIN + '/usuario/entrar/'}>iniciar sesion</a></Trans></h5></div>
    }
    return (
      <PublicBody>
        <HeaderPublico user={usuario} notificaciones={notificaciones} />
        <div className='container mt-3 contacto-add'>
          <div className='row mb-4'>
            <div className='col-12'>
              <Link className='btn btn-secondary' to='/contactos'>
                <i className='fas fa-chevron-left mr-2' />
                <Trans>Volver a Mis contactos</Trans>
              </Link>
            </div>
          </div>
          <div className='row'>
            <div className='col-12'>
              <form onSubmit={this.onSubmitBuscar.bind(this)}>
                <div className='form-group'>
                  <label htmlFor='buscador-contactos'><Trans>Busca usuario</Trans></label>
                  <input ref={this.refBuscar} type='text' className='form-control' id='buscador-contactos' />
                  <small className='form-text text-muted'>
                    <Trans>Puedes introducir su nombre de usuario o su email completo</Trans>
                  </small>
                </div>
                <div className='col-12 d-flex justify-content-end mb-2'>
                  <button className='btn btn-secondary'><Trans>Buscar</Trans></button>
                </div>
              </form>
            </div>
          </div>
          {usuariosBuscadosTerminado && usuariosBuscados.filter(u => u.id !== usuario.id).length === 0 &&
            <div className='row'>
              <div className='col-12 alert alert-danger mt-5' role='alert'>
                {this.state.email
                  ? <Trans>No existe ningún usuario registrado con ese email</Trans>
                  : <Trans>No existe ninguna coincidencia</Trans>}
              </div>
            </div>}
          {usuariosBuscadosTerminado && usuariosBuscados.filter(u => u.id !== usuario.id).map(u =>
            <div key={u.id} className='row bg-light p-1'>
              <div className='col-12'>
                <FichaContacto usuario={u} yo={usuario} contactos={contactos} />
              </div>
            </div>
          )}
        </div>
      </PublicBody>
    )
  }
}

const mapStateToProps = state => ({
  usuario: state.usuario.data,
  usuarioError: state.usuario.error,
  usuariosBuscados: state.usuariosBuscados.data,
  usuariosBuscadosTerminado: state.usuariosBuscados.status === REQUEST_STATUS.SUCCEEDED,
  contactos: state.contactos.data,
  notificaciones: state.notificaciones.data
})

export default connect(mapStateToProps)(ContactoAddScreen)
