import { ACTION_TYPES } from '../actions/recorrido'
import { initialState, REQUEST_STATUS } from '../constants/state'
import polyline from '@mapbox/polyline'
import * as turf from '@turf/turf'

export function recorridosReducer (state = initialState.recorridos, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_RECORRIDOS_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: [],
        error: null
      }
    case ACTION_TYPES.FETCH_RECORRIDOS_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.recorridos
      }
    case ACTION_TYPES.FETCH_RECORRIDOS_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return state
  }
}

export function recorridoReducer (state = initialState.recorrido, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_RECORRIDO_BEGIN:
      return {
        ...state,
        status: REQUEST_STATUS.LOADING,
        data: null,
        error: null
      }
    case ACTION_TYPES.FETCH_RECORRIDO_SUCCESS: {
      const data = action.recorrido
      if (data && data.sectores.length) {
        data.sectores.forEach(sec => {
          if (sec.trayectos) {
            sec.trayectos.forEach(tr => {
              const line = polyline.decode(tr.linea_codificada)
              tr.coordenadas = turf.lineString(line).geometry
              const lineInterpolada = polyline.decode(tr.linea_interpolada_codificada).map((pt, i) =>
                [...pt, tr.elevaciones[i]])
              tr.coordenadas_interpoladas = turf.lineString(lineInterpolada).geometry
            })
          }
        })
      }
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: data
      }
    }
    case ACTION_TYPES.FETCH_RECORRIDO_ERROR:
      return {
        ...state,
        status: REQUEST_STATUS.FAILED,
        error: action.error
      }
    default:
      return state
  }
}
