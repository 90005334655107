import { initialState, REQUEST_STATUS } from '../constants/state'
import { ACTION_TYPES } from '../actions/usuario'

export function usuarioReducer (state = initialState.usuario, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_USUARIO_BEGIN:
      return {
        ...state,
        pendiente: true,
        error: null,
        data: null
      }
    case ACTION_TYPES.FETCH_USUARIO_SUCCESS:
      return {
        ...state,
        data: action.data,
        pendiente: false
      }
    case ACTION_TYPES.FETCH_USUARIO_ERROR:
      return {
        ...state,
        error: action.error,
        data: null,
        pendiente: false
      }
    default :
      return {
        ...state
      }
  }
}

export function usuariosBuscadosReducer (state = initialState.usuariosBuscados, action) {
  switch (action.type) {
    case ACTION_TYPES.FETCH_OTRO_USUARIO_BEGIN:
      return {
        ...state,
        data: [],
        error: null,
        status: REQUEST_STATUS.LOADING
      }

    case ACTION_TYPES.FETCH_OTRO_USUARIO_ERROR:
      return {
        ...state,
        error: action.error,
        status: REQUEST_STATUS.ERROR
      }

    case ACTION_TYPES.FETCH_OTRO_USUARIO_SUCCESS:
      return {
        ...state,
        status: REQUEST_STATUS.SUCCEEDED,
        data: action.usuario
      }

    default:
      return {
        ...state
      }
  }
}
