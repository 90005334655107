import React from 'react'
import Carousel, { slidesToShowPlugin, arrowsPlugin } from '@brainhubeu/react-carousel'
import { Trans } from 'react-i18next'

import '@brainhubeu/react-carousel/lib/style.css'
import './SliderPdis.css'

const SliderPdis = props => {
  const arrowConfiguration = {
    resolve: arrowsPlugin,
    options: {
      arrowLeft: <button className='btn rounded-0'><i className='fa fa-chevron-left' /></button>,
      arrowLeftDisabled: <button className='btn disabled rounded-0'><i className='fa fa-chevron-left' /></button>,
      arrowRight: <button className='btn rounded-0'><i className='fa fa-chevron-right' /></button>,
      arrowRightDisabled: <button className='btn disabled rounded-0'><i className='fa fa-chevron-right' /></button>,
      addArrowClickHandler: true
    }
  }
  const puntos = props.pdis.concat(props.pks.map(pk => pk.punto))
  return (
    <Carousel
      value={props.perfil.indexPdiSeleccionado}
      onChange={val => props.onChangeSlider(val)}
      plugins={[
        'centered',
        {
          resolve: slidesToShowPlugin,
          options: {
            numberOfSlides: 7
          }
        },
        arrowConfiguration
      ]}
      breakpoints={{
        720: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 2
              }
            },
            arrowConfiguration,
            'centered'
          ]
        },
        1024: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 3
              }
            },
            arrowConfiguration,
            'centered'
          ]
        },
        1450: {
          plugins: [
            {
              resolve: slidesToShowPlugin,
              options: {
                numberOfSlides: 5
              }
            },
            arrowConfiguration,
            'centered'
          ]
        }
      }}
    >
      {props.perfil.pdiOrden.map((idPt, i) => {
        const pt = puntos.filter(pt => pt.id === idPt)
        return pt.length > 0 && (
          <div
            className={'card slider-pdis__slide rounded-0' + (props.perfil.indexPdiSeleccionado === i ? ' slider-pdis__slidev--seleccionado' : '')}
            key={idPt}
            onClick={() => props.onChangeSlider(i)}
          >
            <div className='card-body p-2'>
              <h6 className='card-title font-weight-bold m-0'>
                {pt[0].nombre}
              </h6>
              <div className='card-text slider-pdis__distancias text-lowercase'>
                <strong>
                  <i className='mdi mdi-format-vertical-align-top mr-1' />
                  {props.getAltiudPunto(pt[0]).toFixed(0) + ' m'}
                </strong> &nbsp;
                <i className='mdi mdi-arrow-expand-horizontal mr-1' />
                {props.getPasosDistanciasPdi(pt[0]).map((dist, i) => i === 0 ? dist : ' - ' + dist)}
              </div>
              <div className='slider-pdis__list-serv mt-1 text-center'>
                {pt[0].servicios.length <= 5
                  ? (
                    <>
                      {pt[0].servicios.map(s =>
                        <div
                          key={i + '-' + s.id}
                          className='slider-pdis__servicio mr-1'
                          style={{
                            backgroundColor: s.color_fondo,
                            color: s.color_icono
                          }}
                        >
                          <i className={s.icono.prefijo_fuente + ' ' + s.icono.prefijo_fuente + '-' + s.icono.icono} />
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {pt[0].servicios.slice(0, 4).map(s =>
                        <div
                          key={i + '-' + s.id}
                          className='slider-pdis__servicio mr-1'
                          style={{
                            backgroundColor: s.color_fondo,
                            color: s.color_icono
                          }}
                        >
                          <i className={s.icono.prefijo_fuente + ' ' + s.icono.prefijo_fuente + '-' + s.icono.icono} />
                        </div>
                      )}
                      <div
                        key={i + '-' + pt[0].id}
                        className='slider-pdis__servicio slider-pdis__servicio--mas mr-1'
                        onClick={() => props.onClickPdiDetalle(pt[0])}
                      >
                        <i className='fas fa-plus' />
                      </div>
                    </>
                  )}
              </div>
              {(pt[0].servicios.length > 5 || pt[0].descripcion.length > 95 || pt[0].imagen || pt[0].url_embebida) &&
                <div className='card-text slider-pdis__descripcion mt-1'>
                  <span className='mr-1'>{pt[0].descripcion.substring(0, 95) + (pt[0].descripcion.length > 95 ? ' ...' : '')}</span>
                  <button className='btn btn-sm py-0 px-1 slider-pdis__btn-mas' onClick={() => props.onClickPdiDetalle(pt[0])}>
                    <i className='fas fa-plus-circle mr-1' /><Trans>Ver más</Trans>
                  </button>
                </div>}
            </div>
          </div>
        )
      })}
    </Carousel>
  )
}

export default SliderPdis
