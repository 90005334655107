import React from 'react'
import { Trans } from 'react-i18next'
import { DataSearch } from '@appbaseio/reactivesearch'
import './HerramientasBusqueda.css'

export default function HerramientasBusqueda (props) {
  // console.info(ALGOLIA)
  return (
    <div className='herramientas-busqueda pt-0 pt-md-3'>
      <div className='row d-flex justify-content-between justify-content-sm-center'>
        <div className='col-2 col-md-1 d-flex justify-content-start justify-content-md-end'>
          <button
            type='button'
            className='btn btn-dark form-group boton-filtros'
            onClick={props.handleMostrarFiltros}
          >
            <i className='fas fa-sliders-h fa-inverse' />
            <div className='texto-boton'><Trans>Filtros</Trans></div>
          </button>
        </div>
        <div className='col-10 col-md-10'>
          <div className='form-group'>
            <label className='col-form-label '>
              <Trans>Buscar</Trans>
            </label>
            <div className=''>

              <DataSearch
                componentId='searchbox'
                dataField={['nombre', 'descripcion']}
                autoFocus
                autosuggest={false}
                fuzziness='AUTO'
                showClear
                showVoiceSearch
                placeholder='Busca'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
