import React from 'react'
import { Trans } from 'react-i18next'

export const SubmenuPdis = React.forwardRef((props, ref) => {
  return (
    <div className='mapa-submenu sub-heramientas aparece-abajo' ref={ref}>
      <ul className='btn-group boton-visor__group'>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={props.onClickMostrarPdisProyecto}>
            <i className='fas fa-map-marker-alt' />
            <span className='btn__texto'>
              <Trans>Lista PDIs Evento</Trans>
            </span>
          </button>
        </li>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={props.onClickMostrarPdisRecorrido}>
            <i className='rmp-pr' />
            <span className='btn__texto'>
              <Trans>Lista PDIs Recorrrido</Trans>
            </span>
          </button>
        </li>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={props.onClickMapaOcultarPdisProyecto}>
            {props.visor && props.visor.mapaPdisProyectoVisible ? (
              <>
                <span className='fa-stack fa-2x'>
                  <i className='fas fa-map-marker-alt fa-stack-1x' />
                  <i className='fas fa-ban fa-stack-2x' />
                </span>
                <span className='btn__texto'>
                  <Trans>Ocultar PDIs Evnt</Trans>
                </span>
              </>
            ) : (
              <>
                <i className='fas fa-map-marker-alt' />
                <span className='btn__texto'>
                  <Trans>Mostrar PDIs Evnt</Trans>
                </span>
              </>
            )}
          </button>
        </li>
        <li className='btn boton-visor boton-visor--small'>
          <button onClick={props.onClickMapaOcultarPdisRecorrido}>
            {props.visor && props.visor.mapaPdisRecorridoVisible ? (
              <>
                <span className='fa-stack fa-2x'>
                  <i className='fas rmp-pr fa-stack-1x' style={{ marginTop: 0.5 + 'em' }} />
                  <i className='fas fa-ban fa-stack-2x' />
                </span>
                <span className='btn__texto'>
                  <Trans>Ocultar PDIs Rec.</Trans>
                </span>
              </>
            ) : (
              <>
                <i className='rmp-pr' />
                <span className='btn__texto'>
                  <Trans>Mostrar PDIs Rec.</Trans>
                </span>
              </>
            )}
          </button>
        </li>
      </ul>
    </div>
  )
})

export default SubmenuPdis
