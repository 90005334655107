import React from 'react'
import { Link } from 'react-router-dom'
import MapaModal from './MapaModal'

import './MapaModalPerfiles.css'

export default function MapaModalPerfiles (
  { titulo, proyecto, recorrido, sectores, handleClickCerrar, onClickPerfil, className, buildLinkPerfil }
) {
  if (!buildLinkPerfil) {
    buildLinkPerfil = (proyectoId, recorridoId, sectorId) => {
      if (sectorId) {
        return `/mapa/${recorridoId}/perfil/${sectorId}`
      }
      return `/mapa/${recorridoId}/perfil`
    }
  }

  return (
    <MapaModal titulo={titulo} handleClickCerrar={handleClickCerrar} className={className}>
      <table className='table-perfil-resumen'>
        <tbody>
          {sectores.filter(s => s.perfil_publicado).map(sector => {
            const alturaMaxima = Math.round(Math.max(
              ...sector.esquemas.map(e => e.trayecto.altura_maxima)
            ))
            const alturaMinima = Math.round(Math.min(
              ...sector.esquemas.map(e => e.trayecto.altura_minima)
            ))
            const desnivelPositivo = Math.round(
              sector.esquemas
                .map(e => e.trayecto.desnivel_acumulado_positivo)
                .reduce((a, b) => a + b)
            )
            const desnivelNegativo = Math.round(
              sector.esquemas
                .map(e => e.trayecto.desnivel_acumulado_negativo)
                .reduce((a, b) => a + b)
            )

            const perfilLink = '/perfil/' + sector.id + '-' + recorrido.slug

            return (
              <tr key={sector.id}>
                <td className='perfil-link'>

                  <Link to={perfilLink}>
                    <b>{sector.nombre}</b> &nbsp; | &nbsp;
                    {Math.round((sector.distancia_metros) / 1000)}
                    <span className='text-lowercase'> km</span>
                  </Link>
                </td>
                <td>
                  <Link to={perfilLink}>
                    <b style={{ fontSize: '1.2em' }}>
                      <span className='fa-stack fa-rotate-180'>
                        <i className='fas fa-arrow-down fa-stack-1x' />
                        <i className='fas fa-minus fa-pull-bottom fa-stack-1x' />
                      </span>
                    </b> &nbsp;
                    {alturaMaxima}
                    <span className='text-lowercase'>m</span>
                  </Link>
                </td>
                <td>
                  <Link to={perfilLink}>
                    <b style={{ fontSize: '1.2em' }}>
                      <span className='fa-stack'>
                        <i className='fas fa-arrow-down fa-stack-1x' />
                        <i className='fas fa-minus fa-pull-bottom fa-stack-1x' />
                      </span>
                    </b> &nbsp;
                    {alturaMinima}
                    <span className='text-lowercase'>m</span>
                  </Link>
                </td>
                <td>
                  <Link to={perfilLink}>
                    <b style={{ fontSize: '1.2em' }}>
                      <i className='fas fa-long-arrow-alt-up fa-rotate-45' data-fa-transform='rotate-45' />
                    </b> &nbsp;&nbsp;
                    {desnivelPositivo}
                    <span className='text-lowercase'>m</span>
                  </Link>
                </td>
                <td>
                  <Link to={perfilLink}>
                    <b style={{ fontSize: '1.2em' }}>
                      <i className='fas fa-long-arrow-alt-right fa-rotate-45' data-fa-transform='rotate-45' />
                    </b> &nbsp;&nbsp;
                    {desnivelNegativo}
                    <span className='text-lowercase'>m</span>
                  </Link>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </MapaModal>
  )
}
